import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { FaTasks, FaCheckCircle, FaPause } from "react-icons/fa";
import { FcCancel } from "react-icons/fc";
import { MdOutlineFlag } from "react-icons/md";
import { IoIosArrowForward } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import { fetchApi } from "../../../utlis/axios";
import Loader from "../../Loader/Loader";
import { IoIosArrowBack } from "react-icons/io";
import RichTextEditor from "./RichTextEditor";
import RichTextEditor1 from "./RichTextEditor1";
import { addToast } from "../../../Redux/Slices/toast-slice";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import Calendar from "./Calendar";

const ProjectDetail = ({ }) => {
  const { theme } = useSelector((state) => state.theme);
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const { userInfo } = useSelector((state) => state.auth);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState("in-progress");
  const [milestones, setMilestones] = useState([]);
  const [isDropdownOpen1, setIsDropdownOpen1] = useState(false);
  const [selectedOption1, setSelectedOption1] = useState("in-progress");
  const [isModalOpen, setShowModal] = useState(false);
  const [creating, setCreating] = useState(false);
  const [milstoneData, setMileStoneData] = useState({
    title: "",
    amount: "",
    description: "",
    status: selectedOption1,
    project: id,
    started_at: '',
    completed_at: ''
  });
  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const [startDateDropdown, setStartDateDropdown] = useState(false);
  const [endDateDropdown, setEndDateDropdown] = useState(false)
  const [editorContent, setEditorContent] = useState('');
  const [updating, setUpdating] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [mdeleting, setMDeleting] = useState(false);
  const [mileStoneState, setMileStoneState] = useState("new");
  const [mileStoneId, setMileStoneId] = useState("");
  const options = [
    { label: "canceled", icon: <FcCancel className="w-4 h-4 mr-2" /> },
    { label: "paused", icon: <FaPause className="w-4 h-4 mr-2" /> },
    { label: "in-progress", icon: <FaTasks className="w-4 h-4 mr-2" /> },
    { label: "completed", icon: <FaCheckCircle className="w-4 h-4 mr-2" /> },
  ];
  const [projectData, setProjectData] = useState({
    title: "",
    description: "",
    startDate: "",
    endDate: "",
    url: "",
    budget: "",
  });

  const fetchProject = async () => {
    setLoading(true);
    try {
      const res = await fetchApi.get(`projects/${id}/`, {
        headers: { Authorization: `Bearer ${userInfo.access_token}` },
      });
      if (res.status === 200) {
        const response = res.data;
        setProjectData({
          title: response.title,
          description: response.description,
          startDate: response.start_date,
          endDate: response.end_date,
          url: response.url,
          budget: response.total_budget,
        });
        setSelectedOption(response.status)
        setEditorContent(response.description)
      }
    } catch (error) {
      dispatch(addToast({ message: error.message, type: 'error' }));
    } finally {
      setLoading(false);
    }
  };
  const handleSaveDescription = async () => {
    setIsEditorOpen(false)
    setProjectData({ ...projectData, description: editorContent })
  }


  const getMilestones = async () => {
    try {
      const res = await fetchApi.get(`/project-milestones/?project=${id}`, {
        headers: { Authorization: `Bearer ${userInfo.access_token}` },
      });
      if (res.status === 200) {
        setMilestones(res.data.results);
      }
    } catch (error) {
      dispatch(addToast({ message: error.message, type: 'error' }));
    }
  };
  let isFetched = useRef(false);
  useEffect(() => {
    if (isFetched.current) {
      return;
    }
    isFetched.current = true;
    getMilestones();
    fetchProject();
  }, []);

  const handleSubmit = async (event) => {
    if (
      !milstoneData.title ||
      !milstoneData.amount ||
      !milstoneData.description
    ) {
      dispatch(addToast({ message: "Please fill all fields", type: 'warn' }));
      return;
    }
    setCreating(true);
    try {
      const mdata = new FormData();
      mdata.append("title", milstoneData.title);
      mdata.append("amount", milstoneData.amount);
      mdata.append("description", milstoneData.description);
      mdata.append("status", selectedOption1);
      mdata.append("project", id);
      if (milstoneData.started_at) {
        mdata.append("started_at", milstoneData.started_at)
      }
      if (milstoneData.completed_at) {
        mdata.append("completed_at", milstoneData.completed_at)
      }
      const res = await fetchApi.post("/project-milestones/", mdata, {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`, // Only include Authorization
        },
      });

      if (res.status === 201) {
        setMilestones((prev) => [...prev, res.data])
        getMilestones();
        dispatch(addToast({ message: 'Milestone created successfully', type: 'success' }));
        setMileStoneData({
          title: "",
          amount: "",
          description: "",
          status: selectedOption1,
        });
        setSelectedOption1('in-progress');
        setShowModal(false);
      }
    } catch (error) {
      dispatch(addToast({ message: error.message, type: 'error' }));
    } finally {
      setCreating(false);
    }
  };
  const handleUpdate = async (id) => {
    setCreating(true);
    try {
      const mdata = new FormData();
      mdata.append("title", milstoneData.title);
      mdata.append("amount", milstoneData.amount);
      mdata.append("description", milstoneData.description);
      mdata.append("status", selectedOption1);
      mdata.append("project", milstoneData.project);
      if (milstoneData.started_at) {
        mdata.append('started_at', milstoneData.started_at);
      }
      if (milstoneData.completed_at) {
        mdata.append('completed_at', milstoneData.completed_at)
      }
      const res = await fetchApi.put(`/project-milestones/${id}/`, mdata, {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`, // Only include Authorization
        },
      });

      if (res.status === 200) {
        getMilestones();
        setMileStoneState("new");
        dispatch(addToast({ message: 'Milestone updated successfully', type: 'success' }));
        setMileStoneData({
          title: "",
          amount: "",
          description: "",
          status: selectedOption1,
        });
        setSelectedOption1('in-progress')
        setShowModal(false);
      }
    } catch (error) {
      dispatch(addToast({ message: error.message, type: 'error' }));
    } finally {
      setCreating(false);
    }
  };
  function isValidURL(input) {
    try {
      new URL(input); // If it fails, it'll throw an error
      return true;
    } catch (err) {
      return false;
    }
  }
  const handleUpdateProject = async (e) => {
    if (
      !projectData.title ||
      !projectData.startDate
    ) {
      dispatch(addToast({ message: "Please fill all fields", type: 'warn' }));
      return;
    } if (projectData.url) {
      if (!isValidURL(projectData.url)) {
        dispatch(addToast({ message: 'Please enter a valid url', type: 'warn' }));
        return;
      }
    }
    setUpdating(true);
    try {
      const formdata = new FormData();
      formdata.append("title", projectData.title);
      formdata.append("description", projectData.description);
      formdata.append("start_date", projectData.startDate);
      formdata.append("end_date", projectData.endDate);
      if (projectData.url) {
        formdata.append("url", projectData.url);
      }
      formdata.append("budget", projectData.budget);
      formdata.append("status", selectedOption);
      formdata.append("company", userInfo?.company?.id);
      const res = await fetchApi.patch(`projects/${id}/`, formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });
      if (res.status === 200) {
        dispatch(addToast({ message: "Project update successfully", type: 'success' }));
      }
    } catch (error) {
      dispatch(addToast({ message: error.message, type: 'error' }));
    } finally {
      setUpdating(false);
    }
  };
  const handleDeleteProject = async (e) => {
    setDeleting(true);
    try {
      const res = await fetchApi.delete(`projects/${id}/`, {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });
      dispatch(addToast({ message: 'Project deleted successfully', type: 'success' }));
      navigate(-1);
    } catch (error) {
      dispatch(addToast({ message: error.message, type: 'error' }));
    } finally {
      setDeleting(false);
    }
  };
  const handleDeleteMilestone = async (id) => {
    setMDeleting(true);
    try {
      const res = await fetchApi.delete(`/project-milestones/${id}/`, {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });
      setMilestones((prevMilestones) =>
        prevMilestones.filter((milestone) => milestone.id !== id)
      );
      getMilestones()
      dispatch(addToast({ message: 'Milestone deletd successfully', type: 'success' }));
      setShowModal(false)
      setMileStoneData(
        {
          title: "",
          amount: "",
          description: "",
          status: selectedOption1,
        }
      )
      setMileStoneState('new')
    } catch (error) {
      dispatch(addToast({ message: error.message, type: 'error' }));
    } finally {
      setMDeleting(false);
    }
  };

  function timeDifference(dateString) {
    const givenDate = new Date(dateString); // Parse the given date
    const currentDate = new Date(); // Get the current date
    const diffInMilliseconds = currentDate - givenDate; // Difference in milliseconds

    // Calculate time differences
    const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));
    const diffInHours = Math.floor(diffInMilliseconds / (1000 * 60 * 60));
    const diffInDays = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));
    const diffInMonths = Math.floor(
      diffInMilliseconds / (1000 * 60 * 60 * 24 * 30)
    );
    const diffInYears = Math.floor(
      diffInMilliseconds / (1000 * 60 * 60 * 24 * 365)
    );

    if (diffInMinutes < 60) {
      return `${diffInMinutes} minutes ago`;
    } else if (diffInHours < 24) {
      return `${diffInHours} hours ago`;
    } else if (diffInDays < 30) {
      return `${diffInDays} days ago`;
    } else if (diffInMonths < 12) {
      return `${diffInMonths} months ago`;
    } else {
      return `${diffInYears} year${diffInYears > 1 ? "s" : ""} ago`;
    }
  }

  return (
    <div
      className={`w-full customFont ${theme === "dark" ? " text-white" : " text-black"}`}
    >
      <div className="flex w-full items-center justify-center">
        <div
          className="flex w-full gap-2 md:px-2 items-center"
          style={{
            width: "100%",
            maxWidth: "768px",
          }}
        >
          <button
            onClick={() => navigate(-1)}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "10px",
              fontSize: "20px",
              backgroundColor: "red",
              color: "#fff",
              border: "none",
              borderRadius: "50%",
              cursor: "pointer",
              outline: "none",
              zIndex: "9999",
            }}
          >
            <IoIosArrowBack />
          </button>
          <div
            className={` ${theme === "dark" ? "text-white" : "text-black"
              } max-w-3xl`}
            style={{
              fontSize: "x-large",
              margin: "20px 0px",
            }}
          >
            Project Details
          </div>
        </div>
      </div>
      <hr className="w-full" />
      {loading ? (
        <Loader style={{ height: "100%" }} />
      ) : (
        <div
          className={`mt-8 max-w-3xl mx-auto p-6  rounded-lg shadow-xl ${theme === 'dark' ? 'bg-darkMode' : 'bg-[#F7F7F7]'}`}
        >
          <input
            type="text"
            className={`text-2xl font-normal border-none p-2 rounded-md bg-transparent outline-none mt-6 mb-2 w-full ${theme === "dark"
              ? "placeholder:text-gray-600"
              : "placeholder:text-gray-400"
              } ${theme === "dark"
                ? "hover:bg-gradient-to-r hover:from-[#fff3] hover:to-[#fff0] transition-all"
                : "hover:bg-gradient-to-r hover:from-[#6d6d6d33] hover:to-[#fff0] transition-all"
              }`}
            value={projectData.title}
            onChange={(e) =>
              setProjectData({ ...projectData, title: e.target.value })
            }
            placeholder="Add title"
          />
          <div className="flex gap-4 my-4">
            <div className="w-1/2 flex flex-col">
              <label htmlFor="" className="font-bold px-2">
                Amount
              </label>
              <input
                type="number"
                className={`text-base font-normal border-none p-2 bg-transparent outline-none w-full ${theme === "dark"
                  ? "placeholder:text-gray-600"
                  : "placeholder:text-gray-400"
                  }`}
                value={projectData.budget}
                onChange={(e) =>
                  setProjectData({ ...projectData, budget: e.target.value })
                }
                placeholder="Budget"
              />
            </div>
            <div className="w-1/2 flex flex-col">
              <label htmlFor="" className="font-bold px-2">
                Project Url
              </label>
              <input
                type="text"
                className={`text-base font-normal p-2 border-none bg-transparent outline-none w-full ${theme === "dark"
                  ? "placeholder:text-gray-600"
                  : "placeholder:text-gray-400"
                  }`}
                value={projectData.url}
                onChange={(e) =>
                  setProjectData({ ...projectData, url: e.target.value })
                }
                placeholder="Project URL"
              />
            </div>
          </div>
          <div className="flex sm:flex-row flex-col items-center gap-4 my-4">
            <div className="sm:w-1/3 w-full">
              <label htmlFor="">Project Status</label>
              <div
                className={`relative w-full border border-gray-300 dark:border-gray-700 rounded-md p-2 cursor-pointer`}
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              >
                <div className="flex items-center">
                  {
                    options.find((option) => option.label === selectedOption)
                      ?.icon
                  }
                  <span className="ml-2 capitalize">{selectedOption}</span>
                </div>
                {isDropdownOpen && (
                  <div
                    className={`absolute top-full left-0 mt-2 w-full shadow-lg rounded-md z-10 ${theme === "dark" ? "bg-darkMode" : "bg-white"
                      }`}
                  >
                    {options.map((option) => (
                      <div
                        key={option.label}
                        className={`flex items-center px-3 py-2 cursor-pointer ${theme === "dark"
                          ? "hover:bg-gradient-to-r hover:from-[#fff3] hover:to-[#fff0] transition-all"
                          : "hover:bg-gradient-to-r hover:from-[#6d6d6d33] hover:to-[#fff0] transition-all"
                          }`}
                        onClick={() => {
                          setSelectedOption(option.label);
                          setIsDropdownOpen(false);
                        }}
                      >
                        {option.icon}
                        <span className="capitalize ml-2">{option.label}</span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div className="sm:w-1/3 w-full">
              <label htmlFor="Project Start date">Project Start Date</label>
              <input
                type="date"
                className={` w-full text-base bg-transparent outline-none placeholder-gray-400 dark:placeholder-gray-500 border border-gray-300 dark:border-gray-700 rounded-md p-2`}
                value={projectData.startDate}
                onChange={(e) =>
                  setProjectData({ ...projectData, startDate: e.target.value })
                }
              />
            </div>
            <div className="sm:w-1/3 w-full">
              <label htmlFor="">Project End date</label>
              <input
                type="date"
                className={` w-full text-base bg-transparent outline-none placeholder-gray-400 dark:placeholder-gray-500 border border-gray-300 dark:border-gray-700 rounded-md p-2`}
                value={projectData.endDate}
                onChange={(e) =>
                  setProjectData({ ...projectData, endDate: e.target.value })
                }
              />
            </div>
          </div>
          <label htmlFor="" className="font-bold mt-4 mb-1">
            Description
          </label>
          {isEditorOpen ? <RichTextEditor
            description={editorContent}
            setDescription={setEditorContent}
            setEditorOpen={setIsEditorOpen}
            handleSave={handleSaveDescription}
          /> :
            <div
              onClick={() => setIsEditorOpen(true)}
              className={` cursor-pointer mt-1 outline-none rounded-md p-2 ${theme === "dark"
                ? "hover:bg-gradient-to-r hover:from-[#fff3] hover:to-[#fff0] transition-all"
                : "hover:bg-gradient-to-r hover:from-[#6d6d6d33] hover:to-[#fff0] transition-all"
                }`}
              dangerouslySetInnerHTML={{
                __html: projectData.description || "Click to add a description",
              }}
            ></div>
          }
          <div className="flex gap-2 my-2">
            <button
              className=" heroButton"
              onClick={handleUpdateProject}
              disabled={updating}
            >
              {updating ? "Updating..." : "Update"}
            </button>
            <button
              className="heroButton"
              onClick={handleDeleteProject}
              disabled={deleting}
            >
              {deleting ? "Deleting..." : "Delete"}
            </button>
          </div>
          <div className="my-6">
            <h3 className="text-lg font-semibold mb-4">Milestones</h3>
            {milestones.map((milestone) => (
              <div
                key={milestone.id}
                className={`flex items-center justify-between p-4 rounded-md mb-2 cursor-pointer ${theme === "dark" ? "bg-dashboardDarkMode" : "bg-white"
                  } ${theme === "dark"
                    ? "hover:bg-gradient-to-r hover:from-[#fff3] hover:to-[#fff0] transition-all"
                    : "hover:bg-gradient-to-r hover:from-[#6d6d6d33] hover:to-[#fff0] transition-all"
                  }`}
                onClick={() => {
                  setMileStoneState("update");
                  setMileStoneId(milestone?.id);
                  setMileStoneData({
                    title: milestone?.title,
                    description: milestone.description,
                    amount: milestone.amount,
                    status: milestone.status,
                    project: milestone?.project.id,
                    started_at: milestone?.started_at,
                    completed_at: milestone?.completed_at,
                  });
                  setSelectedOption1(milestone?.status)
                  setShowModal(true);
                }}
              >
                <span className="flex items-center gap-2">
                  <MdOutlineFlag className="w-6 h-6" />
                  <h4 className="text-lg font-semibold">{milestone?.title}</h4>
                </span>
                <span className="text-gray-600 font-normal text-base">
                  {timeDifference(milestone?.created_at)}
                </span>
              </div>
            ))}
          </div>
          <button
            className="heroButton"
            onClick={() => {
              setShowModal(true);
              setMileStoneState("new");
            }}
          >
            Add Milestone
          </button>

          {isModalOpen && (
            <>
              <div className="fixed inset-0 bg-black bg-opacity-50 z-10"></div>

              <div className="fixed inset-0 flex items-center justify-center z-20 ">
                <div
                  className={` p-6 rounded-lg shadow-lg max-w-screen-sm w-full max-h-[90vh] ${theme === "dark"
                    ? "bg-darkMode text-white"
                    : "bg-lightMode text-black"
                    }`}
                >
                  <div className="flex justify-between">
                    <div className="flex gap-2 items-center">
                      <IoIosArrowForward className="w-4 h-4" />
                      <h2 className="text-base font-semibold">
                        {mileStoneState === "new"
                          ? "New Milestone"
                          : "Edit Milestone"}
                      </h2>
                    </div>
                    <IoClose
                      className="w-6 h-6"
                      onClick={() => {
                        setShowModal(false);
                        setMileStoneState('new')
                        setMileStoneData({
                          title: '',
                          description: '',
                          amount: '',
                        })
                        setSelectedOption1('in-progress')
                      }}
                    />
                  </div>
                  <div className="flex flex-col w-full mt-4">
                    <label htmlFor="" className="font-semibold px-2 mt-4">
                      Title
                    </label>
                    <input
                      type="text"
                      className={`text-2xl font-normal border-none p-2 rounded-md bg-transparent outline-none w-full ${theme === "dark"
                        ? "placeholder:text-gray-600"
                        : "placeholder:text-gray-400"
                        } ${theme === "dark"
                          ? "hover:bg-gradient-to-r hover:from-[#fff3] hover:to-[#fff0] transition-all"
                          : "hover:bg-gradient-to-r hover:from-[#6d6d6d33] hover:to-[#fff0] transition-all"
                        }`}
                      value={milstoneData.title}
                      onChange={(e) =>
                        setMileStoneData({
                          ...milstoneData,
                          title: e.target.value,
                        })
                      }
                      placeholder="add title"
                    />
                    <label htmlFor="" className="font-semibold mt-4">
                      Amount
                    </label>
                    <input
                      type="text"
                      className={`text-base font-normal border-none p-2 rounded-md bg-transparent outline-none mt-1 w-full ${theme === "dark"
                        ? "placeholder:text-gray-600"
                        : "placeholder:text-gray-400"
                        } ${theme === "dark"
                          ? "hover:bg-gradient-to-r hover:from-[#fff3] hover:to-[#fff0] transition-all"
                          : "hover:bg-gradient-to-r hover:from-[#6d6d6d33] hover:to-[#fff0] transition-all"
                        }`}
                      value={milstoneData.amount}
                      onChange={(e) =>
                        setMileStoneData({
                          ...milstoneData,
                          amount: e.target.value,
                        })
                      }
                      placeholder="add amount"
                    />
                    <label htmlFor="" className="font-semibold px-2 mt-4">
                      Status
                    </label>
                    <div className="flex relative gap-4 mt-1 w-full">
                      <div className="flex flex-col gap-1 w-full">
                        <div
                          className={`flex w-full items-center justify-between border rounded-md px-2 text-base py-2 cursor-pointer ${theme === "dark"
                            ? "border-gray-600"
                            : "border-gray-500"
                            }`}
                          onClick={() => setIsDropdownOpen1(!isDropdownOpen1)}
                        >
                          <div className="flex items-center gap-1">
                            {
                              options.find(
                                (option) => option.label === selectedOption1
                              )?.icon
                            }
                            <span className=" capitalize">
                              {selectedOption1}
                            </span>
                          </div>
                        </div>
                      </div>

                      {isDropdownOpen1 && (
                        <div
                          className={`absolute top-full left-0 mt-2 w-full shadow-lg rounded-md z-10 ${theme === "dark"
                            ? "bg-dashboardDarkMode text-white"
                            : "bg-lightMode text-black"
                            }`}
                        >
                          {options.map((option) => (
                            <div
                              key={option.label}
                              className={`flex items-center px-3 py-2 cursor-pointer ${theme === "dark"
                                ? "hover:bg-gradient-to-r hover:from-[#fff3] hover:to-[#fff0] transition-all"
                                : "hover:bg-gradient-to-r hover:from-[#6d6d6d33] hover:to-[#fff0] transition-all"
                                }`}
                              onClick={() => {
                                setSelectedOption1(option.label);
                                setIsDropdownOpen1(false);
                              }}
                            >
                              {option.icon}
                              <span className="capitalize">{option.label}</span>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                    <div className="flex gap-2 w-full mt-4">
                      <div className="flex gap-1 items-center w-1/2">
                        <label htmlFor="start date" className=" whitespace-nowrap">
                          Start Date:
                        </label>
                        <div
                          className={`cursor-pointer relative bg-transparent w-full flex justify-between items-center outline-none border px-2 py-2 rounded-md ${theme === "dark" ? "border-gray-600" : "border-gray-400"
                            }`}
                        >
                          <span
                            className="w-full flex items-center justify-between"
                            onClick={() => {
                              setStartDateDropdown(!startDateDropdown);
                              setEndDateDropdown(false);
                            }}
                          >
                            {milstoneData.started_at
                              ? milstoneData.started_at
                              : "Choose date"}{" "}
                            <button>
                              {startDateDropdown ? (
                                <FaChevronUp className="w-4 h-4 " />
                              ) : (
                                <FaChevronDown className="w-4 h-4" />
                              )}
                            </button>
                          </span>
                          {startDateDropdown && (
                            <Calendar
                              theme={theme}
                              onDateSelect={(date) => {
                                milstoneData.started_at = date;
                                setStartDateDropdown(false);
                              }}
                            />
                          )}
                        </div>
                      </div>
                      <div className="flex gap-1 items-center w-1/2">
                        <label htmlFor="End Date" className="whitespace-nowrap">
                          End Date:
                        </label>
                        <div
                          className={`relative cursor-pointer bg-transparent w-full outline-none border px-2 py-2 rounded-md ${theme === "dark" ? "border-gray-600" : "border-gray-400"
                            }`}
                        >
                          <span
                            className="w-full flex items-center justify-between"
                            onClick={() => {
                              setEndDateDropdown(!endDateDropdown);
                              setStartDateDropdown(false);
                            }}
                          >
                            {milstoneData.completed_at
                              ? milstoneData.completed_at
                              : "Choose date"}{" "}
                            <button>
                              {endDateDropdown ? (
                                <FaChevronUp className="w-4 h-4 " />
                              ) : (
                                <FaChevronDown className="w-4 h-4" />
                              )}
                            </button>
                          </span>
                          {endDateDropdown && (
                            <Calendar
                              theme={theme}
                              onDateSelect={(date) => {
                                milstoneData.completed_at = date;
                                setEndDateDropdown(false);
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <label htmlFor="" className="font-semibold mt-4">
                      Description
                    </label>
                    <RichTextEditor1 setProjectData={setMileStoneData} projectData={milstoneData} />
                    <div className="flex gap-2 justify-end pt-4 mt-6">
                      <button
                        className={` border rounded-3xl px-3 py-2 bg-transparent  ${theme === "dark"
                          ? "border-gray-700"
                          : "border-gray-500"
                          }`}
                        onClick={() => {
                          setShowModal(false);
                          setMileStoneState('new')
                          setMileStoneData({
                            title: '',
                            description: '',
                            amount: '',
                          })
                          setSelectedOption1('in-progress')
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        className="heroButton"
                        disabled={creating}
                        onClick={() => {
                          if (mileStoneState === "new") {
                            handleSubmit();
                          } else {
                            handleUpdate(mileStoneId);
                          }
                        }}
                      >
                        {creating
                          ? mileStoneState === "new"
                            ? "Creating..."
                            : "Updating..."
                          : mileStoneState === "new"
                            ? "Create"
                            : "Update"}
                      </button>
                      {mileStoneState !== "new" &&
                        <button className="heroButton" onClick={() => handleDeleteMilestone(mileStoneId)} disabled={mdeleting}>{mdeleting ? 'Deleting...' : 'Delete'}</button>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default ProjectDetail;
