import React from "react";
import { MdSecurity } from "react-icons/md";
import {
  AiOutlineBarChart,
  AiOutlineAlert,
  AiOutlineTool,
} from "react-icons/ai";
import workimg from "../../../assets/howitwork.png";
import styles from "./styles.module.css";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import AnimationLottie from "../../../lottie/animation-lottie";
import animation from "../../../lottie/howitWorksAnimation.json";
import { FaRegChartBar } from "react-icons/fa";
import { AiOutlineFieldTime } from "react-icons/ai";
import { GoProjectRoadmap } from "react-icons/go";

const HowItWorks = () => {
  const { theme } = useSelector((state) => state.theme);
  const [selectedTab, setSelectedTab] = React.useState(1);
  return (
    <div className={`${styles.howItWorks} mt-24 md:px-[66px] px-4`}>
      <div className="container">
        <div className={styles.intro}>
          <div className={styles.introText}>
            <h1 className="main-heading">How It Works</h1>
            <p className={styles.description}>
              BugTrackin efficiently tracks work hours, captures periodic
              activity screenshots, and provides detailed reports. Admins can
              manage user profiles, approve requests, and announce company
              holidays. The platform also streamlines project management,
              offering clear views of tasks and progress. Generate reports and
              manage financials effortlessly, ensuring smooth operations for
              remote teams.
            </p>
            <Link to="/subscriptionplan">
              {" "}
              <button className="heroButton">Get Started Now</button>
            </Link>
          </div>
          <div className={styles.introImage}>
            <AnimationLottie animationPath={animation} />
          </div>
        </div>
        <div className="flex gap-4 w-full items-center justify-between md:flex-row flex-col">
          <div className="flex flex-col gap-5 md:w-1/2 w-full">
            {steps.map((step) => (
              <div
                className={`flex gap-2 items-center cursor-pointer ${
                  selectedTab === step.id ? "text-red-700" : "text-[#555657]"
                }`}
                onClick={() => setSelectedTab(step.id)}
                key={step.id}
              >
                <step.icon className={`  w-10 h-10`} />
                <h2
                  className={`text-xl font-bold  ${
                    selectedTab === step.id
                      ? theme === "dark"
                        ? "text-white"
                        : "text-black"
                      : "text-[#555657]"
                  }`}
                >
                  {step.title}
                </h2>
              </div>
            ))}
          </div>
          <div
            className={`${
              selectedTab % 2 === 0
                ? "bg-gradient-to-b from-[#CC0927] to-[#7E0105] text-white border-[#556557]"
                : theme === "dark"
                ? "bg-[#0F0F0F] border-red-700 text-white"
                : "bg-[#F5F5F5] border-[#556557] text-black"
            } rounded-tl-[35px] rounded-bl-[35px] rounded-br-[35px] rounded-tr-[100px] border max-w-[400px]  md:w-1/2 w-full min-h-[350px] flex flex-col justify-center items-center`}
          >
            <div className="flex w-full pt-6 pb-6 px-6 justify-between items-center">
              <h1 className="text-2xl font-medium ">
                {steps.find((step) => step.id === selectedTab).title}
              </h1>
              {React.createElement(
                steps.find((step) => step.id === selectedTab).icon,
                {
                  className: `w-12 h-12 `,
                }
              )}
            </div>
            <p className="mt-2 px-6 pb-4 text-base ">
              {steps.find((step) => step.id === selectedTab).description}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const steps = [
  {
    id: 1,
    icon: MdSecurity,
    title: "User Authentication",
    description:
      "BugTrackIn provides secure role-based access control (RBAC), ensuring users only access relevant data based on their roles. With encrypted logins, multi-factor authentication (MFA), and session management, it prevents unauthorized access while enhancing security and compliance for remote teams. ",
  },
  {
    id: 2,
    icon: FaRegChartBar,
    title: "Analytics and Charts",
    description:
      "BugTrackIn empowers remote teams with comprehensive analytics and visually engaging charts. Track key performance metrics, monitor project budgets, and analyze team productivity through dynamic bar, line, and pie charts. These insights enable admins to make informed decisions, improve workflows, and ensure project success.",
  },
  {
    id: 3,
    icon: AiOutlineFieldTime,
    title: "Track Working Hours",
    description:
      "BugTrackIn simplifies work hour tracking for remote teams with automated logs, manual adjustments, and detailed productivity reports. It provides transparency and accountability by capturing time data and activity patterns, empowering admins to refine workflows and boost team efficiency.",
  },
  {
    id: 4,
    icon: GoProjectRoadmap,
    title: "Manage Projects & Teams ",
    description:
      "BugTrackIn provides effective tools to manage projects and remote teams with ease. Organize tasks, monitor milestones, and track progress effortlessly. Admins can delegate responsibilities, oversee workflows, and ensure team alignment, fostering collaboration and successful project delivery.",
  },
];

export default HowItWorks;
