import { useState } from "react";
import { useSelector } from "react-redux";
import { FiRefreshCw } from "react-icons/fi";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import loader from '../../../../assets/loading-gif.gif'
import { FaSearchDollar } from "react-icons/fa";

const FinanceOverview = ({ data, totalPaid, totalPaidThisYear, getData }) => {
  const { theme } = useSelector((state) => state.theme);
  const [isRefreshing, setIsRefreshing] = useState(false);

  const financeData = [
    { id: 1, title: "Total Payments to BugTrackIn", value: totalPaid, percentage: 97, color: "#A5D6A7" },
    { id: 2, title: "Total Paid This Year", value: data ? totalPaidThisYear : 0, percentage: 20, color: "#FFCC80" },
    { id: 3, title: "Total Paid Salaries", value: data ? data?.total_paid_salaries : 0, percentage: 50, color: "#FFAB91" },
    {
      id: 4, title: "Total Unpaid Salaries", value: data ? data?.total_unpaid_salaries
        : 0, percentage: 25, color: "#B39DDB"
    },
  ];

  const handleRefresh = async () => {
    try {
      setIsRefreshing(true);
      await getData();
    } catch (err) {

    } finally {
      setIsRefreshing(false);
    }

  }

  return (
    <div className={`rounded-[20px] shadow-lg p-4 ${theme === "dark" ? "bg-[#1C1C1E] text-white" : "bg-white text-black"}`}>
      {/* ... (rest of your header code remains the same) ... */}
      <div className='flex justify-between items-center'>
        <div className='flex gap-2 items-center'>
          <span className='bg-gradient-to-r from-[#CC0927] to-[#7E0105] p-2 rounded-[10px]'>
            <FaSearchDollar className='w-6 h-6 text-white' />
          </span>
          <h1 className='text-lg font-semibold fontPoppins'>Finance OverView</h1>
        </div>
        <div
          className={`p-2 flex items-center justify-center rounded-[10px] border cursor-pointer ${theme === 'dark' ? 'bg-[#252529] border-[#555657]' : 'bg-[#F2F4FA] border-[#D8D8D8]'}`}
        >
          {isRefreshing ? (
            <img src={loader} alt="Loading..." className="w-5 h-5" />
          ) : (
            <FiRefreshCw className={`w-5 h-5`} onClick={handleRefresh} />
          )}
        </div>
      </div>

      <Swiper
        modules={[Pagination]}
        spaceBetween={20}
        slidesPerView={1.2}
        pagination={{ clickable: true }}
        breakpoints={{
          640: { slidesPerView: 1.3 },
          768: { slidesPerView: 2.3 },
          1024: { slidesPerView: 2 },
          1340: { slidesPerView: 3 },
          1520: { slidesPerView: 4 },
        }}
        className="mt-4"
      >
        {financeData.map((item) => (
          <SwiperSlide key={item.id}>
            <div className={`p-4 fontPoppins border rounded-[20px] flex gap-4 items-center ${theme === "dark" ? "bg-[#212529] border-[#555657] text-white" : "bg-[#F2F4F7] border-[#D8D8D8] text-black"}`}>
              <div>
                <h4 className="text-base font-semibold whitespace-nowrap">{item.title}</h4>
                <h1 className="text-3xl font-bold mt-1" style={{ color: item.color }}>{`$${item.value}`}</h1>
                {/*<p className="text-sm mt-4">{item.percentage}% of total allocated amount used.</p>*/}
              </div>
              {/*<div className="relative w-32 h-32 mt-4">
                <svg width="100%" height="100%" viewBox="0 0 42 42" className="absolute">
                  <circle cx="21" cy="21" r="15" fill="none" stroke={theme === 'dark' ? '#FFFFFFB2' : '#555657'} strokeWidth="5"></circle>
                  <circle
                    cx="21" cy="21" r="15" fill="none" stroke={item.color} strokeWidth="8"
                    strokeDasharray={`${item.percentage} 100`}
                    transform="rotate(-90 21 21)"
                  ></circle>
                  <text 
                    x="50%" 
                    y="50%" 
                    textAnchor="middle" 
                    dy=".3em" 
                    fontSize="8" 
                    color={theme === 'dark' ? 'white' : 'black'}
                    fontWeight="bold"
                    fill={theme === 'dark' ? 'white' : 'black'}
                  >
                    {item.percentage}%
                  </text>
                </svg>
              </div>*/}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default FinanceOverview;