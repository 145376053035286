import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IoIosArrowForward } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import { fetchApi } from "../../../utlis/axios";
import { FaChevronDown, FaChevronUp, FaSadCry } from "react-icons/fa";
import Calendar from "./Calendar";
import RichTextEditor1 from "./RichTextEditor1";
import { addToast } from "../../../Redux/Slices/toast-slice";
import { Link } from "react-router-dom";

const TicketModal = ({
  setTicketModal,
  openedProject,
  ticketStatus,
  updateTickets,
  setTickets,
  newTicketColumnId,
}) => {
  const { theme } = useSelector((state) => state.theme);
  const users = useSelector((state) => state.allUsers?.filterUsers);
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.auth || {});
  const [piortyDropdown, setPiortyDropdown] = useState(false);
  const [priorityValue, setPriorityValue] = useState("high");
  const piortyOptions = ["low", "medium", "high"];
  const [milestones, setMilestones] = useState([]);
  const [selctedMilestone, setSelectedMilestone] = useState("");
  const [creating, setCreating] = useState(false);
  const [milestoneDropDown, setMilestoneDropDown] = useState(false);
  const [selectedUser, setSelectedUser] = useState("");
  const [userDropDown, setUserDropDown] = useState(false);
  const [typeDropDown, setTypeDropDown] = useState(false);
  const [ticketData, setTicketData] = useState({
    title: "",
    sprint: "",
    description: "",
    time_allotted: "",
    priority: priorityValue,
    type: "",
    started_at: Date.now(),
    // remaining_time: 0,
    milestone: "",
    attachment: [],
  });
  const [calendarDropdown, setCalendarDropdown] = useState(false);
  const [ticketsComment, setTicketsComments] = useState([]);
  const typeOptions = [
    { value: "bug", label: "bug" },
    { value: "task", label: "task" },
    { value: "story", label: "story" },
    { value: "epic", label: "epic" },
    { value: "sub_task", label: "sub task" },
    { value: "spike", label: "spike" },
    { value: "story_point", label: "story point" },
    { value: "improvement", label: "improvement" },
    { value: "change_request", label: "change request" },
    { value: "new_feature", label: "new feature" },
    { value: "incident", label: "incident" },
    { value: "service_request", label: "service request" },
    { value: "problem", label: "problem" },
    { value: "change", label: "change" },
    { value: "support_ticket", label: "support ticket" },
    { value: "test_case", label: "test_case" },
    { value: "requirement", label: "requirement" },
    { value: "deployment", label: "deployment" },
  ];

  const getMilestone = async () => {
    try {
      const res = await fetchApi.get(
        `/project-milestones/?project=${openedProject}`,
        {
          headers: {
            Authorization: `Bearer ${userInfo.access_token}`,
          },
        }
      );
      if (res.status === 200) {
        setMilestones(res.data.results);
      }
    } catch (error) {
      dispatch(addToast({ message: error.message, type: "error" }));
    }
  };
  let milestoneFetched = useRef(false);
  useEffect(() => {
    if (milestoneFetched.current) return;
    milestoneFetched.current = true;
    getMilestone();
  }, []);
  function formatToISO(date) {
    const isoString = new Date(date).toISOString(); // Returns date in ISO format (e.g., 2023-01-20T15:45:00.000Z)
    return isoString;
  }

  const handleSubmit = async () => {
    if (
      !ticketData.title ||
      !ticketData.sprint ||
      !ticketData.description ||
      !ticketData.time_allotted ||
      !ticketData.type
    ) {
      dispatch(addToast({ message: "Please all fields", type: "warn" }));
      return;
    } else if (!priorityValue) {
      dispatch(addToast({ message: "Plaese priority", type: "warn" }));
      return;
    } else if (!selctedMilestone) {
      dispatch(addToast({ message: "Please select milestone", type: "warn" }));
      return;
    } else if (!selectedUser) {
      dispatch(addToast({ message: "Please select one user", type: "warn" }));
      return;
    } else {
      setCreating(true);
      try {
        const startingDate = formatToISO(ticketData.started_at);
        const formattedComments = Array.isArray(ticketsComment)
          ? ticketsComment
          : [ticketsComment]; // Convert to an array if not already
        // Convert ticketData to FormData
        const formData = new FormData();
        formData.append("title", ticketData.title);
        formData.append("sprint", ticketData.sprint);
        formData.append("description", ticketData.description);
        formData.append("time_allotted", ticketData.time_allotted);
        formData.append("started_at", startingDate);
        formData.append("priority", priorityValue);
        formData.append("type", ticketData.type);
        formData.append("ticket_status", newTicketColumnId);
        // formData.append("remaining_time", ticketData.remaining_time);
        formData.append("project", openedProject);
        formData.append("milestone", selctedMilestone);
        formData.append("developer", selectedUser);

        if (ticketData.attachment?.length > 0) {
          ticketData.attachment.forEach((file, index) => {
            formData.append(`attachment[${index}]`, file);
          });
        }

        const res = await fetchApi.post("/project-tickets/", formData, {
          headers: {
            Authorization: `Bearer ${userInfo.access_token}`,
          },
        });

        if (res.status === 201) {
          setTickets((prev) => [...prev, res.data]);
          dispatch(
            addToast({
              message: "Ticket created successfully",
              type: "success",
            })
          );
          // Reset ticket data after successful submission
          setTicketData({
            title: "",
            sprint: "",
            description: "",
            time_allotted: 0,
            priority: priorityValue,
            type: "",
            started_at: "",
            remaining_time: 0,
            milestone: "",
            developer: selectedUser,
            attachment: [],
            comments: "",
          });
          setTicketModal(false);
          updateTickets(openedProject)
        }
      } catch (error) {
        dispatch(addToast({ message: error.message, type: "error" }));
      } finally {
        setCreating(false);
      }
    }
  };

  return (
    <div className="customFont">
      <>
        {/* Overlay */}
        <div className="fixed inset-0 bg-black bg-opacity-50 z-10"></div>

        {/* Modal Content */}
        <div className="fixed inset-0 flex items-center justify-center z-20 ">
          <div
            className={` p-6 rounded-lg shadow-lg max-w-screen-md max-h-[90vh] relative w-full ${
              theme === "dark"
                ? "bg-darkMode text-white"
                : "bg-lightMode text-black"
            }`}
          >
            <div className="flex justify-between">
              <div className="flex gap-2 items-center">
                <IoIosArrowForward className="w-4 h-4" />
                <h2 className="text-base font-semibold">New Ticket</h2>
              </div>
              <IoClose
                className="w-6 h-6"
                onClick={() => {
                  setTicketModal(false);
                }}
              />
            </div>
            <form className=" my-4">
              <label htmlFor="" className="font-semibold text-lg mt-4">
                Title
              </label>
              <input
                type="text"
                className={`text-2xl font-normal border-none bg-transparent outline-none mb-2 w-full ${
                  theme === "dark"
                    ? "placeholder:text-gray-600"
                    : "placeholder:text-gray-400"
                }`}
                value={ticketData.title}
                onChange={(e) => {
                  setTicketData({
                    ...ticketData,
                    title: e.target.value,
                  });
                }}
                placeholder="Add title"
              />
              <div className="flex gap-2 mt-3">
                <div className="w-1/2">
                  <label htmlFor="" className="font-semibold text-lg">
                    Sprint
                  </label>
                  <input
                    type="text"
                    className={`text-base font-normal border-none bg-transparent outline-none w-full mt-1 ${
                      theme === "dark"
                        ? "placeholder:text-gray-600"
                        : "placeholder:text-gray-400"
                    }`}
                    value={ticketData.sprint}
                    onChange={(e) => {
                      setTicketData({
                        ...ticketData,
                        sprint: e.target.value,
                      });
                    }}
                    placeholder="Sprint"
                  />
                </div>
                <div className="flex gap-1 items-center w-1/2">
                  <label htmlFor="status" className="text-base font-semibold">
                    Type
                  </label>
                  <div className="relative w-full">
                    <div
                      className={`${
                        theme === "dark"
                          ? " text-white"
                          : " text-black"
                      } rounded-md px-2 py-1 cursor-pointer flex justify-between items-center`}
                      onClick={() => setTypeDropDown(!typeDropDown)}
                    >
                      <span className=" capitalize">
                        {ticketData.type
                          ? typeOptions.find(
                              (item) => item.value === ticketData.type
                            )?.label || "Select type"
                          : "Select type"}
                      </span>
                      {typeDropDown ? (
                        <FaChevronUp className="w-4 h-4 " />
                      ) : (
                        <FaChevronDown className="w-4 h-4" />
                      )}
                    </div>

                    {typeDropDown && (
                      <div
                        className={`absolute top-full left-0 mt-2 w-full shadow-lg max-h-48 overflow-y-auto rounded-md z-10 ${
                          theme === "dark"
                            ? "bg-dashboardDarkMode text-white"
                            : "bg-lightMode text-black"
                        }`}
                      >
                        {typeOptions.map((option) => (
                          <div
                            key={option.value} // Fix: Correct key usage
                            className={`flex items-center px-3 py-2 hover:bg-gray-200 cursor-pointer ${
                              theme === "dark" ? "hover:bg-gray-700" : ""
                            }`}
                            onClick={() => {
                              setTicketData((prev) => ({
                                ...prev,
                                type: option.value, // Fix: Correct way to update ticketData
                              }));
                              setTypeDropDown(false);
                            }}
                          >
                            <span className="capitalize">{option.label}</span>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="flex gap-2 mt-3">
                <div className="w-1/2">
                  <label htmlFor="" className="text-lg font-semibold">
                    Alloted Time
                  </label>
                  <input
                    type="number"
                    className={`text-base font-normal border-none bg-transparent outline-none w-full mt-1 ${
                      theme === "dark"
                        ? "placeholder:text-gray-600"
                        : "placeholder:text-gray-400"
                    }`}
                    value={ticketData.time_allotted}
                    onChange={(e) => {
                      setTicketData({
                        ...ticketData,
                        time_allotted: e.target.value,
                      });
                    }}
                    placeholder="Enter allotted time"
                  />
                </div>
                <div className="w-1/2">
                  <label htmlFor="" className="font-semibold text-lg">
                    Start Date
                  </label>
                  <div className="w-full relative mt-1">
                    <span
                      className={`w-full flex justify-between items-center ${
                        ticketData.started_at.length > 0
                          ? ""
                          : theme === "dark"
                          ? "text-gray-600"
                          : "text-gray-400"
                      }`}
                      onClick={() => setCalendarDropdown(!calendarDropdown)}
                    >
                      {ticketData.started_at.length > 0
                        ? ticketData.started_at
                        : "Select Start Date"}
                      {calendarDropdown ? (
                        <FaChevronUp className="w-4 h-4 " />
                      ) : (
                        <FaChevronDown className="w-4 h-4" />
                      )}
                    </span>
                    {calendarDropdown && (
                      <Calendar
                        theme={theme}
                        onDateSelect={(date) => {
                          ticketData.started_at = date;
                          setCalendarDropdown(false);
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="flex gap-2 my-3">
                <div className="flex gap-1 items-center w-1/3">
                  <label htmlFor="status" className="text-base font-semibold">
                    Priority
                  </label>
                  <div className="relative w-full">
                    <div
                      className={`${
                        theme === "dark"
                          ? "border-gray-600 text-white"
                          : "border-gray-400 text-black"
                      } border rounded-md px-2 py-1 cursor-pointer flex justify-between items-center`}
                      onClick={() => setPiortyDropdown(!piortyDropdown)}
                    >
                      <span>{priorityValue}</span>
                      {piortyDropdown ? (
                        <FaChevronUp className="w-4 h-4 " />
                      ) : (
                        <FaChevronDown className="w-4 h-4" />
                      )}
                    </div>
                    {piortyDropdown && (
                      <div
                        className={`absolute top-full left-0 mt-2 w-full shadow-lg rounded-md z-10 ${
                          theme === "dark"
                            ? "bg-dashboardDarkMode text-white"
                            : "bg-lightMode text-black"
                        }`}
                      >
                        {piortyOptions.map((option) => (
                          <div
                            key={option}
                            className={`flex items-center px-3 py-2 hover:bg-gray-200 cursor-pointer ${
                              theme === "dark" ? "hover:bg-gray-700" : ""
                            }`}
                            onClick={() => {
                              setPriorityValue(option);
                              setPiortyDropdown(false);
                            }}
                          >
                            {option.icon}
                            <span className="capitalize">{option}</span>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex gap-1 items-center w-1/3">
                  <label
                    htmlFor="status"
                    className=" whitespace-nowrap text-base font-semibold"
                  >
                    Select User
                  </label>
                  <div className="relative w-full">
                    <div
                      className={`${
                        theme === "dark"
                          ? "border-gray-600 text-white"
                          : "border-gray-400 text-black"
                      } border rounded-md px-2 py-1 cursor-pointer flex justify-between items-center ${
                        selectedUser.length > 0
                          ? ""
                          : theme === "dark"
                          ? "text-gray-600"
                          : "text-gray-400"
                      }`}
                      onClick={() => setUserDropDown(!userDropDown)}
                    >
                      <span>
                        {users?.find((user) => user.id === selectedUser)
                          ?.first_name || "Select User"}{" "}
                        {users?.find((user) => user.id === selectedUser)
                          ?.last_name || ""}
                      </span>
                      {userDropDown ? (
                        <FaChevronUp className="w-4 h-4 " />
                      ) : (
                        <FaChevronDown className="w-4 h-4" />
                      )}
                    </div>
                    {userDropDown && (
                      <div
                        className={`absolute top-full left-0 mt-2 w-full h-52 overflow-y-auto shadow-lg rounded-md z-10 ${
                          theme === "dark"
                            ? "bg-dashboardDarkMode text-white"
                            : "bg-lightMode text-black"
                        }`}
                      >
                        {users.map((option) => (
                          <div
                            key={option.id}
                            className={`flex items-center px-3 py-2 hover:bg-gray-200 cursor-pointer ${
                              theme === "dark" ? "hover:bg-gray-700" : ""
                            }`}
                            onClick={() => {
                              setSelectedUser(option?.id);
                              setUserDropDown(false);
                            }}
                          >
                            {option.icon}
                            <span className="capitalize">
                              {option?.first_name} {option?.last_name}
                            </span>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex gap-1 items-center w-1/3">
                  <label htmlFor="status" className="text-base font-semibold">
                    Milestone
                  </label>
                  <div className="relative w-full">
                    <div
                      className={`${
                        theme === "dark"
                          ? "border-gray-600 text-white"
                          : "border-gray-400 text-black"
                      } border rounded-md px-2 py-1 cursor-pointer flex justify-between items-center ${
                        selctedMilestone.length > 0
                          ? ""
                          : theme === "dark"
                          ? "text-gray-600"
                          : "text-gray-400"
                      }`}
                      onClick={() => setMilestoneDropDown(!milestoneDropDown)}
                    >
                      <span>
                        {milestones?.find(
                          (milestone) => milestone.id === selctedMilestone
                        )?.title || "Select Milestone"}
                      </span>
                      {milestoneDropDown ? (
                        <FaChevronUp className="w-4 h-4 " />
                      ) : (
                        <FaChevronDown className="w-4 h-4" />
                      )}
                    </div>
                    {milestoneDropDown && (
                      <div
                        className={`absolute top-full left-0 p-2 mt-2 w-full shadow-lg rounded-md z-10 ${
                          theme === "dark"
                            ? "bg-dashboardDarkMode text-white"
                            : "bg-lightMode text-black"
                        }`}
                      >
                        {milestones.length <= 0 ? <Link to={`/dashboard/project/${openedProject}`} className=" cursor-pointer ">Create Milestone</Link> :
                        milestones?.map((option) => (
                          <div
                            key={option.id} // Use a unique identifier like option.id
                            className={`flex items-center px-3 py-2 hover:bg-gray-200 cursor-pointer ${
                              theme === "dark" ? "hover:bg-gray-700" : ""
                            }`}
                            onClick={() => {
                              setSelectedMilestone(option.id);
                              setMilestoneDropDown(false);
                            }}
                          >
                            {option.icon}
                            <span className="capitalize">{option.title}</span>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <label htmlFor="" className="font-semibold mt-4 text-lg">
                Description
              </label>
              <RichTextEditor1
                setProjectData={setTicketData}
                projectData={ticketData}
              />
            </form>
            <div className="flex gap-2 justify-end pt-4">
              <button
                className={` border rounded-3xl px-3 py-2 bg-transparent  ${
                  theme === "dark" ? "border-gray-700" : "border-gray-500"
                }`}
                onClick={() => {
                  setTicketModal(false);
                }}
              >
                Cancel
              </button>
              <button
                className="heroButton"
                disabled={creating}
                onClick={handleSubmit}
              >
                {creating ? "Creating..." : "Create"}
              </button>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default TicketModal;
