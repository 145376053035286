import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  htmlText: '',
  editorMode:'new'  // Renamed for consistency
};

const editorSlice = createSlice({
  name: 'htmlText', // Changed slice name for better representation
  initialState,
  reducers: {
    toggleHtmlText: (state, { payload }) => {
      state.htmlText = payload; // Assuming payload is a string
    },
    toggleEditorMode: (state, { payload }) => {
      state.editorMode = payload;
    }
  },
});

export const { toggleHtmlText,toggleEditorMode } = editorSlice.actions;
export default editorSlice.reducer;
