import { combineReducers, configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import authReducer from './Slices/auth-slice';  
import plansReducer from './Slices/plans-slice';
import themeReducer from './Slices/theme-slice';
import signUpReducer from './Slices/signup-slice';
import companyReducer from './Slices/registerCompany-slice';
import summaryDataReducer from './Slices/summaryData-slice'
import usersReducer from './Slices/users-slice'
import allPlansReducer from './Slices/all-plan-slice'
import projectReducer from './Slices/project-slice'
import editorReducer from './Slices/editor-slice'
import chatReducer from './Slices/chat-slice'
import toastReducer from './Slices/toast-slice'
// Combine all reducers
const rootReducer = combineReducers({
  auth: authReducer, 
  signUp:signUpReducer, 
  plan:plansReducer,
  theme:themeReducer,
  summaryData:summaryDataReducer,
  registerCompany:companyReducer,
  allUsers:usersReducer,
  allPlans:allPlansReducer,
  board:projectReducer,
  htmlText: editorReducer,
  chat:chatReducer,
  toast:toastReducer,
});

// Persist configuration
const persistConfig = {
  key: 'root',
  storage,
};

// Apply persist reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure the store
export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      // Ignore non-serializable checks for redux-persist specific actions
      ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE'],
    },
  }),
});

// Configure the persistor
export const persistor = persistStore(store);
