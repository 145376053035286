import React, { useEffect, useRef, useState } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { IoMdArrowDropdown } from "react-icons/io";
import styles from "./summary.module.css";
import Tooltip from "../../tooltip/Tooltip";
import { IoIosArrowDown } from "react-icons/io";
import {
  updateSelectedUser,
  updateUserId,
} from "../../../Redux/Slices/users-slice";
import { useDispatch, useSelector } from "react-redux";
import { fetchApi } from "../../../utlis/axios";
import Loader from "../../Loader/Loader";
import { toggleAuth } from "../../../Redux/Slices/auth-slice";
import { addToast } from "../../../Redux/Slices/toast-slice";
import {
  togglesummaryData,
  updateSummaryData,
} from "../../../Redux/Slices/summaryData-slice";
import { useNavigate } from "react-router-dom";
import loader from "../../../assets/loading-gif.gif";
import { FaUserCircle } from "react-icons/fa";

const Summary = () => {
  const { theme } = useSelector((state) => state.theme);
  const { userInfo } = useSelector((state) => state?.auth || {});
  const { userId, users } = useSelector((state) => state?.allUsers || {});
  const { nextPage, selectedUser } = useSelector(
    (state) => state?.allUsers || {}
  );
  const [currentDate, setCurrentDate] = useState(new Date());
  const [days, setDays] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const [userSummary, setUserSummary] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const dispatch = useDispatch();
  const [matchingEntry, setMatchingEntry] = useState();
  const [openDropdownId, setOpenDropdownId] = useState(null);
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [employeeLeaves, setEmployeeLeaves] = useState([]);
  const [companyLeaves, setCompanyLeaves] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const isFetched = useRef(false);
  const isLeavesFetched = useRef(false);
  const isComapnyLeaveFetched = useRef(false);
  const [loading, setLoading] = useState(false);
  const [next, setNext] = useState(nextPage);
  const [userList, setuserList] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const dropdownRef=useRef(null);

  const handleDropdownToggle = (dropdownId) => {
    setOpenDropdownId((prevId) => (prevId === dropdownId ? null : dropdownId));
  };

  const joiningDate = new Date(userInfo?.user?.created_at);

  const getSummaryData = async () => {
    if (selectedUser && startDate && endDate) {
      setIsloading(true);
      try {
        const response = await fetchApi.get(
          `user-summaries/?user=${selectedUser?.id}&date__gte=${startDate}&date__Ite=${endDate}`,
          {
            headers: {
              Authorization: `Bearer ${userInfo.access_token}`,
            },
          }
        );
        if (response.status === 200) {
          const next = response.data.next;
          if (next) {
            handleNextPage(next);
          }
          setIsloading(false);
          setUserSummary(response.data.results);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          dispatch(toggleAuth({ isLogin: false, userInfo: null }));
          dispatch(
            addToast({ message: "Your session expired", type: "error" })
          );
          return;
        }
        setIsloading(false);
        console.log("Error while call user-summaries API: ", error);
        dispatch(addToast({ message: "Something went wrong", type: "error" }));
      }
    }
  };
  const handleNextPage = async (url) => {
    try {
      const response = await fetchApi.get(url, {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });
      if (response.status === 200) {
        const next = response.data.next;
        if (next) {
          handleNextPage(next);
        }
        setUserSummary((prev) => [...prev, ...response.data.results]);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(toggleAuth({ isLogin: false, userInfo: null }));
        dispatch(addToast({ message: "Your session expired", type: "error" }));
        return;
      }
      setIsloading(false);
      console.log("Error while call user-summaries API: ", error);
      dispatch(addToast({ message: "Something went wrong", type: "error" }));
    }
  };
  const filterCompanyLeaves = (data) => {
    const dateTitlePairs = data.results.flatMap((item) =>
      item.dates.map((date) => ({
        date,
        title: item.title,
      }))
    );

    return dateTitlePairs; // Return an array of objects with date and title
  };

  const getLeaves = async () => {
    try {
      const response = await fetchApi.get(
        `user-timeoffs/?user=${userId}&status=approved`,
        {
          headers: {
            Authorization: `Bearer ${userInfo.access_token}`,
          },
        }
      );
      if (response.status === 200) {
        const dateTitlePairs = filterCompanyLeaves(response.data);
        setEmployeeLeaves(dateTitlePairs);
      }
    } catch (error) {}
  };
  const getComapnyTimeoffs = async () => {
    try {
      const response = await fetchApi.get(`company-timeoffs/`, {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });
      if (response.status === 200) {
        const dateTitlePairs = filterCompanyLeaves(response?.data);
        setCompanyLeaves(dateTitlePairs);
      }
    } catch (error) {}
  };
  useEffect(() => {
    if (isComapnyLeaveFetched.current) return;
    isComapnyLeaveFetched.current = true;
    getComapnyTimeoffs();
  });
  useEffect(() => {
    if (!isLeavesFetched.current) {
      getLeaves();
    } else {
      isLeavesFetched.current = true;
    }
  }, [userId]);
  useEffect(() => {
    if (!isFetched.current) {
      getSummaryData();
    } else {
      isFetched.current = true;
    }
  }, [selectedUser, startDate, endDate]);
  useEffect(() => {
    generateCalendar(currentDate);
  }, [currentDate, employeeLeaves]);

  useEffect(() => {
    setuserList(users);
  }, [users]);

  useEffect(() => {
    setNext(next);
  }, [next]);

  function formatToCustomISOString(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}T00:00:00Z`;
  }

  const generateCalendar = (date) => {
    const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    const lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    const lastDayOfPrevMonth = new Date(
      date.getFullYear(),
      date.getMonth(),
      0
    ).getDate();

    const daysInMonth = [];
    for (let i = 1; i <= lastDayOfMonth.getDate(); i++) {
      // Check if current day is a future leave date
      const formattedDate = `${date.getFullYear()}-${String(
        date.getMonth() + 1
      ).padStart(2, "0")}-${String(i).padStart(2, "0")}`;

      const isLeave = employeeLeaves.find(
        (leave) => leave.date === formattedDate
      );
      const companyLeave = companyLeaves.find(
        (leave) => leave.date === formattedDate
      );

      daysInMonth.push({
        day: i,
        currentMonth: true,
        eleave: !!isLeave,
        eltitle: isLeave ? isLeave.title : null,
        companyleave: !!companyLeave,
        title: companyLeave ? companyLeave.title : null, // Add leave flag
      });
    }

    for (let i = firstDayOfMonth.getDay() - 1; i >= 0; i--) {
      daysInMonth.unshift({
        day: lastDayOfPrevMonth - i,
        currentMonth: false,
        eleave: false,
        eltitle: null,
        companyleave: false,
        title: null, // Not in current month
      });
    }

    const totalDays = daysInMonth.length;
    for (let i = 1; totalDays + i <= 35; i++) {
      daysInMonth.push({
        day: i,
        currentMonth: false,
        eleave: false,
        eltitle: null,
        companyleave: false,
        title: null, // Not in current month
      });
    }
    setDays(daysInMonth);

    const startDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      1 - firstDayOfMonth.getDay()
    );

    const endDate = new Date(
      startDate.getFullYear(),
      startDate.getMonth(),
      startDate.getDate() + daysInMonth.length - 1
    );

    setStartDate(formatToCustomISOString(startDate));
    setEndDate(formatToCustomISOString(endDate));
  };
  const goToPrevMonth = () => {
    const prevMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() - 1,
      1
    );
    if (
      prevMonth.getFullYear() > joiningDate.getFullYear() ||
      (prevMonth.getFullYear() === joiningDate.getFullYear() &&
        prevMonth.getMonth() >= joiningDate.getMonth())
    ) {
      setCurrentDate(prevMonth);
    }
  };

  const goToNextMonth = () => {
    const nextMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      1
    );
    const today = new Date();
    if (nextMonth <= today) {
      setCurrentDate(nextMonth);
    }
  };

  const renderCalendarRows = () => {
    const weeks = [];
    const daysInMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    ).getDate();
    const firstDayOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    ).getDay();

    const prevMonthDays = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      0
    ).getDate();

    let dayCount = 1 - firstDayOfMonth; // Start at the correct weekday column

    for (let week = 0; week < 6; week++) {
      const weekRow = [];

      for (let i = 0; i < 7; i++) {
        let dayObj = {
          day: "",
          currentMonth: false,
          eleave: false,
          eltitle: null,
          companyleave: false,
          title: null,
        };

        let formattedDate = `${currentDate.getFullYear()}-${String(
          currentDate.getMonth() + 1
        ).padStart(2, "0")}-${String(dayCount).padStart(2, "0")}`;

        if (dayCount < 1) {
          // Previous month's days
          dayObj.day = prevMonthDays + dayCount;
        } else if (dayCount > daysInMonth) {
          // Next month's days
          dayObj.day = dayCount - daysInMonth;
        } else {
          // Current month's days
          dayObj.day = dayCount;
          dayObj.currentMonth = true;

          // Check for employee leave
          const isLeave = employeeLeaves.find(
            (leave) => leave.date === formattedDate
          );
          const companyLeave = companyLeaves.find(
            (leave) => leave.date === formattedDate
          );

          dayObj.eleave = !!isLeave;
          dayObj.eltitle = isLeave ? isLeave.title : null;
          dayObj.companyleave = !!companyLeave;
          dayObj.title = companyLeave ? companyLeave.title : null;
        }

        weekRow.push(dayObj);
        dayCount++;
      }

      weeks.push(weekRow);
      if (dayCount > daysInMonth) break;
    }
    return weeks;
  };

  const getSelectableMonths = () => {
    const selectableMonths = [];
    const currentYear = currentDate.getFullYear();
    const currentMonth = new Date().getMonth();

    for (let month = joiningDate.getMonth(); month <= currentMonth; month++) {
      selectableMonths.push(new Date(currentYear, month, 1));
    }

    return selectableMonths;
  };

  const handleMonthSelect = (month) => {
    setCurrentDate(month);
    setIsDropdownOpen(false);
  };

  const convertMinutesToHours = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours}:${remainingMinutes.toString().padStart(2, "0")}`;
  };

  const getTotalTrackedTimeForDate = (date) => {
    // Format the input date to "YYYY-MM-DD" using local time
    const formattedDate = `${date.getFullYear()}-${String(
      date.getMonth() + 1
    ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;

    // Ensure userSummary is an array before using .find()
    if (!Array.isArray(userSummary)) {
      console.error("userSummary is not an array:", userSummary);
      return "";
    }

    // Find the summary in userSummary where the date matches the formattedDate
    const summary = userSummary.find(
      (item) => item.date.split("T")[0] === formattedDate
    );
    return summary ? convertMinutesToHours(summary.total_tracked_time) : "";
  };

  const calculateWeeklyTotal = (week) => {
    const totalMinutes = week.reduce((sum, day) => {
      if (day.currentMonth) {
        const trackedTime = getTotalTrackedTimeForDate(
          new Date(currentDate.getFullYear(), currentDate.getMonth(), day.day)
        );
        if (trackedTime) {
          const [hours, minutes] = trackedTime.split(":").map(Number);
          return sum + hours * 60 + minutes;
        }
      }
      return sum;
    }, 0);

    return convertMinutesToHours(totalMinutes);
  };

  const handleGetActivity = (summary) => {
    dispatch(
      togglesummaryData({
        summaryDate: summary?.date.substring(0, 10),
        summaryUserId: summary?.user?.id,
      })
    );
    navigate("/dashboard/activity");
  };
  const measureRef = useRef(null);
  const [maxWidth, setMaxWidth] = useState("auto");

  useEffect(() => {
    if (measureRef.current) {
      setMaxWidth(`${measureRef.current.offsetWidth}px`);
    }
  }, [users]);

  const handleUsersScroll = async (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;

    // Check if we've scrolled to the bottom and there's a next page
    if (scrollHeight - scrollTop === clientHeight && next && !isFetching) {
      setIsFetching(true); // Set fetching state to true
      await fetchNextUsers(next);
      setIsFetching(false); // Reset fetching state after fetching
    }
  };

  const fetchNextUsers = async (url) => {
    try {
      const res = await fetchApi.get(url, {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });
      if (res.status === 200) {
        const timeReporters = res.data.results.filter(
          (user) => user.role.name === "Time Reporter"
        );
        setuserList((prev) => [...prev, ...timeReporters]);
        setNext(res.data.next);
      }
    } catch (error) {
      console.error("Error fetching next users:", error);
    }
  };

  // Add the search function
  const handleSearch = async (e) => {
    if (e.key === "Enter") {
      setIsSearching(true);
      try {
        const res = await fetchApi.get(`users/?search=${searchQuery}`, {
          headers: {
            Authorization: `Bearer ${userInfo.access_token}`,
          },
        });
        if (res.status === 200) {
          setuserList(res.data.results);
          setNext(res.data.next);
        }
      } catch (error) {
        console.error("Search error:", error);
      }
      setIsSearching(false);
    }
  };

  // Add the search input change handler
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchQuery(value);

    // If search input is empty, restore original list
    if (!value.trim()) {
      setuserList(users);
      setNext(nextPage);
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false); // Close dropdown
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [isOpen]);

  return (
    <>
      {isLoading && (
        <>
          <div
            className="fixed inset-0 bg-black bg-opacity-50 z-10"
            style={{ top: "95px" }}
          ></div>
          <div
            className="fixed inset-0 flex items-center justify-center z-20"
            style={{ top: "95px" }}
          >
            <Loader />
          </div>
        </>
      )}
      <div
        className={`${styles.main_Heading} ${
          theme === "dark" ? "text-white" : "text-black"
        }`}
      >
        Summary
      </div>
      <hr className="w-full" />
      <div className={`lg:w-5/6 w-full ${styles.outlet}`}>
        <div
          className={`${styles["calendar-section"]} ${
            theme === "dark" ? "text-white" : "text-black"
          }`}
        >
          <div className="w-full flex justify-center">
            <div className={styles["calendar-header"]}>
              <span className={styles["month-div"]}>
                <FaArrowLeft
                  onClick={goToPrevMonth}
                  className="cursor-pointer"
                />
                <h2
                  className="cursor-pointer"
                  onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                >
                  {currentDate.toLocaleString("default", { month: "long" })}{" "}
                  {currentDate.getFullYear()}
                </h2>
                <div
                  className={styles.dropdown}
                  onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                  onMouseLeave={() => setIsDropdownOpen(false)}
                >
                  <IoMdArrowDropdown />
                  {isDropdownOpen && (
                    <ul className={styles.dropdownMenu}>
                      {getSelectableMonths().map((month) => (
                        <li
                          key={month.getMonth()}
                          onClick={() => handleMonthSelect(month)}
                        >
                          {month.toLocaleString("default", {
                            month: "long",
                          })}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
                <FaArrowRight
                  onClick={goToNextMonth}
                  className="cursor-pointer"
                />
              </span>
              <div className="flex space-x-4 items-center">
                <div className="flex gap-2 items-center relative">
                  <span>Project: </span>
                  <div className="">
                    {selectedUser?.project ? selectedUser?.project?.name
                      : "No project"}{" "}
                  </div>
                </div>
                <div className="flex gap-1 items-center ">
                  <span>User: </span>{" "}
                  {userInfo?.user?.role?.name !== "Time Reporter" ? (
                    <>
                      <div className="relative" ref={dropdownRef}>
                        <div
                          className={`rounded-md gap-4 flex items-center justify-between p-2 min-w-[200px] cursor-pointer ${
                            theme === "dark"
                              ? "hover:bg-gradient-to-r hover:from-[#fff3] hover:to-[#fff0] transition-all"
                              : "hover:bg-gradient-to-r hover:from-[#6d6d6d33] hover:to-[#fff0] transition-all"
                          }`}
                          onClick={() => setIsOpen((prev) => !prev)}
                        >
                          <span>
                            {selectedUser
                              ? selectedUser?.first_name +
                                " " +
                                selectedUser?.last_name
                              : "Select User"}
                          </span>
                          <IoIosArrowDown className="w-4 h-4" />
                        </div>
                        {isOpen && (
                          <div
                            className={`z-10 absolute mt-1 w-full border overflow-auto shadow-lg rounded-[10px] max-h-[280px] ${
                              theme === "dark"
                                ? "bg-[#212529] border-[#555657]"
                                : "bg-[#F2F4F7] border-[#9A9F9A]"
                            }`}
                            onScroll={handleUsersScroll}
                            style={{
                              paddingTop: "4px",
                              paddingBottom: "4px",
                            }}
                          >
                            <div className="relative">
                              <input
                                type="text"
                                className={`p-2 pl-8 bg-transparent border-b outline-none w-full ${
                                  theme === "dark"
                                    ? "border-[#555657]"
                                    : "border-[#D8D8D8]"
                                }`}
                                value={searchQuery}
                                onChange={handleSearchChange}
                                onKeyDown={handleSearch}
                                placeholder="Search users..."
                              />
                              {isSearching && (
                                <div className="absolute left-2 top-1/2 -translate-y-1/2">
                                  <img
                                    src={loader}
                                    alt="Searching..."
                                    className="w-4 h-4"
                                  />
                                </div>
                              )}
                            </div>
                            {userList.length > 0 ?
                            userList?.map((user) => (
                              <div
                                key={user.id}
                                className={`p-2 cursor-pointer  flex items-center gap-2 ${
                                  selectedUser?.id === user?.id
                                    ? "bg-opacity-10 bg-gray-500"
                                    : ""
                                } ${
                                  theme === "dark"
                                    ? "hover:bg-gradient-to-r hover:from-[#fff3] hover:to-[#fff0] transition-all"
                                    : "hover:bg-gradient-to-r hover:from-[#6d6d6d33] hover:to-[#fff0] transition-all"
                                }`}
                                onClick={() => {
                                  dispatch(updateSelectedUser(user));
                                  dispatch(updateUserId(user.id));
                                  setIsOpen(false);
                                }}
                              >
                                {user.picture ? (
                                  <img
                                    src={user.picture}
                                    alt={`${user.first_name}`}
                                    className="w-6 h-6 rounded-full object-cover"
                                  />
                                ) : (
                                  <FaUserCircle className="w-6 h-6 text-gray-400" />
                                )}
                                <span>
                                  {user.first_name} {user.last_name}
                                </span>
                              </div>
                            )) : <div  className={`p-2 cursor-pointer  flex items-center gap-2 ${
                              theme === "dark"
                                ? "hover:bg-gradient-to-r hover:from-[#fff3] hover:to-[#fff0] transition-all"
                                : "hover:bg-gradient-to-r hover:from-[#6d6d6d33] hover:to-[#fff0] transition-all"
                            }`}>No user found</div>}
                            {isFetching && (
                              <div className="flex justify-center p-2">
                                <img
                                  src={loader}
                                  alt="Loading..."
                                  className="w-6 h-6"
                                />
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </>
                  ) : (
                    <span className="text-sm">
                      {userInfo?.user?.first_name} {userInfo?.user?.last_name}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className={`${styles["calendar-inner-section"]} relative`}>
            <div
              className={`flex justify-center ${styles["calendar-section"]}`}
            >
              <table
                className={` ${styles["calendar-table"]} ${
                  theme === "dark" ? "text-white" : "text-black"
                }`}
              >
                <thead>
                  <tr>
                    {[
                      "Sun",
                      "Mon",
                      "Tue",
                      "Wed",
                      "Thu",
                      "Fri",
                      "Sat",
                      "Week",
                    ].map((day) => (
                      <th key={day} className={` ${styles["day-header"]}`}>
                        {day}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {renderCalendarRows().map((week, index) => (
                    <tr key={index}>
                      {week.map((day, i) => (
                        <td
                          key={i}
                          className={`${styles["calendar-cell"]} ${
                            theme === "dark"
                              ? "hover:bg-[#2c2c2e]"
                              : "hover:bg-[#e0e0e0]"
                          } ${
                            day.leave
                              ? theme === "dark"
                                ? "bg-[#2c2c2e]"
                                : "bg-[#e0e0e0]"
                              : ""
                          }
                          `}
                        >
                          <div className={styles["calendar-inner-cell"]}>
                            <span>{day.day}</span>
                            {day.eleave && (
                              <span
                                className={`${
                                  theme === "dark"
                                    ? "text-red-800"
                                    : "text-red-800"
                                } cursor-pointer`}
                              >
                                <Tooltip content={day.eltitle}>Leave</Tooltip>
                              </span>
                            )}
                            {day.companyleave && (
                              <span
                                className={`cursor-pointer ${
                                  theme === "dark"
                                    ? "text-red-800"
                                    : "text-red-800"
                                }`}
                                title={day.title} // Show title as a tooltip
                              >
                                <Tooltip content={day.title}>Holiday</Tooltip>
                              </span>
                            )}
                            {/* Display Leave */}
                            <span
                              className={`text-bold cursor-pointer text-red-800`}
                              onClick={() => {
                                const selectedDate = new Date(currentDate);
                                selectedDate.setDate(day.day);
                                const formattedDate = selectedDate
                                  .toISOString()
                                  .split("T")[0];
                                const matchingEntry = userSummary.find(
                                  (data) => {
                                    const summaryDate = new Date(data.date)
                                      .toISOString()
                                      .split("T")[0];
                                    return (
                                      summaryDate === formattedDate &&
                                      data.user.id === userId
                                    );
                                  }
                                );
                                handleDropdownToggle(day);
                                setMatchingEntry(matchingEntry);
                                handleGetActivity(matchingEntry);
                              }}
                            >
                              {day.currentMonth
                                ? getTotalTrackedTimeForDate(
                                    new Date(
                                      currentDate.getFullYear(),
                                      currentDate.getMonth(),
                                      day.day
                                    )
                                  )
                                : ""}
                            </span>
                          </div>
                        </td>
                      ))}

                      <td
                        className={`${styles["calendar-cell"]} ${styles.result}`}
                      >
                        <span className="text-red-800">
                          {calculateWeeklyTotal(week)}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Summary;
