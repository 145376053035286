import { useState, useEffect } from "react";
import { FaClock } from "react-icons/fa";
import { FiRefreshCw } from "react-icons/fi";
import { useSelector, useDispatch } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import '../../adminDashboard/admin.css'
import loader from "../../../../assets/loading-gif.gif";
import "react-datepicker/dist/react-datepicker.css";
import { AiOutlineFileSearch } from "react-icons/ai";
import { FaCoins } from "react-icons/fa";
import { FaCheckCircle } from "react-icons/fa";
import { MdPauseCircle } from "react-icons/md";
import { MdCancel } from "react-icons/md";
import MonthlyMileStoneBreakdown from "./MonthlyMilestoneBreakDown";
import MilestoneStatusChart from "./MileStoneStatusChart";


const ProjectBudgetOverview = ({data,getData}) => {
  const { theme } = useSelector((state) => state.theme);

  const [isRefreshing, setIsRefreshing] = useState(false);

  const cards = [
    { icon: <FaClock className={`${theme === 'dark' ? 'text-black':'text-white'} w-7 h-7`}/>, value:`$${data?.total_project_budget}`, label: "Total Project Budget", id: 1 },
    { icon: <FaCoins className={`${theme === 'dark' ? 'text-black':'text-white'} w-7 h-7`}/>, value: `$${data?.total_milestone_budget}`, label: "Total Milestone Budget", id: 2 },
    { icon: <FaCheckCircle className={`${theme === 'dark' ? 'text-black':'text-white'} w-7 h-7`}/>, value: data?.milestone_status_counts?.completed, label: "Completed Milestones", id: 3 },
    { icon: <FaClock className={`${theme === 'dark' ? 'text-black':'text-white'} w-7 h-7`}/>, value: data?.milestone_status_counts?.in_progress, label: "In Progress Milestone", id: 4 },
    { icon: <MdPauseCircle className={`${theme === 'dark' ? 'text-black':'text-white'} w-7 h-7`}/>, value: data?.milestone_status_counts?.paused, label: "Paused Milestones ", id: 5 },
    { icon: <MdCancel className={`${theme === 'dark' ? 'text-black':'text-white'} w-7 h-7`}/>, value: data?.milestone_status_counts?.canceled, label: "Canceled Milestones", id: 6 },
  ];


  const handleRefresh = async () => {
    try {
      setIsRefreshing(true);
      await getData();
    } catch (err) {

    } finally {
      setIsRefreshing(false);
    }

  }

  return (
    <div
      className={`p-6 rounded-xl customFont ${theme === "dark" ? "bg-[#1C1C1E] text-white" : "bg-white text-black"
        }`}
    >
      {/* Header */}
      <div className="flex justify-between md:items-center items-start mb-4">
        <div className="flex gap-2 items-center">
          <span className="bg-gradient-to-r from-[#CC0927] to-[#7E0105] p-2 rounded-[10px]">
            <AiOutlineFileSearch className="w-6 h-6 text-white" />
          </span>
          <h1 className="text-lg font-semibold">Project Overview Budget</h1>
        </div>
        <div className="flex gap-2 md:items-center items-start">
          {/* Users Dropdown */}

          <div
            className={`p-2 flex items-center justify-center rounded-[10px] border cursor-pointer ${theme === "dark"
                ? "bg-[#252529] border-[#555657]"
                : "bg-[#F2F4FA] border-[#D8D8D8]"
              }`}
          >
            {isRefreshing ? (
              <img src={loader} alt="Loading..." className="w-5 h-5" />
            ) : (
              <FiRefreshCw className={`w-5 h-5`} onClick={handleRefresh}/>
            )}
          </div>
        </div>
      </div>

      {/* Stats Cards */}
      <Swiper
        spaceBetween={10}
        slidesPerView={1}
        breakpoints={{
          640: { slidesPerView: 1.2 },
          768: { slidesPerView: 2.2 },
          1024: { slidesPerView: 3.2 },
          1280: { slidesPerView: 4.2 },
          1380: { slidesPerView: 5 },
        }}
        pagination={{ clickable: false }}
        navigation={false}
      >
        {cards.map((card) => (
          <SwiperSlide key={card.id}>
            <StatBox
              icon={card.icon}
              value={card.value}
              label={card.label}
              theme={theme}
              id={card.id}
            />
          </SwiperSlide>
        ))}
      </Swiper>
  <div className="flex md:flex-row flex-col items-center justify-center mt-6 gap-4">
    <MilestoneStatusChart data={data?.milestone_status_counts}/>
    <MonthlyMileStoneBreakdown data={data?.monthly_milestone_breakdown}/>
  </div>

    </div>
  );
};

const StatBox = ({ icon, value, label, theme, id }) => (
  <div
    className={`px-6 py-6 border rounded-[20px] flex-1 flex items-center gap-4  ${theme === "dark"
        ? "bg-[#212529] border-[#555657]"
        : "bg-[#F2F4F7] border-[#D8D8D8]"
      }`}
  >
    <div
      className={`text-2xl p-2 rounded-full ${theme === "dark" ? "text-black" : "text-[#FFFFFF]"
        } ${theme === "dark" 
          ? id === 1
            ? "bg-[#FFBD9C]"
            : id === 2
              ? "bg-[#B4E3CA]"
              : id === 3
                ? "bg-[#CABDFD]"
                : id === 4
                  ? "bg-[#FFD891]"
                  : id === 5 
                    ? "bg-[#ADD8E6]"
                    : "bg-[#FFBD9C]"
          : id === 1
            ? "bg-[#D28A6C]"
            : id === 2
              ? "bg-[#5FA780]"
              : id === 3
                ? "bg-[#7A6ACF]"
                : id === 4
                  ? "bg-[#D9AE5F]"
                  : id === 5 
                    ? "bg-[#D28A6C]"
                    : "bg-[#D28A6C]"
        }`}
    >
      {icon}
    </div>
    <div>
      <p
        className={`text-2xl font-semibold} ${
          theme === "dark" 
            ? id === 1
              ? "text-[#FFBD9C]"
              : id === 2
                ? "text-[#B4E3CA]"
                : id === 3
                  ? "text-[#CABDFD]"
                  : id === 4
                    ? "text-[#FFD891]"
                    : id === 5 
                      ? "text-[#ADD8E6]"
                      : "text-[#FFBD9C]"
            : id === 1
              ? "text-[#D28A6C]"
              : id === 2
                ? "text-[#5FA780]"
                : id === 3
                  ? "text-[#7A6ACF]"
                  : id === 4
                    ? "text-[#D9AE5F]"
                    : id === 5 
                      ? "text-[#D28A6C]"
                      : "text-[#D28A6C]"
        }`}
      >
        {value}
      </p>
      <p className="text-sm text-gray-400 whitespace-nowrap">{label}</p>
    </div>
  </div>
);

export default ProjectBudgetOverview;
