import React, { useState } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { PaymentElement } from "@stripe/react-stripe-js";
import styles from "./StripePayment.module.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchApi } from "../../utlis/axios";
import { toggleAuth } from "../../Redux/Slices/auth-slice";
import { useNavigate } from "react-router-dom";
import { addToast } from "../../Redux/Slices/toast-slice";
import { GiCheckMark } from "react-icons/gi";

const CheckoutForm = ({ clientSecret }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { theme } = useSelector((state) => state.theme);
  const { signupInfo } = useSelector((state) => state?.signUp || {});
  const [isPaymentElementLoading, setPaymentElementLoading] = useState(true)
  const plans = useSelector((state) => state.allPlans.plans);

  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) return;

    setIsLoading(true);

    const result = await elements.submit();
    if (result.error) {
      const errorMessage = result.error.message || "Invalid payment details."
      dispatch(addToast({ message: errorMessage, type: 'error' }));
      setIsLoading(false);
      return;
    }

    const { setupIntent, error } = await stripe.confirmSetup({
      elements,
      clientSecret,
      confirmParams: {
        // Pass customer information here
        payment_method_data: {
          billing_details: {
            name: `${signupInfo.first_name} ${signupInfo.last_name}`,
            email: signupInfo.email,
          },
        },
      },
      redirect: "if_required",
    });

    if (error) {
      setIsLoading(false)
      dispatch(addToast({ message: error.message, type: 'error' }));
    } else if (setupIntent && setupIntent.status === "succeeded") {
      try {
        const userData = new FormData();
        userData.append("first_name", signupInfo.first_name);
        userData.append("last_name", signupInfo.last_name);
        userData.append("email", signupInfo.email);
        userData.append("quantity", signupInfo.quantity);
        userData.append("password", signupInfo.password);
        userData.append("role", signupInfo.role);
        userData.append("domain", signupInfo.domain);
        userData.append("plan", signupInfo.plan);
        userData.append("session_key", setupIntent?.id);

        const result = await fetchApi.post("register/", userData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        if (result.status === 200) {
          const data = result.data
          dispatch(toggleAuth({ isLogin: true, userInfo: result.data }));
          dispatch(addToast({ message: 'Account craeted successfully', type: 'success' }));
          const url = window.window.location
          if (data?.user?.plan?.name === 'Basic') {
            window.location.href = `${url.protocol}//${data.user?.domain}.${url.host}/login`
            dispatch(toggleAuth({ isLogin: false, userInfo: null }));
          } else {
            navigate('/registercompany')
          }
        }
      } catch (error) {
        const errorMessage =
          error?.response?.data?.error ||
          error?.response?.data?.email?.[0] ||
          error?.response?.data?.[0] ||
          "Registration failed";

        dispatch(addToast({ message: errorMessage, type: 'error' }));
        navigate("/signup");
        console.log(error);
      }
    }

    setIsLoading(false);
  };

   // 🎨 Theme-based Stripe Styles
   const stripeAppearance = {
    theme: "none",
    variables: {
      colorPrimary: theme === "dark" ? "#ff4d4d" : "#0056b3",
      colorBackground: theme === "dark" ? "#212529" : "#ffffff",
      colorText: theme === "dark" ? "#ffffff" : "#000000",
      borderRadius: "10px",
    },
    rules: {
      ".Input": {
        backgroundColor: theme === "dark" ? "#212529" : "#ffffff",
        color: theme === "dark" ? "#ffffff" : "#000000",
        border: theme === "dark" ? "1px solid #555657" : "1px solid #9CA3AF",
        borderRadius: "10px",
        outline: "none",
        padding: "10px",
      },
      ".PaymentMethod": {
        backgroundColor: theme === "dark" ? "#212529" : "#ffffff",
        border: theme === "dark" ? "1px solid #555657" : "1px solid #9CA3AF",
        borderRadius: "10px",
        padding: "10px",
      },
      ".PaymentMethod--selected": {
        borderColor: theme === "dark" ? "#ff4d4d" : "#0056b3",
        boxShadow: theme === "dark" ? "0px 0px 8px rgba(255, 77, 77, 0.7)" : "0px 0px 8px rgba(0, 86, 179, 0.7)",
      },
    },
  };

  return (
    <div
      className={`${styles.checkoutContainer} xl:max-w-screen-lg lg:max-w-screen-md md:max-w-screen-sm max-w-[450px] sm:w-full w-[90%] ${theme === "dark" ? styles.darkMode : ""
        }`}
    >
      <div className="flex w-full justify-between items-center py-3 md:px-4">
        <div className="flex items-center gap-2 cursor-pointer">
          <GiCheckMark className="sm:w-5 sm:h-5 w-3 h-3 text-green-500" />
          <div className="flex flex-col">
            <span className="sm:text-base text-sm">Step 1</span>
            <span className="sm:text-sm text-xs">Subscription Plan</span>
          </div>
        </div>
        <div className="flex items-center gap-2 cursor-pointer">
          <GiCheckMark className="sm:w-5 sm:h-5 w-3 h-3 text-green-500" />
          <div className="flex flex-col">
            <span className="sm:text-base text-sm">Step 2</span>
            <span className="sm:text-sm text-xs">Create account</span>
          </div>
        </div>
        <div className="flex flex-col border-b-[3px] border-red-600 py-3 cursor-pointer">
          <span className="sm:text-base text-sm">Step 3</span>
          <span className="sm:text-sm text-xs">Complete Payment</span>
        </div>
        <div className={`flex flex-col py-3 cursor-pointer ${theme === 'dark' ? 'text-gray-400' : 'text-gray-600'}`}>
          <span className="sm:text-base text-sm">Step 4</span>
          <span className="sm:text-sm text-xs">Register Company</span>
        </div>

      </div>
      <hr className={`mt-[-12px] border-t ${theme === 'dark' ? 'border-[#556557]' : 'border-[#212529]'}`} />
      <form onSubmit={handleSubmit} className={styles.cardForm}>
        <h2 className={`${styles.formTitle} mt-4`}>Complete Your Payment</h2>
        <PaymentElement onReady={() => setPaymentElementLoading(false)}
          onLoading={() => setPaymentElementLoading(true)} options={{ appearance: stripeAppearance }} />
        <div className="flex justify-center items-center w-full ">
          <button
            className={`heroButton md:w-[30%] sm:w-[50%] w-full ${isLoading || isPaymentElementLoading ? styles.loading : ""
              }`}
            type="submit"
            disabled={isLoading || isPaymentElementLoading}
          >
            {isLoading ? "Loading..." : "Submit"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default CheckoutForm;
