import React, { useState, useEffect } from 'react';
import { MdManageSearch } from 'react-icons/md';
import { FiRefreshCw } from 'react-icons/fi';
import { useSelector, useDispatch } from 'react-redux';
import { FaCheckCircle, FaRegCheckCircle, FaRegClock, FaTasks, FaUserCircle } from 'react-icons/fa';
import { AiOutlineFileDone } from 'react-icons/ai';
import { BsClipboardData } from 'react-icons/bs';
import { RiChatNewLine } from "react-icons/ri";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { fetchApi } from "../../../utlis/axios";
import { addToast } from '../../../Redux/Slices/toast-slice';
import { Swiper, SwiperSlide } from 'swiper/react';
import loader from "../../../assets/loading-gif.gif";
import { Tooltip } from '@mui/material';
import { TiDeleteOutline } from "react-icons/ti";

const AdminRequests = ({ onLoadingChange }) => {
    const { theme } = useSelector((state) => state.theme);
    const { userInfo } = useSelector((state) => state?.auth || {});
    const dispatch = useDispatch();
    const [data, setData] = useState({
        timeOffReq: 0,
        timesheetReq: 0,
        overtimeReq: 0
    });
    const [pendingRequests, setPendingRequests] = useState([]);
    const [isRefreshing, setIsRefreshing] = useState(false);
    const [loadingRequest, setLoadingRequest] = useState([]);

    const cards = [
        { count: data?.timeOffReq, label: "Time-Off Requests", icon: <FaRegClock className="w-6 h-6" />, color: "bg-yellow-500" },
        { count: data?.timesheetReq, label: "Timesheet Requests", icon: <AiOutlineFileDone className="w-6 h-6" />, color: "bg-orange-500" },
        { count: data?.overtimeReq, label: "Overtime Requests", icon: <BsClipboardData className="w-6 h-6" />, color: "bg-green-500" },
    ];

    const getData = async () => {
        try {
            onLoadingChange(true);
            const res = await fetchApi.get('admin-dashboard-approval-request/', {
                headers: {
                    'Authorization': `Bearer ${userInfo?.access_token}`
                }
            });
            if (res.status === 200) {
                setData({
                    timeOffReq: res.data?.timeoff,
                    timesheetReq: res.data?.timesheet,
                    overtimeReq: res.data?.overtime
                });
                setPendingRequests(res.data?.pending_requests || []);
            }
        } catch (error) {
            dispatch(addToast({
                message: error?.response?.data?.message || 'Something went wrong',
                type: 'error'
            }));
        } finally {
            onLoadingChange(false);
        }
    };
    const updateData = async () => {
        try {
            const res = await fetchApi.get('admin-dashboard-approval-request/', {
                headers: {
                    'Authorization': `Bearer ${userInfo?.access_token}`
                }
            });
            if (res.status === 200) {
                setData({
                    timeOffReq: res.data?.timeoff,
                    timesheetReq: res.data?.timesheet,
                    overtimeReq: res.data?.overtime
                });
                setPendingRequests(res.data?.pending_requests || []);
            }
        } catch (error) {
            dispatch(addToast({
                message: error?.response?.data?.message || 'Something went wrong',
                type: 'error'
            }));
        }
    };

    const refreshData = async () => {
        setIsRefreshing(true);
        await getData();
        setIsRefreshing(false);
    };

    const handleApprove = async (item) => {
        setLoadingRequest((prev) => [...prev, { id: item.id, action: 'approve' }]);
        const endpoints = {
            'Time-off': 'user-timeoffs',
            'Overtime': 'employee-overtimes',
            'Timesheet Approval': 'company-user-invoices'
        };
        let data = {};
        if (item.request_type === 'Timesheet Approval') {
            data = {
                is_approved: true,
            };
        } else {
            data = {
                status: 'approved'
            };
        }

        const endpoint = endpoints[item.request_type] || 'company-user-invoices'; // Fallback to invoices if type is unknown

        try {
            const res = await fetchApi.patch(
                `${endpoint}/${item.id}/`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${userInfo?.access_token}`
                    }
                }
            );

            if (res?.status === 200 || res?.ok) {
                dispatch(addToast({ message: 'Request approved successfully', type: 'success' }));
                updateData(); // Refresh data
            } else {
                throw new Error('Failed to approve request');
            }
        } catch (error) {
            dispatch(addToast({
                message: error?.response?.data?.message || error.message || 'Failed to approve request',
                type: 'error'
            }));
        } finally {
            setLoadingRequest((prev) => prev.filter((req) => req.id !== item.id || req.action !== 'approve'));
        }
    };


    const handleReject = async (item) => {
        setLoadingRequest((prev) => [...prev, { id: item.id, action: 'reject' }]);
        const endpoints = {
            'Time-off': 'user-timeoffs',
            'Overtime': 'employee-overtimes',
            'Timesheet Approval': 'company-user-invoices'
        };

        const endpoint = endpoints[item.request_type] || 'company-user-invoices'; // Fallback to invoices if type is unknown
        if (endpoint === 'company-user-invoices') {
            dispatch(addToast({ message: 'You can not reject this request', type: 'error' }));
            return
        }

        try {
            const res = await fetchApi.patch(`${endpoint}/${item.id}/`, { status: 'rejected' }, {
                headers: {
                    'Authorization': `Bearer ${userInfo?.access_token}`
                }
            });
            if (res.status === 200) {
                dispatch(addToast({ message: 'Request rejected successfully', type: 'success' }));
                updateData(); // Refresh data
            }
        } catch (error) {
            dispatch(addToast({
                message: error?.response?.data?.message || 'Failed to reject request',
                type: 'error'
            }));
        } finally {
            setLoadingRequest((prev) => prev.filter((req) => req.id !== item.id || req.action !== 'reject'));
        }
    };

    const handleDelete = async (item) => {
        setLoadingRequest((prev) => [...prev, { id: item.id, action: 'delete' }]);
        const endpoints = {
            'Time-off': 'user-timeoffs',
            'Overtime': 'employee-overtimes',
            'Timesheet Approval': 'company-user-invoices'
        };

        const endpoint = endpoints[item.request_type] || 'company-user-invoices'; // Fallback to invoices if type is unknown

        try {
            const res = await fetchApi.delete(`${endpoint}/${item.id}/`, {
                headers: {
                    'Authorization': `Bearer ${userInfo?.access_token}`
                }
            });
            if (res.status === 200) {
                dispatch(addToast({ message: 'Request deleted successfully', type: 'success' }));
                updateData(); // Refresh data
            }
        } catch (error) {
            dispatch(addToast({
                message: error?.response?.data?.message || 'Failed to delete request',
                type: 'error'
            }));
        } finally {
            setLoadingRequest((prev) => prev.filter((req) => req.id !== item.id || req.action !== 'delete'));
        }
    };

    useEffect(() => {
        getData();
    }, []);
    const isLoading = (id, action) => {
        return loadingRequest.some((req) => req.id === id && req.action === action);
    };


    return (
        <div className={`rounded-[20px] shadow-lg h-full customFont p-4 ${theme === 'dark' ? 'bg-[#1C1C1E] text-white' : 'bg-white text-black'}`}>
            {/* Header Section */}
            <div className='flex justify-between items-center'>
                <div className='flex gap-2 items-center'>
                    <span className='bg-gradient-to-r from-[#CC0927] to-[#7E0105] p-2 rounded-[10px]'>
                        <RiChatNewLine className='w-6 h-6 text-white' />
                    </span>
                    <h1 className='text-lg font-semibold'>Requests & Approvals</h1>
                </div>
                <div
                    className={`p-2 flex items-center justify-center rounded-[10px] border cursor-pointer ${theme === 'dark' ? 'bg-[#252529] border-[#555657]' : 'bg-[#F2F4FA] border-[#D8D8D8]'}`}
                    onClick={refreshData}
                >
                    {isRefreshing ? (
                        <img src={loader} alt="Loading..." className="w-5 h-5" />
                    ) : (
                        <FiRefreshCw className={`w-5 h-5`} />
                    )}
                </div>
            </div>

            {/* Cards Section */}
            <div className="mt-4">
                <Swiper
                    spaceBetween={20}
                    slidesPerView={1} // Show 2 slides by default
                    breakpoints={{
                        640: { slidesPerView: 1.5 },
                        768: { slidesPerView: 2 },
                        1024: { slidesPerView: 3 },
                        1280: { slidesPerView: 2.3 },
                        1390: { slidesPerView: 3 },
                    }}
                    loop={true}
                    touchEventsTarget="container"
                    grabCursor={true}
                >
                    {cards.map((card, index) => (
                        <SwiperSlide key={index}>
                            <div
                                className={`p-6 border rounded-[20px] flex items-center gap-4 
                                ${theme === "dark" ? "bg-[#212529] border-[#555657]" : "bg-[#F2F4F7] border-[#D8D8D8]"}`}
                            >
                                <span className={`p-4 rounded-full ${card.color} ${theme === "dark" ? "text-black" : "text-white"}`}>
                                    {card.icon}
                                </span>
                                <div>
                                    <h1 className="text-2xl font-bold">{card.count}</h1>
                                    <h4 className="text-sm">{card.label}</h4>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>

            {/* Pending Requests Section */}
            <div className='mt-6'>
                <h2 className={`text-lg font-semibold mb-4 ${theme === 'dark' ? 'text-[#555657]' : 'text-[#212529]'}`}>
                    Pending Requests
                </h2>
                <div className={`border rounded-lg overflow-hidden ${theme === 'dark' ? 'border-[#555657]' : 'border-[#D8D8D8]'}`}>
                    <div className='overflow-x-auto'>
                        {/* Table container with fixed header and scrollable body */}
                        <div className='relative max-h-[365px] overflow-y-auto'>
                            <table className='w-full'>
                                <thead className='sticky top-0 z-10'>
                                    <tr className={`${theme === 'dark' ? 'bg-[#212529]' : 'bg-[#F2F4F7]'} text-sm`}>
                                        <th className='p-3 text-left w-1/4'>User</th>
                                        <th className='p-3 text-left w-1/4'>Request Type</th>
                                        <th className='p-3 text-left w-1/4'>Date</th>
                                        <th className='p-3 text-left w-1/4'>Actions</th>
                                    </tr>
                                </thead>
                                <tbody className={`divide-y ${theme === 'dark' ? 'divide-[#555657]' : 'divide-[#D8D8D8]'}`}>
                                    {pendingRequests.length > 0 ? (
                                        pendingRequests.map((req, idx) => (
                                            <tr key={idx}>
                                                <td className='p-2'>
                                                    <div className='flex items-center gap-2'>

                                                        {req.user}
                                                    </div>
                                                </td>
                                                <td className='p-2'>{req.request_type}</td>
                                                <td className='p-2 whitespace-nowrap'>{req.date}</td>
                                                <td className='p-2'>
                                                    <div className='flex gap-2'>
                                                        {/* Approve Button */}
                                                        <Tooltip title="Approve" arrow={true} placement="top">
                                                            <div
                                                                className={`p-1 flex items-center justify-center rounded-[10px] border cursor-pointer ${theme === 'dark' ? 'bg-[#252529] border-[#555657]' : 'bg-[#F2F4FA] border-[#D8D8D8]'}`}
                                                                onClick={() => handleApprove(req)}
                                                            >
                                                                {isLoading(req.id, 'approve') ? (
                                                                    <img src={loader} alt="Loading..." className="w-5 h-5" />
                                                                ) : (
                                                                    <FaRegCheckCircle className={`w-5 h-5 ${theme === 'dark' ? 'text-gray-300' : 'text-gray-600'}`} />
                                                                )}
                                                            </div>
                                                        </Tooltip>

                                                        {/* Reject Button */}
                                                        <Tooltip title="Reject" arrow={true} placement="top">
                                                            <div
                                                                className={`p-1 flex items-center justify-center rounded-[10px] border cursor-pointer ${theme === 'dark' ? 'bg-[#252529] border-[#555657]' : 'bg-[#F2F4FA] border-[#D8D8D8]'}`}
                                                                onClick={() => handleReject(req)}
                                                            >
                                                                {isLoading(req.id, 'reject') ? (
                                                                    <img src={loader} alt="Loading..." className="w-5 h-5" />
                                                                ) : (
                                                                    <TiDeleteOutline className={`w-5 h-5 ${theme === 'dark' ? 'text-gray-300' : 'text-gray-600'}`} />
                                                                )}
                                                            </div>
                                                        </Tooltip>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="4" className="p-4 text-center">No requests found</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminRequests;
