import React, { useEffect } from 'react';
import Navbar from '../layout/Navbar';
import HeroSection from './heroSection/HeroSection';
import BoostProductivity from './boostProductivity/BoostProductivity';
import FeaturesSection from './featuresSection/FeacturesSection';
import Footer from '../layout/Footer';
import HowItWorks from './howItWork/HowItWork';
import ContactUs from './contactUs/ContactUs';
import ParticlesBackground from './ParticalsBackground';
import GoToTop from '../goToTop/GoToTop';
import { useDispatch, useSelector } from 'react-redux';
import SubscriptionPage from './pricingSection/subscription/SubscriptionPage';
import SubscriptionPages from '../subcriptions/SubscriptionPage';
import { updatePlans,updatePlansLoading } from '../../Redux/Slices/all-plan-slice';
import { fetchApi } from '../../utlis/axios';
import { toast } from 'react-toastify';
import Gallery from './gallery/Gallery';
import PrimaryFooter from '../layout/PrimaryFooter'

const HomePage = () => {
  const { theme } = useSelector((state) => state.theme);
    const { userInfo } = useSelector((state) => state?.auth || {});

    const dispatch=useDispatch()
    const order = ["Basic", "Standard", "Premium", "Enterprise"];
    let isFetched=false
    const getPlans = async () => {
      if(isFetched) return;
      isFetched=true;
      dispatch(updatePlansLoading(true));
        try {
          const result = await fetchApi.get("plans/");
          if (result.status === 200) {
            // Sort the array based on the order
            result.data.sort(
              (a, b) => order.indexOf(a.name) - order.indexOf(b.name)
            );
            let res = result.data;
            res.push({
              id: "enterprise",
              name: "Enterprise",
              description: "Fixed Payment For Enterprise",
              interval : "year"
            });
            res.push({
              id: "enterprise",
              name: "Enterprise",
              description: "Fixed Payment For Enterprise",
              interval : "month"
            });
            dispatch(updatePlans(result.data))
          }
        } catch (error) {
          dispatch(updatePlansLoading(false))
          toast.dismiss();
          toast.error("Something went wrong");
          console.log(error);
        } finally {
          dispatch(updatePlansLoading(false))
        }
      };
      useEffect(()=>{
        getPlans()
      },[])
  return (
    <div className={`home ${theme === 'dark' ? 'text-white' : 'text-black'}`}>
      <GoToTop/>
      <ParticlesBackground id="particaljs"/> 
      <Navbar />
      <HeroSection />
      <Gallery/>
      <BoostProductivity />
      <FeaturesSection />
     {userInfo?.user?.is_primary_admin ?  <SubscriptionPages parent='homes'/> :<SubscriptionPage/>}
      <HowItWorks />
      <ContactUs />
      <PrimaryFooter/>
    </div>
  );
};

export default HomePage;
