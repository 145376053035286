import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchApi } from "../../../utlis/axios";
import styles from "./timesheet.module.css";
import Loader from "../../Loader/Loader";
import { FaCheck, FaAngleDown, FaAngleRight } from "react-icons/fa";
import { Tooltip } from "@mui/material";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { CiSearch } from "react-icons/ci";
import loader from '../../../assets/loading-gif.gif'
import { addToast } from "../../../Redux/Slices/toast-slice"; 

const AdminTimeSheet = () => {
  const { userInfo } = useSelector((state) => state.auth || {});
  const { theme } = useSelector((state) => state.theme);
  const dispatch=useDispatch()
  const [timeSheetsData, setTimesheetsData] = useState([]);
  const [dataWithoutSearches, setDataWithoutSearches] = useState([]);
  const [otsData, setOTSData] = useState([]);
  const [next, setNext] = useState(null);
  const [prev, setPrev] = useState(null);
  const [expandedRow, setExpandedRow] = useState("");
  const [loading, setLooading] = useState(false);
  const [activeTab, setActiveTab] = useState("pending");
  const [searchTerm, setSearchTerm] = useState("");
  const [searching, setSearching]=useState(false);
  const [rejectingStates, setRejectStates] = useState({})
  const [approvingStates, setApprovingStates] = useState({});
  const [deletingStates, setDeletingStates] = useState({})

  const getAllTimeSheet = async () => {
    setLooading(true);
    try {
      const res = await fetchApi.get(`company-user-invoices/?status=${activeTab}`, {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });
      if (res.status === 200) {
        setOTSData(res.data.results);
        setDataWithoutSearches(res.data.results);
        setNext(res.data.next);
        setPrev(res.data.previous);
      }
    } catch (error) {
      dispatch(addToast({ message:error.message, type:'error' })); 
    } finally {
      setLooading(false);
    }
  };

  useEffect(() => {
    getAllTimeSheet();
  }, [activeTab]);

  const handlePageChange = async (url) => {
    setLooading(true);
    try {
      const res = await fetchApi.get(`${url}`, {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });
      if (res.status === 200) {
        setDataWithoutSearches(res.data.results);
        setOTSData(res.data.results);
        setNext(res.data.next);
        setPrev(res.data.previous);
      }
    } catch (error) {
      dispatch(addToast({ message:error.message, type:'error' })); 
    } finally {
      setLooading(false);
    }
  };
  const updateTimeSheet = async () => {
    try {
      const res = await fetchApi.get(`company-user-invoices/?status=${activeTab}`, {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });
      if (res.status === 200) {
        setOTSData(res.data.results);
        setDataWithoutSearches(res.data.results);
        setNext(res.data.next);
        setPrev(res.data.previous);
      }
    } catch (error) {
      dispatch(addToast({ message:error.message, type:'error' })); 
    }
  };
  const handleApprove = async (id) => {
    setApprovingStates((state)=> ({...state, [id]:true}))
    try {
      const res = await fetchApi.patch(
        `company-user-invoices/${id}/`,
        { is_approved: true },
        {
          headers: {
            Authorization: `Bearer ${userInfo.access_token}`,
          },
        }
      );
      if (res.status === 200) {
        dispatch(addToast({ message:"Approved successfully", type:'success' })); 
        updateTimeSheet();
      }
    } catch (error) {
      dispatch(addToast({ message:error.message, type:'error' })); 
    }
    setApprovingStates((prev) => ({ ...prev, [id]: false })); 
  };
  const handleDelete = async (id) => {
    setDeletingStates((state)=> ({...state, [id]:true}))
// Optimistically remove item

    try {
        const res = await fetchApi.delete(`company-user-invoices/${id}/`, {
            headers: {
                Authorization: `Bearer ${userInfo.access_token}`,
            },
        });
        setOTSData(prev => prev.filter(item => item.id !== id)); 
        updateTimeSheet(); // Refresh data
    } catch (error) {
        dispatch(addToast({ message: error.message, type: "error" }));
    }finally{
      setDeletingStates((state)=> ({...state, [id]:false}))
    }
};

  const handleSearch = async () => {
   if(!searchTerm){
    dispatch(addToast({ message:"Please enter search query", type:'warn' })); 
   }else{
    setSearching(true)
    try {
    const res = await fetchApi.get(`company-user-invoices/?search=${searchTerm}&status=${activeTab}`, {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });
      if (res.status === 200) {
        setOTSData(res.data.results);
        setNext(res.data.next);
        setPrev(res.data.previous);
      }
    } catch (error) {
      dispatch(addToast({ message:error.message, type:'error' })); 
    }finally{
      setSearching(false)
    }
   }
  };
  const toggleRow = (index) => {
    setExpandedRow((prevIndex) => (prevIndex === index ? null : index));
  };
  const getTabStyles = (tabName) => {
    const isActive = activeTab === tabName;
    const activeColor =
      theme === "dark" ? "bg-red-600 text-white" : "bg-red-600 text-white";
    const inactiveColor =
      theme === "dark"
        ? "bg-[#2A2A2E] text-white"
        : "bg-gray-100 text-gray-600";

    return `w-1/2 text-center outline-none py-2 font-bold transition-colors duration-300 ${
      isActive ? activeColor : inactiveColor
    }`;
  };

  useEffect(() => {
    if (activeTab === "pending") {
      setTimesheetsData(otsData.filter((data) => data.is_approved === false));
    } else {
      setTimesheetsData(otsData.filter((data) => data.is_approved === true));
    }
  },[activeTab,otsData]);

  return (
    <div className=" h-full">
      {loading && (
        <>
          <div
            className="fixed inset-0 bg-black bg-opacity-50 z-10"
            style={{ top: "95px" }}
          ></div>
          <div
            className="fixed inset-0 flex items-center justify-center z-20"
            style={{ top: "95px" }}
          >
            <Loader />
          </div>
        </>
      )}
        <>
          {" "}
          <div className="flex w-full justify-center items-center">
          <div
            className={`flex justify-between items-center mb-4 mt-4 max-w-screen-sm w-full rounded-3xl ${
              theme === "dark" ? "bg-gray-900" : "bg-gray-200"
            }`}
          >
            <button
              className={`${getTabStyles("pending")} rounded-l-3xl`}
              onClick={() => setActiveTab("pending")}
            >
              Pending
            </button>
            <button
              className={`${getTabStyles("approved")} rounded-r-3xl`}
              onClick={() => setActiveTab("approved")}
            >
              Approved
            </button>
          </div>
          </div>
          <div className="mt-2 mb-3">
            <div
              className={`py-2 px-3 justify-between flex gap-2 rounded-3xl md:w-1/2 bg-transparent outline-none border  ${
                theme === "dark"
                  ? "border-[#ff0000]"
                  : "border-gray-600 text-black"
              }`}
            >
              <input
                type="text"
                value={searchTerm}
                placeholder="Search here"
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                  if (!e.target.value) {
                    setOTSData(dataWithoutSearches);
                    setNext(null);
                    setPrev(null);
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSearch();
                  }
                }}
                className="px-1 w-[87%] bg-transparent outline-none"
              />
              {!searching ? 
              <CiSearch
                className="w-6 h-6 cursor-pointer"
                onClick={handleSearch}
              /> : <img src={loader} alt="Loading..." className="w-6 h-6"/>}
            </div>
          </div>
          <div className={`${styles.tableResponsive} max-w-screen-lg mt-8`}>
            <table
              className={`${theme === "dark" ? "text-white" : "text-black"} ${
                styles.table
              }`}
            >
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Amount</th>
                  <th>OverTime Amount</th>
                  <th>Total Amount</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {timeSheetsData.length > 0 ? (
                  timeSheetsData.map((item, i) => (
                    <>
                      <tr
                        key={i}
                        className={` ${
                          theme === "dark"
                            ? "hover:bg-gradient-to-r from-[#fff3] to-[#fff0]"
                            : "hover:bg-gradient-to-r from-[#6d6d6d33] to-[#fff0]"
                        }
                    ${
                      expandedRow === i
                        ? "border-b-0"
                        : "border-b border-red-500"
                    }
                    `}
                      >
                        <td
                          style={{ borderBottom: "none" }}
                          onClick={() => toggleRow(i)}
                          className="cursor-pointer flex gap-1 items-center"
                        >
                          {expandedRow === i ? (
                            <FaAngleDown />
                          ) : (
                            <FaAngleRight />
                          )}
                          {item.title}
                        </td>
                        <td style={{ borderBottom: "none" }}>{item.amount}</td>
                        <td style={{ borderBottom: "none" }}>
                          {item.overtime_amount}
                        </td>
                        <td style={{ borderBottom: "none" }}>
                          {item.total_amount}
                        </td>
                        <td style={{ borderBottom: "none" }}>
                          {item?.is_approved ? (
                            <span className="bg-green-600 text-white h-[30px] w-[80px] flex rounded-[20px] justify-center items-center">
                              Approved
                            </span>
                          ) : (
                            <span className="bg-yellow-600 text-white h-[30px] w-[80px] flex rounded-[20px] justify-center items-center">
                              Pending
                            </span>
                          )}
                        </td>
                        <td style={{ borderBottom: "none" }}>
                          {item.is_approved ? (
                            <Tooltip title="Delete" arrow placement="top">
                              <button
                                className="cursor-pointer outline-none"
                                onClick={() => handleDelete(item.id)} disabled={deletingStates[item.id]}
                              >
                               {deletingStates[item.id] ? <img src={loader} alt="loadin" className="w-5 h-5" /> : <MdOutlineDeleteOutline className="w-5 h-5 cursor-pointer" />}
                              </button>
                            </Tooltip>
                          ) : (
                            <Tooltip title="Approve" arrow placement="top">
                              <button
                                className="cursor-pointer outline-none"
                                disabled={approvingStates[item.id]}
                                onClick={() => handleApprove(item.id)}
                              >
                                {approvingStates[item.id] ? <div className="w-7 h-7 justify-center items-center flex"><img src={loader} alt="loader" className="w-5 h-5" /></div> :
                                <FaCheck className="w-5 h-5" /> }
                              </button>
                            </Tooltip>
                          )}
                        </td>
                      </tr>
                      {expandedRow === i && (
                        <tr>
                          <td
                            colSpan={6}
                            className={`p-4 ${
                              theme === "dark"
                                ? " text-white bg-gradient-to-r from-[#fff3] to-[#fff0]"
                                : " text-black bg-gradient-to-r from-[#6d6d6d33] to-[#fff0]"
                            }`}
                          >
                            <div className={` p-4 rounded`}>
                              <strong>Full Description:</strong>{" "}
                              {item.description}
                            </div>
                          </td>
                        </tr>
                      )}
                    </>
                  ))
                ) : (
                  <tr className="flex m-2">
                    No data found
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="w-full  my-2 justify-between flex">
            <span>
              {prev && (
                <button
                  className="heroButton block"
                  onClick={() => handlePageChange(prev)}
                >
                  Previous
                </button>
              )}
            </span>
            <span>
              {next && (
                <button
                  className="heroButton block"
                  onClick={() => handlePageChange(next)}
                >
                  Next
                </button>
              )}
            </span>
          </div>
        </>
    </div>
  );
};

export default AdminTimeSheet;
