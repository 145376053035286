import React, { useEffect,useState } from 'react';
import { MdManageSearch, MdPendingActions } from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';
import { FiRefreshCw } from 'react-icons/fi';
import { FaFileCircleCheck } from 'react-icons/fa6';
import { BsGraphDownArrow, BsGraphUpArrow, BsPatchCheckFill } from 'react-icons/bs';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import './admin.css';
import { FaUserCircle } from 'react-icons/fa';
import { fetchApi } from "../../../utlis/axios";
import { addToast } from '../../../Redux/Slices/toast-slice';
import loader from "../../../assets/loading-gif.gif";


function isPositiveTrend(value) {
    if (typeof value !== "string") return false; // Ensure it's a string

    // Convert to number after removing `%` sign
    const numericValue = parseFloat(value);

    return numericValue > 0; // Returns true if positive, false otherwise
}

const AdminOverview = ({ onLoadingChange }) => {
    const { theme } = useSelector((state) => state.theme);
    const { userInfo } = useSelector((state) => state?.auth || {});
    const [adminOverviewData, setAdminOverviewData] =useState({
        totalUsers: 0,
        activeProjects: 0,
       pendingTasks: 0,
       resolvedTickets: 0
    });
    const [isRefreshing, setIsRefreshing] = useState(false);
    const dispatch = useDispatch();

    const getData = async () => {
        try {
            onLoadingChange(true); // Set loading state
            const res = await fetchApi.get('admin-dashboard-overview/', {
                headers: {
                    'Authorization': `Bearer ${userInfo?.access_token}`
                }
            });
            if(res.status === 200){
                const data=res.data
                setAdminOverviewData({
                    totalUsers: data?.total_users,
                    activeProjects: data?.active_projects,
                    pendingTasks: data?.pending_tasks,
                    resolvedTickets: data?.resolved_tickets
                })
            }
        } catch (error) {
            dispatch(addToast({
                message: error?.response?.data?.message || 'Something went wrong',
                type: 'error'
            }))
        } finally {
            onLoadingChange(false); // Clear loading state
        }
    }

    const refreshData = async () => {
        setIsRefreshing(true);
        await getData();
        setIsRefreshing(false);
    };

    useEffect(() => {
        getData()
    }, [])
    const cards = [
        { id: 1, title: "Active Projects", value: adminOverviewData.activeProjects , trend: "+5%", trendColor: "text-green-500", bgColor: "bg-green-100", icon: <FaFileCircleCheck className=" w-8 h-8" /> },
        { id: 2, title: "Total Users", value: adminOverviewData.totalUsers, trend: "+5%", trendColor: "text-orange-500", backgroundD: '#FFBD9C', backgroundL: '#D28A6C', icon: <FaUserCircle className=" w-8 h-8" /> },
        { id: 3, title: "Pending Tasks", value: adminOverviewData?.pendingTasks, trend: "-2.5%", trendColor: "text-red-500", bgColor: "bg-purple-100", icon: <MdPendingActions className=" w-8 h-8" /> },
        { id: 4, title: "Resolved Tickets", value: adminOverviewData.resolvedTickets, trend: "+5%", trendColor: "text-yellow-500", bgColor: "bg-yellow-100", icon: <BsPatchCheckFill className=" w-8 h-8" /> },
    ];
    return (
        <div className={`rounded-[20px] shadow-lg customFont py-4 px-4 ${theme === 'dark' ? 'bg-[#1C1C1E] text-white' : 'bg-white text-black'}`}>
            {/* Header */}
            <div className='flex justify-between items-center'>
                <div className='flex gap-2 items-center'>
                    <span className='bg-gradient-to-r from-[#CC0927] to-[#7E0105] p-2 rounded-[10px]'>
                        <MdManageSearch className='w-6 h-6 text-white' />
                    </span>
                    <h1 className='text-lg font-semibold'>Admin Overview</h1>
                </div>
                <div 
                    className={`p-2 flex items-center justify-center rounded-[10px] border cursor-pointer ${theme === 'dark' ? 'bg-[#252529] border-[#555657]' : 'bg-[#F2F4FA] border-[#D8D8D8]'}`}
                    onClick={refreshData}
                >
                    {isRefreshing ? (
                        <img src={loader} alt="Loading..." className="w-5 h-5" />
                    ) : (
                        <FiRefreshCw className={`w-5 h-5`} />
                    )}
                </div>
            </div>

            {/* Swiper Slider */}
            <Swiper
                modules={[Pagination]}
                spaceBetween={20}
                slidesPerView={1}
                pagination={{ clickable: true }}
                breakpoints={{
                    640: { slidesPerView: 1.8 },
                    1024: { slidesPerView: 2 },
                    1280: { slidesPerView: 2.3 },
                    1520: { slidesPerView: 4 },
                }}
                className="mt-4"
            >
                {cards.map((card, index) => (
                    <SwiperSlide key={index}>
                        <div className={`p-6 border rounded-[20px] ${theme === 'dark' ? 'bg-[#212529] border-[#555657]' : 'bg-[#F2F4F7] border-[#D8D8D8]'}`}>
                            <div className="flex gap-4 items-center">
                                <span className={`${card.id === 2 ? theme === 'dark' ? 'bg-[#FFBD9C]' : "bg-[#D28A6C]" : card.id === 1 ? theme === 'dark' ? 'bg-[#B4E3CA]' : 'bg-[#5FA780]' : card.id === 3 ? theme === 'dark' ? 'bg-[#CABDFD]' : 'bg-[#7A6ACF]' : card.id === 4 ? theme === 'dark' ? 'bg-[#FFD891]' : 'bg-[#D9AE5F]' : ''} p-3 flex items-center justify-center rounded-full ${theme === 'dark' ? 'text-[#1C1C1E]' : 'text-white'}`} >
                                    {card.icon}
                                </span>
                                <div className='flex flex-col'>
                                    <h1 className={`text-[32px] font-semibold ${card.id === 2 ? theme === 'dark' ? 'text-[#FFBD9C]' : "text-[#D28A6C]"
                                        : card.id === 1 ? theme === 'dark' ? 'text-[#B4E3CA]' : 'text-[#5FA780]'
                                            : card.id === 3 ? theme === 'dark' ? 'text-[#CABDFD]' : 'text-[#7A6ACF]'
                                                : card.id === 4 ? theme === 'dark' ? 'text-[#FFD891]' : 'text-[#D9AE5F]'
                                                    : ''}
`}>{card.value}</h1>
                                    <h4 className='text-[13px] font-semibold'>{card.title}</h4>
                                </div>
                            </div>
                            <div className='flex gap-2 items-center mt-2 text-sm font-thin'>
                                {isPositiveTrend(card.trend) ? <BsGraphUpArrow className='text-green-600 w-6 h-6' /> : <BsGraphDownArrow className='text-red-600 w-6 h-6' />}
                                <span>{card.trend} from yesterday</span>
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>

            {/* Pagination Dots */}
            <div className="swiper-pagination"></div>
        </div>
    );
};

export default AdminOverview;