import React,{useState, useEffect} from "react";
import AdminOverview from "./AdminOverview";
import { useSelector } from "react-redux";
import TaskProgressMeter from "./TaskProgress";
import AdminRequests from "./AdminRequests";
import UserManagement from "./UserManagment";
import CustomCalendar from "./Calender";
import UserProgressDashboard from "./UserProgressDashboard";
import ProjectProgressChart from "./charts/ProjectProgressChart";
import './admin.css'; // Ensure to import the CSS file
import Loader from "../../Loader/Loader";

const Admin = () => {
  const { theme } = useSelector((state) => state.theme);
  const dummyData = {
    totalTrackedTime: 120,
    activeTime: 70,
    activeTimePercentage: 39,
    mouseProductivity: 50,
    keyboardProductivity: 20,
    weeklyHoursWorked: [3.5, 1.1, 5.4, 1.7, 3.0], // Dummy data for bar chart
    weeklyActiveTime: [3.0, 3.46, 2.35, 2.0, 0.37], // Dummy active time
    weeklyActivePercentage: 68, // Dummy percentage for donut chart
    weeklyProductivity: [
      { day: "2025-03-01", mouse: 20, keyboard: 10 },
      { day: "2025-03-02", mouse: 50, keyboard: 30 },
      { day: "2025-03-03", mouse: 60, keyboard: 40 },
      { day: "2025-03-04", mouse: 80, keyboard: 45 },
      { day: "2025-03-05", mouse: 90, keyboard: 50 },
    ],
  };
  const [isLoading, setIsLoading] = useState(true);
  const [loadingStates, setLoadingStates] = useState({
    adminOverview: true,
    taskProgress: true,
    adminRequests: true,
    userProgress: true
  });

  // Function to update individual loading states
  const updateLoadingState = (component, state) => {
    setLoadingStates(prev => ({
      ...prev,
      [component]: state
    }));
  };

  // Check if all components are loaded
  useEffect(() => {
    const allLoaded = Object.values(loadingStates).every(state => !state);
    setIsLoading(!allLoaded);
  }, [loadingStates]);

  return (
    <div
      className={`rounded-[30px] w-full mt-8 flex flex-col py-4 sm:px-4 px-1 gap-4 ${
        theme === "dark" ? "bg-[#212529]" : "bg-[#F5F6FA]"
      }`}
    >
      {/* Top Header (Always Stays Same) */}

      {/* Large Screens (1240px and above) */}
      <div className="hidden xl:flex flex-col gap-4">
        {/* Left Section */}
        <div className="flex gap-4">
          <div className="w-[70%] flex flex-col gap-4">
            <AdminOverview onLoadingChange={(state) => updateLoadingState('adminOverview', state)} />
            <div className="flex w-full gap-4 h-full">
              {/* AdminRequests & UserManagement (Equal Height) */}
              <div className="xxl:w-2/3 w-3/5 flex-1 flex flex-col min-h-[600px]">
                <AdminRequests onLoadingChange={(state) => updateLoadingState('adminRequests', state)} className="h-full" />
              </div>
              <div className="xxl:w-1/3 w-2/5 flex-1 flex flex-col min-h-[600px]">
                <UserManagement onLoadingChange={(state) => updateLoadingState('userManagement', state)} className="h-full" />
              </div>
            </div>
          </div>

          {/* Right Section */}
          <div className="w-[30%] flex flex-col gap-4 h-full">
            <TaskProgressMeter onLoadingChange={(state) => updateLoadingState('taskProgress', state)} />
            <div className="flex-1 flex flex-col md:min-h-[600px]">
              <CustomCalendar className="h-full" />
            </div>
          </div>
        </div>
        <UserProgressDashboard data={dummyData} className="w-full" onLoadingChange={(state) => updateLoadingState('userProgress', state)} />
        <ProjectProgressChart className="w-full" />
      </div>

      {/* Medium Screens (Below 1240px) */}
      <div className="hidden md:flex xl:hidden flex-col gap-4">
        <div className="flex gap-4">
         <div className="w-3/5"><AdminOverview onLoadingChange={(state) => updateLoadingState('adminOverview', state)}/></div>
         <div className="w-2/5"><TaskProgressMeter onLoadingChange={(state) => updateLoadingState('taskProgress', state)} totalTasks={95} completed={90} toDo={35} inProgress={35} progress={50} remaining={20} /></div>
        </div>
        <AdminRequests onLoadingChange={(state) => updateLoadingState('adminRequests', state)} className="w-full" />
        <div className="flex gap-4">
        <div className="w-2/5 flex-1"><UserManagement onLoadingChange={(state) => updateLoadingState('userManagement', state)}/></div>
        <div className="w-3/5 flex-1">
          <CustomCalendar className="w-3/5" />
          </div>
        </div>
        <UserProgressDashboard data={dummyData} className="w-full" onLoadingChange={(state) => updateLoadingState('userProgress', state)} />
        <ProjectProgressChart className="w-full" />
      </div>

      {/* Small Screens (Below 640px) */}
      <div className="md:hidden flex flex-col gap-4">
        <AdminOverview onLoadingChange={(state) => updateLoadingState('adminOverview', state)} className="w-full" />
        <TaskProgressMeter onLoadingChange={(state) => updateLoadingState('taskProgress', state)} className="w-full" totalTasks={95} completed={90} toDo={35} inProgress={35} progress={50} remaining={20} />
        <AdminRequests onLoadingChange={(state) => updateLoadingState('adminRequests', state)} className="w-full" />
        <UserManagement onLoadingChange={(state) => updateLoadingState('userManagement', state)} className="w-full" />
        <CustomCalendar className="w-full" />
        <UserProgressDashboard data={dummyData} className="w-full" onLoadingChange={(state) => updateLoadingState('userProgress', state)} />
        <ProjectProgressChart className="w-full" />
      </div>
      {isLoading && (
        <>
          <div
            className="fixed inset-0 bg-black bg-opacity-50 z-10 md:top-[95px] top[52px]"
          ></div>
          <div
            className="fixed inset-0 flex items-center justify-center z-20 md:top-[95px] top[52px]"
          >
            <Loader />
          </div>
        </>
      )}
    </div>
  );
};

export default Admin;
