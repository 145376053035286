import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { fetchApi } from "../../../utlis/axios";
import DropZone from "./DropZone";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Loader from "../../Loader/Loader";
import { addToast } from "../../../Redux/Slices/toast-slice";

const UserProject = () => {
  const { theme } = useSelector((state) => state.theme);
  const { userInfo } = useSelector((state) => state?.auth || {});
  const dispatch=useDispatch()
  const [loading, setLoading] = useState(false);
  const [ticketsLoading, setTicketsLoading] = useState(true);
  const [firstProjectId, setFirstProjectId] = useState(
    localStorage.getItem("firstProjectId")
  );
  const [columns, setColumns] = useState([{company:userInfo?.company?.id , id:'1', title:'todo'},{company:userInfo?.company?.id , id:'2', title:'ready for qa'},{company:userInfo?.company?.id , id:'3', title:'done'}])
  const [tickets, setTickets] = useState([
    { id: "1", ticket_status: "1" },
    { id: "2", ticket_status: "2" },
    { id: "3", ticket_status: "3" },
  ]);


  const moveCard = (id, newStatus) => {
    setTickets((prev) =>
      prev.map((ticket) =>
        ticket.id === id
          ? { ...ticket, ticket_status: { ...ticket.ticket_status, id: newStatus } }
          : ticket
      )
    );
    handleUpdateCard(id, newStatus);
  };
  const handleUpdateCard = async (id, status) => {
    try {
      const res = await fetchApi.patch(
        `project-tickets/${id}/`,
        { ticket_status: status },
        {
          headers: {
            Authorization: `Bearer ${userInfo.access_token}`,
          },
        }
      );
      if (res.status === 200) {
      }
    } catch (error) {
      dispatch(addToast({ message:error.message, type:'error' })); 
    }
  };
   const getCompanyColums = async () => {
    try {
      const res = await fetchApi.get(`company-board-columns/?domain=${userInfo.user.domain}`, {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
        }
      })
      if(res.status===200){
        setColumns(res.data.results)
      }
    } catch (error) {
      console.log(error)
      dispatch(addToast({ message: 'Something went wrong', type: 'error' }))
    }
  }
  useEffect(()=>{
    getCompanyColums()
  },[])
  const getTickets = async (id) => {
    setTicketsLoading(true);
    try {
      if(userInfo?.user?.project){
      const res = await fetchApi.post(`get-project-tickets/`,{"project_id":userInfo.user.project.id,
        "developer":userInfo.user.id
      }, {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });
      if (res.status === 200) {
        setTicketsLoading(false);
        setTickets(res.data);
      }
    }else{
     
    }
    } catch (error) {
      dispatch(addToast({ message:error.message, type:'error' })); 
    }
  };
  let projectFetched = useRef(false);
  useEffect(() => {
    if (projectFetched.current) return;
    projectFetched.current = true;
      getTickets(firstProjectId);
    
  }, []);

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="w-full customFont">
        <div
          className={` ${theme === "dark" ? "text-white" : "text-black"} px-6`}
          style={{
            width: "90%",
            maxWidth: "1020px",
            fontSize: "x-large",
            margin: "20px 0px",
          }}
        >
          Projects
        </div>
        <hr className="w-full" />

        {loading ? (
          <Loader style={{ height: "100%" }} />
        ) : (
          <div className="w-full flex md:flex-row flex-col gap-4 lg:px-8 px-4">
            <div
              className="cards_container overflow-x-scroll flex gap-4 md:w-3/4 w-full mt-8"
              style={{ minWidth: "100%" }}
              onDragOver={(e) => {
                e.preventDefault();

                const container = e.currentTarget;
                const scrollSpeed = 5; // Adjust speed for smooth scrolling
                const { left, right } = container.getBoundingClientRect();

                // Start scrolling left if cursor is near the left edge
                if (e.clientX < left + 50) {
                  if (!container.scrollInterval) {
                    container.scrollInterval = setInterval(() => {
                      container.scrollBy({ left: -scrollSpeed });
                    }, 10);
                  }
                }
                // Start scrolling right if cursor is near the right edge
                else if (e.clientX > right - 50) {
                  if (!container.scrollInterval) {
                    container.scrollInterval = setInterval(() => {
                      container.scrollBy({ left: scrollSpeed });
                    }, 10);
                  }
                } else {
                  // Clear the interval if not near the edges
                  clearInterval(container.scrollInterval);
                  container.scrollInterval = null;
                }
              }}
              onDragLeave={(e) => {
                // Clear scrolling when leaving the container
                const container = e.currentTarget;
                clearInterval(container.scrollInterval);
                container.scrollInterval = null;
              }}
              onDrop={(e) => {
                // Clear scrolling when dropping a card
                const container = e.currentTarget;
                clearInterval(container.scrollInterval);
                container.scrollInterval = null;
              }}
            >
              {columns.length > 0 &&
            columns.map((column) => (
              <DropZone
                key={column.id}
                column={column}
                tickets={tickets?.filter((ticket) => ticket?.ticket_status?.id === column.id)}
                ticketsLoading={ticketsLoading}
                moveCard={moveCard}
                theme={theme}
              />
            ))}
            </div>
          </div>
        )}
       
      </div>
    </DndProvider>
  );
};

export default UserProject;
