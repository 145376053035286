import React, { useState, useEffect, useRef } from "react";
import { MdElectricBolt, MdMenu, MdClose, MdOutlineChat } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";
import styles from "./navbar.module.css";
import logo from "../../../assets/bugtrack.png";
import { toggleAuth } from "../../../Redux/Slices/auth-slice";
import {
  FaWindows,
  FaApple,
  FaLinux,
  FaDownload,
  FaSun,
  FaMoon,
  FaDollarSign,
  FaUserCircle,
  FaUser,
} from "react-icons/fa";
import { FiBell, FiSettings, FiLogOut } from "react-icons/fi";
import profile from "../../../assets/face1.jpg";
import HourlyRate from "../Settings/hourlyRate/HourlyRate";
import { toggleTheme } from "../../../Redux/Slices/theme-slice";
import { fetchApi } from "../../../utlis/axios";
import { addToast } from "../../../Redux/Slices/toast-slice";

const Navbar = () => {
  const [isNavVisible, setIsNavVisible] = useState(false);
  const { theme } = useSelector((state) => state.theme);
  const { userInfo } = useSelector((state) => state?.auth || {});
  const [activeDropdown, setActiveDropdown] = useState(null);
  const plans = useSelector((state) => state.allPlans.plans);
  const isLoading = useSelector((state) => state.allPlans.plansIsLoading);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dropdownRef = useRef(null);
  const [showHourRate, setShowHourRate] = useState();
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setActiveDropdown(null);
    }
  };
  const [activeTab, setActivetab] = useState("dashboard");
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDropdown = (dropdown) => {
    setActiveDropdown((prev) => (prev === dropdown ? null : dropdown));
  };

  const handleLogout = async () => {
    dispatch(toggleAuth({ isLogin: false, userInfo: null }));
    navigate("/");
    try {
      const res = await fetchApi.post(
        "/logout/",
        { refresh_token: userInfo.refresh_token },
        {
          headers: {
            Authorization: `Bearer ${userInfo.access_token}`,
          },
        }
      );
    } catch (error) {}
  };

  const toggleNavVisibility = () => {
    setIsNavVisible(!isNavVisible);
  };

  return (
    <div
      className={` ${styles.header} ${
        theme === "dark" ? `${styles.dark_mode}` : `${styles.light_mode}`
      }`}
    >
      {showHourRate && (
        <div className={styles.modalOverlay}>
          <div
            className={`${styles.modal} ${
              theme === "dark"
                ? "bg-[#212529] text-white"
                : "bg-[#e5e5e5] text-black"
            }`}
          >
            <HourlyRate setShowHourRate={setShowHourRate} />
          </div>{" "}
        </div>
      )}

      <div
        className={`${styles.sticky_navbar} ${
          theme === "dark" ? `${styles.dark_mode}` : `${styles.light_mode}`
        }`}
      >
        <div className={`${styles.header_top}`}>
          <div className="flex gap-2 items-center">
            <NavLink to="/">
              <div className={`${styles.logo}`}>
                <img src={logo} className="h-6 w-6 mb-1" />
                <span>
                  <span className="text-[#FF0000] ">ug</span>Trackin
                </span>
              </div>
            </NavLink>
            <span className={`text-3xl text-gray-500 ${styles.logobar}`}>
              /
            </span>
            <span className={styles.profileName}>
              {userInfo?.user?.first_name} {userInfo?.user?.last_name}
            </span>
          </div>
          <div className={styles.nav_toggle_btn}>
            <div
              className={`text-sm ${styles.menuContainer2}`}
              ref={dropdownRef}
            >
              {userInfo?.user?.role?.name !== "Time Reporter" && (
                <div className={styles.dropdownWrapper}>
                  <button
                    onClick={() => toggleDropdown("subscription")}
                    className={`${styles.menuItem} ${styles.iconButton}`}
                  >
                    <FaDollarSign />
                  </button>
                  {activeDropdown === "subscription" && (
                    <div className={styles.dropdownContent}>
                      <h6 className={styles.dropdownTitle}>
                        Subscription Plans
                      </h6>
                      {isLoading ? (
                        <div>Loading....</div>
                      ) : (
                        plans.map((plan, index) => (
                          <div
                            className={`flex items-center justify-between gap-3 mb-2 ${
                              plan?.id === userInfo?.user?.plan?.id
                                ? "text-green-500"
                                : ""
                            }`}
                          >
                            <p className={styles.notificationText}>
                              {plan.name}
                            </p>
                            <p>
                              {plan?.amount} {plan?.currency}/month
                            </p>
                          </div>
                        ))
                      )}
                    </div>
                  )}
                </div>
              )}

              <div className={styles.dropdownWrapper}>
                <button
                  onClick={() => toggleDropdown("download")}
                  className={`${styles.menuItem} ${styles.iconButton}`}
                >
                  <FaDownload />
                </button>
                {activeDropdown === "download" && (
                  <div className={styles.dropdownContent}>
                    <h6 className={styles.dropdownTitle}>Downloads</h6>
                    <p className={styles.notificationText}>
                      <FaWindows /> Windows Agent
                    </p>
                    <p className={styles.notificationText}>
                      <FaApple /> Mac OS Agent
                    </p>
                    <p className={styles.notificationText}>
                      <FaLinux /> Linux Agent
                    </p>
                  </div>
                )}
              </div>
            </div>
            <button
              className="text-3xl hover:text-[#ff0000]"
              onClick={toggleNavVisibility}
            >
              {isNavVisible ? <MdClose /> : <MdMenu />}
            </button>
          </div>
        </div>
        <div
          className={`${styles.nav} ${styles.mobileNavbar} ${
            isNavVisible ? styles.visible : ""
          } ${
            isNavVisible
              ? theme === "dark"
                ? styles.navdark
                : styles.navlight
              : ""
          }
  text-sm`}
        >
          {userInfo?.user?.role?.name !== "Time Reporter" &&
            (userInfo?.user?.plan?.name === "Premium" ||
              userInfo?.user?.plan?.is_enterprise) && (
              <Link
                to={`${userInfo?.company ? '/dashboard' : ''}`}
                onClick={()=>{
                  toggleNavVisibility()
                  if(!userInfo?.company){
                    dispatch(addToast({
                      message: 'Please create a company first', type:'error'
                    }))
                  }
                }}
                className={` ${styles.menuItem} ${styles.iconButton} text-sm`}
              >
                Dashboard
              </Link>
            )}
          {/* Summary */}
          <NavLink
            to="/dashboard/summary"
            className={({ isActive }) =>
              `${styles.menuItem} ${styles.iconButton} text-sm ${
                isActive ? styles.active : ""
              }`
            }
            onClick={toggleNavVisibility}
          >
            Summary
          </NavLink>

          {/* Activity */}
          <NavLink
            to="/dashboard/activity"
            className={({ isActive }) =>
              `${styles.menuItem} ${styles.iconButton} text-sm ${
                isActive ? styles.active : ""
              }`
            }
            onClick={toggleNavVisibility}
          >
            Activity
          </NavLink>

          {/* Admin only: Users */}
          {userInfo?.user?.role?.name !== "Time Reporter" && (
            <NavLink
              to="/dashboard/users"
              className={({ isActive }) =>
                `${styles.menuItem} ${styles.iconButton} text-sm ${
                  isActive ? styles.active : ""
                }`
              }
              onClick={toggleNavVisibility}
            >
              Users
            </NavLink>
          )}

          {/* Non-Time Reporter: Analytics & Charts */}
          {userInfo?.user?.role?.name !== "Time Reporter" &&
            userInfo?.user?.plan?.name === "Standard" && (
              <NavLink
                to="/dashboard/analytics_charts"
                className={({ isActive }) =>
                  `${styles.menuItem} ${styles.iconButton} text-sm ${
                    isActive ? styles.active : ""
                  }`
                }
                onClick={toggleNavVisibility}
              >
                Analytics & Charts
              </NavLink>
            )}
          {userInfo?.user?.role?.name !== "Time Reporter" &&
            userInfo?.user?.plan?.name !== "Basic" && (
              <NavLink
                to="/dashboard/reports"
                className={({ isActive }) =>
                  `${styles.menuItem} ${styles.iconButton} text-sm ${
                    isActive ? styles.active : ""
                  }`
                }
                onClick={toggleNavVisibility}
              >
                Reports
              </NavLink>
            )}
          {(userInfo?.user?.role?.name !== "Time Reporter" && !userInfo?.user?.plan.is_enterprise) && (
            <Link
              to="/dashboard/changeplan"
              className={`${styles.menuItem} ${styles.iconButton} text-sm`}
              onClick={toggleNavVisibility}
            >
              Subscription
            </Link>
          )}
          <>
            {userInfo?.user?.role?.name !== "Time Reporter" &&
              userInfo?.user?.plan?.name !== "Basic" && (
                <Link
                  to="/dashboard/updatecompany"
                  className={`${styles.menuItem} ${styles.iconButton} text-sm`}
                  onClick={toggleNavVisibility}
                >
                  Company
                </Link>
              )}

            <Link
              to="/dashboard/invoices"
              className={`${styles.menuItem} ${styles.iconButton} text-sm`}
              onClick={toggleNavVisibility}
            >
              Payments
            </Link>
          </>

          {/* Projects (Conditional route) */}
          {userInfo?.user?.plan?.name !== "Basic" && (
            userInfo?.user?.role?.name !== "Time Reporter" ?
            <Link
              to="/dashboard/board"
              className={`${styles.menuItem} ${styles.iconButton} text-sm`}
              onClick={toggleNavVisibility}
            >
              Projects
            </Link>
        :
            <div
              className={`${styles.menuItem} ${styles.iconButton} text-sm`}
              onClick={()=>{
                toggleNavVisibility()
              if(userInfo.user?.project){
                navigate(`/dashboard/project`)
              }else{
                dispatch(addToast({
                  message: 'You have no project assigned', type:'error'
                }))
              }
              }}
            >
              Projects
            </div>
          )}

          {/* Settings */}
          <NavLink
            to="/dashboard/setting/personalinformation"
            className={({ isActive }) =>
              `${styles.menuItem} ${styles.iconButton} text-sm ${
                isActive ? styles.active : ""
              }`
            }
            onClick={toggleNavVisibility}
          >
            Settings
          </NavLink>

          {(userInfo?.user?.is_staff || userInfo?.user?.is_superuser) && (
            <Link
              to="/dashboard/enterprice"
              onClick={toggleNavVisibility}
              className={` ${styles.menuItem} ${styles.iconButton} text-sm`}
            >
              Enterprise
            </Link>
          )}
          <Link
            to="/dashboard/user-guide"
            onClick={toggleNavVisibility}
            className={` ${styles.menuItem} ${styles.iconButton} text-sm whitespace-nowrap`}
          >
            User Guid
          </Link>

          {(userInfo?.user?.is_staff || userInfo?.user?.is_superuser) && (
            <Link to="/dashboard/chat" onClick={toggleNavVisibility}>
              <MdOutlineChat className="w-6 h-6" />
            </Link>
          )}

          <div className="flex justify-between w-full">
            <span>Theme</span>
            <div className={styles.toggleContainer}>
              <button
                className={`${styles.toggleButton} ${
                  theme === "light" ? styles.active : ""
                }`}
                onClick={() => {
                  dispatch(toggleTheme({ theme: "light" }));
                  toggleNavVisibility();
                }}
              >
                <FaSun />
              </button>
              <button
                className={`${styles.toggleButton} ${
                  theme === "dark" ? styles.active : ""
                }`}
                onClick={() => {
                  dispatch(toggleTheme({ theme: "dark" }));
                  toggleNavVisibility();
                }}
              >
                <FaMoon />
              </button>
            </div>
          </div>

          <div
            className="flex justify-between w-full cursor-pointer hover:text-[#ff0000]"
            onClick={() => {
              handleLogout();
              toggleNavVisibility();
            }}
          >
            <span>Log Out</span>
            <FiLogOut />
          </div>
        </div>

        <div className={styles.menuContainer} ref={dropdownRef}>
          {userInfo?.user?.role?.name !== "Time Reporter" &&
            (userInfo?.user?.plan?.name === "Premium" ||
              userInfo?.user?.plan?.is_enterprise) && (
              <div className={styles.dropdownWrapper}>
                <Link
                 to={`${userInfo?.company ? '/dashboard' : ''}`}
                 onClick={()=>{
                   setActivetab('dashboard')
                   if(!userInfo?.company){
                     dispatch(addToast({
                       message: 'Please create a company first', type:'error'
                     }))
                   }
                 }}
                  className={` ${styles.menuItem} ${
                    styles.iconButton
                  } text-sm ${activeTab === "dashboard" ? "text-[red]" : ""}`}
                >
                  Dashboard
                </Link>
              </div>
            )}

          {(userInfo?.user?.role?.name !== "Time Reporter" && !userInfo?.user?.plan.is_enterprise) && (
            <div className={styles.dropdownWrapper}>
              <Link
                to="/dashboard/changeplan"
                onClick={() => setActivetab("subcription")}
                className={` ${styles.menuItem} ${styles.iconButton} text-sm ${
                  activeTab === "subcription" ? "text-[red]" : ""
                }`}
              >
                Subscription
              </Link>
            </div>
          )}

          {userInfo?.user?.role?.name !== "Time Reporter" &&
            userInfo?.user?.plan?.name !== "Basic" && (
              <Link
                to="/dashboard/updatecompany"
                onClick={() => setActivetab("company")}
                className={` ${styles.menuItem} ${styles.iconButton} text-sm ${
                  activeTab === "company" ? "text-[red]" : ""
                }`}
              >
                Company
              </Link>
            )}
          {userInfo?.user?.plan?.name !== "Basic" && (
             userInfo?.user?.role?.name !== "Time Reporter" ? 
            <Link
              to="/dashboard/board"
              onClick={() => setActivetab("projects")}
              className={` ${styles.menuItem} ${styles.iconButton} text-sm ${
                activeTab === "projects" ? "text-[red]" : ""
              }`}
            >
              Projects
            </Link> :  <div
              onClick={() => {
                setActivetab("projects")
                if(userInfo.user?.project){
                  navigate(`/dashboard/project`)
                }else{
                  dispatch(addToast({
                    message: 'You have no project assigned', type:'error'
                  }))
                }
              }}
              className={` ${styles.menuItem} ${styles.iconButton} text-sm ${
                activeTab === "projects" ? "text-[red]" : ""
              }`}
            >
              Projects
            </div>
          )}
          {userInfo?.user?.role?.name !== "Time Reporter" && (
            <Link
              to="/dashboard/invoices"
              onClick={() => setActivetab("payments")}
              className={` ${styles.menuItem} ${styles.iconButton} text-sm ${
                activeTab === "payments" ? "text-[red]" : ""
              }`}
            >
              Payments
            </Link>
          )}
          <Link
            to="/dashboard/user-guide"
            onClick={() => setActivetab("guide")}
            className={` ${styles.menuItem} ${
              styles.iconButton
            } text-sm whitespace-nowrap ${
              activeTab === "guide" ? "text-[red] " : ""
            }`}
          >
            User Guide
          </Link>
          {(userInfo?.user?.is_staff || userInfo?.user?.is_superuser) && (
            <Link
              to="/dashboard/enterprice"
              onClick={() => {
                setActivetab("enterprise");
              }}
              className={` ${styles.menuItem} ${styles.iconButton} text-sm ${
                activeTab === "enterprise" ? "text-[red]" : ""
              }`}
            >
              Enterprise
            </Link>
          )}
          {(userInfo?.user?.is_staff || userInfo?.user?.is_superuser) && (
            <Link
              to="/dashboard/chat"
              onClick={() => setActivetab("chat")}
              className={`${activeTab === "chat" ? "text-[red]" : ""}`}
            >
              <MdOutlineChat className="w-6 h-6" />
            </Link>
          )}

          <div className={styles.dropdownWrapper}>
            <button
              onClick={() => toggleDropdown("download")}
              className={`${styles.menuItem} ${styles.iconButton}`}
            >
              <FaDownload />
            </button>
            {activeDropdown === "download" && (
              <div
                className={`${
                  theme === "dark"
                    ? "bg-dashboardDarkMode text-white"
                    : "bg-white text-black"
                } p-2 rounded-lg shadow-xl customFont w-60 absolute top-7 right-0`}
              >
                <h6 className="text-lg font-semibold mb-2">Downloads</h6>
                <ul className="">
                  <Link
                    to="https://bugdev.s3.us-east-2.amazonaws.com/tracker_media/app+builds/bugtrack-rust.exe"
                    className={`flex items-center gap-2 p-2 rounded-md  cursor-pointer transition ${
                      theme === "dark"
                        ? "hover:bg-gradient-to-r hover:from-[#fff3] hover:to-[#fff0] transition-all"
                        : "hover:bg-gradient-to-r hover:from-[#6d6d6d33] hover:to-[#fff0] transition-all"
                    }`}
                  >
                    <FaWindows className="text-xl" />
                    <span>Windows Agent</span>
                  </Link>
                  <li
                    className={`flex items-center gap-2 p-2 rounded-md cursor-pointer transition ${
                      theme === "dark"
                        ? "hover:bg-gradient-to-r hover:from-[#fff3] hover:to-[#fff0] transition-all"
                        : "hover:bg-gradient-to-r hover:from-[#6d6d6d33] hover:to-[#fff0] transition-all"
                    }`}
                  >
                    <FaApple className="text-xl" />
                    <span>Mac OS Agent</span>
                  </li>
                  <li
                    className={`flex items-center gap-2 p-2 rounded-md cursor-pointer transition ${
                      theme === "dark"
                        ? "hover:bg-gradient-to-r hover:from-[#fff3] hover:to-[#fff0] transition-all"
                        : "hover:bg-gradient-to-r hover:from-[#6d6d6d33] hover:to-[#fff0] transition-all"
                    }`}
                  >
                    <FaLinux className="text-xl" />
                    <span>Linux Agent</span>
                  </li>
                </ul>
              </div>
            )}
          </div>
          <div className={styles.dropdownWrapper}>
            <div
              className={styles.profile}
              onClick={() => toggleDropdown("profile")}
            >
              {userInfo?.user?.picture ? (
              <img
                className={`${styles.profileImg} object-cover `}
                src={userInfo.user.picture}
                alt="Profile"
              />) : <FaUserCircle className={`w-8 h-8 ${theme === 'dark' ? 'text-gray-300': 'text-gray-600'}`}/> }
            </div>
            {activeDropdown === "profile" && (
              <div className={styles.dropdownContent2}>
                <h6 className={`px-3 ${styles.dropdownTitle}`}>
                  {userInfo?.user?.first_name} {userInfo?.user?.last_name}
                </h6>
                {}
                {userInfo?.user?.role?.name !== "Admin" && (
                <>
                  <div className={styles.dropdownItem}>
                    <span
                      className={`flex justify-between w-full ${styles.dropdownItem}`}
                    >
                      {userInfo?.user?.payment_type} Rate{" "}
                      <span>
                        {userInfo?.user?.payment_amount}{" "}
                        {userInfo?.user?.currency}
                      </span>
                    </span>
                  </div>

                  <hr className="mt-2 mb-2" />
                </> )}

                <div className={styles.dropdownItem}>
                  <div
                    className={`flex justify-between w-full ${styles.dropdownItem}`}
                  >
                    <span>Theme</span>
                    <div className={styles.toggleContainer}>
                      <button
                        className={`${styles.toggleButton} ${
                          theme === "light" ? `${styles.active}` : ""
                        } `}
                        onClick={() => {
                          dispatch(toggleTheme({ theme: "light" }));
                        }}
                      >
                        <FaSun />
                      </button>
                      <button
                        className={`${styles.toggleButton} ${
                          theme === "dark" ? `${styles.active}` : ""
                        }`}
                        onClick={() => {
                          dispatch(toggleTheme({ theme: "dark" }));
                        }}
                      >
                        <FaMoon />
                      </button>
                    </div>
                  </div>
                </div>
                <div className={styles.dropdownItem}>
                  <NavLink
                    to="/dashboard/setting/personalinformation"
                    className={`flex justify-between w-full ${styles.dropdownItem}`}
                  >
                    <span>Settings</span>
                    <FiSettings />
                  </NavLink>
                </div>
                <hr className="mt-2 mb-2" />

                <div className={styles.dropdownItem}>
                  <div
                    className={`flex justify-between w-full ${styles.dropdownItem}`}
                    onClick={handleLogout}
                  >
                    <span>Log Out</span>
                    <FiLogOut />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={styles.navLinkMain}>
        <NavLink
          to="/dashboard/summary"
          className={({ isActive }) =>
            isActive ? `${styles.navLink} ${styles.active}` : styles.navLink
          }
          onClick={() => setActivetab(null)}
        >
          Summary
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            isActive ? `${styles.navLink} ${styles.active}` : styles.navLink
          }
          onClick={() => setActivetab(null)}
          to="/dashboard/activity"
        >
          Activity
        </NavLink>
        {userInfo?.user?.role?.name === "Admin" && (
          <NavLink
            className={({ isActive }) =>
              isActive ? `${styles.navLink} ${styles.active}` : styles.navLink
            }
            onClick={() => setActivetab(null)}
            to="/dashboard/users"
          >
            Users
          </NavLink>
        )}
        {userInfo?.user?.role?.name !== "Time Reporter" &&
          userInfo?.user?.plan.name === "Standard" && (
            <NavLink
              className={({ isActive }) =>
                isActive ? `${styles.navLink} ${styles.active}` : styles.navLink
              }
              onClick={() => setActivetab(null)}
              to="/dashboard/analytics_charts"
            >
              Analytics & Charts
            </NavLink>
          )}
        {userInfo?.user?.role?.name !== "Time Reporter" && (
          <NavLink
            className={({ isActive }) =>
              isActive ? `${styles.navLink} ${styles.active}` : styles.navLink
            }
            onClick={() => setActivetab(null)}
            to="/dashboard/reports"
          >
            Reports
          </NavLink>
        )}
        {userInfo?.user?.role?.name === "Time Reporter" &&
          userInfo?.user?.plan?.name !== "Basic" && (
            <>
              <NavLink
                className={({ isActive }) =>
                  isActive
                    ? `${styles.navLink} ${styles.active}`
                    : styles.navLink
                }
                onClick={() => setActivetab(null)}
                to="/dashboard/overtime"
              >
                Overtime
              </NavLink>
            </>
          )}
        {userInfo?.user?.role?.name === "Time Reporter" &&
          userInfo?.user?.plan?.name !== "Basic" && (
            <>
              <NavLink
                className={({ isActive }) =>
                  isActive
                    ? `${styles.navLink} ${styles.active}`
                    : styles.navLink
                }
                onClick={() => setActivetab(null)}
                to="/dashboard/timeoffs"
              >
                TimeOffs
              </NavLink>
              <NavLink
                className={({ isActive }) =>
                  isActive
                    ? `${styles.navLink} ${styles.active}`
                    : styles.navLink
                }
                onClick={() => setActivetab(null)}
                to="/dashboard/timesheet"
              >
                Time Sheet
              </NavLink>
            </>
          )}
      </div>
    </div>
  );
};

export default Navbar;
