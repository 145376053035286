import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    allChats: [],
    userChats: [],
  };

  const chatSlice = createSlice({
    name: 'chat',
    initialState,
    reducers: {
      toggleAllChats: (state, { payload }) => {
        state.allChats = payload?.allChats;
      },
      toggleUserChats: (state, { payload }) => {
        state.userChats = payload.userChats || [];
      }
    },
  });
  export const { toggleAllChats,toggleUserChats } = chatSlice.actions;

  export default chatSlice.reducer;
  