import React, { useState, useEffect, useRef } from "react";
import { MdGridOff } from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";
import { FiRefreshCw } from "react-icons/fi";
import { FaCalendarAlt, FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { fetchApi } from "../../../utlis/axios";
import loader from "../../../assets/loading-gif.gif";
import styles from "../offtime/offtime.module.css";
import { addToast } from "../../../Redux/Slices/toast-slice";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { IoIosClose, IoMdArrowDropdown } from "react-icons/io";
import { Tooltip } from "@mui/material";
import UserProfileCard from "./UserProfileCard";

const CustomCalendar = () => {
  const { theme } = useSelector((state) => state.theme);
  const [mainCalendarDate, setMainCalendarDate] = useState(new Date());
  const [dropdownCalendarDate, setDropdownCalendarDate] = useState(new Date());
  const [hoveredDay, setHoveredDay] = useState(null);
  const [approvedLeaves, setApprovedLeaves] = useState({});
  const [isRefreshing, setIsRefreshing] = useState(false);
  const { userInfo } = useSelector((state) => state?.auth || {});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [updating, setUpdating] = useState(false);
  const dispatch = useDispatch();
  const [selectedDate, setSelectedDate] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [days, setDays] = useState([]);

  const daysOfWeek = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];

  const getDaysInMonth = (date) => {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
  };

  const getFirstDayOfMonth = (date) => {
    return new Date(date.getFullYear(), date.getMonth(), 1).getDay();
  };

  const getPreviousMonthDays = (date) => {
    const firstDay = getFirstDayOfMonth(date);
    const prevMonthLastDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      0
    ).getDate();
    return Array.from(
      { length: firstDay },
      (_, i) => prevMonthLastDate - firstDay + i + 1
    );
  };

  const getNextMonthDays = (date) => {
    const totalDays = getDaysInMonth(date);
    const firstDay = getFirstDayOfMonth(date);
    const totalCells = firstDay + totalDays;
    return Array.from(
      { length: totalCells % 7 === 0 ? 0 : 7 - (totalCells % 7) },
      (_, i) => i + 1
    );
  };

  const handlePrevMonth = () => {
    setMainCalendarDate(
      new Date(
        mainCalendarDate.getFullYear(),
        mainCalendarDate.getMonth() - 1,
        1
      )
    );
  };

  const handleNextMonth = () => {
    setMainCalendarDate(
      new Date(
        mainCalendarDate.getFullYear(),
        mainCalendarDate.getMonth() + 1,
        1
      )
    );
  };

  const isDateApproved = (day) => {
    const currentDateStr = `${mainCalendarDate.getFullYear()}-${String(
      mainCalendarDate.getMonth() + 1
    ).padStart(2, "0")}-${String(day).padStart(2, "0")}`;
    return currentDateStr in approvedLeaves;
  };

  const isPastDate = (day) => {
    const today = new Date();
    const dateToCheck = new Date(
      mainCalendarDate.getFullYear(),
      mainCalendarDate.getMonth(),
      day
    );
    return (
      dateToCheck <
      new Date(today.getFullYear(), today.getMonth(), today.getDate())
    );
  };

  const filterFutureDates = (data) => {
    const currentDate = new Date();
    const futureDates = {};

    data.results.forEach((item) => {
      item.dates.forEach((date) => {
        const dateObj = new Date(date);
        if (dateObj > currentDate) {
          futureDates[date] = item.title;
        }
      });
    });

    return futureDates;
  };

  const GetTimeOffData = async () => {
    try {
      const res = await fetchApi.get(`company-timeoffs/`, {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });

      if (res.status === 200) {
        setApprovedLeaves(filterFutureDates(res.data));
      }
    } catch (error) {}
  };

  const refreshData = async () => {
    setIsRefreshing(true);
    await GetTimeOffData();
    setIsRefreshing(false);
  };

  useEffect(() => {
    GetTimeOffData();
  }, []);

  const handleCreate = async () => {
    if (!title || selectedDate.length === 0) {
      dispatch(addToast({ message: "Please fill all fields", type: "error" }));
    } else {
      try {
        setUpdating(true);
        const result = await fetchApi.post(
          "company-timeoffs/",
          {
            title,
            dates: selectedDate,
            company: userInfo?.company?.id,
          },
          {
            headers: {
              Authorization: `Bearer ${userInfo.access_token}`,
            },
          }
        );

        if (result.status === 201) {
          GetTimeOffData();
          setTitle("");
          setIsModalOpen(false);
          dispatch(
            addToast({
              message: "Holiday posted successfully",
              type: "success",
            })
          );
        }
      } catch (error) {
        dispatch(addToast({ message: "Error posting holiday", type: "error" }));
      } finally {
        setUpdating(false);
      }
    }
  };

  const joiningDate = new Date(userInfo?.user?.created_at);
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  useEffect(() => {
    generateCalendar(dropdownCalendarDate);
  }, [dropdownCalendarDate]);

  

  const generateCalendar = (date) => {
    const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    const lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    const lastDayOfPrevMonth = new Date(
      date.getFullYear(),
      date.getMonth(),
      0
    ).getDate();
  
    const today = new Date();
    today.setHours(0, 0, 0, 0);
  
    const daysInMonth = [];
  
    // Add previous month's days
    const startDay = firstDayOfMonth.getDay();
    for (let i = startDay - 1; i >= 0; i--) {
      const dayDate = new Date(
        date.getFullYear(),
        date.getMonth() - 1,
        lastDayOfPrevMonth - i
      );
      daysInMonth.push({
        day: lastDayOfPrevMonth - i,
        currentMonth: false,
        isPastDate: dayDate < today,
      });
    }
  
    // Add current month's days
    for (let i = 1; i <= lastDayOfMonth.getDate(); i++) {
      const dayDate = new Date(date.getFullYear(), date.getMonth(), i);
      daysInMonth.push({
        day: i,
        currentMonth: true,
        isPastDate: dayDate < today,
      });
    }
  
    // Add next month's days
    const remainingDays = 35 - daysInMonth.length; // Ensure 5 rows (35 cells)
    for (let i = 1; i <= remainingDays; i++) {
      const dayDate = new Date(date.getFullYear(), date.getMonth() + 1, i);
      daysInMonth.push({
        day: i,
        currentMonth: false,
        isPastDate: dayDate < today,
      });
    }
  
    setDays(daysInMonth);
  };

  const goToPrevMonth = (event) => {
    event.stopPropagation(); // Stop event propagation
    const prevMonth = new Date(
      dropdownCalendarDate.getFullYear(),
      dropdownCalendarDate.getMonth() - 1,
      1
    );
    if (
      prevMonth.getFullYear() > joiningDate.getFullYear() ||
      (prevMonth.getFullYear() === joiningDate.getFullYear() &&
        prevMonth.getMonth() >= joiningDate.getMonth())
    ) {
      setDropdownCalendarDate(prevMonth);
    }
  };
  
  const goToNextMonth = (event) => {
    event.stopPropagation(); // Stop event propagation
    const nextMonth = new Date(
      dropdownCalendarDate.getFullYear(),
      dropdownCalendarDate.getMonth() + 1,
      1
    );
    setDropdownCalendarDate(nextMonth);
  };

  const handleDateClick = (event, day, isCurrentMonth, dateContext) => {
    event.stopPropagation();
  
    if (!isCurrentMonth) {
      return; // Do nothing if it's not a day from the current month
    }
  
    // Use the correct date context (mainCalendarDate or dropdownCalendarDate)
    const selectedDate = formatDate(
      new Date(
        dateContext.getFullYear(),
        dateContext.getMonth(),
        day
      )
    );
  
    setSelectedDate((prevDates) => {
      if (prevDates.includes(selectedDate)) {
        return prevDates.filter((date) => date !== selectedDate);
      } else {
        return [...prevDates, selectedDate];
      }
    });
  };
  return (
    <div
      className={`rounded-[20px] shadow-lg h-full customFont flex flex-col justify-center items-center py-4 px-4 ${
        theme === "dark" ? "bg-[#1C1C1E] text-white" : "bg-white text-black"
      }`}
    >
      {/* Header */}
      <div className="flex justify-between items-center w-full">
        <div className="flex gap-2 items-center">
          <span className="bg-gradient-to-r from-[#CC0927] to-[#7E0105] p-2 rounded-[10px]">
            <MdGridOff className="w-5 h-5 text-white" />
          </span>
          <h1 className="text-lg font-semibold">Company Timeoff</h1>
        </div>
        <div
          className={`p-2 flex items-center justify-center rounded-[10px] border cursor-pointer ${
            theme === "dark"
              ? "bg-[#252529] border-[#555657]"
              : "bg-[#F2F4FA] border-[#D8D8D8]"
          }`}
          onClick={refreshData}
        >
          {isRefreshing ? (
            <img src={loader} alt="Loading..." className="w-5 h-5" />
          ) : (
            <FiRefreshCw className={`w-5 h-5`} />
          )}
        </div>
      </div>

      {/* Main Calendar */}
      <div
        className={`w-full max-w-md p-6 mt-6 border rounded-[20px] ${
          theme === "dark"
            ? "bg-[#212529] border-[#555657]"
            : "bg-[#F2F4F7] border-[#D8D8D8]"
        }`}
      >
        <div className="flex items-center justify-between p-2">
          <h2 className="text-xl font-semibold">
            {mainCalendarDate.toLocaleString("default", {
              month: "long",
              year: "numeric",
            })}
          </h2>
          <div className="flex gap-4 items-center">
            <button onClick={handlePrevMonth}>
              <FaChevronLeft className="w-4 h-4" />
            </button>
            <button onClick={handleNextMonth}>
              <FaChevronRight className="w-4 h-4" />
            </button>
          </div>
        </div>
        <div className="grid grid-cols-7 text-center text-sm font-medium">
          {daysOfWeek.map((day) => (
            <div key={day} className="p-1">
              {day}
            </div>
          ))}
        </div>
        <div className="grid grid-cols-7 gap-1 text-center">
          {getPreviousMonthDays(mainCalendarDate).map((day, index) => (
            <div key={`prev-${index}`} className="p-3 text-gray-400">
              {day}
            </div>
          ))}
            {Array.from({ length: getDaysInMonth(mainCalendarDate) }, (_, i) => i + 1).map((day) => {
      const formattedDate = `${mainCalendarDate.getFullYear()}-${String(
        mainCalendarDate.getMonth() + 1
      ).padStart(2, "0")}-${String(day).padStart(2, "0")}`;

      const tooltipTitle = approvedLeaves[formattedDate];

      // Prepare a proper date object for formatting
      const fullDate = new Date(
        mainCalendarDate.getFullYear(),
        mainCalendarDate.getMonth(),
        day
      );

      // Format: Monday, March 2025
      const formattedFullDate = fullDate.toLocaleDateString("en-US", {
        weekday: "long",
        month: "long",
        year: "numeric",
      });

      return (
        <div
          key={day}
          className={`relative p-2 rounded-full text-center ${
            isPastDate(day)
              ? "cursor-not-allowed text-gray-400"
              : "hover:bg-red-600 hover:text-white cursor-pointer"
          }`}
          onMouseEnter={() => setHoveredDay(day)}
          onMouseLeave={() => setHoveredDay(null)}
        >
          {/* Tooltip only on hover */}
          {tooltipTitle && hoveredDay === day && (
            <div
              className={`absolute z-[10] bottom-10 left-1/2 transform -translate-x-1/2 border rounded-md pl-2 pr-4 flex gap-2 min-w-max ${
                theme === "dark"
                  ? "bg-[#212529] border-[#555657]"
                  : "bg-[#FFFFFF] border-[#D8D8D8]"
              }`}
            >
              <div className="w-2 h-auto bg-[#CE0927]"></div>
              <div className="text-lg py-1 flex flex-col">
                <span className={`font-medium whitespace-nowrap text-left ${theme === 'dark' ? 'text-white':'text-black'}`}>{tooltipTitle}</span>
                <span className="font-normal text-base text-gray-500 whitespace-nowrap">{formattedFullDate}</span>
              </div>
            </div>
          )}

          {/* Date Number */}
          {day}

          {/* Red Dot if tooltip available */}
          {tooltipTitle && (
            <div className="absolute bottom-1 left-1/2 transform -translate-x-1/2 w-1.5 h-1.5 bg-red-600 rounded-full"></div>
          )}
        </div>
      );
    })}
          {getNextMonthDays(mainCalendarDate).map((day, index) => (
            <div key={`next-${index}`} className="p-2 text-gray-400">
              {day}
            </div>
          ))}
        </div>
      </div>
      <button
        className="heroButton w-[70%] mt-8 mb-6"
        onClick={() => setIsModalOpen(true)}
      >
        Post Holiday
      </button>
      {isModalOpen && (
        <div className={`${styles.modalOverlay}`}>
          <div
            className={` max-w-sm rounded-[20px] w-full ${styles.modal} ${
              theme === "dark"
                ? "bg-[#212529] text-white"
                : "bg-[#F5F6FA] text-black"
            }`}
            style={{ overflow: "visible" }}
          >
            <div className={styles.modalHeader}>
              <h2 className="text w-full text-center text-lg font-medium">
                Post Holiday
              </h2>
              <button
                className={` p-2 outline-none text-center flex items-center rounded-full cursor-pointer ${
                  theme === "dark" ? "bg-[#555657]" : "bg-[#D8D8D8"
                }`}
                onClick={() => setIsModalOpen(false)}
              >
                <IoIosClose className="w-6 h-6" />
              </button>
            </div>
            <hr
              className={`border-t-2 mt-4 mb-3 rounded-md ${
                theme === "dark" ? "border-[#555657]" : "border-gray-400"
              }`}
            />
            <div
              className={`${styles.modalContent}`}
              style={{ minWidth: "auto", position: "relative" }}
            >
              <div className="text-left mt-3">
                <div className="w-full flex flex-col gap-4 ">
                  <div className={`${styles.formGroup}`}>
                    <label className="text-lg mb-2 font-normal">Title*</label>
                    <input
                      className={`rounded-[10px] w-full  bg-transparent border p-3 ${
                        theme === "dark"
                          ? "border-[#555657]"
                          : "border-gray-400"
                      }`}
                      type="text"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      placeholder="Enter title of holiday"
                      required
                    />
                  </div>
                  <div className="">
                    <label className="text-lg font-normal mb-2">Date*</label>
                    <div
                      className={`${
                        styles["calendar-header"]
                      } rounded-[10px] w-full  bg-transparent border p-3 ${
                        theme === "dark"
                          ? "border-[#555657]"
                          : "border-gray-400"
                      }`}
                      style={{
                        position: "relative",
                        cursor: "pointer",
                      }}
                      onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                    >
                      <div className="w-full">
                        <span className="flex justify-between items-center w-full">
                          <h2
                            className="cursor-pointer"
                            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                          >
                            {dropdownCalendarDate.toLocaleString("default", {
                              month: "long",
                            })}{" "}
                            {dropdownCalendarDate.getFullYear()}
                          </h2>
                          <div
                            className={styles.dropdown}
                            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                          >
                            <FaCalendarAlt className="w-6 h-6" />
                          </div>
                        </span>
                        {isDropdownOpen && (
                          <div
                            className={`${styles.dropdownMenu} ${
                              theme === "dark"
                                ? "bg-black text-white"
                                : "bg-white text-black"
                            } w-72`}
                            style={{
                              display: "block",
                              position: "absolute",
                              bottom: "47px",
                              right: "0",
                              border: "none",
                            }}
                          >
                            <div className="flex justify-center gap-2 items-center px-2">
                              <button
                                className=" outline-none"
                                onClick={(event)=>goToPrevMonth(event)}
                              >
                                <FaChevronLeft className="w-4 h-4" />
                              </button>
                              <h2 className="text-center p-2 text-xl">
                                {dropdownCalendarDate.toLocaleString(
                                  "default",
                                  {
                                    month: "long",
                                  }
                                )}{" "}
                                {dropdownCalendarDate.getFullYear()}
                              </h2>
                              <button
                                className="ouline-none"
                                onClick={(event)=>goToNextMonth(event)}
                              >
                                <FaChevronRight className="w-4 h-4" />
                              </button>
                            </div>
                            <div className="flex justify-around">
                              {["S", "M", "T", "W", "T", "F", "S"].map(
                                (day) => (
                                  <th
                                    key={day}
                                    className={` ${styles["day-header"]}`}
                                  >
                                    {day}
                                  </th>
                                )
                              )}
                            </div>
                            <div className={styles.calendar}>
  {days.map((day, index) => {
    const dayDate = new Date(
      dropdownCalendarDate.getFullYear(),
      day.currentMonth ? dropdownCalendarDate.getMonth() : dropdownCalendarDate.getMonth() + (day.day < 15 ? 1 : -1),
      day.day
    );

    const formattedDate = formatDate(dayDate);
    const isLeaveDate = formattedDate in approvedLeaves;

    return (
      <div
        key={index}
        onClick={(event) => {
          if (!isLeaveDate && day.currentMonth && !day.isPastDate) {
            handleDateClick(event, day.day, day.currentMonth, dropdownCalendarDate); // Pass dropdownCalendarDate as context
          }
        }}
        className={`p-2 flex items-center text-center rounded-full
          ${theme === "dark" ? "hover:bg-[#CC0927] hover:text-white" : "hover:bg-[#CC0927] hover:text-white"} 
          ${day.currentMonth ? (theme === "dark" ? "text-white" : "text-black") : (theme === "dark" ? "text-gray-400" : "text-gray-600")} 
          ${selectedDate?.includes(formattedDate) ? "bg-[#CC0927] text-white" : ""} 
          ${day.isPastDate || isLeaveDate ? "cursor-not-allowed opacity-50 border-none" : ""}
        `}
      >
        {day.day}
      </div>
    );
  })}
</div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`${styles.footer}`}>
                <button
                  className="heroButton w-full mt-2"
                  onClick={handleCreate}
                  disabled={updating}
                >
                  {updating ? "Creating..." : "Create"}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomCalendar;
