import React, { useEffect, useState, useRef } from "react";
import { Outlet } from "react-router-dom";
import DashboardNavbar from "./Navbar/DashboardNavbar";
import GoToTop from "../goToTop/GoToTop";
import { useSelector } from "react-redux";
import AlertBar from "./AlertBar";
import { MdOutlineChat } from "react-icons/md";
import { RxCross1 } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import { toggleAuth } from "../../Redux/Slices/auth-slice";
import {
  toggleDataLoading,
  updateFilterUsers,
  updateNextPage,
  updateSelectedUser,
  updateTotalNoOfUser,
  updateUserId,
  updateUsers,
} from "../../Redux/Slices/users-slice";
import { useDispatch } from "react-redux";
import { fetchApi } from "../../utlis/axios";
import { addToast } from "../../Redux/Slices/toast-slice";
import Chat from "./chatsupport/Chat";
import useWebSocket from "react-use-websocket";
import { toggleUserChats } from "../../Redux/Slices/chat-slice";
import AdminDashboard from "./adminDashboard/AdminDashboard";

const Dashboard = () => {
  const navigate = useNavigate();
  const { userInfo } = useSelector((state) => state?.auth || {});
  const { theme } = useSelector((state) => state.theme);
  const { userId, selectedUser } = useSelector(
    (state) => state?.allUsers || {}
  );
  const [isOpen, setIsOpen] = useState(false);
  const [unReadMessage, setUnReadMessage] = useState(false);
  const [messageHistory, setMessageHistory] = useState([]);
  const dispatch = useDispatch();
  const [socketUrl, setSocketUrl] = useState(null);
  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl || null, {
    shouldReconnect: (closeEvent) => true,
    reconnectAttempts: 10,
    reconnectInterval: 3000,
  });
  const [activeChat, setActiveChat] = useState(null);
  const [openedChat, setOpenedChat] = useState([]);
  const messagesEndRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [allUser, setAllUser] = useState(null);
  const [user, setUser] = useState(null);
  const [chatStatus, setChatStatus] = useState('existing');// new state to track upload progress

  const toggleChat = () => {
    if (chatStatus === 'new') {
      createNewChat()
    }
    setUnReadMessage(false)
    setIsOpen(!isOpen);
  };

  const getSubdomain = () => {
    const host = window.location.host;
    if (host.includes("localhost")) {
      return host.split(".localhost")[0];
    } else if (host.includes(".bugtrackin")) {
      return host.split(".bugtrackin.com")[0];
    }
    return null;
  };

  const subdomain = getSubdomain();
  useEffect(() => {
    if (!subdomain || subdomain !== userInfo?.user?.domain) {
      console.log("Subdomain mismatch! Calling handleLogout...");
      handleLogout();
    }
  }, []);


  const handleLogout = async () => {
    try {
      const refresh_token = userInfo.refresh_token
      // Remove user info from Redux state
      dispatch(toggleAuth({ isLogin: false, userInfo: null }));
      if (userInfo?.refresh_token) {
        await fetchApi.post("/logout/", { refresh_token: refresh_token });
      }
    } catch (error) {
      console.error("Logout failed:", error);
    } finally {
      navigate("/"); // Ensure redirection happens after logout
    }
  };
  const isFetched = useRef(false); // Use a ref to prevent repeated API calls

  const getUsers = async () => {
    if (isFetched.current) return;
    isFetched.current = true;
    dispatch(toggleDataLoading(true));
  
    try {
      const response = await fetchApi.get(`users/?domain=${subdomain}`, {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });
  
      if (response.status === 200) {
        let allUsers = response?.data?.results || [];
        const next = response.data.next;
        dispatch(updateTotalNoOfUser(response.data.count))
  
        if (next) {
          dispatch(updateNextPage(next))
          allUsers = [...allUsers]; // Merge the next users*/
        }
  
        // Remove duplicates based on user ID
        const uniqueUsers = Array.from(new Map(allUsers.map(user => [user.id, user])).values());
  
        dispatch(updateFilterUsers(uniqueUsers));
  
        if (userInfo?.user?.role?.name === "Time Reporter") {
          dispatch(updateUserId(userInfo?.user?.id));
          dispatch(updateSelectedUser(userInfo?.user));
        } else {
          const timeReporters = uniqueUsers.filter(user => user.role.name === "Time Reporter");
          if(!userId){
          if (timeReporters.length === 0) {
            dispatch(updateUserId(userInfo?.user?.id));
          } else {
            dispatch(updateUserId(timeReporters[0].id));
          }
        }
        if(!selectedUser){
          if (timeReporters.length === 0) {
            dispatch(updateSelectedUser(userInfo?.user));
          } else {
            dispatch(updateSelectedUser(timeReporters[0]));
          }
        }
          dispatch(updateUsers(timeReporters));
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(toggleAuth({ isLogin: false, userInfo: null }));
        dispatch(addToast({ message: 'Your session expired', type: 'error' }));
        return;
      }
      dispatch(addToast({ message: 'Something went wrong', type: 'error' }));
      console.error(error);
    } finally {
      dispatch(toggleDataLoading(false));
    }
  };
  
  const handleNextUsers = async (url) => {
    try {
      const response = await fetchApi.get(url, {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });
  
      if (response.status === 200) {
        let newUsers = response?.data?.results || [];
        const next = response.data.next;
  
        if (next) {
          const nextUsers = await handleNextUsers(next);
          newUsers = [...newUsers, ...nextUsers]; // Merge recursively fetched users
        }
  
        return newUsers;
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(toggleAuth({ isLogin: false, userInfo: null }));
        dispatch(addToast({ message: 'Your session expired', type: 'error' }));
        return [];
      }
      dispatch(addToast({ message: 'Something went wrong', type: 'error' }));
      console.error(error);
      return [];
    }
  };
  
  useEffect(() => {
    getUsers();
  }, []);
  
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  let isCreated = useRef(false);
  const createNewChat = async () => {
    if (isCreated.current) {
      return;
    } else {
      isCreated.current = true;
      setIsLoading(true);
      try {
        const res = await fetchApi.post(
          "chat/",
          { subject: `new chat ${Date.now()}`, user: userInfo.user.id },
          {
            headers: {
              Authorization: `Bearer ${userInfo.access_token}`,
            },
          }
        );

        if (res.status === 201) {
          setActiveChat(res.data.id);
          setSocketUrl(
            `wss://bugtrack-eatwy.ondigitalocean.app/ws/support/${res.data.id}/`
          );
          setOpenedChat(res.data);
          setChatStatus('existing')
        }
      } catch (error) {
        console.error(error);
        isCreated.current = false;
      } finally {
        setIsLoading(false);
      }
    }
  };
  const existingChat = async () => {
    setIsLoading(true);
    try {
      const res = await fetchApi.get(`chat/?user=${userInfo?.user?.id}`, {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });
      if (res.status === 200) {
        if (res.data.results.length > 0) {
          const activeChat = res.data.results.find(
            (chat) => chat.status !== "closed"
          );
          if (activeChat) {
            setSocketUrl(
              `wss://bugtrack-eatwy.ondigitalocean.app/ws/support/${activeChat.id}/`
            );
            fetchChatHistory(activeChat.id);
            setActiveChat(activeChat.id);
            setOpenedChat(activeChat);
          } else {
            setChatStatus('new')
          }
        } else {
          setChatStatus('new')
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  let existingChatFetched = useRef(false);
  useEffect(() => {

    existingChat();
  }, []);

  const fetchChatHistory = async (chatId) => {
    let isFetching = false;
    if (isFetching) {
      return;
    } else {
      isFetching = true;
      try {
        const response = await fetchApi.get(`message/?chat=${chatId}`, {
          headers: {
            Authorization: `Bearer ${userInfo.access_token}`,
          },
        });

        dispatch(toggleUserChats({ userChats: response.data.results }));
        scrollToBottom();
      } catch (error) {
        dispatch(addToast({ message: 'Error loading chats', type: 'error' }));
      }
    }
  };

  useEffect(() => {
    if (lastMessage !== null) {
      try {
        const parsedMessage = JSON.parse(lastMessage.data);
        setMessageHistory((prev) => [...prev, parsedMessage]);
        if (!isOpen) {
          setUnReadMessage(true);
        }
      } catch (error) {
        console.error("Error parsing WebSocket message:", error);
      }
    }
  }, [lastMessage]);

  return (
    <div className="relative min-h-screen overflow-hidden">
      {userInfo?.user?.is_verified === false ? <AlertBar /> : <></>}
      <GoToTop />
      <div className="flex min-h-screen text-white">
        <div
          className={`flex-grow min-h-screen  overflow-hidden ${theme === "dark" ? "bg-dashboardDarkMode" : "bg-white"
            }`}
        >
          <DashboardNavbar />
          <div className="flex justify-center overflow-x-hidden overflow-auto h-max md:mt-[100px] mt-[52px]">
            <div className="w-full h-full flex flex-col items-center" >
              <Outlet />
            </div>
            {/* <div
              className={`w-1/4 flex-grow p-5 lg:block hidden fixed right-4`}
            >
              <SideBar />
            </div> */}
          </div>
        </div>
      </div>
      {userInfo?.user?.plan?.name !== 'Basic' &&
      <div
        className="bg-red-600 text-white h-12 w-12 fixed bottom-10 right-10 flex justify-center items-center cursor-pointer z-50 text-2xl ease-in-out transition-all"
        style={{ borderRadius: "50%" }}
        onClick={toggleChat}
      >
        {!isOpen && unReadMessage && (
          <div className="absolute top-[8px] right-[8px] text-xs bg-blue-600 w-4 h-4 rounded-full flex justify-center items-center"></div>
        )}
        {isOpen ? <RxCross1 /> : <MdOutlineChat />}
      </div> }
      {isOpen && (
        <Chat
          setIsOpen={setIsOpen}
          unReadMessage={unReadMessage}
          sendMessage={sendMessage}
          readyState={readyState}
          messageHistory={messageHistory}
          messagesEndRef={messagesEndRef}
          isLoading={isLoading}
          activeChat={activeChat}
          openedChat={openedChat}
        />
      )}
    </div>
  );
};

export default Dashboard;
