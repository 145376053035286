import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { fetchApi } from "../../../utlis/axios";
import Loader from "../../Loader/Loader";
import { IoIosSend } from "react-icons/io";
import { FaDownload, FaUserCircle } from "react-icons/fa";
import { IoIosArrowBack } from "react-icons/io";
import { GrAttachment } from "react-icons/gr";
import RichTextEditor from "./RichTextEditor";
import styles from "./style.module.css";
import { FiCheck } from "react-icons/fi";
import { MdClose } from "react-icons/md";
import { BsThreeDots } from "react-icons/bs";
import { MdDeleteForever } from "react-icons/md";
import LoadingGif from "../../../assets/loading-gif.gif";
import RichTextEditor1 from "./CommentTextEditor";
import { addToast } from "../../../Redux/Slices/toast-slice";

const TicketDetail = () => {
  const { theme } = useSelector((state) => state.theme);
  const { userInfo } = useSelector((state) => state.auth);
  const users = useSelector((state) => state.allUsers?.filterUsers);
  const dispatch=useDispatch()
  const navigate = useNavigate();
  let isFetched = useRef(false);
  const [editorOpen, setEditorOpen] = useState(false);
  const { id } = useParams();
  const [ticketData, setTicketData] = React.useState({
    created_at: "",
    title: "",
    description: "",
    priority: "",
    sprint: "",
    type: "",
    developer: {},
    project: {},
    milestone: {},
    comments: [],
    attachment: [],
  });
  const [loading, setLoading] = React.useState(false);
  const [comment, setComment] = React.useState("");
  const [comments, setComments] = useState([]);
  const [actionsDropdown, setActionsDropdown] = useState(false);
  const [isTitleEditing, setIsTitleEditing] = useState(false);
  const [description, setDescription] = useState("");
  const [title, setTitle] = useState("");
  const [titleUpdating, setTitleUpdating] = useState(false);
  const [priorityUpdating, setPriorityUpdating] = useState(false);
  const [userUpdating, setUserUpdating] = useState(false);
  const [commentEditing, setCommentEditing] = useState(false);
  const [commentDescription, setCommentDescription] = useState("");
  const [editingCommentId, setEditingCommentId] = useState(null);
  const [editingAttachments, setEditingAttachments] = useState([]);
  const fileInputRef = useRef(null);
  const fileInputRef1 = useRef(null);
  const [preview, setPreview] = useState(null);
  const [attachmentPreview, setAttachmentPreview] = useState(null);
  const [attachments, setAttachments] = useState([]);
  const [uploadedFile, setUploadedFile] = useState([]);
  const [fileUploading, setFileUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [userDropDown, setUserDropDown] = useState(false);
  const [piortyDropdown, setPiortyDropdown] = useState(false);
  const [commentDeleting, setCommentDeleting] = useState(null);
  const [commentSaving, setCommentSaving] = useState(false);
  const fetchTicketsDetail = async () => {
    setLoading(true);
    try {
      const res = await fetchApi.get(`project-tickets/${id}/`, {
        headers: { Authorization: `Bearer ${userInfo.access_token}` },
      });
      if (res.status === 200) {
        const result = res.data;
        setTicketData({
          created_at: result.created_at,
          title: result.title,
          description: result.description,
          type: result.type,
          priority: result.priority,
          sprint: result.sprint,
          project: result.project,
          milestone: result.milestone,
          developer: result.developer,
          comments: result.comments,
          attachment: result.attachment,
        });
        setTitle(result.title);
        setDescription(res.data.description);
        setComments(res.data.comments);
        setAttachments(res.data.attachment);
      }
    } catch (error) {
      dispatch(addToast({ message:error.message, type:'error' })); 
    } finally {
      setLoading(false);
    }
  };
  const updatTicket = async () => {
    try {
      const res = await fetchApi.get(`project-tickets/${id}/`, {
        headers: { Authorization: `Bearer ${userInfo.access_token}` },
      });
      if (res.status === 200) {
        const result = res.data;
        setTicketData({
          created_at: result.created_at,
          title: result.title,
          description: result.description,
          type: result.type,
          priority: result.priority,
          sprint: result.sprint,
          project: result.project,
          milestone: result.milestone,
          developer: result.developer,
          comments: result.comments,
          attachment: result.attachment,
        });
        setTitle(result.title);
        setDescription(res.data.description);
        setComments(res.data.comments);
        setAttachments(res.data.attachment);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (isFetched.current) return;
    isFetched.current = true;
    fetchTicketsDetail();
  }, []);
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (fileUploading) {
      dispatch(addToast({ message:"File uploading...", type:'warn' })); 
      return;
    }
    try {
      const formData = new FormData();
      if (uploadedFile) {
        const formattedComments = Array.isArray(uploadedFile)
          ? uploadedFile
          : [uploadedFile];
        formattedComments.forEach((comment) => {
          formData.append("attachment[]", comment); // Adjust based on API requirements
        });
      }
      if(!comment){
        formData.append('description' , 'no description');
      }else{
      formData.append("description", comment);
      }
      formData.append("created_by", userInfo?.user.id);
      const res = await fetchApi.post(`comments/`, formData, {
        headers: { Authorization: `Bearer ${userInfo.access_token}` },
      });
      if (res.status === 201) {
        setUploadedFile(null);
        setComment("");
        setPreview(null);
        addComment(res.data.id, res.data);
      }
    } catch (error) {
      dispatch(addToast({ message:error.message, type:'error' })); 
    }
  };
  const addComment = async (cid, newComm) => {
    try {
      let previousComments = [];
      if (comments?.length > 0) {
        comments.map((e) => previousComments.push(e?.id));
      }
      const res = await fetchApi.patch(
        `project-tickets/${id}/`,
        { comments: [...previousComments, cid] },
        {
          headers: { Authorization: `Bearer ${userInfo.access_token}` },
        }
      );
      if (res.status === 200) {
        updatTicket();
        dispatch(addToast({ message:'Comment added', type:'success' })); 
      }
    } catch (error) {
      dispatch(addToast({ message:error.message, type:'error' })); 
    }
  };
  function timeDifference(dateString) {
    const givenDate = new Date(dateString); // Parse the given date
    const currentDate = new Date(); // Get the current date
    const diffInMilliseconds = currentDate - givenDate; // Difference in milliseconds

    // Calculate time differences
    const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
    const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));
    const diffInHours = Math.floor(diffInMilliseconds / (1000 * 60 * 60));
    const diffInDays = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));
    const diffInMonths = Math.floor(
      diffInMilliseconds / (1000 * 60 * 60 * 24 * 30)
    );
    const diffInYears = Math.floor(
      diffInMilliseconds / (1000 * 60 * 60 * 24 * 365)
    );

    if (diffInSeconds < 60) {
      return `${diffInSeconds} seconds ago`;
    } else if (diffInMinutes < 60) {
      return `${diffInMinutes} minutes ago`;
    } else if (diffInHours < 24) {
      return `${diffInHours} hours ago`;
    } else if (diffInDays < 30) {
      return `${diffInDays} days ago`;
    } else if (diffInMonths < 12) {
      return `${diffInMonths} months ago`;
    } else {
      return `${diffInYears} year${diffInYears > 1 ? "s" : ""} ago`;
    }
  }

  const handleButtonClick = () => {
    fileInputRef.current.click(); // Trigger the hidden file input
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0]; // Get the selected file

    if (file) {
      // Show file preview (for images)
      if (file.type.startsWith("image/")) {
        const reader = new FileReader();
        reader.onload = () => setPreview(reader.result);
        reader.readAsDataURL(file);
      } else {
        setPreview(file.name); // Show the file name for non-image files
      }

      // Simulate file upload
      const formData = new FormData();
      formData.append("file", file);
      setFileUploading(true);
      try {
        const response = await fetchApi.post("/attachments/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${userInfo.access_token}`,
          },
        });

        if (response.status === 201) {
          setUploadedFile(response.data.id);
        } else {
          dispatch(addToast({ message:'File upload failed', type:'error' })); 
        }
      } catch (error) {
        dispatch(addToast({ message:error.message, type:'error' })); 
      } finally {
        setFileUploading(false);
      }
    }
  };
  const handleButtonClick1 = () => {
    fileInputRef1.current.click(); // Trigger the hidden file input
  };

  const handleFileChange1 = async (event) => {
    const file = event.target.files[0]; // Get the selected file

    if (file) {
      // Show file preview (for images)
      if (file.type.startsWith("image/")) {
        const reader = new FileReader();
        reader.onload = () => setAttachmentPreview(reader.result);
        reader.readAsDataURL(file);
      } else {
        setAttachmentPreview(file.name); // Show the file name for non-image files
      }

      // Simulate file upload
      const formData = new FormData();
      formData.append("file", file);
      formData.append("created_by", userInfo?.user.id);
      try {
        const response = await fetchApi.post("/attachments/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${userInfo.access_token}`,
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadProgress(percentCompleted); // Update state to display progress
          },
        });
        setUploadProgress(0);
        if (response.status === 201) {
          const newAttachment = response.data.id;
          const newAttachmentFile = response.data;
          let previousAttachments = [];
          if (attachments.length > 0) {
            attachments.map((e) => previousAttachments.push(e?.id));
          }
          try {
            const res = await fetchApi.patch(
              `project-tickets/${id}/`,
              { attachment: [...previousAttachments, newAttachment] },
              {
                headers: { Authorization: `Bearer ${userInfo.access_token}` },
              }
            );
            if (res.status === 200) {
              setAttachmentPreview(null);
              setAttachments((previousAttachments) => [
                ...previousAttachments,
                newAttachmentFile,
              ]);
              dispatch(addToast({ message:'File uploaded', type:'success' })); 
            }
          } catch (error) {
            dispatch(addToast({ message:error.message, type:'error' })); 
          }
        } else {
          dispatch(addToast({ message:'File upload failed', type:'error' })); 
        }
      } catch (error) {
        dispatch(addToast({ message:error.message, type:'error' })); 
        setUploadProgress(0);
      }
    }
  };

  const handleDeleteComment = async (commentId) => {
    setCommentDeleting(commentId);
    try {
      // Step 1: Remove the comment ID from the ticket's comment list
      const remainingComments = comments
        .filter((e) => e.id !== commentId)
        .map((e) => e.id);

      const updateTicketRes = await fetchApi.patch(
        `project-tickets/${id}/`,
        { comments: remainingComments },
        {
          headers: { Authorization: `Bearer ${userInfo.access_token}` },
        }
      );

      if (updateTicketRes.status === 200) {
        updatTicket();
        dispatch(addToast({ message:'Comment removed', type:'success' })); 
        setComments((prevComments) =>
          prevComments.filter((comment) => comment.id !== commentId)
        );
        removeFromDb(commentId);
      } else {
        dispatch(addToast({ message:'Failed to remove comment', type:'error' })); 
      }
    } catch (error) {
      dispatch(addToast({ message:error.message, type:'error' })); 
    } finally {
      setCommentDeleting(null);
    }
  };
  const removeFromDb = async (commentId) => {
    try {
      const deleteCommentRes = await fetchApi.delete(`comments/${commentId}/`, {
        headers: { Authorization: `Bearer ${userInfo.access_token}` },
      });
    } catch (error) {}
  };
  const handleEditClick = (comment) => {
    setEditingCommentId(comment.id); // Set the comment ID being edited
    setCommentDescription(comment.description); // Set the description for editing
    setEditingAttachments([...comment.attachment]); // Copy the attachments for editing
  };

  const handleDeleteAttachment = async (attachmentId, commentId) => {
    try {
      // Filter out the attachment to remove from the local state first
      setEditingAttachments((prev) =>
        prev.filter((a) => a.id !== attachmentId)
      );

      // Prepare the updated attachment list
      const updatedAttachments = editingAttachments
        .filter((attachment) => attachment.id !== attachmentId)
        .map((attachment) => attachment.id);

      // Update the comment's attachments on the server
      const patchResult = await fetchApi.patch(
        `comments/${commentId}/`,
        { attachment: updatedAttachments },
        {
          headers: { Authorization: `Bearer ${userInfo.access_token}` },
        }
      );
      updatTicket();
      // If PATCH is successful, delete the attachment
      if (patchResult.status === 200) {
        await fetchApi.delete(`attachments/${attachmentId}/`, {
          headers: { Authorization: `Bearer ${userInfo.access_token}` },
        });
      } else {
        throw new Error("Failed to update comment's attachments.");
      }
    } catch (error) {
      console.error("Error deleting attachment:", error);
    }
  };
  const handleRemoveAttachment = async (attachmentId) => {
    try {
      const updatedAttachments = attachments
        .filter((attachment) => attachment.id !== attachmentId)
        .map((attachment) => attachment.id);

      // Update the comment's attachments on the server
      const patchResult = await fetchApi.patch(
        `project-tickets/${id}/`,
        { attachment: updatedAttachments },
        {
          headers: { Authorization: `Bearer ${userInfo.access_token}` },
        }
      );
      if (patchResult.status === 200) {
        setAttachments((prev) => prev.filter((a) => a.id !== attachmentId));
        removeAttachment(attachmentId);
      }
    } catch (error) {
      dispatch(addToast({ message:'Error deleting attachment', type:'error' })); 
    }
  };
  const removeAttachment = async (attachmentId) => {
    try {
      await fetchApi.delete(`attachments/${attachmentId}/`, {
        headers: { Authorization: `Bearer ${userInfo.access_token}` },
      });
    } catch (error) {
      console.error(error);
    }
  };
  const handleSaveDescription = async () => {
    try {
      setEditorOpen(false);
      const res = await fetchApi.patch(
        `project-tickets/${id}/`,
        { description: description },
        {
          headers: { Authorization: `Bearer ${userInfo.access_token}` },
        }
      );
      if (res.status === 200) {
        setDescription(res.data.description);
        dispatch(addToast({ message:'Description updated', type:'success' })); 
      }
    } catch (error) {
      dispatch(addToast({ message:error.message, type:'error' })); 
    }
  };
  const handleSaveTitle = async () => {
    setTitleUpdating(true);
    try {
      setIsTitleEditing(false);
      const res = await fetchApi.patch(
        `project-tickets/${id}/`,
        { title: ticketData.title },
        {
          headers: { Authorization: `Bearer ${userInfo.access_token}` },
        }
      );
      if (res.status === 200) {
        setTitle(res.data.title);
        dispatch(addToast({ message:'Title updated', type:'success' })); 
      }
    } catch (error) {
      dispatch(addToast({ message:error.message, type:'error' })); 
    } finally {
      setTitleUpdating(false);
    }
  };
  const handleChangeUser = async (userId) => {
    setUserUpdating(true);
    try {
      setIsTitleEditing(false);
      const res = await fetchApi.patch(
        `project-tickets/${id}/`,
        { developer: userId },
        {
          headers: { Authorization: `Bearer ${userInfo.access_token}` },
        }
      );
      if (res.status === 200) {
        updatTicket();
        dispatch(addToast({ message:'Assigned user changed', type:'success' })); 
      }
    } catch (error) {
      dispatch(addToast({ message:error.message, type:'error' })); 
    } finally {
      setUserUpdating(false);
    }
  };
  const handlePiorityChange = async (piority) => {
    setPriorityUpdating(true);
    try {
      setIsTitleEditing(false);
      const res = await fetchApi.patch(
        `project-tickets/${id}/`,
        { priority: piority },
        {
          headers: { Authorization: `Bearer ${userInfo.access_token}` },
        }
      );
      if (res.status === 200) {
        setTicketData((prev) => ({ ...prev, priority: res.data.priority }));
        dispatch(addToast({ message:'Piority changed', type:'success' })); 
      }
    } catch (error) {
      dispatch(addToast({ message:error.message, type:'error' })); 
    } finally {
      setPriorityUpdating(false);
    }
  };
  const handleDeleteTicket = async () => {
    try {
      const updateTicketRes = await fetchApi.delete(`project-tickets/${id}/`, {
        headers: { Authorization: `Bearer ${userInfo.access_token}` },
      });

      if (updateTicketRes.status === 204) {
        dispatch(addToast({ message:'Tickets deleted successfully', type:'success' })); 
        navigate(-1);
      } else {
        dispatch(addToast({ message:'Failed to delete', type:'error' })); 
      }
    } catch (error) {
      dispatch(addToast({ message:error.message, type:'error' })); 
    }
  };
  const handleUpdateComment = async (commentId) => {
    setCommentSaving(true);
    try {
      const updatedComment = await fetchApi.patch(
        `comments/${commentId}/`,
        { description: commentDescription },
        {
          headers: { Authorization: `Bearer ${userInfo.access_token}` },
        }
      );
      if (updatedComment.status === 200) {
        setCommentEditing(false);
        setEditingCommentId(null);
        setCommentDescription("");
        setEditingAttachments([]);
        dispatch(addToast({ message:'Comment updated successfully', type:'success' })); 
        setComments((prevComments) =>
          prevComments.map((comment) =>
            comment.id === commentId
              ? { ...comment, description: updatedComment.data.description }
              : comment
          )
        );
      }
    } catch (error) {
      dispatch(addToast({ message:error.message, type:'error' })); 
    } finally {
      setCommentSaving(false);
    }
  };

  return (
    <div className={`w-full flex flex-col items-center ${styles.customFont}`}>
      <div className="flex w-full items-center justify-center">
        <div
          className="flex gap-2 items-center"
          style={{ width: "90%", maxWidth: "1024px", margin: "20px 0px" }}
        >
          <button
            onClick={() => navigate(-1)}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "10px",
              fontSize: "20px",
              backgroundColor: "red",
              color: "#fff",
              border: "none",
              borderRadius: "50%",
              cursor: "pointer",
              outline: "none",
              zIndex: "9999",
            }}
          >
            <IoIosArrowBack />
          </button>
          <div
            className={` ${
              theme === "dark" ? "text-white" : "text-black"
            } max-w-3xl flex gap-2`}
            style={{
              fontSize: "x-large",
            }}
          >
            Ticket Detail
          </div>
        </div>
      </div>
      <hr className="w-full" />
      {loading ? (
        <Loader style={{ height: "100%" }} />
      ) : (
        <div
          className={`w-[90%] max-w-screen-lg shadow-xl lg:px-auto px-6 my-6 rounded-md min-h-[80vh] justify-between py-8 relative flex flex-col ${
            theme === "dark"
              ? "text-white bg-darkMode"
              : "text-black bg-[#F7F7F7]"
          }`}
        >
          <div className="w-full">
            <div className=" capitalize text-[13px] font-normal flex justify-between items-center">
              {`${ticketData?.project?.title}  /  ${ticketData?.milestone?.title}  /  ${ticketData?.sprint} / ${ticketData?.type}`}
              <div className="relative">
                <BsThreeDots
                  className="w-6 h-6 cursor-pointer"
                  onClick={() => setActionsDropdown(!actionsDropdown)}
                />
                {actionsDropdown && (
                  <div
                    className={`absolute top-full right-0 p-2 mt-2 w-28 shadow-lg rounded-md z-10 ${
                      theme === "dark"
                        ? "bg-dashboardDarkMode text-white"
                        : "bg-lightMode text-black"
                    }`}
                  >
                    <div
                      className={`flex items-center px-3 py-2  cursor-pointer ${
                        theme === "dark"
                          ? "hover:bg-gradient-to-r hover:from-[#fff3] hover:to-[#fff0] transition-all"
                          : "hover:bg-gradient-to-r hover:from-[#6d6d6d33] hover:to-[#fff0] transition-all"
                      }`}
                      onClick={() => {
                        setActionsDropdown(false);
                        handleDeleteTicket();
                      }}
                    >
                      Delete
                    </div>
                  </div>
                )}
              </div>
            </div>
            <input
              value={ticketData?.title}
              onChange={(e) => {
                const newTitle = e.target.value;
                setTicketData((prevData) => ({
                  ...prevData, // Spread previous data to retain unchanged fields
                  title: newTitle, // Update the specific field
                }));
                setIsTitleEditing(true);
              }}
              className={`text-4xl  cursor-pointer px-2 py-1  w-full rounded-md border-none bg-transparent outline-none ${
                theme === "dark"
                  ? "hover:bg-gradient-to-r hover:from-[#fff3] hover:to-[#fff0] transition-all"
                  : "hover:bg-gradient-to-r hover:from-[#6d6d6d33] hover:to-[#fff0] transition-all"
              }`}
              style={{ lineHeight: "2.25rem" }}
            />
            {isTitleEditing && (
              <div className=" flex justify-end gap-2">
                <MdClose
                  className="w-6 h-6"
                  onClick={() => {
                    setIsTitleEditing(false);
                    setTicketData((prevData) => ({
                      ...prevData, // Spread previous data to retain unchanged fields
                      title: title, // Update the specific field
                    }));
                  }}
                  title="Cancel"
                />
                <FiCheck
                  onClick={handleSaveTitle}
                  className="w-6 h-6"
                  title="Save Title"
                />
              </div>
            )}

            <p className="text-gray-500 font-normal outline-none px-2">
              {timeDifference(ticketData.created_at)}
            </p>
            <div className="flex justify-start w-full my-2">
              <div className={styles["button-container"]}>
                <button onClick={handleButtonClick1} className={`heroButton`}>
                  <span>Add Attachment</span>
                  <input
                    type="file"
                    ref={fileInputRef1}
                    style={{ display: "none" }} // Hide the file input
                    onChange={handleFileChange1}
                  />
                </button>
              </div>
            </div>

            <div className="flex gap-2 items-center">
              <label htmlFor="Piriorty" className="text-lg font-semibold">
                Priority:
              </label>
              <div className="w-48">
                {priorityUpdating ? (
                  <img src={LoadingGif} alt="loading" className="w-6 h-6" />
                ) : (
                  <div
                    className={`flex relative gap-1 text-base font-light w-48 rounded-md p-2 cursor-pointer ${
                      theme === "dark"
                        ? "hover:bg-gradient-to-r hover:from-[#fff3] hover:to-[#fff0] transition-all"
                        : "hover:bg-gradient-to-r hover:from-[#6d6d6d33] hover:to-[#fff0] transition-all"
                    }`}
                    onClick={() => setPiortyDropdown(!piortyDropdown)}
                  >
                    <div className="flex gap-[2px] items-baseline">
                    <span className={`${ticketData?.priority === 'low' ? 'bg-green-600': ticketData?.priority === 'medium' ? 'bg-yellow-600' : 'bg-red-600'} w-[3px] rounded-md h-[10px]`}></span>
              <span
                className={`w-[3px] h-[14px] rounded-md ${
                  ticketData?.priority === "medium" ? 'bg-yellow-600': ticketData?.priority === "high"
                    ? "bg-red-600"
                    : "bg-gray-700"
                }`}
              ></span>
              <span
                className={`w-[3px] h-[19px] rounded-md ${
                  ticketData?.priority === "high" ? "bg-red-600" : "bg-gray-700"
                }`}
              ></span>
                    </div>
                    <span>{ticketData.priority}</span>
                    {piortyDropdown && (
                      <div
                        className={`absolute top-full left-0 mt-2 w-full shadow-lg rounded-md z-10 ${
                          theme === "dark"
                            ? "bg-dashboardDarkMode text-white"
                            : "bg-lightMode text-black"
                        }`}
                      >
                        {["low", "medium", "high"].map((option) => (
                          <div
                            key={option}
                            className={`flex items-center px-3 py-2  cursor-pointer ${
                              theme === "dark"
                                ? "hover:bg-gradient-to-r hover:from-[#fff3] hover:to-[#fff0] transition-all"
                                : "hover:bg-gradient-to-r hover:from-[#6d6d6d33] hover:to-[#fff0] transition-all"
                            }`}
                            onClick={() => {
                              handlePiorityChange(option);
                              setPiortyDropdown(false);
                            }}
                          >
                            {option.icon}
                            <span className="capitalize">{option}</span>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="flex gap-2 my-2 items-center">
              <label htmlFor="" className="text-lg font-semibold">
                Assigned To:
              </label>
              {userUpdating ? (
                <img src={LoadingGif} alt="loading" className="w-6 h-6" />
              ) : (
                <div
                  className={`p-2 relative cursor-pointer flex gap-2 items-center text-base font-light rounded-md w-48 ${
                    theme === "dark"
                      ? "hover:bg-gradient-to-r hover:from-[#fff3] hover:to-[#fff0] transition-all"
                      : "hover:bg-gradient-to-r hover:from-[#6d6d6d33] hover:to-[#fff0] transition-all"
                  }`}
                  onClick={() => setUserDropDown(!userDropDown)}
                >
                  {ticketData?.developer?.picture === null ? (
                    <FaUserCircle className="w-6 h-6" />
                  ) : (
                    <img
                      src={ticketData?.developer?.picture}
                      className="w-6 h-6 rounded-full object-cover"
                      alt="profile picture" loading="lazy"
                    />
                  )}
                  {ticketData?.developer?.first_name}{" "}
                  {ticketData?.developer?.last_name}
                  {userDropDown && (
                    <div
                      className={`absolute top-full left-0 mt-2 w-full h-52 overflow-y-auto shadow-lg rounded-md z-10 ${
                        theme === "dark"
                          ? "bg-dashboardDarkMode text-white"
                          : "bg-[#f2f2f2] text-black"
                      }`}
                    >
                      {users.map((option) => (
                        <div
                          key={option.id}
                          className={`flex items-center px-3 py-2 cursor-pointer ${
                            theme === "dark"
                              ? "hover:bg-gradient-to-r hover:from-[#fff3] hover:to-[#fff0] transition-all"
                              : "hover:bg-gradient-to-r hover:from-[#6d6d6d33] hover:to-[#fff0] transition-all"
                          } ${
                            ticketData?.developer?.id === option?.id
                              ? theme === "dark"
                                ? "bg-gradient-to-r from-[#fff3] to-[#fff0] transition-all"
                                : "bg-gradient-to-r from-[#6d6d6d33] to-[#fff0] transition-all"
                              : ""
                          }`}
                          onClick={() => {
                            handleChangeUser(option.id);
                            setUserDropDown(false);
                          }}
                        >
                          {option.icon}
                          <span className="capitalize">
                            {option?.first_name} {option?.last_name}
                          </span>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
            </div>
            <label htmlFor="description" className="mt-4 text-lg font-semibold">
              Description:
            </label>
            {editorOpen ? (
              <RichTextEditor
                description={description}
                setDescription={setDescription}
                setEditorOpen={setEditorOpen}
                handleSave={handleSaveDescription}
              />
            ) : (
              <div
                onClick={() => setEditorOpen(true)}
                className={` cursor-pointer mt-1 outline-none rounded-md p-2 ${
                  theme === "dark"
                    ? "hover:bg-gradient-to-r hover:from-[#fff3] hover:to-[#fff0] transition-all"
                    : "hover:bg-gradient-to-r hover:from-[#6d6d6d33] hover:to-[#fff0] transition-all"
                }`}
                dangerouslySetInnerHTML={{
                  __html: description || "Click to add a description",
                }}
              ></div>
            )}
            {attachmentPreview && (
              <div
                style={{ marginTop: "10px" }}
                className="w-full flex justify-center relative items-center flex-col"
              >
                {typeof attachmentPreview === "string" &&
                attachmentPreview.startsWith("data:image/") ? (
                  <img src={attachmentPreview} alt="File Preview" />
                ) : (
                  <div
                    className={`w-full flex justify-between items-center p-4 rounded-md ${
                      theme === "dark" ? "bg-darkMode" : "bg-gray-200"
                    }`}
                  >
                    {attachmentPreview}
                    <FaDownload className="w-5 h-5 cursor-pointer" />
                  </div>
                )}
                {uploadProgress > 0 && (
                  <div className="absolute w-12 h-12">
                    {/* Background Circle */}
                    <svg className="w-full h-full">
                      <circle
                        cx="50%"
                        cy="50%"
                        r="35%"
                        fill="none"
                        stroke={theme === "dark" ? "gray" : "#e5e5e5"}
                        strokeWidth="6"
                      />
                    </svg>
                    {/* Progress Circle */}
                    <svg className="w-full h-full absolute top-0 left-0 transform rotate-[-90deg]">
                      <circle
                        cx="50%"
                        cy="50%"
                        r="35%"
                        fill="none"
                        stroke="red"
                        strokeWidth="6"
                        strokeDasharray="283" // Circumference of the circle (2πr, where r = 45)
                        strokeDashoffset={283 - (uploadProgress / 100) * 283} // Offset based on progress
                        strokeLinecap="round"
                      />
                    </svg>
                  </div>
                )}
              </div>
            )}
            {attachments.length > 0 && (
              <div className="my-4 flex gap-4 flex-col w-full">
                <h3 className="text-lg font-semibold">Attachments:</h3>
                {attachments.map((e) => {
                  // Extract the file name and extension
                  const fileUrlParts = e?.file.split("/");
                  const fileName = fileUrlParts[fileUrlParts.length - 1]; // Get the file name
                  const fileExtension = fileName
                    .split(".")
                    .pop()
                    ?.toLowerCase(); // Get file type

                  // Determine the type of file
                  const isImage = ["jpg", "jpeg", "png", "gif"].includes(
                    fileExtension
                  );
                  const isVideo = ["mp4", "webm", "ogg"].includes(
                    fileExtension
                  );
                  const isPDF = fileExtension === "pdf";

                  return (
                    <div className="flex gap-2">
                      <div
                        key={e.id}
                        className="flex flex-col justify-center my-2 w-full items-center relative group"
                      >
                        {isImage && (
                          <div className="relative w-4/5 flex flex-col justify-center items-center">
                            <img
                              src={e?.file}
                              alt={fileName}
                              className="rounded-md"
                            />
                            <div className="absolute top-2 right-2 flex gap-2 opacity-0 group-hover:opacity-100 transition-opacity">
                              <a
                                href={e?.file}
                                download={fileName}
                                target="_blank"
                                className="bg-gray-700 text-white p-1 rounded-full"
                                title="Download"
                              >
                                <FaDownload className="w-5 h-5" />
                              </a>
                              <button
                                onClick={() => handleRemoveAttachment(e.id)}
                                className="bg-red-600 text-white p-1 rounded-full"
                                title="Delete"
                              >
                                <MdDeleteForever className="w-5 h-5" />
                              </button>
                            </div>
                          </div>
                        )}
                        {isVideo && (
                          <div className="relative w-4/5 flex flex-col justify-center items-center">
                            <video
                              src={e?.file}
                              controls
                              className="rounded-md"
                            />
                            <div className="absolute top-2 right-2 flex gap-2 opacity-0 group-hover:opacity-100 transition-opacity">
                              <a
                                href={e?.file}
                                download={fileName}
                                className="bg-gray-700 text-white p-1 rounded-full"
                                title="Download"
                              >
                                <FaDownload className="w-5 h-5" />
                              </a>
                              <button
                                onClick={() => handleRemoveAttachment(e.id)}
                                className="bg-red-600 text-white p-1 rounded-full"
                                title="Delete"
                              >
                                <MdDeleteForever className="w-5 h-5" />
                              </button>
                            </div>
                          </div>
                        )}
                        {isPDF && (
                          <div className="relative w-4/5 flex flex-col justify-center items-center">
                            <embed
                              src={e?.file}
                              type="application/pdf"
                              className="rounded-md"
                            />
                            <div className="absolute top-2 right-2 flex gap-2 opacity-0 group-hover:opacity-100 transition-opacity">
                              <a
                                href={e?.file}
                                download={fileName}
                                className="bg-gray-700 text-white p-1 rounded-full"
                                title="Download"
                              >
                                <FaDownload className="w-5 h-5" />
                              </a>
                              <button
                                onClick={() => handleRemoveAttachment(e.id)}
                                className="bg-red-600 text-white p-1 rounded-full"
                                title="Delete"
                              >
                                <MdDeleteForever className="w-5 h-5" />
                              </button>
                            </div>
                          </div>
                        )}
                        {!isImage && !isVideo && !isPDF && (
                          <div
                            className={`flex justify-between items-center p-4 w-4/5 rounded-md ${
                              theme === "dark"
                                ? "bg-dashboardDarkMode"
                                : "bg-gray-200"
                            }`}
                          >
                            <p className="text-sm font-semibold">{fileName}</p>
                            <div className="flex gap-2">
                              <a
                                href={e?.file}
                                download={fileName}
                                className="bg-gray-700 text-white p-1 rounded-full"
                                title="Download"
                              >
                                <FaDownload className="w-5 h-5" />
                              </a>
                              <button
                                onClick={() => handleRemoveAttachment(e.id)}
                                className="bg-red-600 text-white p-1 rounded-full"
                                title="Delete"
                              >
                                <MdDeleteForever className="w-5 h-5" />
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          <div>
            <label htmlFor="" className="font-semibold text-lg mt-4 mb-2">
              Activity:
            </label>
            <div className="comments">
              {comments?.map((comment) => (
                <div className="flex gap-2 items-start mb-2" key={comment.id}>
                  {comment?.created_by?.picture ? <img src={comment.created_by.picture} className="w-6 h-6 rounded-full object-cover" loading="lazy"/> : 
                  <FaUserCircle className="w-6 h-6" /> }
                  <div className="flex flex-col w-full">
                    <span className="flex gap-1 items-center mb-1 font-semibold">
                      {comment?.created_by?.first_name}{" "}
                      {comment?.created_by?.last_name}
                      <p className="text-gray-600 text-sm">
                        {" "}
                        {timeDifference(comment?.created_at)}
                      </p>
                    </span>
                    {comment.attachment.length > 0 &&
                      editingCommentId !== comment.id &&
                      comment.attachment.map((e) => {
                        const fileUrlParts = e?.file.split("/");
                        const fileName = fileUrlParts[fileUrlParts.length - 1];
                        const fileExtension = fileName
                          .split(".")
                          .pop()
                          ?.toLowerCase();

                        const isImage = ["jpg", "jpeg", "png", "gif"].includes(
                          fileExtension
                        );
                        const isVideo = ["mp4", "webm", "ogg"].includes(
                          fileExtension
                        );
                        const isPDF = fileExtension === "pdf";

                        return (
                          <div
                            key={e.id}
                            className="flex flex-col justify-center my-2 w-full items-center"
                          >
                            {isImage && (
                              <img
                                src={e.file}
                                alt={fileName}
                                className="rounded-md"
                              />
                            )}
                            {isVideo && (
                              <video
                                src={e.file}
                                controls
                                className="rounded-md"
                              />
                            )}
                            {isPDF && (
                              <embed
                                src={e.file}
                                type="application/pdf"
                                className="rounded-md"
                              />
                            )}
                          </div>
                        );
                      })}
                    {editingCommentId === comment.id ? (
                      <>
                        <div
                          className={`border rounded-md p-2 w-full ${
                            theme === "dark" ? "" : "border-gray-600"
                          }`}
                        >
                          {/* Editable attachments */}
                          {editingAttachments.map((attachment) => (
                            <div
                              key={attachment.id}
                              className="relative w-full flex flex-col items-center my-2"
                            >
                              <img
                                src={attachment.file}
                                alt={attachment.id}
                                className="rounded-md"
                              />
                              <button
                                onClick={() =>
                                  handleDeleteAttachment(
                                    attachment.id,
                                    comment.id
                                  )
                                }
                                className="absolute top-2 right-4 p-1 rounded-full"
                                title="Delete Attachment"
                              >
                                <MdDeleteForever className="w-6 h-6" />
                              </button>
                            </div>
                          ))}
                          <RichTextEditor1
                            comment={commentDescription}
                            setComment={setCommentDescription}
                          />
                        </div>
                        <div className="flex gap-2 items-center mt-2">
                          <button
                            className="heroButton"
                            onClick={() => handleUpdateComment(comment.id)}
                            disabled={commentSaving}
                          >
                            {commentSaving ? "Saving..." : "Save"}
                          </button>
                          <button onClick={() => setEditingCommentId(null)}>
                            Cancel
                          </button>
                        </div>
                      </>
                    ) : (
                      <div>
                        <div>
                          <div
                            className="font-light text-base"
                            dangerouslySetInnerHTML={{
                              __html: comment.description,
                            }}
                          ></div>
                          {comment.created_by !== null && (
                          comment.created_by.id === userInfo?.user?.id && (
                            <div className="flex gap-2 text-sm font-bold">
                              <span
                                className="hover:underline cursor-pointer"
                                onClick={() => handleEditClick(comment)}
                              >
                                Edit
                              </span>
                              <span
                                className="hover:underline cursor-pointer"
                                onClick={() => handleDeleteComment(comment.id)}
                              >
                                {commentDeleting === comment.id
                                  ? "Deleting..."
                                  : "Delete"}
                              </span>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
            <div className="flex gap-2 w-full mt-4">
             {userInfo?.user?.picture ? <img src={userInfo?.user?.picture} alt="Profile picture" className="w-8 h-8 rounded-full object-cover" loading="lazy"/> :  <FaUserCircle className="w-8 h-8" /> }
              <div
                className={`p-2 border rounded-md w-full flex flex-col justify-center ${
                  theme === "dark" ? "" : "border-gray-600"
                }`}
              >
                {preview && (
                  <div
                    style={{ marginTop: "10px" }}
                    className="w-full flex justify-center relative items-center"
                  >
                    {typeof preview === "string" &&
                    preview.startsWith("data:image/") ? (
                      <img src={preview} alt="File Preview" />
                    ) : (
                      <p>{preview}</p>
                    )}
                  </div>
                )}
                <RichTextEditor1 comment={comment} setComment={setComment} />
                <div className="flex w-full mt-1 justify-end items-center">
                  <div>
                    <button onClick={handleButtonClick}>
                      <GrAttachment className="w-6 h-6" />
                    </button>
                    <input
                      type="file"
                      ref={fileInputRef}
                      style={{ display: "none" }} // Hide the file input
                      onChange={handleFileChange}
                    />
                  </div>
                  <button
                    className="bg-red-600 text-white p-2 rounded-full ml-2 flex items-center justify-center"
                    onClick={handleSubmit}
                  >
                    <IoIosSend className="w-5 h-5" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TicketDetail;
