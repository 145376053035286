import React, { useState } from "react";
import dayjs from "dayjs";

const Calendar = ({ theme = "light", onDateSelect, }) => {
  const [currentDate, setCurrentDate] = useState(dayjs()); // Current displayed month/year
  const [selectedDate, setSelectedDate] = useState(dayjs()); // Selected date

  const startOfMonth = currentDate.startOf("month");
  const endOfMonth = currentDate.endOf("month");
  const startDay = startOfMonth.day(); // Day of the week the month starts on
  const daysInMonth = endOfMonth.date();

  const handlePrevMonth = () => setCurrentDate(currentDate.subtract(1, "month"));
  const handleNextMonth = () => setCurrentDate(currentDate.add(1, "month"));

  const handleDateClick = (date) => {
    setSelectedDate(date);
    onDateSelect && onDateSelect(date.format("YYYY-MM-DD"));
  };

  const renderDays = () => {
    const days = [];

    // Add empty cells for the previous month's trailing days
    for (let i = 0; i < startDay; i++) {
      days.push(<div key={`empty-${i}`} className="flex justify-center items-center h-8"></div>);
    }

    // Add cells for the current month's days
    for (let day = 1; day <= daysInMonth; day++) {
      const date = startOfMonth.date(day);
      const isSelected = date.isSame(selectedDate, "day");
      const isToday = date.isSame(dayjs(), "day");

      days.push(
        <div
          key={`day-${day}`}
          onClick={() => handleDateClick(date)}
          className={`flex justify-center items-center h-8 w-8 rounded-md cursor-pointer transition-all 
            ${isSelected ? "bg-red-600 text-white" : ''} 
            ${theme === "dark" ? "hover:bg-red-600 text-white" : "hover:bg-red-600 hover:text-white text-black"}`}
        >
          {day}
        </div>
      );
    }

    return days;
  };

  return (
    <div className={`p-4 rounded-lg w-64 top-full left-0 absolute z-50 ${theme === "dark" ? "bg-black text-white" : "bg-white text-black"}`}  style={{ top: "100%", right: "0", left: "0" }}>
      <div className="flex justify-between items-center mb-4">
        <button
          onClick={handlePrevMonth}
          className={`p-2 rounded ${theme === "dark" ? "hover:bg-gray-700" : "hover:bg-gray-200"}`}
        >
          &#8592;
        </button>
        <h2 className="font-semibold">
          {currentDate.format("MMMM YYYY")}
        </h2>
        <button
          onClick={handleNextMonth}
          className={`p-2 rounded ${theme === "dark" ? "hover:bg-gray-700" : "hover:bg-gray-200"}`}
        >
          &#8594;
        </button>
      </div>

      <div className="grid grid-cols-7 gap-1 text-center font-medium mb-2">
        {["S", "M", "T", "W", "T", "F", "S"].map((day, idx) => (
          <div key={`day-header-${idx}`}>{day}</div>
        ))}
      </div>

      <div className="grid grid-cols-7 gap-1">
        {renderDays()}
      </div>
    </div>
  );
};

export default Calendar;

// Usage Example
// import Calendar from "./Calendar";
// <Calendar theme="dark" onDateSelect={(date) => console.log("Selected date:", date)} />;
