import { useDispatch, useSelector } from "react-redux";
import { removeToast } from "../../Redux/Slices/toast-slice"; // Import removeToast action
import { motion, AnimatePresence } from "framer-motion";
import { useEffect } from "react";
import { MdErrorOutline, MdOutlineError } from "react-icons/md";
import { IoWarning, IoWarningOutline } from "react-icons/io5";
import { FaRegCircleCheck } from "react-icons/fa6";


const toastVariants = {
  hidden: { x: "100%", opacity: 0 },
  visible: { x: 0, opacity: 1, transition: { type: "spring", stiffness: 200 } },
  exit: { x: "100%", opacity: 0, transition: { duration: 0.3 } },
};

const toastStyles = {
  base: "px-4 py-3 rounded-md shadow-xl mb-2 w-80 flex items-center gap-2",
};

// Icon mapping for each state
const toastIcons = {
  success: <FaRegCircleCheck className="w-6 h-6 text-green-500 " />,
  error: <MdErrorOutline className="w-6 h-6 text-red-500"/>,
  warn: <IoWarningOutline className="w-6 h-6 text-yellow-500" />,
};

const ToastContainer = () => {
  const dispatch = useDispatch();
  const toasts = useSelector((state) => state.toast.toasts);
  const { theme}=useSelector((state) => state.theme)

  useEffect(() => {
    if (toasts.length > 0) {
      const timer = setTimeout(() => {
        dispatch(removeToast(toasts[0].id)); // Remove first toast
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [toasts, dispatch]);

  return (
    <div className="fixed top-5 right-5 flex flex-col-reverse gap-2 z-[50000] customFont">
      <AnimatePresence>
        {toasts.map((toast) => (
         <motion.div
         key={toast.id}
         variants={toastVariants}
         initial="hidden"
         animate="visible"
         exit="exit"
         className={`${toastStyles.base} ${toastStyles[toast.type]} ${
           theme === "dark" ? "bg-black text-white" : "bg-white text-black"
         }`}
       >
         {/* ✅ Wrap Icon in a Fixed-Size Flexbox */}
         <div className="flex items-center justify-center w-8 h-8">
           {toastIcons[toast.type]} 
         </div>
         
         <span className="flex-1">
  {toast.message?.message || (typeof toast.message === "object" ? JSON.stringify(toast.message) : toast.message)}
</span>

         <button
           className="ml-auto text-lg font-bold cursor-pointer"
           onClick={() => dispatch(removeToast(toast.id))}
         >
           ✖
         </button>
       </motion.div>
       
        ))}
      </AnimatePresence>
    </div>
  );
};

export default ToastContainer;
