import { useEffect, useState, useCallback, useMemo, useRef } from "react";
import { fetchApi } from "../../../utlis/axios";
import { useSelector, useDispatch } from "react-redux";
import { FaUserCircle } from "react-icons/fa";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { IoSend } from "react-icons/io5";
import Loader from "../../Loader/Loader";
import { toggleAllChats } from "../../../Redux/Slices/chat-slice";
import { format, parseISO } from "date-fns";
import { MdOutlineChat } from "react-icons/md";
import { GrAttachment } from "react-icons/gr";
import { MdChatBubbleOutline } from "react-icons/md";
import { addToast } from "../../../Redux/Slices/toast-slice";
const AgentChat = () => {
  const dispatch = useDispatch();
  const [socketUrl, setSocketUrl] = useState("");
  const [message, setMessage] = useState("");
  const { userInfo } = useSelector((state) => state?.auth || {});
  const { theme } = useSelector((state) => state.theme);
  const chatUsers = useSelector((state) => state.chat.allChats);
  const [messageHistory, setMessageHistory] = useState([]);
  const [chatHistory, setChatHistory] = useState([]);
  const [activeChat, setActiveChat] = useState(null);
  const [chatLoading, setChatLoading] = useState(false);
  const [activeChatUserName, setActiveChatUserName] = useState(""); 
  const [isLoading, setIsLoading]=useState(false);
  const [activeTab, setActiveTab] = useState("open");
  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, {
    shouldReconnect: (closeEvent) => true,
    reconnectAttempts: 10,
    reconnectInterval: 3000,
  });
  const messagesEndRef = useRef(null);
  const fileInputRef = useRef(null);
  const [uploadedFileLink, setUploadedFileLink] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const getAllChat = async (status) => {
    let isFetched = false;
    if (isFetched) {
      return;
    } else {
      isFetched = true;
      setIsLoading(true)
      try {
        const res = await fetchApi.get(`chat/?status=${status}`, {
          headers: {
            Authorization: `Bearer ${userInfo.access_token}`,
          },
        });
        if (res.status === 200) {
          dispatch(toggleAllChats({ allChats: res.data.results }));
        }
      } catch (error) {
        dispatch(addToast({ message : 'Error loading chats', type : '' })); 
      }finally{
        setIsLoading(false)
      }
    }
  };
  const FetchChats = async () => {
    let isFetched = false;
    if (isFetched) {
      return;
    } else {
      isFetched = true;
      try {
        const res = await fetchApi.get(`chat/?status=${activeTab}`, {
          headers: {
            Authorization: `Bearer ${userInfo.access_token}`,
          },
        });
        if (res.status === 200) {
          dispatch(toggleAllChats({ allChats: res.data.results }));
        }
      } catch (error) {
        dispatch(addToast({ message: 'Error loading chats', type :'Error' })); 
      }
    }
  };
  useEffect(() => {
    FetchChats()
  }, []);
  

  const fetchChatHistory = async (chatId) => {
    let isFetched = false;
    if (isFetched) {
      return;
    } else {
      isFetched = true;
      setChatLoading(true);
      try {
        const response = await fetchApi.get(`message/?chat=${chatId}`, {
          headers: {
            Authorization: `Bearer ${userInfo.access_token}`,
          },
        });
        const messages = response.data.results;

        const unreadMessages = messages.filter(
          (msg) => msg.sender.id !== userInfo.user.id && !msg.read_status
        );

        setChatHistory(response.data.results);
        scrollToBottom(); // ✅ Store old messages in state
      } catch (error) {
        dispatch(addToast({ message :'Error loading chats', type : 'error' })); 
      } finally {
        setChatLoading(false);
      }
    }
  };
  const handleChangeChat = async (chatId) => {
    setSocketUrl(`wss://bugtrack-eatwy.ondigitalocean.app/ws/support/${chatId}/`);
    setMessageHistory([]);
    fetchChatHistory(chatId);
    try {
      const res = await fetchApi.patch(
        `chat/${chatId}/`,
        { support_agent: userInfo?.user?.id, status: "in_progress" },
        {
          headers: {
            Authorization: `Bearer ${userInfo.access_token}`,
          },
        }
      );
      if (res.status === 200) {
        setActiveChat(res.data);
      }
    } catch {
      dispatch(addToast({ message:'Something went wrong', type :'error' })); 
    }
  };
  useEffect(() => {
    if (lastMessage !== null) {
      try {
        const parsedMessage = JSON.parse(lastMessage.data);

        setMessageHistory((prev) => [...prev, parsedMessage]); // ✅ Store message in history
        scrollToBottom();
      } catch (error) {
        console.error("Error parsing WebSocket message:", error);
      }
    }
  }, [lastMessage]);
  useEffect(() => {
    scrollToBottom();
  }, [messageHistory]);

  const handleClickSendMessage = useCallback(() => {
    if (readyState === ReadyState.OPEN) {
      if (message.trim() !== "" || uploadedFileLink) {
        let fileMessage = "";
        if (isUploading) {
          dispatch(addToast({ message :'Uploading file', type : 'warn' })); 
          return;
        } else {
          if (uploadedFileLink) {
            // Extract file extension from the URL
            const fileExtension = uploadedFileLink
              .split(".")
              .pop()
              .toLowerCase();

            // Define supported file types
            const imageTypes = ["jpg", "jpeg", "png", "gif", "webp"];
            const videoTypes = ["mp4", "webm", "ogg"];
            const pdfTypes = ["pdf"];

            if (imageTypes.includes(fileExtension)) {
              fileMessage = `<img src="${uploadedFileLink}" alt="attachment" />`;
            } else if (videoTypes.includes(fileExtension)) {
              fileMessage = `<video controls><source src="${uploadedFileLink}" type="video/${fileExtension}">Your browser does not support the video tag.</video>`;
            } else if (pdfTypes.includes(fileExtension)) {
              fileMessage = `<a href="${uploadedFileLink}" target="_blank">View PDF</a>`;
            } else {
              fileMessage = `<a href="${uploadedFileLink}" target="_blank">Download File</a>`;
            }
          }

          // Construct the final message
          const finalMessage = fileMessage
            ? `${fileMessage} <p>${message}</p>`
            : message;

          sendMessage(
            JSON.stringify({
              message: finalMessage,
              sender: userInfo?.user?.id,
            })
          );

          // Reset fields
          setMessage("");
          setUploadedFileLink(null);
        }
      }
    } else {
      dispatch(addToast({ message : 'Message not sent try again', type : 'error'})); 
    }
  }, [message, readyState, uploadedFileLink, userInfo?.user?.id]);

  // Group chatHistory messages by date
  const groupedChatHistory = useMemo(() => {
    const groups = {};

    chatHistory?.sort((a, b) => new Date(a.created_at) - new Date(b.created_at)); // Sort in ascending order

    chatHistory?.forEach((msg) => {
      const dateKey = format(parseISO(msg.created_at), "dd MMM yyyy"); // e.g., "07 Feb 2025"
      if (!groups[dateKey]) {
        groups[dateKey] = [];
      }
      groups[dateKey].push(msg);
    });

    return groups;
  }, [chatHistory]);

  const closeChat = async (chatId) => {
    let chatUser= chatUsers
    dispatch(toggleAllChats({ allChats: chatUsers.filter((chat) => chat.id !== chatId) }));
    setActiveChat('');
      setSocketUrl("");
      setChatHistory([]);
      setMessageHistory([]);
    try {
      const res = await fetchApi.patch(
        `chat/${chatId}/`,
        { status: "closed" },
        {
          headers: {
            Authorization: `Bearer ${userInfo.access_token}`,
          },
        }
      );
      setActiveChat('');
      setSocketUrl("");
    } catch (error) {
      toggleAllChats({allChats:chatUser})
    }
  };
  const removeChat = async (chatId) => {
    let originalUser=chatUsers
    dispatch(toggleAllChats({ allChats: chatUsers.filter((chat) => chat.id !== chatId) }));
    setActiveChat('')
    setSocketUrl('')
    setChatHistory([])
    setMessageHistory('')
    try {
      const res = await fetchApi.delete(`chat/${chatId}/`, {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });
      setActiveChat("");
      setChatHistory([]);
      setMessageHistory([]);
      setSocketUrl("");
      dispatch(
        toggleAllChats({
          allChats: chatUsers.filter((chat) => chat.id !== chatId),
        })
      );
    } catch (error) {
      dispatch(addToast({ message:'Chat not removed', type :'error' })); 
      dispatch(toggleAllChats({ allChats: originalUser }));
    }
  };

  const handleButtonClick = () => {
    fileInputRef.current.click(); // Trigger the hidden file input
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0]; // Get the selected file

    if (file) {
      // Simulate file upload
      const formData = new FormData();
      formData.append("file", file);
      setIsUploading(true);
      try {
        const response = await fetchApi.post("/attachments/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${userInfo.access_token}`,
          },
        });

        if (response.status === 201) {
          setUploadedFileLink(response.data.file);
        } else {
          dispatch(addToast({ message :'File Upload failed', type :'error' })); 
        }
      } catch (error) {
        dispatch(addToast({ message:'Something went wrong', type :'error' })); 
      } finally {
        setIsUploading(false);
      }
    }
  };

  return (
    <div
      className={`flex h-screen customFont w-full transition-colors duration-300 ${
        theme === "dark" ? "bg-darkMode text-white" : "bg-gray-100 text-black"
      }`}
    >
      {/* Sidebar */}
      <div
        className={`md:w-1/3 w-full border-r p-4 overflow-x-auto overflow-y-auto max-h-[85vh] ${
          theme === "dark" ? "border-gray-700" : "border-gray-300"
        } ${activeChat ? " md:block hidden" : "block"}`}
      >
        <h2 className="text-lg font-semibold mb-4">Chats</h2>
        <div className="my-2 flex flex-wrap gap-2">
          <div
            className={`${
              activeTab === "open"
                ? "bg-red-600 text-white"
                : theme === "dark"
                ? "bg-[#535353] text-white"
                : "bg-gray-400"
            } rounded-3xl py-2 px-3 cursor-pointer w-max`}
            onClick={() => {
              setActiveTab("open");
              getAllChat('open')
              setActiveChat("");
            }}
          >
            Open
          </div>
          <div
            className={`${
              activeTab === "in_progress"
                ? "bg-red-600 text-white"
                : theme === "dark"
                ? "bg-[#535353] text-white"
                : "bg-gray-400"
            } rounded-3xl py-2 px-3 cursor-pointer w-max`}
            onClick={() => {
              setActiveTab("in_progress");
              getAllChat('in_progress')
              setActiveChat("");
            }}
          >
            In Progress
          </div>
          <div
            className={`${
              activeTab === "closed"
                ? "bg-red-600 text-white"
                : theme === "dark"
                ? "bg-[#535353] text-white"
                : "bg-gray-400"
            } rounded-3xl py-2 px-3 cursor-pointer w-max`}
            onClick={() => {
              setActiveTab("closed");
              getAllChat('closed')
              setActiveChat("");
            }}
          >
            Closed
          </div>
        </div>
        {isLoading ? <div className="h-full flex justify-center items-center"><Loader/></div> :
        chatUsers.length > 0 ?
        chatUsers?.map((chat) => (
          <div
            key={chat?.id}
            className={`flex items-center p-3 rounded-lg cursor-pointer transition hover:bg-opacity-70 ${
              activeChat?.id === chat.id
                ? theme === "dark"
                  ? "bg-gray-800"
                  : "bg-gray-300"
                : ""
            }`}
            onClick={() => {
              setActiveChat(chat);
              setActiveChatUserName(
                `${chat.user.first_name} ${chat.user.last_name}`
              );
              setMessageHistory([]);
              setSocketUrl(
                `wss://bugtrack-eatwy.ondigitalocean.app/ws/support/${chat.id}/`
              );
              fetchChatHistory(chat.id);
            }}
          >
            <div className="flex items-center justify-between  w-full">
              <div className="flex items-center">
                <FaUserCircle className="w-8 h-8 text-gray-500" />
                <div className="ml-3">
                  <h3 className="font-normal text-base">
                    {chat.user.first_name} {chat.user.last_name}
                  </h3>
                  <p className="text-sm text-gray-400">{chat.lastMessage}</p>
                </div>
              </div>
              <span className="flex items-center space-x-2">
                <span
                  className={`w-3 h-3 rounded-full ${
                    chat.status === "open"
                      ? "bg-gray-500"
                      : chat.status === "closed"
                      ? "bg-red-500"
                      : "bg-green-500"
                  }`}
                ></span>
                <span>
                  {chat.status === "open"
                    ? "Waiting"
                    : chat.status === "closed"
                    ? "Ended"
                    : "Active"}
                </span>
              </span>
            </div>
          </div>
        )) :   <div className="flex flex-col items-center justify-center text-center text-gray-500 p-6">
        <MdChatBubbleOutline className="w-12 h-12 text-gray-400" />
        <h2 className="text-lg font-semibold mt-2">No Conversations Yet</h2>
        <p className="text-sm mt-1">Start a new chat to connect with support.</p>
      </div>}
      </div>

      {/* Chat Window */}
      <div className={`md:w-2/3 w-full flex-col md:max-h-[85vh] max-h-screen  ${activeChat ? "flex" : "md:flex hidden"}`}>
        {/* Chat Header */}
        <div
          className={`px-4 py-3 border-b flex items-center justify-between ${
            theme === "dark" ? "border-gray-700" : "border-gray-300"
          }`}
        >
          <div className=" flex items-center">
            <FaUserCircle className="w-10 h-10 text-gray-500" />
            <h2 className="ml-3 font-semibold text-lg">
              {activeChat ? activeChatUserName : "Select a chat"}
            </h2>
          </div>
          {activeChat?.status === "in_progress" ? (
            <button
              className="heroButton"
              onClick={() => closeChat(activeChat.id)}
            >
              End Chat
            </button>
          ) : activeChat?.status === "open" ? (
            <button
              className="heroButton"
              onClick={() => handleChangeChat(activeChat.id)}
            >
              Join Chat
            </button>
          ) : activeChat?.status === "closed" ? (
            <button
              className="heroButton"
              onClick={() => removeChat(activeChat.id)}
            >
              Delete Chat
            </button>
          ) : null}
        </div>

        {/* Messages */}
        {chatLoading ? (
          <div className="w-full h-full justify-center items-center">
            <Loader />
          </div>
        ) : activeChat ? (
          <div className="flex-1 overflow-y-auto p-4 space-y-2">
            {/* Render chat history first */}
            {Object.entries(groupedChatHistory).map(([date, messages]) => (
              <div key={date}>
                {/* Date Badge */}
                <div className="text-center text-xs bg-gray-200 dark:bg-gray-700 text-gray-600 dark:text-gray-300 rounded-full py-1 my-2">
                  {date}
                </div>

                {/* Messages */}
                {messages.map((msg, i) => (
                  <div
                    key={i}
                    className={`flex items-end gap-2 my-2 ${
                      msg.sender.id === userInfo.user.id
                        ? "justify-start flex-row-reverse"
                        : ""
                    }`}
                  >
                    <FaUserCircle className="w-5 h-5" />
                    <div className="max-w-xs">
                      <div className="text-sm font-semibold flex gap-2 items-center">
                        {msg.sender.first_name} {msg.sender.last_name}
                        <span className="text-xs text-gray-700 dark:text-gray-400">
                          {format(parseISO(msg.created_at), "hh:mm a")}
                        </span>
                      </div>
                      <div
                        className={`mt-1 text-sm flex gap-2} ${
                          msg.sender.id === userInfo.user.id
                            ? "justify-end"
                            : ""
                        }`}
                        style={{ lineBreak: "anywhere" }}
                      >
                        <p
                          className={`p-2 rounded-t-md ${
                            msg.sender.id === userInfo.user.id
                              ? "bg-red-600 text-white rounded-bl-md"
                              : "bg-gray-100 dark:bg-gray-800 text-black dark:text-white rounded-br-md"
                          }`}
                          dangerouslySetInnerHTML={{ __html: msg.content }}
                        ></p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ))}
            {/* Render live messageHistory (WebSocket messages) */}
            {messageHistory.map((msg, i) => (
              <div
                key={i}
                className={`flex items-end gap-2 my-2 ${
                  msg.sender === userInfo.user.id
                    ? "justify-start flex-row-reverse"
                    : ""
                }`}
              >
                <FaUserCircle className="w-5 h-5 " />
                <div className="max-w-xs">
                  <div className="text-sm font-semibold flex gap-2 items-center">
                    {msg.user_name}
                    <span className="text-xs text-gray-500 dark:text-gray-400">
                      {format(parseISO(msg.timestamp), "hh:mm a")}
                    </span>
                  </div>
                  <div
                    className={`p-2 rounded-t-md text-sm mt-1 ${
                      msg.sender.id === userInfo.user.id
                        ? "bg-red-600 text-white rounded-bl-md"
                        : "bg-gray-100 dark:bg-gray-800 text-black dark:text-white rounded-br-md"
                    }`}
                    dangerouslySetInnerHTML={{ __html: msg.message }}
                    style={{ lineBreak: "anywhere" }}
                  ></div>
                </div>
              </div>
            ))}
            <div ref={messagesEndRef} />
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center h-full text-center">
            <div className="text-gray-500 dark:text-gray-400">
              <div className="flex items-center justify-center mb-2">
                <MdOutlineChat className="w-10 h-10 " />
              </div>
              <h2 className="text-lg font-semibold">No Active Chats</h2>
            </div>
          </div>
        )}

        {/* Input Box */}
        <div
          className={`p-3 flex items-center border-t ${
            theme === "dark" ? "border-gray-700" : "border-gray-300"
          }`}
        >
          {" "}
          <div
            className={`flex gap-2 px-2 w-full items-center  border bg-transparent rounded-md f ${
              theme === "dark"
                ? "text-white border-gray-600"
                : "text-gray-900 border-gray-400"
            }`}
          >
            <input
              type="text"
              className={`w-full bg-transparent py-2 outline-none ${
                theme === "dark" ? " text-white" : " text-black"
              } `}
              placeholder="Type a message..."
              value={message}
              disabled={
                activeChat?.status === "closed"
                  ? true
                  : activeChat?.status === "open"
                  ? true
                  : !activeChat
                  ? true
                  : false
              }
              onChange={(e) => setMessage(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleClickSendMessage();
                }
              }}
            />
            <div>
              <button onClick={handleButtonClick}>
                <GrAttachment className="w-6 h-6" />
              </button>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }} // Hide the file input
                onChange={handleFileChange}
              />
            </div>
          </div>
          <button
            className="ml-2 p-2 heroButton rounded-lg"
            onClick={handleClickSendMessage}
            disabled={activeChat?.status === "closed" ? true : false}
          >
            <IoSend className="w-6 h-6" />
          </button>
        </div>
      </div>
    </div>
  );
};
export default AgentChat;
