import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { FaCaretDown, FaCircleExclamation } from "react-icons/fa6";
import { PopupModal } from "react-calendly";

const CustomPopconfirm = ({ visible, setVisible, title, description, okText, cancelText, onConfirm, onCancel, children,parent }) => {
  const popconfirmRef = useRef(null);
  const { theme } = useSelector((state) => state.theme);
  const [calendlyOpen, setCalendlyOpen] = useState(false);

  const handleClickOutside = (event) => {
    if (popconfirmRef.current && !popconfirmRef.current.contains(event.target)) {
      setVisible(false);
    }
  };

  useEffect(() => {
    if (visible) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [visible]);

  return (
    <div style={{ position: "relative", display: "inline-block" }}>
      <div onClick={() => setVisible(!visible)}>{children}</div>
      {visible && (
        <div
          ref={popconfirmRef}
          className={`absolute fontRoboto z-[1000] p-4 top-[-7rem] sm:left-[-50px] left-[-10px] rounded-[20px] border shadow-lg min-w-[330px] min-h-[100px] ${
            theme === "dark" ? parent === 'no'? 'bg-darkMode border-gray-500': "bg-[#1C1C1E] border-gray-400 text-white" : "bg-gray-200 text-black"
          }`}
        >
          <h4 className="font-bold flex items-center gap-2">
            <FaCircleExclamation className="fill-yellow-500 w-6 h-6 font-normal" />
            {title}
          </h4>
          <div className="mt-4 flex justify-start items-center gap-2">
            <button
              className={`px-3 py-2 border-2 rounded-full ${
                theme === "dark" ? "border-gray-500 text-white" : "border-gray-400 text-black"
              }`}
              onClick={() => {
                onCancel();
                setVisible(false);
              }}
            >
              {cancelText}
            </button>
            <button className="heroButton" onClick={() => setCalendlyOpen(true)}>
              Schedule a meeting
            </button>
          </div>
          <div className="w-[80%] justify-end flex absolute bottom-[-24px]">
            <FaCaretDown className={`w-10 h-10 ${theme === "dark" ? parent === 'no'? 'text-[#212529] ': "text-[#1C1C1E]" : "text-gray-200"}`} />
          </div>
        </div>
      )}
      
      {/* Calendly Modal */}
      <PopupModal 
        url="https://calendly.com/bugtrackin-support"
        rootElement={document.body} // ✅ REQUIRED to prevent the error
        onModalClose={() => setCalendlyOpen(false)}
        open={calendlyOpen}
      />
    </div>
  );
};
export default CustomPopconfirm;
