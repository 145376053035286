import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import ParticlesBackground from "../../ParticalsBackground";
import GlowCard from "../glow-card";
import Loader from "../../../Loader/Loader";
import { togglePlan } from "../../../../Redux/Slices/plans-slice";
import { Modal, Popconfirm, Spin } from "antd";
import ContactUsViaMail from "../../contactUs/ContactUsViaMail";
import Calendly from "./Calendly";
import { IoDiamond } from "react-icons/io5";
import { FaAngleDown, FaAngleUp, FaStar } from "react-icons/fa";
import { LuHeartHandshake } from "react-icons/lu";
import { FaCircleCheck, FaCrown } from "react-icons/fa6";
import FeatureTable from "../FeaturesTable";
import { motion } from "framer-motion";
import CustomPopconfirm from "./CustomPop";
import { IoClose } from "react-icons/io5";

const SubscriptionPage = (props) => {
  const { userInfo } = useSelector((state) => state?.auth || {});
  const plans = useSelector((state) => state.allPlans.plans);
  const navigate = useNavigate();
  const { theme } = useSelector((state) => state.theme);
  const dispatch = useDispatch();
  const [childComponent, setChilComponent] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [planType, setPlanType] = useState("month");
  const [plansList, setPlansList] = useState([]);
  const [isContacFormOpen, setIsContacFormOpen] = useState(false)
  const [loading, setLoading]=useState(false)

  const handlePlanType = (type) => {
    setPlanType(type);
  };

  const onClickSignup = (plan) => {
    console.log("i am clicking on signup");
    if (plan === "enterprise") {
      navigate("/contact-us");
      return;
    } else {
      dispatch(togglePlan({ plan: plan }));
      navigate("/signup");
    }
  };

  useEffect(() => {
    setLoading(true)
    setPlansList(plans.filter((plan) => plan.interval === planType));
    setTimeout(()=>{
     setLoading(false)
    },500)
  }, [plans, planType]);

  const handlePlans = (plan) => {
    dispatch(togglePlan({ plan: plan }));
    navigate("/signup");
  };

  const features = {
    Basic: [
      "Time tracker",
      "Activity level",
      "Productivity Calculation",
      "Keyboard & Mouse Productivity",
      "Multiple roles & Permissions",
      "Limited screenshots",
      "Limited reports",
      "1 Year data history",
      "Idle timeout",
      "Two-day email support",
      "Multiple screen support",
    ],
    Standard: [
      "Everything in Basic",
      "Unlimited reports",
      "Company setup",
      "Company time offs",
      "Project Management",
      "Milestones Tracking",
      "Tasks Management",
      "2 Years data history",
      "Chat support",
      "One day email support",
    ],
    Premium: [
      "Everything in Standard",
      "Unlimited screenshots",
      "Company user invoices",
      "Overtime",
      "Time off & holidays",
      "Timesheet approval",
      "Offline Time",
      "5 Years data history",
      "Admin Dashboard",
      "Finance & reporting dashboard",
    ],
    Enterprise: [
      "Everything in Premium",
      "Fixed year payment",
      "For above 100 users",
    ],
  };

  const getPrice = (str) => {
    const input = str;
    const separator = "per";
    // Using `split`
    const parts = input.split(separator);
    const beforePer = parts[0].trim(); // Text before 'per'
    const price = parseFloat(beforePer.replace("$", ""));

    // Check the plan type and adjust the price accordingly
    const formattedPrice = planType === "year" ? price : price + "$";
    return "$" + price;
  };

  const [visible, setVisible] = useState(false);





  const onViaMail = (plan) => {
    setIsContacFormOpen(true)
  };

  return (
    <>
      <ParticlesBackground id="particaljs" />
      {childComponent}
      <section
        className={`${styles.pricingSection} ${theme === "dark" ? "text-white" : "text-black"
          } `}
        id="pricingPlans"
      >
        <div className="plans-container flex flex-col items-center">
          <div className="flex sm:flex-row flex-col w-full xl:max-w-[1230px] justify-center items-center lg:pl-44 mt-8 md:pl-24 lg:pr-4 md:pr-4 pl-2 pr-2 py-8">
            <h2 className={`main-heading w-full text-center`} style={{marginBottom:'0px'}}>
              Subscription Plans
            </h2>
            <div
              className={`relative flex p-2 sm:mt-0 mt-4 rounded-[35px] w-auto border fontRoboto ${theme === "dark"
                  ? "bg-black border-[#555657] "
                  : "bg-white border-[#212529]"
                } ${theme === "light" ? "left-0" : ""} `}
            >
              {/* Background Slider */}
              <motion.div
                className={`absolute top-[2px] h-[90%] w-[50%] bg-gradient-to-r from-[#CC0927] to-[#7E0105] rounded-[30px] ${planType === "month" ? "left-[2px]" : "left-[-2px]"
                  } `}
                animate={{ x: planType === "month" ? 0 : "100%" }}
                transition={{ type: "spring", stiffness: 200, damping: 20 }}
              />

              {/* Admin Button */}
              <button
                className={`relative flex items-center gap-1 rounded-full text-base font-medium transition-all duration-300 w-28 justify-center z-10 outline-none ${planType === "month" ? "text-white" : "text-gray-400"
                  }`}
                onClick={() => handlePlanType("month")}
              >
                <span className="text-[16px]">Monthly</span>
              </button>

              {/* Finance Button */}
              <button
                className={`relative flex items-center gap-1  rounded-full text-base font-medium transition-all duration-300 w-28 justify-center z-10 outline-none ${planType === "year" ? "text-white" : "text-gray-600"
                  }`}
                onClick={() => handlePlanType("year")}
              >
                <span className="text-[16px]">{`Yearly(-10%)`}</span>
              </button>
            </div>
          </div>
          {loading  && (
            <div className="flex w-full h-[80vh] justify-center items-center">
            <Loader />
            </div>
          )}
            <div
              className={`gap-6 ${styles.pricingPlans} items-strech md:px-8 ${loading ? 'hidden':''}`}
            >
              {plansList.map((plan, index) => (
                <div
                  key={plan.id}
                  className={`min-h-[680px] w-[285px] ${styles.planCard} ${theme === "dark" ? "bg-[#000000]" : "bg-[#FFFFFF]"
                    }`}
                >
                  <GlowCard
                    key={plan.id}
                    identifier={index}
                    plan={plan}
                    page={props.parent}
                    className="h-full"
                  >
                    <div className="border-b-2 border-[#CC0927]">
                      <div className="flex  items-center flex-row py-6 px-4 gap-3">
                        <div className="h-12 w-12 bg-[#CC0927] rounded-md flex items-center justify-center">
                          {plan.name === "Basic" ? (
                            <FaStar
                              className={`w-6 h-6 ${theme === "dark" ? "text-black" : "text-white"
                                }`}
                            />
                          ) : plan.name === "Premium" ? (
                            <FaCrown
                              className={`w-6 h-6 ${theme === "dark" ? "text-black" : "text-white"
                                }`}
                            />
                          ) : plan.name === "Standard" ? (
                            <IoDiamond
                              className={`w-6 h-6 ${theme === "dark" ? "text-black" : "text-white"
                                }`}
                            />
                          ) : (
                            <LuHeartHandshake
                              className={`w-6 h-6 ${theme === "dark" ? "text-black" : "text-white"
                                }`}
                            />
                          )}
                        </div>
                        <div className={`${styles.planDescriptionCard}`}>
                          <h3 className={styles.planTitle}>{plan?.name}</h3>
                          {/* <p className={styles.planDescription}>{plan?.description}</p>  */}
                          {plan.name === "Enterprise" ? (
                            <>
                              <span
                                style={{
                                  fontSize: "14px",
                                }}
                              >
                                <b>{plan.description}</b>
                              </span>
                            </>
                          ) : (
                            <span className={styles.planPrice}>
                              {getPrice(plan?.description).split(".").length > 0
                                ? getPrice(plan?.description).split(".")[0]
                                : ""}
                              <sup style={{ fontSize: "14px" }}>
                                {plan.amount.split(".").length > 0
                                  ? plan.amount.split(".")[1]
                                    ? "." + plan.amount.split(".")[1]
                                    : ""
                                  : ""}
                              </sup>
                              <sub style={{ fontSize: "14px" }}>
                                {"/user/" + plan.interval}
                              </sub>
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div
                      className={`${styles.plan}`}
                      style={{ color: theme === "light" ? "black" : "white" }}
                    >
                      <ul className="gap-3 flex flex-col ">
                        {features[plan.name].map((feature, key) => (
                          <li
                            key={key}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "8px",
                            }}
                          >
                            <span
                              style={{ fontSize: "24px", color: "#C4242C" }}
                            >
                              <FaCircleCheck
                                className={`text-black fill-[#CC0927] w-6 h-6`}
                              />
                            </span>
                            <span>{feature}</span>
                          </li>
                        ))}
                      </ul>
                      <div
                        style={{
                          marginTop: "auto",
                          position: "absolute",
                          bottom: "24px",
                          width: "87%",
                        }}
                      >
                        {plan.name === "Enterprise" ? (
                          <div className="realtive w-full">
                            {visible && (
                              <CustomPopconfirm
                                visible={visible}
                                setVisible={setVisible}
                                title="Get in touch"
                                description="Are you sure you want to proceed?"
                                okText="Schedule a meeting!"
                                cancelText="Via email"
                                onCancel={onViaMail}

                              />
                            )}
                            <div className="flex w-full justify-center items-center">
                              <button
                                style={{
                                  width:
                                    plan.name === "Enterprise" ? "100%" : "",
                                }}
                                className={`${styles.planButton} heroButton`}
                                onClick={() => {
                                  setVisible(true);
                                }}
                              >
                                {plan.name === "Enterprise"
                                  ? "Get in touch"
                                  : "Sign Up"}
                              </button>
                            </div>

                          </div>
                        ) : (
                          <div className="flex items-center justify-center">
                            <button
                              style={{
                                padding: "9px 30px",
                              }}
                              className={`${styles.planButton} heroButton`}
                              onClick={() => handlePlans(plan?.id)}
                            >
                              Signup
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </GlowCard>
                </div>
              ))}
            </div>
</div>
        <hr
          className={`mt-12 border-t ${theme === "dark" ? "border-[#555657]" : "border-gary-400"
            }`}
        />
        <div className="flex w-full items-center justify-center mt-[-22px]">
          <button
            className=" text-white flex items-center justify-center py-2 px-3 rounded-[10px] text-lg font-medium outline-none"
            style={{
              background: "linear-gradient(90deg, #CC0927 0%, #7E0105 100%)",
            }}
            onClick={() => setIsOpen(!isOpen)}
          >
            View Complete Plans Details
            {!isOpen ? (
              <FaAngleDown className="w-6 h-6" />
            ) : (
              <FaAngleUp className="w-6 h-6" />
            )}
          </button>
        </div>
        {isOpen && (
          <div className="flex justify-center items-center mt-20 md:px-16 px-4 ">
            {" "}
            <FeatureTable />{" "}
          </div>
        )}
      </section>
      {
        isContacFormOpen && <>
          {/* Overlay */}
          <div className="fixed inset-0 bg-black h-100vh w-100vw bg-opacity-50 z-10"></div>

          {/* Modal Content */}
          <div className="fixed inset-0 flex items-center h-full w-full justify-center z-20 ">
            <div
              className={` md:mt-6 mt-2 p-6 rounded-[20px] customFont shadow-lg max-w-[450px] max-h-[90vh] relative w-full ${theme === "dark"
                  ? "bg-[#212529] text-white"
                  : "bg-lightMode text-black"
                }`}
            >
              <div className="flex justify-between">
                <div className="flex gap-2 w-full items-center">
                  <h2 className="text-xl font-semibold w-full text-center">Contact Us</h2>
                </div>
                <IoClose
                  className={`w-6 h-6`}
                  onClick={() => {
                    setIsContacFormOpen(false)
                  }}
                />
              </div>
              <ContactUsViaMail setIsOpen={setIsContacFormOpen}/>
            </div>
          </div>
        </>
      }

    </>
  );
};

export default SubscriptionPage;
