import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { TiThLargeOutline } from "react-icons/ti";
import { motion } from 'framer-motion';
import { TbCoins } from "react-icons/tb";
import Admin from './Admin';
import { fetchApi } from '../../../utlis/axios';
import Dashboard from '../financeDashboard/Dashboard';
const AdminDashboard = () => {
    const { theme } = useSelector((state) => state.theme)
      const { userInfo } = useSelector((state) => state?.auth || "");
    const [selected, setSelected] = useState('admin')
   

    return (
        <div className=' w-full'>
          <div className="flex w-full justify-between items-center py-4 sm:px-8 px-2">
            <h1 className={`md:text-2xl text-xl font-medium ${theme === "dark" ? "text-white" : "text-black"}`}>
                Dashboard
            </h1>
            <div className={`relative flex p-3 rounded-[35px] w-auto border bg-transparent ${theme === "dark" ? "border-gray-600" : "border-gray-400"}`}>
                {/* Background Slider */}
                <motion.div
                    className={`absolute top-[4px] h-[calc(100%-8px)] w-[50%] bg-gradient-to-r from-[#CC0927] to-[#7E0105] rounded-[30px] ${selected === 'admin'?'left-1' :'left-[-2px]'}`}
                    animate={{ x: selected === "admin" ? 0 : "100%" }}
                    transition={{ type: "spring", stiffness: 200, damping: 20 }}
                />

                {/* Admin Button */}
                <button
                    className={`relative flex items-center gap-2 rounded-full text-base font-medium transition-all duration-300 w-28 justify-center z-10 outline-none ${selected === "finance" ? "text-[#9A9FA5]" : "text-white"}`}
                    onClick={() => setSelected("admin")}
                >
                    <TiThLargeOutline className="xl:w-7 xl:h-7 md:w-6 md:h-6 w-5 h-5" />
                    <span>Admin</span>
                </button>

                {/* Finance Button */}
                <button
                    className={`relative flex items-center gap-2  rounded-full text-base font-medium transition-all duration-300 w-28 justify-center z-10 outline-none ${selected === "admin" ? "text-[#9A9FA5]" : "text-white"}`}
                    onClick={() => setSelected("finance")}
                >
                    <TbCoins className="xl:w-7 xl:h-7 md:w-6 md:h-6 w-5 h-5" />
                    <span>Finance</span>
                </button>
            </div>
        </div>
            <hr />
            {
                selected === 'admin'?
                <div className='sm:px-8 px-2'> <Admin/> </div> : <div className='sm:px-8 px-2'> <Dashboard/> </div>
            }
        </div>
    )
}

export default AdminDashboard