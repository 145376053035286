import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchApi } from "../../utlis/axios";
import { toast } from "react-toastify";
import Loader from "../Loader/Loader";
import { togglePlan } from "../../Redux/Slices/plans-slice";
import "./allfeatures.css";
import BackBtn from "../backBtn/BackBtn";
import styles from "./styles.module.css";
import { Modal, Popconfirm } from "antd";
import Calendly from "./Calendly";
import ContactUsViaMail from "../home/contactUs/ContactUsViaMail";
import { toggleAuth } from "../../Redux/Slices/auth-slice";
import LoaderGif from "../../assets/loading-gif.gif";
import ParticlesComponent from "../home/ParticalsBackground";
import { addToast } from "../../Redux/Slices/toast-slice";

const AllFeatures = (props) => {
  const { userInfo } = useSelector((state) => state?.auth || {});
  const navigate = useNavigate();
  const dispatch=useDispatch()
  const plans = useSelector((state) => state.allPlans.plans);
  const isLoading = useSelector((state) => state.allPlans.plansIsLoading);
  const [loader, setLoader] = useState(false);
  const { theme } = useSelector((state) => state.theme);
  const [changigPlan, setChangingPlan] = useState("");
  const [qty, setQty] = useState(userInfo?.user?.plan?.number_of_users || "");
  const [childComponent, setChilComponent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setChilComponent(null);
  };


  const onClickSignup = (plan) => {
    if (plan === "enterprise") {
      navigate("/contact-us");
      return;
    } else {
      dispatch(togglePlan({ plan: plan }));
      navigate("/signup");
    }
  };

  const handlePlans = (plan) => {
    onUpgradePlan(plan);
    setChangingPlan(plan.id);
  };

  const handleChangeQty = (e) => {
    setQty(e.target.value);
  };
  const onUpgradePlan = async (plan) => {
    setLoader(true);
    let payload = {
      plan: plan.id,
      quantity: plan.number_of_users,
    };
    try {
      const result = await fetchApi.post("upgrade/", payload, {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });
      if (result.status === 200) {
        dispatch(addToast({ message:'Plan updated successfully', type:'success' })); 
        setChangingPlan("");
        dispatch(togglePlan({ plan: plan }));

        let userData = { ...userInfo, user: { ...userInfo.user, plan } };
        dispatch(toggleAuth({ isLogin: true, userInfo: { ...userData } }));
      }
      setLoader(false);
    } catch (error) {
      const errorMsg=error?.response?.data?.error || "failed"
      dispatch(addToast({ message:errorMsg, type:'error' })); 
      if (error.response.data.detail) {
        dispatch(addToast({ message:error.response.data.detail, type:'error' })); 
      }
      if (error.response && error.response.data.length && error.response.data) {
        error.response.data.forEach((value) => {
          dispatch(addToast({ message:value, type:'error' })); 
        });
      }
      setChangingPlan("");
      //toast.error(error?.response?.data?.error || "failed");
      setLoader(false);
    }
  };

  const onViaMail = (plan) => {
    setChilComponent(
      <Modal
        width={500}
        title="Contact Us"
        open={true}
        height={50}
        onCancel={handleCancel}
        footer={null}
      >
        <ContactUsViaMail parent="plans" />
      </Modal>
    );
  };
  const onViaMeeting = (plan) => {
    alert("via meeting");
  };

  const features = {
    Basic: [
      "Time tracker",
      "Activity level",
      "Limited screenshots",
      "Limited reports",
      "Idle timeout",
      "Two-day email support",
    ],
    Standard: [
      "Unlimited reports",
      "Project",
      "Milestone",
      "Task",
      "Expenses",
      "One day email support",
      "Company setup",
    ],
    Premium: [
      "Unlimited screenshots",
      "Company user invoices",
      "Overtime",
      "Time off and holidays",
      "Timesheet approval",
      "Chat support",
      "Payments & payroll",
      "Admin dashboard",
      "Finance & reporting dashboard",
      "One day email support",
    ],
    Enterprise: [
      "Everything in Premium",
      "Fixed yearly payment",
      "For above 100 users",
      "chat support",
      "one day email support",
    ],
  };
  const allFeatures = [
    ...features.Basic,
    ...features.Standard,
    ...features.Premium,
  ];

  return (
    <>
      <ParticlesComponent id="particlesjs" />
      <BackBtn bgColor={"red"} />
      {isLoading && plans.length === 0 ? (
        <Loader />
      ) : (
        <div
          className={`features-container absolute w-full ${
            theme === "dark" ? " text-white" : " text-black"
          }`}
        >
          <h3 className={`comparison-table-heading `}>
            <strong>BugTrackIn</strong>
          </h3>
          {childComponent}
          <div className={`comparison-table hidden md:flex`}>
            <table className="">
              <thead
                style={{
                  borderBottom: theme === "dark" ? "" : "1px solid lightgrey",
                  width: "100%",
                }}
              >
                <tr
                  className="w-full grid grid-cols-5 gap-2"
                  style={{ width: "100%" }}
                >
                  <th style={{ textAlign: "left" }} className="table-h"></th>
                  {plans.map((plan, index) => (
                    <th
                      key={index}
                      className="table-h flex justify-center items-center"
                    >
                      {plan.name}
                    </th>
                  ))}
                </tr>
                <tr
                  className="w-full grid grid-cols-5 gap-2"
                  style={{ width: "100%" }}
                >
                  <th style={{ textAlign: "left" }}></th>
                  {plans.map((plan, index) => (
                    <th
                      key={index}
                      className="flex justify-center items-center"
                    >
                      {plan.name === "Enterprise" ? (
                        <Popconfirm
                          title="Get in touch"
                          description=""
                          okText={<Calendly />}
                          cancelText="Via email"
                          //onConfirm={()=>onViaMeeting(plan)}
                          onCancel={() => onViaMail(plan)}
                        >
                          <div className="flex justify-center items-center">
                            <button
                              style={{
                                width:
                                  plan.name === "Enterprise"
                                    ? "max-content"
                                    : "",
                                fontSize:
                                  plan.name === "Enterprise" ? "15px" : "",
                                borderRadius:
                                  plan.name === "Enterprise" ? "30px" : "",
                                fontWeight:
                                  plan.name === "Enterprise" ? "700" : "",
                                padding:
                                  plan.name === "Enterprise" ? "8px 30px" : "",
                              }}
                              className={`${styles.planButton} heroButton`}
                            >
                              {plan.name === "Enterprise"
                                ? "Get in touch"
                                : "Sign Up"}
                            </button>
                          </div>
                        </Popconfirm>
                      ) : props.page === "subscriptionplan" ||
                        props.parent === "home" ? (
                        <div className="flex justify-center items-center">
                          <button
                            className={`signup-btn heroButton `}
                            onClick={() => onClickSignup(plan?.id)}
                            style={{
                              whiteSpace: "nowrap",
                              padding: "8px 30px",
                            }}
                            //  styles={{ width: "200px" }}
                          >
                            {plan.name === "Enterprise"
                              ? "Get in touch"
                              : "Sign Up"}
                          </button>
                        </div>
                      ) : (
                        <div className="flex justify-center items-center">
                          <button
                            disabled={
                              plan?.id === userInfo?.user?.plan?.id || loader
                            }
                            className={`signup-btn heroButton w-[max-content] flex justify-center items-cnter`}
                            onClick={() => handlePlans(plan)}
                            style={{ padding: "8px 30px" }}
                          >
                            {plan.name === "Enterprise" ? (
                              "Get in touch"
                            ) : !userInfo ||
                              userInfo?.user?.role?.name === "Time Reporter" ? (
                              "Signup"
                            ) : plan?.id === userInfo?.user?.plan?.id ? (
                              <b>Current</b>
                            ) : plan?.id === changigPlan ? (
                              <img
                                src={LoaderGif}
                                alt="Loading..."
                                className="w-5 h-5"
                              />
                            ) : (
                              "Change"
                            )}
                          </button>
                        </div>
                      )}
                    </th>
                  ))}
                </tr>
              </thead>

              <tbody>
                <tr style={{ textAlign: "left" }}>
                  <th>
                    <h3
                      style={{
                        marginLeft: "5px",
                        marginTop: "10px",
                        fontSize: "24px",
                      }}
                    >
                      <strong>Features</strong>
                    </h3>
                  </th>
                </tr>
                {allFeatures.map((each) => (
                  <tr
                    className="grid grid-cols-5 gap-2"
                    style={{ width: "100%" }}
                  >
                    <td
                      className="feature-name table-data"
                      style={{
                        borderRight:
                          theme === "dark"
                            ? "1px solid #dc2626"
                            : "1px solid lightGray",
                      }}
                    >
                      <span>{each}</span>
                    </td>
                    <td
                      style={{
                        color: "red",
                        borderRight:
                          theme === "dark"
                            ? "1px solid #dc2626"
                            : "1px solid lightGray",
                      }}
                    >
                      {features["Basic"].includes(each) ? `✔` : "✘"}
                    </td>
                    <td
                      style={{
                        color: "red",
                        borderRight:
                          theme === "dark"
                            ? "1px solid #dc2626"
                            : "1px solid lightGray",
                      }}
                    >
                      {features["Basic"].includes(each) ||
                      features["Standard"].includes(each)
                        ? `✔`
                        : "✘"}
                    </td>
                    <td
                      style={{
                        color: "red",
                        borderRight:
                          theme === "dark"
                            ? "1px solid #dc2626"
                            : "1px solid lightGray",
                      }}
                    >
                      {features["Basic"].includes(each) ||
                      features["Standard"].includes(each) ||
                      features["Premium"].includes(each) ? (
                        `✔`
                      ) : (
                        <span style={{ color: "red" }}>✘</span>
                      )}
                    </td>
                    <td
                      style={{
                        color: "red",
                        borderRight:
                          theme === "dark"
                            ? "1px solid #dc2626"
                            : "1px solid lightGray",
                      }}
                    >
                      {features["Basic"].includes(each) ||
                      features["Standard"].includes(each) ||
                      features["Premium"].includes(each) ||
                      features["Enterprise"].includes(each) ? (
                        `✔`
                      ) : (
                        <span style={{ color: "red" }}>✘</span>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div
            className={`flex flex-col items-start md:hidden min-h-[80vh] px-5 py-2 ${
              theme === "dark" ? " text-white" : " text-black"
            }`}
          >
            {plans.map((plan, i) => (
              <div key={i}>
                <h3 className="text-[32px] my-2 font-bold">{plan.name}</h3>
                <ul className="gap-1">
                  {features[plan.name].map((feature, j) => (
                    <li key={j} className="text-lg font-normal">
                      ✔ {feature}
                    </li>
                  ))}
                </ul>
                <div>
                  {plan.name === "Enterprise" ? (
                    <Popconfirm
                      title="Get in touch"
                      description=""
                      okText={<Calendly />}
                      cancelText="Via email"
                      //onConfirm={()=>onViaMeeting(plan)}
                      onCancel={() => onViaMail(plan)}
                    >
                      <button
                        style={{
                          width: plan.name === "Enterprise" ? "max-content" : "",
                          marginLeft: plan.name === "Enterprise" ? "0px" : "",
                          fontSize: plan.name === "Enterprise" ? "14px" : "",
                          fontWeight: plan.name === "Enterprise" ? "700" : "",
                        }}
                        className={`${styles.planButton} heroButton`}
                      >
                        {plan.name === "Enterprise"
                          ? "Get in touch"
                          : "Sign Up"}
                      </button>
                    </Popconfirm>
                  ) : props.page === "subscriptionplan" ||
                    props.parent === "home" ? (
                    <button
                      className={`signup-btn heroButton`}
                      onClick={() => onClickSignup(plan?.id)}
                      styles={{ width: "200px" }}
                    >
                      {plan.name === "Enterprise" ? "Get in touch" : "Sign Up"}
                    </button>
                  ) : (
                    <button
                      disabled={plan?.id === userInfo?.user?.plan?.id || loader}
                      className={`signup-btn heroButton w-[max-content] flex justify-center`}
                      onClick={() => handlePlans(plan)}
                      style={{
                        padding: "9px 30px",
                        marginLeft: "0px",
                        fontSize: "16px",
                      }}
                    >
                      {plan.name === "Enterprise" ? (
                        "Get in touch"
                      ) : !userInfo ||
                        userInfo?.user?.role?.name === "Time Reporter" ? (
                        "Signup"
                      ) : plan?.id === userInfo?.user?.plan?.id ? (
                        <b>Current</b>
                      ) : plan?.id === changigPlan ? (
                        <img
                          src={LoaderGif}
                          alt="Loading..."
                          className="w-5 h-5"
                        />
                      ) : (
                        "Change"
                      )}
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default AllFeatures;
