import React, { useState, useEffect } from 'react';
import { FiRefreshCw } from 'react-icons/fi';
import { useSelector, useDispatch } from 'react-redux';
import { FaChartBar, FaFileInvoiceDollar, FaMoneyBillWave, FaUserTie } from 'react-icons/fa';
import loader from '../../../../assets/loading-gif.gif';
import { TbCalendarDollar, TbUserDollar, TbUsersGroup } from "react-icons/tb";
import { FaSackDollar } from 'react-icons/fa6';
import { Swiper, SwiperSlide } from 'swiper/react';
import SalaryPieChart from './SalaryAnalysisChart';
import SalaryBreakdownChart from './SalaryBreakdownChart';

const EmployeeBudgetOverview = ({data, getData, salaryData}) => {
    const { theme } = useSelector((state) => state.theme);
    const [isRefreshing, setIsRefreshing] = useState(false);
    

    const refreshData = async () => {
        setIsRefreshing(true);
        await getData();
        setIsRefreshing(false);
    };


    const cards = [
        { count: `$${data.total_paid_last_month}`, label: 'Last Months Salary', icon: <TbCalendarDollar className='w-8 h-8' />, color: 'bg-green-500',text:'text-green-500' },
        { count: `$${data.total_overtime_paid}`, label: 'Total Overtime paid', icon: <FaSackDollar className='w-8 h-8' />, color: 'bg-blue-500', text:'text-blue-500' },
        { count: data?.total_employees_paid, label: 'Employees Paid', icon: <TbUserDollar className='w-8 h-8' />, color: 'bg-yellow-500', text:'text-yellow-500' },
        { count: `$${data.pending_approvals}`, label: 'Pending Approvals', icon: <FaFileInvoiceDollar className='w-8 h-8' />, color: 'bg-orange-500',text:'text-orange-500' },
        { count: `$${data.average_salary}`, label: 'Average Salary', icon: <FaChartBar className='w-8 h-8' />, color: 'bg-purple-500',text:'text-purple-500' },
    ];

    return (
        <div className={`rounded-[20px] shadow-lg p-4 fontPoppins ${theme === 'dark' ? 'bg-[#1C1C1E] text-white' : 'bg-white text-black'}`}>

            <div className='flex justify-between items-center'>
                <div className='flex gap-2 items-center'>
                    <span className='bg-gradient-to-r from-[#CC0927] to-[#7E0105] p-2 rounded-[10px]'>
                        <TbUsersGroup className='w-6 h-6 text-white' />
                    </span>
                    <h1 className='text-lg font-semibold'>Employee Budget</h1>
                </div>
                <div
                    className={`p-2 flex items-center justify-center rounded-[10px] border cursor-pointer ${theme === 'dark' ? 'bg-[#252529] border-[#555657]' : 'bg-[#F2F4FA] border-[#D8D8D8]'}`}
                    onClick={refreshData}
                >
                    {isRefreshing ? (
                        <img src={loader} alt="Loading..." className="w-5 h-5" />
                    ) : (
                        <FiRefreshCw className={`w-5 h-5`} />
                    )}
                </div>
            </div>

            <div className="mt-4">
                <Swiper
                    spaceBetween={20}
                    slidesPerView={1.3} // Show 2 slides by default
                    breakpoints={{
                        640: { slidesPerView: 1.5 },
                        768: { slidesPerView: 2.6 },
                        900: { slidesPerView: 2.6 }, // Additional breakpoint to maintain 2.6
                        1024: { slidesPerView: 2 },
                        1280: { slidesPerView: 3 },
                        1390: { slidesPerView: 3.6 },
                      }}
                    loop={true}
                    touchEventsTarget="container"
                    grabCursor={true}
                >
                    {cards.map((card, index) => (
                        <SwiperSlide key={index}>
                            <div
                                className={`p-4 border rounded-[20px] flex items-center gap-4 
                                ${theme === "dark" ? "bg-[#212529] border-[#555657]" : "bg-[#F2F4F7] border-[#D8D8D8]"}`}
                            >
                                <span className={`p-2 rounded-full ${card.color}`}>{card.icon}</span>
                                <div>
                                    <h1 className={`text-2xl font-bold ${card.text}`}>{card.count}</h1>
                                    <h4 className='text-sm'>{card.label}</h4>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
            <div className="flex md:flex-row flex-col gap-4 my-4 w-full">
                <SalaryBreakdownChart data={salaryData}/>
            <SalaryPieChart paidSalaries={data?.total_paid_salaries} pendingApprovals={data?.pending_approvals} />
            </div>
        </div>
    );
};

export default EmployeeBudgetOverview;
