import { useDrag } from "react-dnd";
import { useDispatch, useSelector } from "react-redux";
import { GoProject } from "react-icons/go";
import { FaCalendarAlt, FaCheck } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { fetchApi } from "../../../utlis/axios";
import { addToast } from "../../../Redux/Slices/toast-slice";
import { useState } from "react";
import loader from '../../../assets/loading-gif.gif'

const Card = ({ ticket, ticketLoading,setTickets, tickets }) => {
  const { theme } = useSelector((state) => state.theme);
  const [, drag] = useDrag({
    type: "CARD",
    item: { id: ticket?.id },
  });
  const navigate = useNavigate();
  const { userInfo } = useSelector((state) => state?.auth || {});
  const dispatch=useDispatch()
  const [updating, setUpdating]=useState(false)

  const handleUpdateCard = async (id) => {
    setUpdating(true)
    try {
      const res = await fetchApi.patch(
        `project-tickets/${id}/`,
        { is_completed:true },
        {
          headers: {
            Authorization: `Bearer ${userInfo.access_token}`,
          },
        }
      );
      if (res.status === 200) {
        setTickets((prevTickets) =>
          prevTickets.map((ticket) =>
            ticket.id === id ? { ...ticket, ...res.data } : ticket
          )
        );
        dispatch(addToast({ message: "Ticket marked as completed", type: "success" }));
        // navigate("/dashboard");
      }
    } catch (error) {
      dispatch(addToast({ message: error.message, type: "error" }));
    }finally{
      setUpdating(false)
    }
  };

  return (
    <div
      ref={drag}
      className={`p-4 mb-2 rounded-md shadow-md cursor-pointer ${
        theme === "dark"
          ? "text-white bg-dashboardDarkMode"
          : "text-black bg-slate-50"
      } ${
        theme === "dark"
          ? "hover:bg-gradient-to-r hover:from-[#fff3] hover:to-[#fff0] transition-all"
          : "hover:bg-gradient-to-r hover:from-[#6d6d6d33] hover:to-[#fff0] transition-all"
      }`}
      draggable // Ensure draggable is true
     
      
    >
      {ticketLoading ? (
        <div className="animate-pulse">
          <div
            className={`h-3 bg-gray-500 rounded-md mb-2 ${
              theme === "dark" ? "bg-gray-600" : "bg-gray-400"
            }`}
          ></div>
          <div
            className={`h-3 bg-gray-500 rounded-md mb-2 w-3/4 ${
              theme === "dark" ? "bg-gray-600" : "bg-gray-400"
            }`}
          ></div>
          <div
            className={`h-3 rounded-md mb-2 w-1/2 ${
              theme === "dark" ? "bg-gray-600" : "bg-gray-400"
            }`}
          ></div>
        </div>
      ) : (
        <div className="flex w-full justify-between">
          <div className="flex flex-col"  onClick={() =>
        !ticketLoading &&
        navigate(
          `${
            userInfo.user.role.name !== "Time Reporter"
              ? `/dashboard/ticket/${ticket?.id}`
              : `/dashboard/tickets/${ticket?.id}`
          }`
        )
      }>
            <div className="flex gap-2 items-center">
              <GoProject />
              <span className="text-base font-normal">{ticket?.title}</span>
            </div>
            <div className="flex mt-2 items-center justify-between">
            <div className="flex gap-2 items-center">
              <FaCalendarAlt />
              <span className="text-xs">
                {new Date(ticket?.started_at).toISOString().split("T")[0]}
              </span>
            </div>
          </div>
          </div>
          <div className="flex flex-col gap-3">
          <div className="flex gap-[2px] items-baseline">
              <span className={`${ticket?.priority === 'low' ? 'bg-green-600': ticket?.priority === 'medium' ? 'bg-yellow-600' : 'bg-red-600'} w-[2px] rounded-md h-2`}></span>
              <span
                className={`w-[2px] h-3 rounded-md ${
                  ticket?.priority === "medium" ? 'bg-yellow-600': ticket?.priority === "high"
                    ? "bg-red-600"
                    : "bg-gray-700"
                }`}
              ></span>
              <span
                className={`w-[2px] h-4 rounded-md ${
                  ticket?.priority === "high" ? "bg-red-600" : "bg-gray-700"
                }`}
              ></span>
            </div>
            {userInfo?.user?.role?.name !== 'Time Reporter' &&
            <button onClick={() => handleUpdateCard(ticket.id)} className="outline-none">
              {updating ? <img src={loader} className="w-5 h-5" alt="Loading..."/> :
              <FaCheck className={`${ticket?.is_completed ? 'text-green-600' : 'text-gray-500'} `}/>}
            </button> }
            </div>
        </div>
      )}
    </div>
  );
};

export default Card;