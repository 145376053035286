import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useSelector } from "react-redux";

const SemiCircularProgressBar = ({ percentage }) => {
  const { theme } = useSelector((state) => state.theme);

  const mainLabels = [0, 25, 50, 75, 100];
  const numTicks = 24;
  const tickAngles = Array.from({ length: numTicks + 1 }, (_, i) => i * (180 / numTicks));
  
  const outerRadius = 90;
  const innerRadius = 85;
  const labelRadius = 95; // Adjusted so labels are between lines

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: "auto",
        aspectRatio: "2 / 1",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ position: "absolute", width: "65%", height: "65%" }}>
        <CircularProgressbar
          value={percentage}
          text={`${percentage}%`}
          circleRatio={0.5}
          styles={buildStyles({
            rotation: 0.75,
            strokeLinecap: "butt",
            trailColor: "#7E0105",
            pathColor: "#CC0927",
            textColor: theme === 'dark' ? '#fff' : '#000',
            height: "100%",
            width: "100%",
            borderRadius: "10px"
          })}
        />
      </div>

      <svg width="100%" height="100%" viewBox="0 0 200 120" style={{ position: "absolute", top: 0, left: 0 }}>
        {/* Small Tick Marks */}
        {tickAngles.map((angle, index) => {
          const radian = (angle * Math.PI) / 180;
          const x1 = 100 + innerRadius * Math.cos(radian);
          const y1 = 100 - innerRadius * Math.sin(radian);
          const x2 = 100 + outerRadius * Math.cos(radian);
          const y2 = 100 - outerRadius * Math.sin(radian);
          return <line key={`tick-${index}`} x1={x1} y1={y1} x2={x2} y2={y2} stroke="#666" strokeWidth="1" />;
        })}

          {/* Main Labels and Ticks */}
          {mainLabels.map((label, index) => {
          const angle = 180 - index * (180 / (mainLabels.length - 1));
          const radian = (angle * Math.PI) / 180;
          const x1 = 100 + innerRadius * Math.cos(radian);
          const y1 = 100 - innerRadius * Math.sin(radian);
          const x2 = 100 + outerRadius * Math.cos(radian);
          const y2 = 100 - outerRadius * Math.sin(radian);
          const lx = 100 + labelRadius * Math.cos(radian);
          const ly = 105 - labelRadius * Math.sin(radian);

          return (
            <g key={label-{index}}>
              <text x={lx} y={ly} textAnchor="middle" fontSize="12" fill={theme === 'dark' ? '#fff' : '#000'}>
                {label}
              </text>
            </g>
          )
        })}
      </svg>
    </div>
  );
};

export default SemiCircularProgressBar;
