import React, { useState } from 'react';
import styles from './tooltip.module.css'
import { useSelector} from 'react-redux'

const Tooltip = ({ content, children }) => {
  const [visible, setVisible] = useState(false);

  return (
    <div
      className={`${styles.tooltip_wrapper} `}
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
    >
      {children}
      {visible && (
        <div className={styles.tooltip_box}>
          {content}
          <div className={styles.tooltip_arrow} />
        </div>
      )}
    </div>
  );
};

export default Tooltip;
