import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FinanceOverview from './miniComponent/FinianceOverView';
import UserCard from './miniComponent/Usercard';
import EmployeeBudgetOverview from './miniComponent/EmployeeBudget';
import SalaryRankingTable from './miniComponent/SalaryRankingTable';
import ProjectBudgetOverview from './miniComponent/ProjectBudgetOverView';
import BugTrackPaymentChart from './miniComponent/BugtrackPaymentChart';
import { fetchApi } from '../../../utlis/axios';
import { addToast } from '../../../Redux/Slices/toast-slice';
import Loader from '../../Loader/Loader';

const Dashboard = () => {
  const { theme } = useSelector((state) => state.theme);
  const { userInfo } = useSelector((state) => state?.auth || {});
  const [employeeBudgetData, setEmployeeBudgetData] = useState({
    "total_paid_salaries": 0,
    "total_paid_last_month": 0,
    "total_unpaid_salaries": 0,
    "total_overtime_paid": 0,
    "total_employees_paid": 0,
    "highest_salary_paid": 0,
    "pending_approvals": 0,
    "pending_salaries_count": 0,
    "average_salary": 0,
    "total_salary_this_year": 0,
    "top_earners": [],
    "monthly_salary_breakdown": {},
    "total_paid_next_month": 0
  });
  const [totalPaid, setTotalPaid] = useState(0);
  const [totalPaidThisYear, setTotalPaidThisYear] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [projectData, setProjectData] = useState({
    "year": new Date().getFullYear(),
    "total_project_budget": 0,
    "total_milestone_budget": 0,
    "milestone_status_counts": {
      "completed": 0,
      "in_progress": 0,
      "paused": 0,
      "canceled": 0
    },
    "project_counts": {
      "projects_started": 0,
      "projects_completed": 0
    },
    "monthly_milestone_breakdown": {}
  });
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [chartData, setChartData] = useState([]);
  const dispatch = useDispatch();

  const processChartData = useCallback((rawData, year) => {
    const currentYear = year || new Date().getFullYear();
    
    // Create array with all 12 months
    const allMonths = Array.from({ length: 12 }, (_, i) => {
      const month = String(i + 1).padStart(2, '0');
      const dateKey = `${currentYear}-${month}-01`;
      
      return {
        name: new Date(dateKey).toLocaleString('default', { month: 'short' }),
        value: rawData?.[dateKey]?.amount || 0,
        fullDate: dateKey
      };
    });

    return allMonths;
  }, []);

  const getData = useCallback(async () => {
    try {
      const res = await fetchApi.get('finance-dashboard-employee-budget/', {
        headers: { 'Authorization': `Bearer ${userInfo?.access_token}` }
      });
      if (res.status === 200) {
        setEmployeeBudgetData({
          ...res.data,
          // Ensure all fields have defaults if missing from API
          total_paid_salaries: res.data.total_paid_salaries || 0,
          total_paid_last_month: res.data.total_paid_last_month || 0,
          // ... other fields
        });
      }
    } catch (error) {
      dispatch(addToast({
        message: error?.response?.data?.message || 'Something went wrong',
        type: 'error'
      }));
    }
  }, [dispatch, userInfo?.access_token]);

  const fetchData = useCallback(async () => {
    try {
      const res = await fetchApi.get('finance-dashboard-project-budget/', {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`
        }
      });
      if (res.status === 200) {
        setProjectData({
          ...res.data,
          year: res.data.year || new Date().getFullYear()
        });
      }
    } catch (error) {
      dispatch(addToast({ message: error.message, type: 'error' }));
    }
  }, [dispatch, userInfo.access_token]);

  const getTotalInvoices = useCallback(async (year) => {
    try {
      const res = await fetchApi.get(`finance-dashboard-total-invoice/?year=${year}`, {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
        }
      });
      if (res.status === 200) {
        setChartData(processChartData(res.data?.monthly_breakdown, year));
        setTotalPaid(res.data.total_paid_all_years || 0);
        setTotalPaidThisYear(res.data.total_paid_of_selected_year || 0);
      }
    } catch (error) {
      dispatch(addToast({ message: error.message, type: "error" }));
    }
  }, [dispatch, processChartData, userInfo.access_token]);

  useEffect(() => {
    const fetchAllData = async () => {
      setIsLoading(true);
      try {
        await Promise.all([
          getData(),
          fetchData(),
          getTotalInvoices(selectedYear)
        ]);
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAllData();
  }, [getData, fetchData, getTotalInvoices, selectedYear]);

  return (
    <div className={`rounded-[30px] w-full mt-8 flex flex-col py-4 sm:px-4 px-1 gap-4 ${theme === "dark" ? "bg-[#212529]" : "bg-[#F5F6FA]"}`}>
      {/* Desktop View */}
      <div className='lg:flex hidden gap-4'>
        <div className="xl:w-[70%] w-[60%] gap-4 flex flex-col">
          <FinanceOverview 
            data={employeeBudgetData} 
            getData={getData} 
            totalPaid={totalPaid} 
            totalPaidThisYear={totalPaidThisYear} 
          />
          <EmployeeBudgetOverview 
            data={employeeBudgetData} 
            getData={getData} 
            salaryData={employeeBudgetData.monthly_salary_breakdown}
          />
        </div>
        <div className="xl:w-[30%] w-[40%] flex flex-col gap-4">
          <UserCard
            theme={theme}
            user={{
              name: "Usama Haider",
              email: "usamaaa0786@gmail.com",
              type: "Hourly",
              currency: "USD",
              lastMonthSalary: employeeBudgetData.total_paid_last_month,
              upcomingMonthSalary: employeeBudgetData.total_paid_next_month,
              profilePic: "",
            }}
          />
          <SalaryRankingTable data={employeeBudgetData} getData={getData} />
        </div>
      </div>
      
      {/* Tablet View */}
      <div className='lg:hidden md:flex flex-col hidden gap-4'>
        <FinanceOverview data={employeeBudgetData} getData={getData} totalPaid={totalPaid} totalPaidThisYear={totalPaidThisYear} />
        <div className='gap-4 grid grid-cols-2'>
          <UserCard theme={theme} />
          <SalaryRankingTable data={employeeBudgetData} getData={getData} />
        </div>
        <EmployeeBudgetOverview data={employeeBudgetData} getData={getData} salaryData={employeeBudgetData.monthly_salary_breakdown} />
      </div>

      {/* Mobile View */}
      <div className='md:hidden flex flex-col gap-4'>
        <FinanceOverview data={employeeBudgetData} getData={getData} totalPaid={totalPaid} totalPaidThisYear={totalPaidThisYear} />
        <UserCard theme={theme} />
        <EmployeeBudgetOverview data={employeeBudgetData} getData={getData} salaryData={employeeBudgetData.monthly_salary_breakdown}/>
        <SalaryRankingTable data={employeeBudgetData} getData={getData} />
      </div>

      <ProjectBudgetOverview data={projectData} getData={fetchData} />
      <BugTrackPaymentChart 
        chartData={chartData} 
        selectedYear={selectedYear} 
        setSelectedYear={setSelectedYear} 
        getTotalInvoices={getTotalInvoices}
      />

      {isLoading && (
        <div className="fixed inset-0 z-10 flex items-center justify-center bg-black bg-opacity-50 md:top-[95px] top-[52px]">
          <Loader />
        </div>
      )}
    </div>
  );
};

export default Dashboard;