export const GA_TRACKING_ID = "G-WVQBJ4TDG0"; // Replace with your actual Google Analytics ID

// Load Google Analytics script dynamically (if not already loaded)
export const loadGoogleAnalytics = () => {
  if (window.gtag) return;

  const script = document.createElement("script");
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`;
  document.head.appendChild(script);

  // Ensure dataLayer is attached to the window object
  window.dataLayer = window.dataLayer || [];
  window.gtag = function () { window.dataLayer.push(arguments); };

  window.gtag("js", new Date());
  window.gtag("config", GA_TRACKING_ID, { send_page_view: false });
};

// Function to track page views
export const trackPageView = (url) => {
  if (window.gtag) {
    window.gtag("config", GA_TRACKING_ID, { page_path: url });
  }
};

// Function to track custom events
export const trackEvent = (action, category, label, value = 0) => {
  if (window.gtag) {
    window.gtag("event", action, {
      event_category: category,
      event_label: label,
      value: value,
    });
  }
};
