import React, { useState, useEffect, useRef } from "react";
import styles from "./personalInformation.module.css";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { fetchApi } from "../../../../utlis/axios";
import { toggleAuth } from "../../../../Redux/Slices/auth-slice";
import ParticlesBackground from "../../../home/ParticalsBackground";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { addToast } from "../../../../Redux/Slices/toast-slice";

const PersonalInformation = () => {
  const { theme } = useSelector((state) => state.theme);
  const { isLogin, userInfo } = useSelector((state) => state?.auth || {});
  const dispatch = useDispatch();
  const fileInputRef = useRef();
  const fileInputRef1 = useRef();
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    role: "",
  });
  const [preview, setPreview] = useState(null);
  const [fileUploading, setFileUploading] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setNewShowPassword] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (userInfo) {
      setFormData((prevData) => ({
        ...prevData,
        first_name: userInfo?.user?.first_name || "",
        last_name: userInfo?.user?.last_name || "",
        email: userInfo?.user?.email || "",
        role: userInfo?.user?.role?.name || "",
      }));
    }
  }, [userInfo]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    if (e.target.name === "newPassword") {
      calculatePasswordStrength(e.target.value);
    }
  };

  const calculatePasswordStrength = (password) => {
    let strength = 0;
    if (password.length >= 8) strength++;
    if (/[A-Z]/.test(password)) strength++;
    if (/[a-z]/.test(password)) strength++;
    if (/\d/.test(password)) strength++;
    if (/[@$!%*?&]/.test(password)) strength++;
    setPasswordStrength(strength);
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission

    const updatedFormData = new FormData(); // Rename FormData object
    updatedFormData.append("first_name", formData.first_name);
    updatedFormData.append("last_name", formData.last_name);
    updatedFormData.append("is_archived", false);

    setSaving(true);
    try {
      const response = await fetchApi.patch(
        `users/${userInfo?.user?.id}/`,
        updatedFormData, // Use updatedFormData here
        {
          headers: {
            Authorization: `Bearer ${userInfo.access_token}`,
          },
        }
      );

      if (response.status === 200) {
        dispatch(
          toggleAuth({
            isLogin: true,
            userInfo: {
              ...userInfo, // Preserve existing data
              user: response.data.user, // Update user data
            },
          })
        );
        dispatch(addToast({ message: "Information updated successfully", type: "success" }));
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(toggleAuth({ isLogin: false, userInfo: null }));
        dispatch(addToast({ message: "Your session expired", type: "error" }));
        return;
      }
      dispatch(addToast({ message: "Something went wrong", type: "error" }));
    } finally {
      setSaving(false);
    }
  };

  const handleChangePassword = async () => {
    if (!newPassword || !confirmPassword) {
      dispatch(addToast({ message: "Please enter new password", type: "warn" }));
      return;
    }
    if (newPassword.length < 8) {
      dispatch(addToast({ message: "Password must be at least 8 characters long", type: "warn" }));
      return;
    }
    if (newPassword !== confirmPassword) {
      dispatch(addToast({ message: "Password not matched", type: "warn" }));
      return;
    }
    setUpdating(true);
    try {
      const res = await fetchApi.post(
        "change-password/",
        {
          user_id: userInfo?.user?.id,
          password: newPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${userInfo.access_token}`,
          },
        }
      );
      if (res.status === 200) {
        setModalOpen(false);
        dispatch(addToast({ message: "Password updated successfully", type: "success" }));
        setNewPassword("");
        setConfirmPassword("");
      }
    } catch (error) {
      dispatch(addToast({ message: "Something went wrong", type: "error" }));
    } finally {
      setUpdating(false);
    }
  };

  const handleUploadClick = (event) => {
    event.stopPropagation(); // Stop event bubbling
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const handleFileChange = async (event, ref) => {
    const file = event.target.files[0]; // Get the selected file
    if (!file) return; // If no file is selected, return early

    const formData = new FormData();
    formData.append("picture", file);
    formData.append("is_archived", false);
    setFileUploading(true);
    try {
      const res = await fetchApi.patch(`users/${userInfo.user.id}/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });
      if (res.status === 200) {
        dispatch(
          toggleAuth({
            isLogin: true,
            userInfo: {
              ...userInfo, // Preserve existing data
              user: res.data.user, // Add new key
            },
          })
        );
        dispatch(addToast({ message: "Profile picture updated successfully", type: "success" }));
      }
    } catch (error) {
      dispatch(addToast({ message: error.message, type: "error" }));
    } finally {
      setFileUploading(false);
    }
  };

  return (
    <>
      <style>{`
        .input {
          background-color: rgba(255, 255, 255, 0.1); 
          backdrop-filter: blur(8px);
          color: ${theme === "dark" ? "#ffffff" : "#000000"};
          width: 100%;
          border: 1px solid #828282;
          height: calc(2.25rem + 2px);
          font-weight: 400;
          font-size: .875rem;
          padding: .625rem .6875rem;
          border-radius: 10px;
        }
      `}</style>
      <div
        className={`${styles.main_Heading} ${theme === "dark" ? "text-white" : "text-black"}`}
      >
        Personal Information
      </div>
      <hr className="w-full" />
      <div
        className={`${styles.container} ${theme === "dark" ? " text-white" : "text-black"}`}
      >
        <div className="md:flex justify-between">
          <div className={styles.formGroup}>
            <label className={styles.label}>
              First Name:
              <input
                type="text"
                name="first_name"
                value={formData.first_name}
                onChange={(e) => setFormData((prev) => ({ ...prev, first_name: e.target.value }))}
                className="input"
              />
            </label>
          </div>
          <div className={styles.formGroup}>
            <label className={styles.label}>
              Last Name:
              <input
                type="text"
                name="last_name"
                value={formData.last_name}
                onChange={(e) => setFormData((prev) => ({ ...prev, last_name: e.target.value }))}
                className="input"
              />
            </label>
          </div>
        </div>
        <div className="md:flex justify-between">
          <div className={styles.formGroup}>
            <label className={styles.label}>
              Email:
              <input
                type="email"
                name="email"
                value={formData.email}
                className="input"
              />
            </label>
          </div>
          <div className="md:flex md:w-[45%] w-full mb-4 md:mb-auto justify-between items-center">
            <div className={styles.formGroup}>
              <label className={styles.label}>
                Current Password:
                <div className="relative">
                  <input
                    type={"password"}
                    name="currentPassword"
                    value={"........"}
                    className="input"
                  />
                  <div
                    className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {<FaEyeSlash />}
                  </div>
                </div>
              </label>
            </div>
            <div className="mt-4 md:mt-0 md:ml-4">
              <button className="text-base hover:text-red-600 outline-none" onClick={() => setModalOpen(true)}>
                Change Password
              </button>
            </div>
          </div>
        </div>
        <div className={styles.formGroup}>
          <label className={`${styles.label} mt-2`}>
            Profile Picture:
            {userInfo?.user?.picture ? (
              <div className="w-full flex justify-center items-center">
                <div
                  className="relative w-32 h-32 rounded-full overflow-hidden"
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                >
                  <img
                    src={userInfo.user.picture}
                    alt="Profile"
                    className="w-full h-full object-cover"
                  />
                  {isHovered && (
                    <div
                      className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center text-white text-sm font-normal cursor-pointer"
                      onClick={handleUploadClick}
                      disabled={fileUploading}
                    >
                      {fileUploading ? "Changing..." : "Change"}
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <button
                type="button"
                className="heroButton ml-4 font-normal"
                disabled={fileUploading}
                onClick={handleUploadClick}
              >
                {fileUploading ? "Uploading..." : "Upload"}
              </button>
            )}
          </label>
          <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
        </div>

        <div className={styles.formGroup}>
          <button type="submit" className="heroButton" disabled={saving} onClick={handleSubmit}>
            {saving ? "Saving..." : "Save Changes"}
          </button>
        </div>
      </div>
      <ToastContainer />
      {modalOpen && (
        <>
          {/* Overlay */}
          <div className="fixed inset-0 bg-black bg-opacity-50 z-10"></div>

          {/* Modal Content */}
          <div className="fixed inset-0 flex items-center justify-center z-20">
            <div
              className={`p-6 rounded-lg shadow-lg max-w-md w-full max-h-[80vh] overflow-auto ${theme === "dark" ? "bg-darkMode text-white" : "bg-lightMode text-black"
                }`}
            >
              <div className="flex justify-between">
                <div className="flex gap-2 items-center">
                  <h2 className="text-base font-semibold">Update Password</h2>
                </div>
                <IoClose
                  className="w-6 h-6"
                  onClick={() => {
                    setModalOpen(false);
                  }}
                />
              </div>
              <div className="justify-between w-full mt-4">
                <div className={`flex-col ${styles.formGroup} w-full`}>
                  <label className={`w-full ${styles.label}`}>
                    New Password:
                    <div className="relative">
                      <input
                        type={showNewPassword ? "text" : "password"}
                        name="newPassword"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        className="input"
                      />
                      <div
                        className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                        onClick={() => setNewShowPassword(!showNewPassword)}
                      >
                        {showNewPassword ? <FaEye /> : <FaEyeSlash />}
                      </div>
                    </div>
                  </label>
                  <div className="w-full h-2 mb-2 rounded-full overflow-hidden">
                    <div
                      className={`h-full rounded-full transition-all duration-300 ${passwordStrength < 2
                          ? "bg-red-500"
                          : passwordStrength < 4
                            ? "bg-orange-500"
                            : "bg-green-500"
                        }`}
                      style={{ width: `${(passwordStrength / 5) * 100}%` }}
                    ></div>
                  </div>
                </div>

                <div className={`${styles.formGroup} w-full`}>
                  <label className={styles.label}>
                    Confirm New Password:
                    <input
                      type={showNewPassword ? "text" : "password"}
                      name="confirmPassword"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      className="input"
                    />
                    <div className="w-full h-2 mb-2 rounded-full overflow-hidden"></div>
                  </label>
                </div>
              </div>
              <div className="flex gap-2 justify-end pt-4">
                <button
                  className="heroButton"
                  disabled={updating}
                  onClick={handleChangePassword}
                >
                  {updating ? "Changing..." : "Change"}
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default PersonalInformation;