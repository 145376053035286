import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "../users/user.module.css";
import { fetchApi } from "../../../utlis/axios";
import { FaRegCopy } from "react-icons/fa";
import { MdCheck } from "react-icons/md";
import Loader from "../../Loader/Loader";
import { addToast } from "../../../Redux/Slices/toast-slice";
import { CiSearch } from "react-icons/ci";
import loader from '../../../assets/loading-gif.gif'

const EnterpricePlan = () => {
  const { theme } = useSelector((state) => state.theme);
  const dispatch = useDispatch()
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    noOfUsers: "",
    descrption: "",
    price: "",
    currency: "",
    interval: "",
  });
  const [enterprisePlans, setEnterprisePlans] = useState([]);
  const [originalPlans, setOriginalPlans] = useState([]);
  const { userInfo } = useSelector((state) => state?.auth || {});
  const [copiedIndex, setCopiedIndex] = useState(null);
  const [intervalDropdown, setIntervalDropdown] = useState(false);
  const [next, setNext] = useState(null)
  const [prev, setPrev] = useState(null)
  const [searching, setSearching] = useState(false)
  const [searchQuery, setSearchQuery] = useState(null)
  const [oNext, setONext] = useState(null)
  const [oPrev, setOPrev] = useState(null)

  const handleCopy = (text, index) => {
    navigator.clipboard.writeText(text);
    setCopiedIndex(index);

    // Reset back to copy icon after 3 seconds
    setTimeout(() => setCopiedIndex(null), 3000);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !formData.name ||
      !formData.email ||
      !formData.noOfUsers ||
      !formData.descrption ||
      !formData.price ||
      !formData.interval
    ) {
      dispatch(addToast({ message: 'All fields required', type: 'error' }));
      return;
    }
    setLoading(true);
    const data = new FormData();
    data.append("name", formData.name);
    data.append("email", formData.email);
    data.append("number_of_users", formData.noOfUsers);
    data.append("description", formData.descrption);
    data.append("amount", formData.price);
    data.append("currency", "USD");
    data.append("interval", formData.interval);
    data.append("is_enterprise", true);

    try {
      const response = await fetchApi.post("enterprise-plans/", data, {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });

      if (response.status === 201) {
        setIsModalOpen(false);
        dispatch(addToast({ message: 'Plan created successfully', type: 'success' }));
        setFormData({
          name: "",
          email: "",
          noOfUsers: "",
          description: "",
          price: "",
          currency: "",
          interval: "",
        });
      }
    } catch (error) {
      dispatch(addToast({ message: 'Something went wrong', type: 'error' }));
    } finally {
      setLoading(false);
    }
  };
  const getPlans = async () => {
    setIsLoading(true);
    try {
      const response = await fetchApi.get("enterprise-plans/", {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });
      if (response.status === 200) {
        setEnterprisePlans(response.data.results);
        setNext(response.data.next)
        setPrev(response.data.previous);
        setONext(response.data.next)
        setOPrev(response.data.previous)
        setOriginalPlans(response.data.results)
      }
    } catch (error) {
      dispatch(addToast({ message: 'Something went wrong', type: 'error' }));
    } finally {
      setIsLoading(false);
    }
  };
  const updatePlans = async () => {
    try {
      const response = await fetchApi.get("enterprise-plans/", {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });
      if (response.status === 200) {
        setEnterprisePlans(response.data.results);
        setNext(response.data.next)
        setPrev(response.data.previous);
        setONext(response.data.next)
        setOPrev(response.data.previous)
        setOriginalPlans(response.data.results)
      }
    } catch (error) {
      dispatch(addToast({ message: 'Something went wrong', type: 'error' }));
    }
  };
  useEffect(() => {
    getPlans();
  }, []);

  const formatDate = (isoString) => {
    const date = new Date(isoString);
    return date.toLocaleString(); // Converts to local date & time
  };
  const handleClearSearch = () => {
    setSearchQuery("");
    setEnterprisePlans(originalPlans); // Restore original data
    setNext(oNext); // Restore original pagination
    setPrev(oPrev);
  };
  const handlePageChange = async (url) => {
    setIsLoading(true);
    try {
      const res = await fetchApi.get(url, {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });
      if (res.status === 200) {
        setEnterprisePlans(res.data.results)
        setOriginalPlans(res.data.results)
        setNext(res.data.next)
        setPrev(res.data.previous)
        setONext(res.data.next)
        setOPrev(res.data.previous)
        setIsLoading(false);
      } else {
        dispatch(addToast({ message: res.message, type: 'error' }));
      }
    } catch (error) {
      dispatch(addToast({ message: 'Something went wrong', type: 'error' }));
      setIsLoading(false);
    }
  };

  const handleSearch = async () => {
    if (!searchQuery) {
      dispatch(addToast({ message: 'Please enter a search query', type: 'warn' }));
      return
    }
    setSearching(true)
    try {
      const res = await fetchApi.get(`enterprise-plans/?search=${searchQuery}`, {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`, // Attach token
        },
      });
      setEnterprisePlans(res.data.results)
      if (res.data.next) {
        setNext(res.data.next)
      } else {
        setNext(null)
      }
      if (res.data.previous) {
        setPrev(res.data.previous)
      } else {
        setPrev(null)
      }
    } catch (error) {
      dispatch(addToast({ message: 'Something went wrong', type: 'error' }));
    }
    finally {
      setSearching(false)
    }
  }

  return (
    <div className="w-full flex flex-col justify-center">
      {isLoading && (
        <>
          <div
            className="fixed inset-0 bg-black bg-opacity-50 z-10"
            style={{ top: "95px" }}
          ></div>
          <div
            className="fixed inset-0 flex items-center justify-center z-20"
            style={{ top: "95px" }}
          >
            <Loader />
          </div>
        </>
      )}
      <div
        className={`my-5 text-xl w-[90%] max-w-[1024px] mx-auto ${theme === "dark" ? "text-white" : "text-black"
          }`}
      >
        Enterprise Plans
      </div>
      <hr className="w-full" />
      <div className="w-[90%] max-w-[1024px] mx-auto">
        <div className="w-full flex justify-between items-center ">
          <button
            className="my-4 heroButton"
            onClick={() => setIsModalOpen(true)}
          >
            Create Enterprise plan
          </button>
          <div className={`flex items-center gap-2 sm:w-56 w-full justify-between px-4 border border-red-600 rounded-3xl ${theme === 'dark' ? 'text-white':'text-black'}`}>
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value);
                if (!e.target.value) {
                  handleClearSearch();
                }
              }}
              placeholder="Search plans here"
              className="py-2 bg-transparent border-none outline-none w-full"
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSearch();
                }
              }}
            />
            {searching ? (
              <img src={loader} alt="loading" className="w-6 h-6" />
            ) : (
              <CiSearch
                className={`w-6 h-6 cursor-pointer ${theme === 'dark' ? "text-white" : "text-black"}`}
                onClick={handleSearch}
              />
            )}
          </div>
        </div>
        <div>
          <div
            className={`overflow-x-auto ${theme === "dark" ? "text-white" : "text-black"
              }`}
          >
            <div className={styles.tableResponsive}>
              <table className={styles.table}>
                <thead>
                  <tr>
                    <th>Email</th>
                    <th>Created By</th>
                    <th>Created At</th>
                    <th>URL</th>
                  </tr>
                </thead>
                <tbody>
                  {enterprisePlans.length <= 0 ? (
                    <td colSpan={4} className="m-2">
                      No data found
                    </td>
                  ) : (
                    enterprisePlans?.map((plan, index) => (
                      <tr key={plan.id}>
                        <td>{plan.email}</td>
                        <td>{plan.created_by_email}</td>
                        <td>{formatDate(plan.created_at)}</td>
                        <td>
                          {copiedIndex === index ? (
                            <MdCheck className="w-5 h-5" />
                          ) : (
                            <FaRegCopy
                              onClick={() => handleCopy(plan.url, index)}
                              className="cursor-pointer w-4 h-4"
                            />
                          )}
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="w-full  my-3 justify-between flex">
            <span>
              {prev && (
                <button
                  className="heroButton block outline-none"
                  onClick={() => handlePageChange(prev)}
                >
                  Previous
                </button>
              )}
            </span>
            <span>
              {next && (
                <button
                  className="heroButton block outline-none"
                  onClick={() => handlePageChange(next)}
                >
                  Next
                </button>
              )}
            </span>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <div className={styles.modalOverlay}>
          <div
            className={`${styles.modal} ${theme === "dark"
              ? "bg-[#212529] text-white"
              : "bg-[#e5e5e5] text-black"
              }`}
            style={{ width: "max-content" }}
          >
            <div className={styles.modalHeader}>
              <h2 className="font-semibold ">New plan</h2>
              <button
                className={styles.closeButton}
                onClick={() => {
                  setIsModalOpen(false);
                }}
              >
                &times;
              </button>
            </div>
            <form onSubmit={handleSubmit}>
              <div className=" gap-2 grid grid-cols-1 sm:grid-cols-2">
                <div className={styles.formGroup}>
                  <label>Name</label>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    placeholder="Enter name"
                    className={`w-full bg-transparent border ${theme === "dark"
                      ? "border-white text-white"
                      : "border-black text-black"
                      } p-2 rounded-md outline-none`}
                  />
                </div>

                <div className={styles.formGroup}>
                  <label>Email</label>
                  <input
                    type="text"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    placeholder="Enter email address"
                    className={`w-full bg-transparent border ${theme === "dark"
                      ? "border-white text-white"
                      : "border-black text-black"
                      } p-2 rounded-md outline-none`}
                  />
                </div>

                <div className={styles.formGroup}>
                  <label>Amount</label>
                  <input
                    type="number"
                    name="price"
                    value={formData.price}
                    onChange={handleInputChange}
                    placeholder="Enter amount"
                    className={`w-full bg-transparent border ${theme === "dark"
                      ? "border-white text-white"
                      : "border-black text-black"
                      } p-2 rounded-md outline-none`}
                  />
                </div>
                <div className={styles.formGroup}>
                  <label>No of User</label>
                  <input
                    className={`w-full bg-transparent border ${theme === "dark"
                      ? "border-white text-white"
                      : "border-black text-black"
                      } p-2 rounded-md outline-none`}
                    type="number"
                    name="noOfUsers"
                    value={formData.noOfUsers}
                    onChange={handleInputChange}
                    placeholder="Enter number of users"
                  />
                </div>
                <div className={`${styles.formGroup} relative `}>
                  <label>Interval</label>
                  <div
                    className={`w-full bg-transparent border ${theme === "dark"
                      ? "border-white text-white"
                      : "border-black text-black"
                      } p-2 rounded-md outline-none capitalize cursor-pointer`} onClick={() => setIntervalDropdown(!intervalDropdown)}
                  >
                    {formData.interval ? formData.interval : 'Choose plan interval'}
                  </div>
                  {intervalDropdown && (
                    <ul
                      className={`shadow-lg p-2 rounded-md z-50 absolute w-full ${theme === "dark"
                        ? "bg-dashboardDarkMode text-white"
                        : "bg-white text-black"
                        } ${styles.dropdown_menu}`}
                      style={{ top: "100%", right: "0", left: "0" }}
                    >
                      <li
                        value="year"
                        onClick={() => {
                          setFormData({ ...formData, interval: 'year' });
                          setIntervalDropdown(false)
                        }}
                        className={`py-1 px-2 rounded-md ${theme === "dark"
                          ? "hover:bg-gradient-to-r hover:from-[#fff3] hover:to-[#fff0] transition-all"
                          : "hover:bg-gradient-to-r hover:from-[#6d6d6d33] hover:to-[#fff0] transition-all"
                          }`}
                      >
                        Year
                      </li>
                      <li
                        value="month"
                        onClick={() => {
                          setFormData({ ...formData, interval: 'month' });
                          setIntervalDropdown(false)
                        }}
                        className={`py-1 px-2 rounded-md ${theme === "dark"
                          ? "hover:bg-gradient-to-r hover:from-[#fff3] hover:to-[#fff0] transition-all"
                          : "hover:bg-gradient-to-r hover:from-[#6d6d6d33] hover:to-[#fff0] transition-all"
                          }`}
                      >
                        Month
                      </li>
                    </ul>
                  )}
                </div>
              </div>
              <div className={styles.formGroup}>
                <label>Description</label>
                <textarea
                  type="text"
                  name="descrption"
                  value={formData.descrption}
                  onChange={handleInputChange}
                  placeholder="Enter description"
                  className={`w-full bg-transparent border ${theme === "dark"
                    ? "border-white text-white"
                    : "border-black text-black"
                    } p-2 rounded-md outline-none`}
                  rows={2}
                />
              </div>

              <div className={styles.actions} disabled={loading}>
                <button type="submit" className="heroButton w-full">
                  {loading ? "Creating..." : "Create"}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default EnterpricePlan;
