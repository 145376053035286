import React from "react";
import { useSelector } from "react-redux";
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Area
} from "recharts";

// Custom dot component with glow effect
const CustomDot = (props) => {
  const { cx, cy, stroke } = props;
  
  return (
    <svg x={cx - 8} y={cy - 8} width={16} height={16}>
      <defs>
        <filter id="dot-glow" x="-50%" y="-50%" width="200%" height="200%">
          <feGaussianBlur stdDeviation="3" result="blur" />
          <feMerge>
            <feMergeNode in="blur" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
      
      <circle cx={8} cy={8} r={6} fill="rgba(255, 155, 34, 0.8)" filter="url(#dot-glow)" />
      <circle cx={8} cy={8} r={4} fill={stroke} stroke="white" strokeWidth={1.5} />
    </svg>
  );
};

const CustomTooltip = ({ active, payload, label, theme }) => {
  if (active && payload && payload.length) {
    return (
      <div className={`p-3 w-full ${theme === "dark" ? "bg-[#222]" : "bg-white"} shadow-lg border ${theme === "dark" ? "border-gray-600" : "border-gray-200"}`}>
        <p className="font-semibold mb-1">{formatMonthYearLabel(label)}</p>
        <div className="flex items-center gap-2 mb-1">
          <div className="w-3 h-3 rounded-full bg-[#FF9B22]"></div>
          <p className={`text-sm ${theme === "dark" ? "text-white" : "text-gray-800"}`}>
            <span className="font-medium">Salary: </span>
            ${payload[0].value.toLocaleString()}
          </p>
        </div>
      </div>
    );
  }
  return null;
};

// Format date to show as "MMM YYYY" (e.g., "Mar 2025")
const formatMonthYearLabel = (dateStr) => {
  if (!dateStr) return "";
  const date = new Date(dateStr);
  return `${date.toLocaleString('default', { month: 'short' })} ${date.getFullYear()}`;
};

// Format tick to show 3-letter month (e.g., "Mar")
const formatMonthTick = (dateStr) => {
  if (!dateStr) return "";
  const date = new Date(dateStr);
  return date.toLocaleString('default', { month: 'short' }).substring(0, 3);
};

// Process data to always include all 12 months
const processData = (rawData) => {
  // Determine year from data or use current year
  const year = rawData && Object.keys(rawData).length > 0 
    ? new Date(Object.keys(rawData)[0]).getFullYear()
    : new Date().getFullYear();

  // Create array with all 12 months
  return Array.from({ length: 12 }, (_, i) => {
    const month = (i + 1).toString().padStart(2, '0');
    const dateKey = `${year}-${month}-01`;
    const value = rawData?.[dateKey] || 0;
    
    return {
      date: dateKey,
      value: typeof value === 'string' ? parseInt(value) : value,
      name: new Date(dateKey).toLocaleString('default', { month: 'short' })
    };
  });
};

const SalaryBreakdownChart = ({ data }) => {
  const { theme } = useSelector((state) => state.theme);
  const chartData = processData(data);
  
  // Calculate Y-axis domain
  const maxValue = Math.max(...chartData.map(item => item.value), 0);
  const yAxisDomain = [0, maxValue + (maxValue * 0.2)];

  return (
    <div className={`py-4 fontPoppins border rounded-[20px] flex-col flex items-center justify-center gap-4 h-full md:w-1/2 w-full ${theme === 'dark' ? 'bg-[#212529] border-[#555657]' : 'bg-[#F2F4F7] border-[#D8D8D8]'}`}>
      <div className="flex justify-start mb-4 px-4 w-full">
        <h2 className="text-lg font-semibold capitalize">Monthly Salary Breakdown</h2>
      </div>

      <ResponsiveContainer width="100%" height={300}>
        <LineChart data={chartData} margin={{ top: 0, right: 30, left: 0, bottom: 0 }}>
          <CartesianGrid 
            strokeDasharray="3 3" 
            stroke={theme === 'dark' ? "#FFFFFF40" : "#00000040"}  
            horizontal={true} 
            vertical={true} 
          />

          <XAxis 
            dataKey="date" 
            tickFormatter={formatMonthTick}
            tick={{ fill: theme === 'dark' ? "white" : 'black' }} 
            axisLine={{ stroke: theme === 'dark' ? '#fff' : '#000' }}
            tickLine={{ stroke: theme === 'dark' ? '#fff' : '#000' }}
            fontSize={10}
            tickMargin={10}
            interval={0}
          />
          
          <YAxis 
            tick={{ fill: theme === 'dark' ? "white" : 'black' }} 
            fontSize={12}
            domain={yAxisDomain}
            tickFormatter={(value) => `$${value.toLocaleString()}`}
            width={80}
            axisLine={{ stroke: theme === 'dark' ? '#fff' : '#000' }}
            tickLine={{ stroke: theme === 'dark' ? '#fff' : '#000' }}
          />

          <Tooltip 
            content={<CustomTooltip theme={theme} />}
            cursor={{ stroke: theme === 'dark' ? '#555' : '#ddd', strokeWidth: 1 }}
          />

          <defs>
            <linearGradient id="lightGradient" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="rgba(204, 9, 39, 0.3)" />
              <stop offset="100%" stopColor="rgba(204, 9, 39, 0.05)" />
            </linearGradient>
            <linearGradient id="darkGradient" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="rgba(255, 77, 77, 0.3)" />
              <stop offset="100%" stopColor="rgba(255, 77, 77, 0.05)" />
            </linearGradient>
          </defs>

          <Area
            type="monotone"
            dataKey="value"
            stroke="transparent"
            fill={theme === "dark" ? "url(#darkGradient)" : "url(#lightGradient)"}
            baseValue={0}
          />

          <Line
            type="monotone"
            dataKey="value"
            stroke="#FF9B22"
            strokeWidth={2}
            dot={<CustomDot />}
            activeDot={{
              r: 6, 
              stroke: "white", 
              strokeWidth: 2, 
              fill: "#FF9B22",
              style: { filter: 'drop-shadow(0 0 6px rgba(255, 155, 34, 0.6))' }
            }}
            connectNulls={false}
          />
        </LineChart>
      </ResponsiveContainer>

      <div className={`text-sm ${theme === 'dark' ? 'text-gray-400' : 'text-gray-600'}`}>
        {chartData.length > 0 ? new Date(chartData[0].date).getFullYear() : new Date().getFullYear()}
      </div>
    </div>
  );
};

export default SalaryBreakdownChart;