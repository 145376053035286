import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Default styling for React Quill
import "react-quill/dist/quill.bubble.css"; // Optional styling
import { useSelector } from "react-redux";

const RichTextEditor1 = ({ setComment, comment }) => {
  const { theme } = useSelector((state) => state.theme);

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "code-block"],
      [{ align: [] }],
      ["clean"],
    ],
  };

  const editorStyles = {
    backgroundColor: theme === "dark" ? "transparent" : "transparent",
    color: theme === "dark" ? "#ffffff" : "#000000",
    borderRadius: "10px",
  };

  return (
    <div className={theme === "dark" ? "quill-dark-theme" : "quill-light-theme"}>
      <ReactQuill
        value={comment}
        onChange={(e) => setComment(e)}
        modules={modules}
        theme="snow"
        style={editorStyles}
        className="no-border" // Add a custom class
      />
    </div>
  );
};

export default RichTextEditor1;
