import React, { useEffect, } from "react";
import { Route, Routes, BrowserRouter, Navigate, useLocation } from "react-router-dom";
import HomePage from "./components/home/HomePage";
import Login from "./components/authpages/login/Login";
import SignUp from "./components/authpages/signup/SignUp";
import RegCompany from "./components/authpages/registerCompany/RegCmpany"
import ForgotPassword from "./components/forgotPassword/ForgotPassword";
import SubscriptionPage from "./components//subcriptions/SubscriptionPage";
import Dashboard from "./components/Dashboard/Dashboard";
import Summary from "./components/Dashboard/summary/Summary";
import Activity from "./components/Dashboard/activity/Activity";
import Users from "./components/Dashboard/users/Users";
import AnalyticsAndCharts from "./components/Dashboard/analyticsAndCharts/AnalyticsAndCharts";
import Reports from "./components/Dashboard/reports/Reports";
import PersonalInformation from "./components/Dashboard/Settings/personalInformation/PersonalInformation";
import Error from "./components/404error/Error";
import TermsOfService from "./components/terms/TermsOfService";
import PrivacyPolicy from "./components/privacyPolicy/PrivacyPolicy";
import { useSelector } from "react-redux";
import StripePayment from "./components/stripePayment/StripePayment";
import AllFeatures from './components/subcriptions/AllFeatures'
import Invoices from "./components/stripePayment/Invoices";
import UpdateCompany from "./components/Dashboard/company/UpdateCompany";
import CreateOvertime from "./components/Dashboard/overtime/CreateOvertime";
import OfftimeCreate from "./components/Dashboard/offtime/OfftimeCreate";
import UserTimeSheet from "./components/Dashboard/timesheet/UserTimeSheet";
import Board from "./components/Dashboard/board/Board";
import ProjectDetail from './components/Dashboard/board/ProjectDetail'
import TicketDetail from './components/Dashboard/board/TicketDetail'
import UserProject from "./components/Dashboard/board/UserProject";
import UserTicketDetail from "./components/Dashboard/board/UserTicketDetail";
import TemplateEditor from "./components/Dashboard/template/TemplateEditor";
import NewCompanyTemplate from "./components/Dashboard/template/NewCompanyTemplate";
import TemplatePreview from "./components/Dashboard/template/TemplatePreview";
import AgentChat from "./components/Dashboard/chatsupport/AgentChat";
import { SpeedInsights } from '@vercel/speed-insights/react';
import Guid from "./components/userguid/Guid";
import { loadGoogleAnalytics, trackPageView } from "../src/utlis/gtag";
import EnterpricePlan from "./components/Dashboard/enterprice/EnterpricePlan";
import { Analytics } from '@vercel/analytics/react';
import ToastContainer from "./components/toast/ToastContainer";
import AdminDashboard from "./components/Dashboard/adminDashboard/AdminDashboard";
import UnAuthoRized from "./components/404error/Unauthorized";
const PageTracker = () => {
  const location = useLocation();

  useEffect(() => {
    loadGoogleAnalytics(); // Ensure Google Analytics is loaded
    trackPageView(location.pathname); // Track page view on route change
  }, [location]);

  return null; // No UI needed
};

const App = () => {
  const { isLogin, userInfo } = useSelector((state) => state?.auth || "");
  const { theme } = useSelector((state) => state.theme);
  useEffect(() => {
    document.body.className = theme === "dark" ? "dark-theme" : "light-theme";
  }, [theme]);
  return (
    <BrowserRouter>
      <PageTracker />
      <ToastContainer />
      <Routes>
        <Route path="/" element={<HomePage />} exact />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/enterprice-signup/:planId" element={<SignUp parent='enterprice' />} />
        <Route path="/registercompany" element={<RegCompany />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/subscriptionplan" element={<SubscriptionPage parent='subcriptionplan' />} />
        <Route path="/terms_of_services" element={<TermsOfService />} />
        <Route path="/privacy_policy" element={<PrivacyPolicy />} />
        <Route path="/see-all-features" element={<AllFeatures parent="home" />} />
        <Route path="/see-features" element={<AllFeatures />} />
        <Route path="/unauthorized" element={<UnAuthoRized />} />

        <Route
          path="/dashboard"
          element={
            isLogin ? (
              <>
                <Dashboard />
              </>
            ) : <Navigate to='/' />
          }
        >
          <Route index element={((userInfo?.user?.plan?.name === 'Premium' || userInfo?.user?.plan?.is_enterprise) && userInfo?.user.role?.name === 'Admin' && userInfo?.company) ? <AdminDashboard /> : <Navigate to="/dashboard/summary" />} />
          <Route path="summary" element={<Summary />} />
          <Route path="activity" element={<Activity />} />
          <Route path="users" element={userInfo?.user?.role?.name === 'Time Reporter' ? <Navigate to='/dashboard/summary' /> : <Users />} />
          <Route path="analytics_charts" element={(userInfo?.user?.role.name === 'Time Reporter' || userInfo?.user?.plan?.name !== 'Standard') ? <Navigate to='/unauthorized' /> : <AnalyticsAndCharts /> }/>
          <Route path="reports" element={userInfo?.user?.role.name === 'Time Reporter' ? <Navigate to='/dashboard/summary' /> : <Reports />} />
          <Route path="changeplan" element={userInfo?.user?.role.name === 'Time Reporter' ? <Navigate to='/dashboard/summary' /> : <SubscriptionPage parant='no' />} />
          <Route path='invoices' element={userInfo?.user?.role.name === 'Time Reporter' ? <Navigate to='/dashboard/summary' /> : <Invoices />} />

          <Route path="updatecompany" element={(userInfo?.user?.role.name === 'Time Reporter' || userInfo?.user?.plan?.name === 'Basic') ? <Navigate to='/unauthorized' /> : <UpdateCompany />} />
          <Route path="overtime" element={(userInfo?.user?.plan?.name === 'Basic' || userInfo?.user?.role?.name !== 'Time Reporter') ? <Navigate to='/unauthorized' /> : <CreateOvertime />} />
          <Route path="timeoffs" element={(userInfo?.user?.plan?.name === 'Basic' || userInfo?.user?.role?.name !== 'Time Reporter') ? <Navigate to='/unauthorized' /> : <OfftimeCreate />} />
          <Route path="timesheet" element={(userInfo?.user?.plan?.name === 'Basic' || userInfo?.user?.role?.name !== 'Time Reporter') ? <Navigate to='/unauthorized' /> : <UserTimeSheet />} />
          <Route path='board' element={(userInfo?.user?.role.name === 'Time Reporter' || userInfo?.user?.plan?.name === 'Basic') ? <Navigate to='/unauthorized' /> : <Board />} />
          <Route path="project" element={<UserProject />} />
          <Route path='project/:id' element={userInfo?.user?.role.name === 'Time Reporter' ? <Navigate to='/dashboard/summary' /> : <ProjectDetail />} />
          <Route path="ticket/:id" element={userInfo?.user?.role.name === 'Time Reporter' ? <Navigate to='/dashboard/summary' /> : <TicketDetail />} />
          <Route path="tickets/:id" element={<UserTicketDetail />} />
          <Route path='chat' element={(userInfo?.user?.is_staff || userInfo?.user?.is_superuser) ? <AgentChat /> : <Navigate to='/unauthorized' />} />
          <Route path='enterprice' element={(userInfo?.user?.is_staff || userInfo?.user?.is_superuser) ? <EnterpricePlan /> : <Navigate to='/unauthorized' />} />
          <Route path="user-guide" element={<Guid />} />
          {/* <Route path="admin" element={userInfo?.user?.role.name === 'Time Reporter' ? <Navigate to='/dashboard/summary' /> :<AdminDashboard/>}/>*/}
          {/*<Route path="template" element={<TemplateEditor/>}/>
          <Route path="create-new-template" element={<NewCompanyTemplate/>}/>
          <Route path="template-preview" element={<TemplatePreview/>}/>*/}
          <Route path="setting/personalinformation" element={<PersonalInformation />} />
        </Route>
        <Route path="/payment" element={<StripePayment />} />

        <Route path="*" element={<Error />} />

      </Routes>
      <SpeedInsights />
      <Analytics />
    </BrowserRouter >
  );
};

export default App;
