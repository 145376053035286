import React, { useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import styles from "./styles.module.css"; 
import { fetchApi } from '../../../utlis/axios';
import { useDispatch, useSelector } from "react-redux";
import { toggleAuth } from "../../../Redux/Slices/auth-slice";
import {addToast} from "../../../Redux/Slices/toast-slice";
import { FaUser } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { IoCall } from "react-icons/io5";
import { FaMessage } from "react-icons/fa6";


const ContactUsViaMail = ({setIsOpen}) => {
  const { theme } = useSelector((state) => state.theme);
  const { userInfo } = useSelector((state) => state?.auth || {});
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  const handleSubmit = async (e) => {    
    e.preventDefault();
    if (!formData.name || !formData.email || !formData.message  || !formData.phone) {
      dispatch(addToast({ message:'All fileds required', type:'error' })); 
    }
setIsLoading(true)
    try {
     
      const response = await fetchApi.post('contact/', formData);
      
      if (response.status===200) {
        setIsOpen(false)
        dispatch(addToast({ message:'Message sent successfully', type:'success' })); 
        setFormData({
          name: "",
          email: "",
          phone:"",
          message: "",
        });
      } else {
        dispatch(addToast({ message:'Failed to send message. Please try again later.', type:'error' })); 
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(toggleAuth({ isLogin: false, userInfo: null }));
        dispatch(addToast({ message : 'Your session expired', type :'error' })); 
        return;
      }
      dispatch(addToast({ message:'Something went wrong', type :'error' })); 
    }
    setIsLoading(false)
  };


  return (
    <>
    
    <style>{`
        .input,
  .textarea {
    width: 100%;
    padding: 0.75rem;
    border-radius: 0.25rem;
    background-color:${theme === 'dark' ? '#333333' : '#ffffff'} ;
    transform: scale(1.05);
    color:${theme === 'dark' ? '#ffffff' : '#000000'};
    border: none;
  }
  .textarea {
    height: 8rem;
    resize: none;
  }
  
    `}</style>
  
      <div className={styles.content}>
        <div className={styles.formContainer}>
          <form className={`${styles.contactForm} `} onSubmit={handleSubmit}>
            <div className={styles.formGroupContact}>
              <label htmlFor="name" className={styles.label}>
                <FaUser className="w-5 h-5 ml-2"/>
                Full Name
              </label>
              <input
                id="name"
                placeholder="Your full name"
                className={`border w-full rounded-[10px] p-3 outline-none ${theme === 'dark'?'bg-[#1E1E1E] border-[#555657]':''}`}
                value={formData.name}
                onChange={handleInputChange}
              />
            </div>
            <div className={styles.formGroupContact}>
              <label htmlFor="email" className={styles.label}>
                <MdEmail className="w-5 h-5 ml-2"/>
                Email Address
              </label>
              <input
                id="email"
                type="email"
                placeholder="Your email address"
                className={`border w-full p-3 rounded-[10px] outline-none ${theme === 'dark'?'bg-[#1E1E1E] border-[#555657]':''}`}
                value={formData.email}
                onChange={handleInputChange}
              />
            </div>
            <div className={styles.formGroupContact}>
              <label htmlFor="phone" className={styles.label}>
                <IoCall className="w-5 h-5 ml-2"/>
                Phone number
              </label>
              <input
                id="phone"
                type="number"
                placeholder="Your phone number"
                className={`border w-full rounded-[10px] p-3 outline-none ${theme === 'dark'?'bg-[#1E1E1E] border-[#555657]':''}`}
                value={formData.phone}
                onChange={handleInputChange}
                maxlength="20"
              />
            </div>
            <div className={styles.formGroupContact}>
              <label htmlFor="message" className={styles.label}>
                <FaMessage className="w-5 h-5 ml-2"/>
                Your Message
              </label>
              <textarea
                id="message"
                placeholder="Write your message"
                rows={3}
                className={`border w-full rounded-[10px] p-3 outline-none ${theme === 'dark'?'bg-[#1E1E1E] border-[#555657]':''}`}
                value={formData.message}
                onChange={handleInputChange}
              />
            </div>
           
            <button type="submit" className="heroButton w-full">
            {isLoading?"Sending...":"Send"}
            </button>
          </form>
        </div>
     
      </div>
   
   
    </>
  );
};

export default ContactUsViaMail;
