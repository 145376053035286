import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styles from "../Dashboard/users/user.module.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchApi } from "../../utlis/axios";
import Loader from "../Loader/Loader";
import { FaDownload, FaEye } from "react-icons/fa";
import { CiSearch } from "react-icons/ci";
import loader from '../../assets/loading-gif.gif'

import HourlyRate from "../Dashboard/Settings/hourlyRate/HourlyRate";
import dayjs from "dayjs";
import { addToast } from "../../Redux/Slices/toast-slice";

const Invoices = () => {
  const { theme } = useSelector((state) => state.theme);
  const { userInfo } = useSelector((state) => state?.auth || {});
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showHourRate, setShowHourRate] = useState();
  const [invoices, setInvoices] = useState([]);
  const [originalInvioces, setOriginalIvoices] = useState([]);
  const [next, setNext] = useState(null)
  const [prev, setPrev] = useState(null)
  const [oNext, setONext] = useState(null)
  const [oPrev, setOPrev] = useState(null)
  const [searching, setSearching] = useState(false)
  let isFetched = false;
  const getInvoice = async () => {
    if (isFetched) return;
    isFetched = true;
    setLoading(true);

    try {
      const result = await fetchApi.get("admin-invoice/", {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });
      if (result.status === 200) {
        //toast.success("Updated Successfully!");
        setNext(result.data.next)
        setPrev(result.data.previous)
        setInvoices(result.data.results);
        setONext(result.data.next)
        setOPrev(result.data.previous)
        //props.onCompleted(result);
      }
      setLoading(false);
    } catch (error) {
      dispatch(addToast({ message: error?.response?.data?.error || "failed", type: 'error' }));
      if (error.response.data.detail) {
        dispatch(addToast({ message: error.response.data.detail, type: 'error' }));
      }

      //toast.error(error?.response?.data?.error || "failed");
      setLoading(false);
    }
  };

  useEffect(() => {
    getInvoice();
  }, []);

  const handlePageChange = async (url) => {
    setLoading(true);
    try {
      const res = await fetchApi.get(url, {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });
      if (res.status === 200) {
        setInvoices(res.data.results)
        setOriginalIvoices(res.data.results)
        setNext(res.data.next)
        setPrev(res.data.previous)
        setONext(res.data.next)
        setOPrev(res.data.previous)
      } else {
        dispatch(addToast({ message: res.message, type: 'error' }));
      }
    } catch (error) {
      dispatch(addToast({ message: 'Something went wrong', type: 'error' }));
    } finally {
      setLoading(false)
    }
  };
 
  return (
    <>
      <ToastContainer />
      <style>
        {`
      .input {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
   border: ${theme === "dark" ? "1px solid #ffffff" : "1px solid #000000"};
  box-sizing: border-box;
  background-color: transparent;
  option{
     color: #000000; 
    }
}
      `}
      </style>
      {showHourRate && (
        <div className={styles.modalOverlay}>
          <div
            className={`${styles.modal} ${theme === "dark"
              ? "bg-[#212529] text-white"
              : "bg-[#e5e5e5] text-black"
              }`}
          >
            <HourlyRate setShowHourRate={setShowHourRate} />
          </div>{" "}
        </div>
      )}
      {loading && (
        <>
          <div
            className="fixed inset-0 bg-black bg-opacity-50 z-10"
            style={{ top: "95px" }}
          ></div>
          <div
            className="fixed inset-0 flex items-center justify-center z-20"
            style={{ top: "95px" }}
          >
            <Loader />
          </div>
        </>
      )}
      <div
        className={`${styles.main_Heading} ${theme === "dark" ? "text-white" : "text-black"
          }`}
      >
        Payments
      </div>
      <hr className="w-full" />
      <div
        className={` ${styles.outlet}  ${theme === "dark" ? "text-white" : "text-black"
          }`}
      >
        {isModalOpen && (
          <div className={styles.modalOverlay}>
            <div
              className={`${styles.modal} ${theme === "dark"
                ? "bg-[#212529] text-white"
                : "bg-[#e5e5e5] text-black"
                }`}
            ></div>
          </div>
        )}

        <>
          <div className="overflow-x-auto md:mt-4 mt-4">
            <div className={styles.tableResponsive}>
              <table className={styles.table}>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Amount Paid</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {invoices?.map((invoice, i) => (
                    <tr key={invoice?.id}>
                      <td>{dayjs(invoice?.date).format("DD MMMM YYYY")}</td>

                      <td> ${invoice?.amount_paid}</td>

                      <td>
                        <span className="flex gap-2 items-center">
                          <a
                            style={{ cursor: "pointer" }}
                            href={invoice?.url}
                            target="__blank"
                            className="text-success"
                          >
                            <FaDownload
                              className={`cursor-${userInfo?.user?.role?.name === "Admin"
                                ? "pointer"
                                : "not-allowed"
                                }`}
                            />
                          </a>
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </>

        <div className="w-full justify-between flex">
          <span>
            {prev && (
              <button
                className="heroButton block"
                onClick={() => handlePageChange(prev)}
              >
                Previous
              </button>
            )}
          </span>
          <span>
            {next && (
              <button
                className="heroButton block"
                onClick={() => handlePageChange(next)}
              >
                Next
              </button>
            )}
          </span>
        </div>
      </div>
    </>
  );
};

export default Invoices;
