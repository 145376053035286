import React,{useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { MdOutlineTask } from "react-icons/md";
import { FiRefreshCw } from "react-icons/fi";
import { fetchApi } from "../../../utlis/axios";
import { addToast } from '../../../Redux/Slices/toast-slice';
import SemiCircularProgressBar from "./charts/ProgressChart";
import loader from "../../../assets/loading-gif.gif";

const TaskProgress = ({ totalTasks, completed, remaining, onLoadingChange }) => {
  const { theme } = useSelector((state) => state.theme);
   const { userInfo } = useSelector((state) => state?.auth || {});
   const [taskData, setTaskData]=useState({
    progress:0,
    totalTasks:0,
    completed:0,
    remaining:0
   })
   const [isRefreshing, setIsRefreshing] = useState(false);

  const dispatch=useDispatch()
  const radius = 100;
  const strokeWidth = 12;
  const circumference = Math.PI * radius;
  const strokeDashoffset = circumference * (1 - taskData.progress / 100);


  // Tick marks and labels order (left to right)
  const tickMarks = Array.from({ length: 25 }, (_, i) => i * (180 / 24));
  const labels = [0, 25, 50, 75, 100];

  const getData = async () => {
    try {
      onLoadingChange(true);
      const res = await fetchApi.get('admin-dashboard-task-progress/', {
        headers: {
          'Authorization': `Bearer ${userInfo?.access_token}`
        }
      });
      if(res.status === 200){
        const taskData = {
          completed_tasks: res.data.completed_tasks,
          remaining_tasks: res.data.remaining_tasks,
          total_tasks: res.data.total_tasks,
        };
        const progress=await calculateProgress(taskData)
        setTaskData({
          progress,
          totalTasks: taskData.total_tasks,
          completed: taskData.completed_tasks,
          remaining: taskData.remaining_tasks
        })
        
      }
    } catch (error) {
        dispatch(addToast({
            message: error?.response?.data?.message || 'Something went wrong',
            type: 'error'
        }))
    } finally {
      onLoadingChange(false);
    }
}

const refreshData = async () => {
  setIsRefreshing(true);
  await getData();
  setIsRefreshing(false);
};

useEffect(() => {
    getData()
}, [])
const calculateProgress = ({ completed_tasks, total_tasks }) => {
  if (total_tasks === 0) return 0; // Avoid division by zero
  return Math.round((completed_tasks / total_tasks) * 100); 
};

  return (
    <div className={`relative shadow-lg flex flex-col justify-center items-center py-4 px-4 rounded-[20px] customFont ${theme === 'dark' ? 'bg-[#1C1C1E] text-white' : 'bg-white text-black'}`}>
      {/* Header */}
      <div className='flex justify-between items-center w-full mb-4'>
        <div className='flex gap-2 items-center'>
          <span className='bg-gradient-to-r from-[#CC0927] to-[#7E0105] p-2 rounded-[10px]'>
            <MdOutlineTask className='w-6 h-6 text-white' />
          </span>
          <h1 className='text-lg font-semibold'>Task Progress</h1>
        </div>
        <div 
          className={`p-2 flex items-center justify-center rounded-[10px] border cursor-pointer ${theme === 'dark' ? 'bg-[#252529] border-[#555657]' : 'bg-[#F2F4FA] border-[#D8D8D8]'}`}
          onClick={refreshData}
        >
          {isRefreshing ? (
            <img src={loader} alt="Loading..." className="w-5 h-5" />
          ) : (
            <FiRefreshCw className={`w-5 h-5`} />
          )}
        </div>
      </div>
    {/*}  <svg width={radius * 2 + 40} height={radius + 40} viewBox={`0 0 ${radius * 2 + 40} ${radius + 40}`}>
  {/* Base Arc (Static) 
  <circle
    cx={(radius * 2 + 40) / 2}
    cy={radius + 20}
    r={radius}
    fill="transparent"
    stroke={theme === "dark" ? "#7E0105" : "#7E0105"}
    strokeWidth={strokeWidth}
    strokeLinecap="round"
    strokeDasharray={circumference}
    strokeDashoffset={circumference * 0.5} // Half the circumference for a semi-circle
    transform={`rotate(-90 ${(radius * 2 + 40) / 2} ${radius + 20})`} // Start from the left
  />

  {/* Progress Arc (Animated) *
  <motion.circle
    cx={(radius * 2 + 40) / 2}
    cy={radius + 20}
    r={radius}
    fill="transparent"
    stroke={theme === "dark" ? "#CC0927" : "#CC0927"}
    strokeWidth={strokeWidth}
    strokeLinecap="round"
    strokeDasharray={circumference}
    strokeDashoffset={circumference * (1 - taskData.progress / 100)} // Corrected calculation
    transform={`rotate(-90 ${(radius * 2 + 40) / 2} ${radius + 20})`} // Start from the left
    initial={{ strokeDashoffset: circumference }} // Start from 0%
    animate={{ strokeDashoffset: circumference * (1 - taskData.progress / 100) }} // Animate to progress%
    transition={{ duration: 2.5, ease: "easeInOut" }}
  />

  {/* Tick Marks and Labels (unchanged) *
  {tickMarks.map((angle, index) => {
    const x1 = (radius + 20) + (radius + 10) * Math.cos((angle * Math.PI) / 180);
    const y1 = (radius + 20) - (radius + 10) * Math.sin((angle * Math.PI) / 180);
    const x2 = (radius + 20) + (radius + 20) * Math.cos((angle * Math.PI) / 180);
    const y2 = (radius + 20) - (radius + 20) * Math.sin((angle * Math.PI) / 180);
    return <line key={index} x1={x1} y1={y1} x2={x2} y2={y2} stroke="#666" strokeWidth={2} />;
  })}

  {/* Labels (unchanged) *
  {labels.map((label, index) => {
    const angle = 180 - index * (180 / (labels.length - 1));
    const x = (radius + 20) + (radius + 30) * Math.cos((angle * Math.PI) / 180);
    const y = (radius + 20) - (radius + 35) * Math.sin((angle * Math.PI) / 180);
    return (
      <text
        key={index}
        x={x}
        y={y}
        textAnchor="middle"
        fontSize={12}
        fill={theme === "dark" ? "white" : "black"}
      >
        {label}
      </text>
    );
  })}
</svg>

      {/* Center Progress Text *
      <div className="absolute top-[50%] text-center">
        <p className="text-3xl font-bold" style={{ color: theme === "dark" ? "white" : "black" }}>{taskData.progress}%</p>
        <p className="text-sm text-gray-400">Completed</p>
      </div>

      {/* Task Stats */}
      <SemiCircularProgressBar percentage={taskData.progress} />
     
      <div className="w-full flex justify-around mt-4">
        <div className="text-center">
          <p className="text-lg font-bold" style={{ color: theme === "dark" ? "white" : "black" }}>{taskData?.totalTasks}</p>
          <p className="text-xs text-gray-400">Total Tasks</p>
        </div>
        <div className="text-center">
          <p className="text-lg font-bold text-[#CC0927]">{taskData?.completed}</p>
          <p className="text-xs text-gray-400">Completed</p>
        </div>
        <div className="text-center">
          <p className="text-lg font-bold text-[#7E0105]">{taskData?.remaining}</p>
          <p className="text-xs text-gray-400">Remaining</p>
        </div>
      </div>
    </div>
  );
};

export default TaskProgress;
