import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IoIosArrowForward } from "react-icons/io";
import "react-datepicker/dist/react-datepicker.css";
import { IoAdd, IoClose } from "react-icons/io5";
import { Link } from "react-router-dom";
import { FaTasks, FaCheckCircle, FaPause } from "react-icons/fa";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { FaCalendarAlt } from "react-icons/fa";
import { GoProject } from "react-icons/go";
import { fetchApi } from "../../../utlis/axios";
import DropZone from "./DropZone";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Loader from "../../Loader/Loader";
import { FcCancel } from "react-icons/fc";
import {
  updateColumns,
  updateProject,
  updateTickets,
} from "../../../Redux/Slices/project-slice";
import TicketModal from "./TicketModal";
import Calendar from "./Calendar";
import RichTextEditor1 from "./RichTextEditor1";
import { addToast } from "../../../Redux/Slices/toast-slice";

const Board = () => {
  const { theme } = useSelector((state) => state.theme);
  const { userInfo } = useSelector((state) => state?.auth || {});
  const [isModalOpen, setShowModal] = useState(false);
  const [ticketModal, setTicketModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState("in-progress");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [creating, setCreating] = useState(false);
  const [columTitle, setColumTitle] = useState(null);
  const [columnModal, setColumnModal] = useState(false);
  const [columns, setColumns] = useState([
    { company: userInfo?.company?.id, id: "1", title: "todo" },
    { company: userInfo?.company?.id, id: "2", title: "ready for qa" },
    { company: userInfo?.company?.id, id: "3", title: "done" },
  ]);

  const [newTicketColumnId, setNewTocketColumnId] = useState(null);
  const [projectData, setProjectData] = useState({
    title: "",
    description: "",
    startDate: "",
    endDate: "",
    url: "",
    budget: "",
  });
  const [startDateDropdown, setStartDateDropdown] = useState(false);
  const [endDateDropdown, setEndDateDropdown] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ticketsLoading, setTicketsLoading] = useState(true);
  const [firstProjectId, setFirstProjectId] = useState(
    localStorage.getItem("firstProjectId")
  );
  const [projects, setProjects] = useState([]);
  const [tickets, setTickets] = useState([
    { id: "1", ticket_status: "1" },
    { id: "2", ticket_status: "2" },
    { id: "3", ticket_status: "3" },
  ]);
  const dispatch = useDispatch();
  const [openedProject, setOpenedProject] = useState("");
  const [newTicketStatus, setNewTicketStatus] = useState("todo");
  const [draggingColumn, setDraggingColumn] = useState(null);

  const options = [
    { label: "canceled", icon: <FcCancel className="w-4 h-4 mr-2" /> },
    { label: "paused", icon: <FaPause className="w-4 h-4 mr-2" /> },
    { label: "in-progress", icon: <FaTasks className="w-4 h-4 mr-2" /> },
    { label: "completed", icon: <FaCheckCircle className="w-4 h-4 mr-2" /> },
  ];

  const handleSubmit = async (e) => {
    if (
      !projectData.title ||
      !projectData.description ||
      !projectData.startDate
    ) {
      dispatch(addToast({ message: "Please fill all fields", type: "error" }));
      return;
    }
    setCreating(true);
    try {
      const formdata = new FormData();
      formdata.append("title", projectData.title);
      formdata.append("description", projectData.description);
      formdata.append("start_date", projectData.startDate);
      formdata.append("end_date", projectData.endDate);
      //  formdata.append("url", projectData.url);
      formdata.append("budget", projectData.budget);
      formdata.append("status", selectedOption);
      formdata.append("company", userInfo?.company?.id);
      formdata.append("is_active", true);
      const res = await fetchApi.post("/projects/", formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });
      if (res.status === 201) {
        updateProjects();
        setShowModal(false);
        dispatch(
          addToast({ message: "Project created successfully", type: "success" })
        );
        setProjectData({
          title: "",
          description: "",
          startDate: new Date(),
          endDate: "",
          url: "",
          budget: "",
        });
      }
    } catch (error) {
      dispatch(addToast({ message: error.message, type: "error" }));
    } finally {
      setCreating(false);
    }
  };

  const handleCreate = (column) => {
    setTicketModal(true);
    setNewTocketColumnId(column);
  };

  const moveCard = (id, newStatus) => {
    setTickets((prev) =>
      prev.map((ticket) =>
        ticket.id === id
          ? {
              ...ticket,
              ticket_status: { ...ticket.ticket_status, id: newStatus },
            }
          : ticket
      )
    );
    handleUpdateCard(id, newStatus);
  };

  const handleUpdateCard = async (id, status) => {
    try {
      const res = await fetchApi.patch(
        `project-tickets/${id}/`,
        { ticket_status: status },
        {
          headers: {
            Authorization: `Bearer ${userInfo.access_token}`,
          },
        }
      );
      if (res.status === 200) {
      }
    } catch (error) {
      dispatch(addToast({ message: error.message, type: "error" }));
    }
  };

  const getProjects = async () => {
    setLoading(true);
    try {
      const res = await fetchApi.get("projects/", {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });
      if (res.status === 200) {
        setProjects(res.data.results);
        if (!firstProjectId) {
          getTickets(res.data.results[0].id);
        }
        setOpenedProject(res.data.results[0].id);
        localStorage.setItem("firstProjectId", res.data.results[0].id);
        if (res.data.next) {
          getNextProjects(res.data.next);
        }
      }
    } catch (error) {
      dispatch(addToast({ message: error.message, type: "error" }));
    } finally {
      setLoading(false);
    }
  };
  const getNextProjects = async (url) => {
    try {
      const res = await fetchApi.get(url, {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });
      if (res.status === 200) {
        setProjects((prev) => [...prev, ...res.data.results]);
      }
    } catch (error) {
      dispatch(addToast({ message: error.message, type: "error" }));
    }
  };

  const updateProjects = async () => {
    try {
      const res = await fetchApi.get("projects/", {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });
      if (res.status === 200) {
        setProjects(res.data.results);
        setOpenedProject(res.data.results[0].id);
      }
    } catch (error) {
      dispatch(addToast({ message: error.message, type: "error" }));
    }
  };
  const getTickets = async (id) => {
    setLoading(true);
    setTicketsLoading(true);
    try {
      const res = await fetchApi.post(
        `get-project-tickets/`,
        { project_id: id },
        {
          headers: {
            Authorization: `Bearer ${userInfo.access_token}`,
          },
        }
      );
      if (res.status === 200) {
        setTicketsLoading(false);
        setTickets(res.data);
      }
    } catch (error) {
      dispatch(addToast({ message: error.message, type: "error" }));
    } finally {
      setLoading(false);
    }
  };
  let projectFetched = useRef(false);
  useEffect(() => {
    if (projectFetched.current) return;
    projectFetched.current = true;
    getProjects();
    if (firstProjectId) {
      getTickets(firstProjectId);
    }
  }, []);

  const getCompanyColums = async () => {
    try {
      const res = await fetchApi.get(
        `company-board-columns/?company=${userInfo.company.id}`,
        {
          headers: {
            Authorization: `Bearer ${userInfo.access_token}`,
          },
        }
      );
      if (res.status === 200) {
        const data = res.data.results.sort((a, b) => a.order - b.order);
        setColumns(data);
      }
    } catch (e) {
      dispatch(addToast({ message: "Something went wrong", type: "error" }));
    }
  };

  const handleColumCreate = async () => {
    setCreating(true);
    let columorder = columns.length;
    try {
      const res = await fetchApi.post(
        "company-board-columns/",
        {
          company: userInfo.company.id,
          title: columTitle,
          order: columorder,
        },
        {
          headers: {
            Authorization: `Bearer ${userInfo.access_token}`,
          },
        }
      );
      if (res.status === 201) {
        setColumns((prev) => [...prev, res.data]);
        dispatch(addToast({ message: "Column created", type: "success" }));
        setColumnModal(false);
      }
    } catch (eror) {
      dispatch(addToast({ message: "Something went wrong", type: "error" }));
    } finally {
      setCreating(false);
    }
  };
  useEffect(() => {
    getCompanyColums();
  }, []);

  const moveColumn = (fromIndex, toIndex, columnId) => {
    setColumns((prevColumns) => {
      const updatedColumns = [...prevColumns];
      const [movedColumn] = updatedColumns.splice(fromIndex, 1); // Remove the column
      updatedColumns.splice(toIndex, 0, movedColumn); // Insert at new position
      return updatedColumns;
    });
  };
  const handleUpdateColumePosition = async (columnId, order) => {
    try {
      const res = await fetchApi.patch(
        `company-board-columns/${columnId}/`,
        { order: order },
        {
          headers: {
            Authorization: `Bearer ${userInfo.access_token}`,
          },
        }
      );
    } catch (error) {}
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="w-full customFont">
        <div
          className={` ${theme === "dark" ? "text-white" : "text-black"} px-6`}
          style={{
            width: "90%",
            maxWidth: "1020px",
            fontSize: "x-large",
            margin: "20px 0px",
          }}
        >
          Projects
        </div>
        <hr className="w-full" />
        {loading && (
          <>
            <div
              className="fixed inset-0 bg-black bg-opacity-50 z-10"
              style={{ top: "95px" }}
            ></div>
            <div
              className="fixed inset-0 flex items-center justify-center z-20"
              style={{ top: "95px" }}
            >
              <Loader />
            </div>
          </>
        )}
        <div className="w-full flex md:flex-row flex-col gap-4 lg:px-8 px-4 py-2 relative">
          <div className="md:w-1/4 w-full mt-4">
            <button
              className={`heroButton mb-2 w-full`}
              onClick={() => setShowModal(true)}
            >
              Create Project
            </button>
            <div className="md:max-h-[80vh] max-h-[40vh] overflow-y-auto">
              {projects?.map((project) => (
                <div
                  key={project.id}
                  className={`p-4 mb-2 rounded-md shadow-md cursor-pointer ${
                    openedProject === project.id
                      ? theme === "dark"
                        ? "bg-gradient-to-r from-[#fff3] to-[#fff0] transition-all text-white"
                        : "bg-gradient-to-r from-[#6d6d6d33] to-[#fff0] transition-all text-black"
                      : theme === "dark"
                      ? "text-white bg-darkMode"
                      : "text-black bg-slate-50"
                  } ${
                    theme === "dark"
                      ? "hover:bg-gradient-to-r hover:from-[#fff3] hover:to-[#fff0] transition-all"
                      : "hover:bg-gradient-to-r hover:from-[#6d6d6d33] hover:to-[#fff0] transition-all"
                  } ${openedProject}`}
                  onClick={() => {
                    getTickets(project.id);
                    setOpenedProject(project.id);
                  }}
                >
                  <div className="flex gap-2 items-center">
                    <GoProject />
                    <span className="text-base font-normal">
                      {project.title}
                    </span>
                  </div>
                  <div className="flex w-full justify-between items-center">
                    <div className="flex gap-2 mt-2 items-center">
                      <FaCalendarAlt />
                      <span className="text-xs ">{project.start_date}</span>
                    </div>
                    <Link
                      to={`/dashboard/project/${project.id}`}
                      className={`cursor-pointer text-base font-semibold ${
                        theme === "dark"
                          ? "text-gray-300 hover:text-white"
                          : "text-gray-600 hover:text-black"
                      }`}
                      onClick={() => dispatch(updateProject({ project }))}
                    >
                      Detail
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div
            className="cards_container overflow-x-scroll flex gap-4 md:w-3/4 w-full mt-8"
            style={{ minWidth: "75%" }}
            onDragOver={(e) => {
              e.preventDefault();

              const container = e.currentTarget;
              const scrollSpeed = 5; // Adjust speed for smooth scrolling
              const { left, right } = container.getBoundingClientRect();

              // Start scrolling left if cursor is near the left edge
              if (e.clientX < left + 50) {
                if (!container.scrollInterval) {
                  container.scrollInterval = setInterval(() => {
                    container.scrollBy({ left: -scrollSpeed });
                  }, 10);
                }
              }
              // Start scrolling right if cursor is near the right edge
              else if (e.clientX > right - 50) {
                if (!container.scrollInterval) {
                  container.scrollInterval = setInterval(() => {
                    container.scrollBy({ left: scrollSpeed });
                  }, 10);
                }
              } else {
                // Clear the interval if not near the edges
                clearInterval(container.scrollInterval);
                container.scrollInterval = null;
              }
            }}
            onDragLeave={(e) => {
              // Clear scrolling when leaving the container
              const container = e.currentTarget;
              clearInterval(container.scrollInterval);
              container.scrollInterval = null;
            }}
            onDrop={(e) => {
              // Clear scrolling when dropping a card
              const container = e.currentTarget;
              clearInterval(container.scrollInterval);
              container.scrollInterval = null;
            }}
          >
            <div className="flex gap-4 overflow-x-auto">
              {columns?.map((column, index) => (
                <DropZone
                  key={column.id}
                  index={index}
                  column={column}
                  tickets={tickets?.filter(
                    (ticket) => ticket?.ticket_status?.id === column.id
                  )}
                  setTickets={setTickets}
                  ticketsLoading={ticketsLoading}
                  moveCard={moveCard}
                  moveColumn={moveColumn}
                  theme={theme}
                  handleCreate={handleCreate}
                  setDraggingColumn={setDraggingColumn} // Pass function to track dragging
                  draggingColumn={draggingColumn}
                  handleUpdateColumePosition={handleUpdateColumePosition} // Track which column is being dragged
                />
              ))}
            </div>
          </div>
          {userInfo.user.role.name === "Admin" && (
            <button
              className={`absolute top-3 shadow-lg right-0 z-10 py-2 px-3 text-xl rounded-l-md ${
                theme === "dark"
                  ? "bg-darkMode text-white"
                  : "bg-white text-black"
              }`}
              onClick={() => setColumnModal(true)}
            >
              +
            </button>
          )}
        </div>
        {isModalOpen && (
          <>
            {/* Overlay */}
            <div className="fixed top-0 left-0 h-full w-full  inset-0 bg-black bg-opacity-50 z-10"></div>

            {/* Modal Content */}
            <div className="fixed inset-0 flex items-center justify-center z-20 ">
              <div
                className={` p-6 rounded-lg shadow-lg max-w-screen-md w-full ${
                  theme === "dark"
                    ? "bg-darkMode text-white"
                    : "bg-lightMode text-black"
                }`}
              >
                <div className="flex justify-between">
                  <div className="flex gap-2 items-center">
                    <IoIosArrowForward className="w-4 h-4" />
                    <h2 className="text-base font-semibold">New Project</h2>
                  </div>
                  <IoClose
                    className="w-6 h-6"
                    onClick={() => {
                      setShowModal(false);
                      setProjectData({
                        title: "",
                        budget: "",
                        url: "",
                        description: "",
                        startDate: "",
                        endDate: "",
                      });
                    }}
                  />
                </div>
                <label htmlFor="" className="font-semibold mt-6">
                  Title
                </label>
                <input
                  type="text"
                  className={`text-2xl font-normal border-none mt-1 py-1 bg-transparent outline-none w-full ${
                    theme === "dark"
                      ? "placeholder:text-gray-600"
                      : "placeholder:text-gray-400"
                  }`}
                  value={projectData.title}
                  onChange={(e) =>
                    setProjectData({ ...projectData, title: e.target.value })
                  }
                  placeholder="Enter title of project"
                />
                <label htmlFor="" className="font-semibold mt-4">
                  Budget
                </label>
                <input
                  type="text"
                  className={`text-base font-normal border-none bg-transparent py-1 outline-none mt-1 w-full ${
                    theme === "dark"
                      ? "placeholder:text-gray-600"
                      : "placeholder:text-gray-400"
                  }`}
                  value={projectData.budget}
                  onChange={(e) =>
                    setProjectData({ ...projectData, budget: e.target.value })
                  }
                  placeholder="Budget of project"
                />
                <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-2 mb-3 gap-3">
                  <div className="flex gap-1 items-center">
                    <label htmlFor="status">Status:</label>
                    <div
                      className={`relative flex items-center w-full justify-between border rounded-md px-2 py-2 cursor-pointer ${
                        theme === "dark" ? "border-gray-600" : "border-gray-400"
                      }`}
                      onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                    >
                      <div className="flex items-center">
                        {
                          options.find(
                            (option) => option.label === selectedOption
                          )?.icon
                        }
                        <span className=" capitalize">{selectedOption}</span>
                      </div>
                      {isDropdownOpen ? (
                        <FaChevronUp className="w-4 h-4 " />
                      ) : (
                        <FaChevronDown className="w-4 h-4" />
                      )}
                      {isDropdownOpen && (
                        <div
                          className={`absolute top-full left-0 mt-2 w-44 shadow-lg rounded-md z-10 ${
                            theme === "dark"
                              ? "bg-dashboardDarkMode text-white"
                              : "bg-lightMode text-black"
                          }`}
                        >
                          {options.map((option) => (
                            <div
                              key={option.label}
                              className={`flex items-center px-3 py-2 hover:bg-gray-200 cursor-pointer ${
                                theme === "dark" ? "hover:bg-gray-700" : ""
                              }`}
                              onClick={() => {
                                setSelectedOption(option.label);
                                setIsDropdownOpen(false);
                              }}
                            >
                              {option.icon}
                              <span className="capitalize">{option.label}</span>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="flex gap-1 items-center">
                    <label htmlFor="start date" className=" whitespace-nowrap">
                      Start Date:
                    </label>
                    <div
                      className={`cursor-pointer relative bg-transparent w-full flex justify-between items-center outline-none border px-2 py-2 rounded-md ${
                        theme === "dark" ? "border-gray-600" : "border-gray-400"
                      }`}
                    >
                      <span
                        className="w-full flex items-center justify-between"
                        onClick={() => {
                          setStartDateDropdown(!startDateDropdown);
                          setEndDateDropdown(false);
                        }}
                      >
                        {projectData.startDate.length > 0
                          ? projectData.startDate
                          : "Choose date"}{" "}
                        <button>
                          {startDateDropdown ? (
                            <FaChevronUp className="w-4 h-4 " />
                          ) : (
                            <FaChevronDown className="w-4 h-4" />
                          )}
                        </button>
                      </span>
                      {startDateDropdown && (
                        <Calendar
                          theme={theme}
                          onDateSelect={(date) => {
                            projectData.startDate = date;
                            setStartDateDropdown(false);
                          }}
                        />
                      )}
                    </div>
                  </div>
                  <div className="flex gap-1 items-center">
                    <label htmlFor="End Date" className="whitespace-nowrap">
                      End Date:
                    </label>
                    <div
                      className={`relative cursor-pointer bg-transparent w-full outline-none border px-2 py-2 rounded-md ${
                        theme === "dark" ? "border-gray-600" : "border-gray-400"
                      }`}
                    >
                      <span
                        className="w-full flex items-center justify-between"
                        onClick={() => {
                          setEndDateDropdown(!endDateDropdown);
                          setStartDateDropdown(false);
                        }}
                      >
                        {projectData.endDate.length > 0
                          ? projectData.endDate
                          : "Choose date"}{" "}
                        <button>
                          {endDateDropdown ? (
                            <FaChevronUp className="w-4 h-4 " />
                          ) : (
                            <FaChevronDown className="w-4 h-4" />
                          )}
                        </button>
                      </span>
                      {endDateDropdown && (
                        <Calendar
                          theme={theme}
                          onDateSelect={(date) => {
                            projectData.endDate = date;
                            setEndDateDropdown(false);
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <label htmlFor="" className="font-semibold mt-4">
                  Description
                </label>

                <RichTextEditor1
                  setProjectData={setProjectData}
                  projectData={projectData}
                />
                <div className="flex gap-2 justify-end pt-4">
                  <button
                    className={` border rounded-3xl px-3 py-2 bg-transparent  ${
                      theme === "dark" ? "border-gray-600" : "border-gray-400"
                    }`}
                    onClick={() => {
                      setShowModal(false);
                      setProjectData({
                        title: "",
                        budget: "",
                        url: "",
                        description: "",
                        startDate: "",
                        endDate: "",
                      });
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="heroButton"
                    disabled={creating}
                    onClick={handleSubmit}
                  >
                    {creating ? "Creating..." : "Create"}
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
        {columnModal && (
          <>
            {/* Overlay */}
            <div className="fixed inset-0 bg-black bg-opacity-50 z-10"></div>

            {/* Modal Content */}
            <div className="fixed inset-0 flex items-center justify-center z-20 ">
              <div
                className={` p-6 rounded-lg shadow-lg max-w-md w-full max-h-[80vh] overflow-auto ${
                  theme === "dark"
                    ? "bg-darkMode text-white"
                    : "bg-lightMode text-black"
                }`}
              >
                <div className="flex justify-between">
                  <div className="flex gap-2 items-center">
                    <IoIosArrowForward className="w-4 h-4" />
                    <h2 className="text-base font-semibold">New Column</h2>
                  </div>
                  <IoClose
                    className="w-6 h-6"
                    onClick={() => {
                      setColumTitle(null);
                      setColumnModal(false);
                    }}
                  />
                </div>
                <label htmlFor="" className="font-semibold mt-6">
                  Title
                </label>
                <input
                  type="text"
                  className={`text-base font-normal border-none mt-1 py-1 bg-transparent outline-none w-full ${
                    theme === "dark"
                      ? "placeholder:text-gray-600"
                      : "placeholder:text-gray-400"
                  }`}
                  value={columTitle}
                  onChange={(e) => setColumTitle(e.target.value)}
                  placeholder="Enter title of colum"
                />
                <div className="flex gap-2 justify-end pt-4">
                  <button
                    className={` border rounded-3xl px-3 py-2 bg-transparent  ${
                      theme === "dark" ? "border-gray-600" : "border-gray-400"
                    }`}
                    onClick={() => {
                      setColumTitle(null);
                      setColumnModal(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="heroButton"
                    disabled={creating}
                    onClick={handleColumCreate}
                  >
                    {creating ? "Creating..." : "Create"}
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
        {ticketModal && (
          <TicketModal
            setTicketModal={setTicketModal}
            newTicketColumnId={newTicketColumnId}
            ticketStatus={newTicketStatus}
            openedProject={openedProject}
            updateTickets={getTickets}
            setTickets={setTickets}
          />
        )}
      </div>
    </DndProvider>
  );
};

export default Board;
