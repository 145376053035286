import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { IoIosArrowDown } from "react-icons/io";
import { fetchApi } from "../../../utlis/axios";
import BackBtn from "../../backBtn/BackBtn";
import { togglePlan } from "../../../Redux/Slices/plans-slice";
import { toggleSignUp } from "../../../Redux/Slices/signup-slice";
import ParticlesBackground from "../../home/ParticalsBackground";
import { FaEye, FaEyeSlash, FaRegUser } from "react-icons/fa";
import { addToast } from "../../../Redux/Slices/toast-slice";
import { TbUsersPlus, TbWorld } from "react-icons/tb";
import { MdOutlineEmail } from "react-icons/md";
import { CiLock, CiUnlock } from "react-icons/ci";
import { RiProgress1Line } from "react-icons/ri";
import { GiCheckMark } from "react-icons/gi";

const SignUp = ({ parent }) => {
  const { planId } = useParams();
  const { theme } = useSelector((state) => state.theme);
  const { plan } = useSelector((state) => state.plan);
  const plans = useSelector((state) => state.allPlans.plans);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [formValues, setFormValues] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    confirmPassword: "",
    domain: "",
    role: "",
    plan: "",
    quantity: "2",
  });
  const [role, setRole] = useState();
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [isChecked, setIsChecked] = useState();
  const [isOpen, setIsOpen] = useState(false);

  const handlePlanChange = (e) => {
    dispatch(togglePlan({ plan: e }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });

    if (name === "password") {
      calculatePasswordStrength(value);
    }
  };

  useEffect(() => {
    if (planId && parent === "enterprice") {
      setFormValues((prevState) => ({
        ...prevState,
        plan: planId,
      }));
    } else {
      setFormValues((prevState) => ({
        ...prevState,
        plan: plan,
      }));
    }
  }, [planId, parent, plan]);

  const calculatePasswordStrength = (password) => {
    let strength = 0;
    if (password.length >= 8) strength++;
    if (/[A-Z]/.test(password)) strength++;
    if (/[a-z]/.test(password)) strength++;
    if (/\d/.test(password)) strength++;
    if (/[@$!%*?&]/.test(password)) strength++;
    setPasswordStrength(strength);
  };

  const handleGetRoles = async () => {
    try {
      const result = await fetchApi.get("roles/");
      if (result.status === 200) {
        const data = result.data.results;
        const userRole = data.find((item) => item.name === "Admin");
        setRole(userRole?.id);
        setFormValues((prevState) => ({
          ...prevState,
          role: userRole?.id,
        }));
      }
    } catch (error) {
      dispatch(addToast({ message: 'Something went wrong', type: 'error' }));
      console.error("Error getting roles: ", error);
    }
  };
  useEffect(() => {
    const fetchRolesAndSetRole = async () => {
      try {
        const result = await fetchApi.get("roles/");
        if (result.status === 200) {
          const data = result.data.results;
          const userRole = data.find((item) => item.name === "Admin");
          setRole(userRole?.id);
          setFormValues((prevState) => ({
            ...prevState,
            role: userRole?.id,
          }));
        }
      } catch (error) {
        dispatch(addToast({ message: 'Something went wrong', type: 'error' }));
        console.error("Error getting roles: ", error);
      }
    };

    fetchRolesAndSetRole();
  }, []);

  const handleSignUp = async (e) => {
    e.preventDefault();

    if (
      !formValues.first_name ||
      !formValues.last_name ||
      !formValues.email ||
      !formValues.password ||
      !formValues.confirmPassword ||
      !formValues.domain ||
      !formValues.quantity
    ) {
      dispatch(addToast({ message: 'All fields required', type: 'error' }));
      return;
    }

    if (formValues.password.length < 8) {
      dispatch(addToast({ message: 'Password must be least 8 Characters', type: "error" }));
      return;
    }
    if (formValues.password !== formValues.confirmPassword) {
      dispatch(addToast({ message: 'Password not matched', type: 'error' }));
      return;
    }
    if (!isChecked) {
      dispatch(addToast({ message: 'Please accept the terms and conditions', type: 'error' }));
      return;
    }
    setLoading(true);
    dispatch(toggleSignUp({ signupInfo: formValues }));
    navigate("/payment");
    setLoading(false);
  };

  useEffect(() => {
    //handleGetRoles();
  }, []);

  return (
    <>
      <style>{`
        .login-box {
          background-color:${theme === "dark" ? "rgba(33, 33, 33, 0.9)" : "#dfdfdf"
        };
          color:${theme === "dark" ? "#ffffff" : "#000000"};
        }
        .input-field {
          background-color:${theme === "dark" ? "#444" : "#ffffff"};
          color:${theme === "dark" ? "#ffffff" : "#000000"};
        }
        .forgot-link, .terms-text {
          color:${theme === "dark" ? "#ccc" : "#000000"};
        }
        .terms-link {
          color:${theme === "dark" ? "#ffffff" : "#ff2f2f"};
        }
        .password-strength-container {
          width: 100%;
          height: 6px;
          background-color: #ddd;
          border-radius: 3px;
          margin-top: 5px;
        }
        .password-strength {
          height: 100%;
          border-radius: 3px;
          transition: width 0.3s ease;
        }
      `}</style>
      <ParticlesBackground id="particaljs" />
      <div className="login-container">
        <BackBtn bgColor={"red"} />
        <div className="flex justify-center w-full ">
          <div
            className={`signup-box max-w-screen-lg rounded-[20px] fontPoppins ${theme === "dark"
              ? "text-[#FFFFFF] bg-[#1E1E1E]/80"
              : "text-[#000000] bg-[#dfdfdf]/80"
              }`}
          >
            <div className="flex w-full justify-between items-center py-3 sm:px-3">
              <div className="flex items-center gap-2 cursor-pointer">
                <GiCheckMark className="sm:w-5 sm:h-5 w-3 h-3 text-green-500"/>
                <div className="flex flex-col">
                  <span className="sm:text-base text-sm">Step 1</span>
                  <span className="sm:text-sm text-xs">Subscription Plan</span>
                </div>
              </div>
              <div className="flex flex-col border-b-[3px] border-red-600 py-3 cursor-pointer">
                <span className="sm:text-base text-sm">Step 2</span>
                <span className="sm:text-sm text-xs">Create Account</span>
              </div>
              <div className={`flex flex-col py-3 cursor-pointer ${theme === 'dark' ? 'text-gray-400' : 'text-gray-600'}`}>
                <span className="sm:text-base text-sm">Step 3</span>
                <span className="sm:text-sm text-xs" >Complete Payment</span>
              </div>
              <div className={`flex flex-col py-3 cursor-pointer ${theme === 'dark' ? 'text-gray-400' : 'text-gray-600'}`}>
                <span className="sm:text-base text-sm">Step 4</span>
                <span className="sm:text-sm text-xs">Register Company</span>
              </div>
            </div>
            <hr className={`mt-[-12px] border-t ${theme === 'dark' ? 'border-[#556557]' : 'border-[#212529]'}`} />

            <h2 className="main-heading text-center mt-3">Create an account</h2>
            <form onSubmit={handleSignUp}>
              <div className="grid sm:grid-cols-2 grid-cols-1  gap-4">
                {parent !== "enterprice" && (
                  <>
                    <div className="input-group">
                      <label className="mb-2 text-sm flex items-center gap-2">
                        <RiProgress1Line className="w-6 h-6" />
                        Your selected plan</label>
                      <div className="custom_dropdown">
                        <span
                          className={`flex items-center justify-between w-full p-2 h-10 border rounded-[10px] outline-none ${theme === 'dark' ? 'bg-[#212529] text-white border-[#555657] autofill:bg-[#212529] autofill:text-white' : 'bg-[white] autofill:bg-white autofill:text-black border-gray-t00  text-black'}`}
                          onClick={() => {
                            if (parent === "enterprice") {
                              return;
                            }
                            setIsOpen(!isOpen);
                          }}
                        >
                          { plan ? `${plans.find((item) => item.id === plan)?.name}(${plans.find((item) => item.id === plan)?.interval}ly)` : ''} 
                          <IoIosArrowDown />
                        </span>
                        {isOpen && (
                          <ul
                            className={`${theme === "dark"
                              ? "bg-darkMode text-white"
                              : "bg-white text-black"
                              } dropdown_menu`}
                          >
                            {plans.filter((item) => item.name !== 'Enterprise').map((planItem) => (
                              <li
                                key={planItem.id}
                                value={planItem.id}
                                onClick={() => {
                                  handlePlanChange(planItem.id);
                                  setIsOpen(!isOpen);
                                }}
                              >
                                {`${planItem.name}(${planItem.interval}ly)`}
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    </div>

                    <div className="input-group">
                      <label className="mb-2 text-sm flex items-center gap-2">
                        <TbUsersPlus className="w-6 h-6" />
                        Users you want to register
                      </label>
                      <input
                        type="number"
                        name="quantity"
                        value={formValues.quantity}
                        min={2}
                        onChange={handleChange}
                        placeholder="Enter number of users"
                        className={`w-full p-2 h-10 border rounded-[10px] outline-none ${theme === 'dark' ? 'bg-[#212529] text-white border-[#555657] autofill:bg-[#212529] autofill:text-white' : 'bg-[white] autofill:bg-white autofill:text-black border-gray-400  text-black'}`}
                      />
                    </div>
                  </>
                )}
                <div className="input-group">
                  <label className="mb-2 text-sm flex items-center gap-2">
                    <FaRegUser className="w-6 h-6" />
                    First Name</label>
                  <input
                    type="text"
                    name="first_name"
                    value={formValues.first_name}
                    onChange={handleChange}
                    placeholder="Enter your first name"
                    className={`w-full p-2 h-10 border rounded-[10px] outline-none ${theme === 'dark' ? 'bg-[#212529] text-white border-[#555657] autofill:bg-[#212529] autofill:text-white' : 'bg-[white] autofill:bg-white autofill:text-black border-gray-400  text-black'}`}
                  />
                </div>
                <div className="input-group">
                  <label className="mb-2 text-sm flex items-center gap-2">
                    <FaRegUser className="w-6 h-6" />
                    Last Name</label>
                  <input
                    type="text"
                    name="last_name"
                    value={formValues.last_name}
                    onChange={handleChange}
                    placeholder="Enter last name"
                    className={`w-full p-2 h-10 border rounded-[10px] outline-none ${theme === 'dark' ? 'bg-[#212529] text-white border-[#555657] autofill:bg-[#212529] autofill:text-white' : 'bg-[white] autofill:bg-white autofill:text-black border-gray-400  text-black'}`}
                  />
                </div>
                <div className="input-group">
                  <label className="mb-2 text-sm flex items-center gap-2">
                    <MdOutlineEmail className="w-6 h-6" />
                    Email</label>
                  <input
                    type="email"
                    name="email"
                    value={formValues.email}
                    onChange={handleChange}
                    placeholder="Enter email"
                    className={`w-full p-2 h-10 border rounded-[10px] outline-none ${theme === 'dark' ? 'bg-[#212529] text-white border-[#555657] autofill:bg-[#212529] autofill:text-white dark-input' : 'bg-[white] autofill:bg-white autofill:text-black border-gray-400  text-black light-input'}`}
                  />
                </div>
                <div className="input-group">
                  <label className="mb-2 text-sm flex items-center gap-2">
                    <TbWorld className="w-6 h-6" />
                    Choose a domain</label>
                  <div className="domain-main">
                    <div
                      className={`http ${theme === "dark" ? "text-white" : "text-black"
                        }`}
                    >
                      https://
                    </div>
                    <div className={`w-full p-2 h-10 border rounded-[10px] outline-none ${theme === 'dark' ? 'bg-[#212529] text-white border-[#555657] autofill:bg-[#212529] autofill:text-white' : 'bg-[white] autofill:bg-white autofill:text-black border-gray-400  text-black'}  flex gap-2 justify-between`} style={{ paddingRight: '0px' }}>
                      <input
                        type="text"
                        name="domain"
                        value={formValues.domain}
                        onChange={handleChange}
                        className="bg-transparent outline-none w-full"
                      />
                      <div className="">.bugtrack.com</div>
                    </div>
                  </div>
                </div>
                <div className="input-group">
                  <label className="mb-2 text-sm flex items-center gap-2">
                    <CiLock className='w-6 h-6' />
                    Password</label>
                  <div className="relative">
                    <input
                      type={showPassword ? "text" : "password"}
                      name="password"
                      value={formValues.password}
                      onChange={handleChange}
                      placeholder="Enter password"
                      className={`w-full p-2 h-10 border rounded-[10px] outline-none ${theme === 'dark' ? 'bg-[#212529] text-white border-[#555657] autofill:bg-[#212529] autofill:text-white ' : 'bg-[white] autofill:bg-white autofill:text-black border-gray-400  text-black'}`}
                      style={{ paddingRight: "35px" }}
                    />
                    <div
                      className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <FaEye /> : <FaEyeSlash />}
                    </div>
                  </div>
                  <div className="w-full h-2 mt-2 rounded-full overflow-hidden">
                    <div
                      className={`h-full rounded-full transition-all duration-300 ${passwordStrength < 2
                        ? "bg-red-500"
                        : passwordStrength < 4
                          ? "bg-orange-500"
                          : "bg-green-500"
                        }`}
                      style={{ width: `${(passwordStrength / 5) * 100}%` }}
                    ></div>
                  </div>
                </div>
                <div className="input-group">
                  <label className="mb-2 text-sm flex items-center gap-2">
                    <CiUnlock className="w-6 h-6" />
                    Confirm password</label>
                  <div className="relative">
                    <input
                      type={showPassword ? "text" : "password"}
                      name="confirmPassword"
                      value={formValues.confirmPassword}
                      onChange={handleChange}
                      placeholder="Enter confirm password"
                      className={`w-full p-2 h-10 border rounded-[10px] outline-none ${theme === 'dark' ? 'bg-[#212529] text-white border-[#555657] autofill:bg-[#212529] autofill:text-white' : 'bg-[white] autofill:bg-white autofill:text-black border-gray-400  text-black'}`}
                      style={{ paddingRight: "35px" }}
                    />
                  </div>
                </div>
              </div>
              <div className="flex gap-2 items-center mb-2">
                <input
                  type="checkbox"
                  onClick={(e) => setIsChecked(e.target.checked)}
                  value={isChecked}
                />
                <p className="terms-text">
                  By clicking Sign Up, you agree to our{" "}
                  <Link to="/terms_of_service" className="terms-link">
                    Terms of Service
                  </Link>{" "}
                  and{" "}
                  <Link to="/privacy_policy" className="terms-link">
                    Privacy Policy
                  </Link>
                  .
                </p>
              </div>
              <div className="flex w-full gap-6 justify-center items-center">
                <div
                  className={`lg:w-[35%] w-1/2 cursor-pointer flex text-center py-2 rounded-[20px] justify-center items-center border-2 ${theme === 'dark' ? 'border-[#565656]' : 'border-gray-500'}`} onClick={() => navigate(-1)}

                >
                  Previous
                </div>
                <button
                  className="heroButton lg:w-[35%] w-1/2"
                  type="submit"
                  disabled={loading}
                >
                  {loading ? "Loading..." : "Next"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUp;
