import React from "react";
import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip } from "recharts";
import { useSelector } from "react-redux";

const COLORS = {
  light: ["#008000", "#FFA500", "#555", "#D00000"], // Green, Orange, Gray, Red
  dark: ["#07DB7E", "#F7A600", "#888", "#FF4D4D"],
};

// Custom label rendering function from your original example
const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ 
  cx, 
  cy, 
  midAngle, 
  innerRadius, 
  outerRadius, 
  percent, 
  index 
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text 
      x={x} 
      y={y} 
      fill="white" 
      textAnchor="middle" 
      dominantBaseline="central"
      fontSize={14}
      fontWeight="500"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const MilestoneStatusChart = ({ data }) => {
  const { theme } = useSelector((state) => state.theme);

  const formattedData = [
    { name: "Completed", value: data.completed },
    { name: "In Progress", value: data.in_progress },
    { name: "Paused", value: data.paused },
    { name: "Canceled", value: data.canceled },
  ];

  return (
    <div className={`p-4 border rounded-[20px] lg:w-[35%] md:w-[45%] w-full ${
      theme === "dark" 
        ? "bg-[#212529] border-[#555657] text-white" 
        : "bg-[#F2F4F7] border-[#D8D8D8] text-black"
    }`}>
      <h2 className="text-lg font-semibold mb-4 capitalize">
        Milestone Status Distribution
      </h2>
      
      <div className="flex justify-center">
        <ResponsiveContainer width="100%" height={300}>
          <PieChart>
            <Pie
              data={formattedData}
              cx="50%"
              cy="50%"
              outerRadius={100}
              innerRadius={0} // Creates a donut chart to better fit labels
              paddingAngle={0}
              dataKey="value"
              label={renderCustomizedLabel}
              labelLine={false}
            >
              {formattedData.map((entry, index) => (
                <Cell 
                  key={`cell-${index}`} 
                  fill={COLORS[theme][index]} 
                  stroke="none"
                />
              ))}
            </Pie>
            <Tooltip 
              formatter={(value, name) => [value, name]}
              contentStyle={{
                backgroundColor: theme === "dark" ? "#222" : "#fff", 
                borderRadius: 5,
                border: `1px solid ${theme === "dark" ? "#555" : "#ddd"}`
              }}
              itemStyle={{
                color: theme === "dark" ? "white" : "black",
              }}
            />
            <Legend 
              layout="horizontal" 
              verticalAlign="bottom" 
              align="center"
              wrapperStyle={{
                color: theme === "dark" ? "white" : "black",
                paddingTop: "20px"
              }}
            />
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default MilestoneStatusChart;