import { useDrag, useDrop } from "react-dnd";
import Card from "./Card";
import { useSelector } from "react-redux";

const DropZone = ({
  column,
  index,
  tickets,
  moveCard,
  moveColumn,
  theme,
  handleCreate,
  ticketsLoading,
  draggingColumn,
  setDraggingColumn,
  handleUpdateColumePosition,
  setTickets
}) => {
  const { userInfo } = useSelector((state) => state?.auth || {});

  const [{ isDragging }, dragColumn] = useDrag({
    type: "COLUMN",
    item: () => {
      setDraggingColumn(column.id);
      return { id: column.id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (item, monitor) => {
      setDraggingColumn(null);
      if (monitor.didDrop()) {
        handleUpdateColumePosition(item.id, item.index); // Call API only on drop
      }
    },
  });
  
  const [, dropColumn] = useDrop({
    accept: "COLUMN",
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        moveColumn(draggedItem.index, index, column.id);
        draggedItem.index = index; // Update the index after movement
      }
    },
  });

  const [, drop] = useDrop({
    accept: "CARD",
    drop: (item) => moveCard(item.id, column.id),
  });

  return (
    <div
      ref={(node) =>{ 
        if(userInfo.user.role.name !== 'Time Reporter'){
        dropColumn(dragColumn(node))
      }}}
      className={`parent-card group px-4 py-4 shadow-md xs:min-w-[290px] min-w-full flex-shrink-0 min-h-[70vh] rounded-md flex flex-col transition-transform ${
        theme === "dark" ? "bg-darkMode" : "bg-[#F7F7F7] text-black"
      } 
      ${isDragging ? "opacity-100 " : ""}`}
    >
      <div className="flex flex-col w-full h-full" ref={drop}>
        <div className="content">
          <div className="flex w-full justify-between mb-2">
            <h2 className="font-semibold text-base capitalize">{column?.title}</h2>
          </div>
          {tickets?.map((ticket) => (
            <Card key={ticket.id} ticket={ticket} ticketLoading={ticketsLoading} setTickets={setTickets} tickets={tickets} />
          ))}
        </div>
        <div
          className={`mt-4 opacity-0 ${
            userInfo?.user?.role?.name !== "Time Reporter" ? "group-hover:opacity-100" : "hidden"
          } transition-opacity duration-300`}
        >
          <button className="w-full heroButton" onClick={() => handleCreate(column.id)}>
            Create Ticket
          </button>
        </div>
      </div>
    </div>
  );
};

export default DropZone;
