import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import BackBtn from "../../backBtn/BackBtn";
import ParticlesBackground from "../../home/ParticalsBackground";
import AnimationLottie from "../../../lottie/animation-lottie";
import registerCompanyAnimation from "../../../lottie/registerCompanyAnimation.json";
import { toggleCompany } from "../../../Redux/Slices/registerCompany-slice";
import { fetchApi } from "../../../utlis/axios";
import { addToast } from "../../../Redux/Slices/toast-slice";
import { toggleAuth } from "../../../Redux/Slices/auth-slice";
import { GiCheckMark } from "react-icons/gi";

const RegCompany = () => {
  const { theme } = useSelector((state) => state.theme);
  const { userInfo, isLogin } = useSelector((state) => state?.auth || {});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState({
    name: "",
    site_url: "",
    logo: [],
    stamp: [],
    address: "",
    postal_code: "",
    city: "",
    state: "",
    country: "",
    owner: "",
  });
  const [isChecked, setIsChecked] = useState(false);

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    if (files?.length > 0) {
      // Update state for file inputs
      setFormValues({ ...formValues, [name]: files[0] });
    } else {
      // Update state for other input types
      setFormValues({ ...formValues, [name]: value });
    }
  };

  const handleRegisterCompany = async (e) => {
    e.preventDefault();

    const requiredFields = [
      "name",
      "address",
      "postal_code",
      "city",
      "state",
      "country",
    ];
    for (let field of requiredFields) {
      if (!formValues[field]) {
        dispatch(addToast({ message: `${field.replace("_", " ")} is required!`, type: 'error' }));
        return;
      }
    }

    if (!isChecked) {
      dispatch(addToast({ message: "Please accept the Terms and Conditions to continue!", type: 'error' }));
      return;
    }
    setLoading(true);
    try {
      // Handle registration logic here
      const companyData = new FormData();
      companyData.append("name", formValues.name);
      companyData.append("site_url", formValues.site_url);
      if (formValues.logo) companyData.append("logo", formValues.logo);
      if (formValues.stamp) companyData.append("stamp", formValues.stamp);
      companyData.append("address", formValues.address);
      companyData.append("postal_code", formValues.postal_code);
      companyData.append("city", formValues.city);
      companyData.append("state", formValues.state);
      companyData.append("country", formValues.country);
      companyData.append("owner", userInfo?.user?.id);
      const result = await fetchApi.post("companies/", companyData, {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      dispatch(toggleCompany({ companyInfo: formValues }));
      dispatch(addToast({ message: 'Company registered successfully', type: 'success' }));
      dispatch(toggleAuth({ isLogin: false, userInfo: null }));
      const url = window.location
      window.location.href = `${url.protocol}//${userInfo?.user?.domain}.${url.host}/login`
    } catch (error) {
      dispatch(addToast({ message: 'Something went wrong', type: 'error' }));
    } finally {
      setLoading(false);
    }
  };

  const handleSkip = () => {
    dispatch(toggleAuth({ isLogin: false, userInfo: null }));
    const url = window.location
    window.location.href = `${url.protocol}//${userInfo?.user?.domain}.${url.host}/login`
  };

  return (
    <>
      <style>{`
        .login-box {
          background-color:${theme === "dark" ? "rgba(33, 33, 33, 0.9)" : "#dfdfdf"
        };
          color:${theme === "dark" ? "#ffffff" : "#000000"};
        }
        .forgot-link, .terms-text {
          color:${theme === "dark" ? "#ccc" : "#000000"};
        }
        .terms-link {
          color:${theme === "dark" ? "#ffffff" : "#ff2f2f"};
        }
      `}</style>
      <ParticlesBackground id="particaljs" />
      <div className="login-container fontPoppins">
        <BackBtn bgColor={"red"} />
        <div className="flex justify-center w-full md:py-4 ">
          <div
            className={`signup-box max-w-screen-lg md:px-4 px-2 ${theme === "dark"
                ? "text-[#ffffff] bg-[#1E1E1E]/80"
                : "text-[#000000] bg-[#dfdfdf]/80"
              }`}
          >
            <div className="flex w-full justify-between items-center py-3 md:px-4">
              <div className="flex items-center gap-2 cursor-pointer">
                <GiCheckMark className="sm:w-5 sm:h-5 w-3 h-3 text-green-500" />
                <div className="flex flex-col">
                  <span className="sm:text-base text-sm">Step 1</span>
                  <span className="sm:text-sm text-xs">Subscription Plan</span>
                </div>
              </div>
              <div className="flex items-center gap-2 cursor-pointer">
                <GiCheckMark className="sm:w-5 sm:h-5 w-3 h-3 text-green-500" />
                <div className="flex flex-col">
                  <span className="sm:text-base text-sm">Step 2</span>
                  <span className="sm:text-sm text-xs">Create account</span>
                </div>
              </div>
              <div className="flex items-center gap-2 cursor-pointer">
                <GiCheckMark className="sm:w-5 sm:h-5 w-3 h-3 text-green-500" />
                <div className="flex flex-col">
                  <span className="sm:text-base text-sm">Step 3</span>
                  <span className="sm:text-sm text-xs">Complete Payment</span>
                </div>
              </div>
              <div className="flex flex-col border-b-[3px] border-red-600 py-3 cursor-pointer">
                <span className="sm:text-base text-sm">Step 4</span>
                <span className="sm:text-sm text-xs">Register Company</span>
              </div>

            </div>
            <hr className={`mt-[-12px] border-t ${theme === 'dark' ? 'border-[#556557]' : 'border-[#212529]'}`} />
            <h2 className="main-heading text-center mt-4">Register Your Company</h2>
            <form onSubmit={handleRegisterCompany} className="">
              <div className="grid sm:grid-cols-2 sm:gap-6 grid-cols-1 gap-4">
                <div className="input-group">
                  <label className="input-label">Name*</label>
                  <input
                    type="text"
                    name="name"
                    value={formValues.name}
                    onChange={handleChange}
                    placeholder="Enter company name"
                    className={`w-full p-2 h-10 border rounded-[10px] outline-none ${theme === 'dark' ? 'bg-[#212529] text-white border-[#555657] autofill:bg-[#212529] autofill:text-white' : 'bg-[white] autofill:bg-white autofill:text-black border-gray-400  text-black'}`}
                  />
                </div>
                <div className="input-group">
                  <label className="input-label">Logo</label>
                  <input
                    type="file"
                    name="logo"
                    onChange={handleChange}
                    placeholder="logo"
                    className={`w-full p-2 h-10 border rounded-[10px] outline-none ${theme === 'dark' ? 'bg-[#212529] text-white border-[#555657] autofill:bg-[#212529] autofill:text-white' : 'bg-[white] autofill:bg-white autofill:text-black border-gray-400  text-black'} file-input`}
                  />
                </div>
                <div className="input-group">
                  <label className="input-label">Stamp</label>
                  <input
                    type="file"
                    name="stamp"
                    onChange={handleChange}
                    placeholder="stamp"
                    className={`w-full p-2 h-10 border rounded-[10px] outline-none ${theme === 'dark' ? 'bg-[#212529] text-white border-[#555657] autofill:bg-[#212529] autofill:text-white' : 'bg-[white] autofill:bg-white autofill:text-black border-gray-400  text-black'} file-input`}
                  />
                </div>
                <div className="input-group">
                  <label className="input-label">Address*</label>
                  <input
                    type="text"
                    name="address"
                    value={formValues.address}
                    onChange={handleChange}
                    placeholder="Enter address"
                    className={`w-full p-2 h-10 border rounded-[10px] outline-none ${theme === 'dark' ? 'bg-[#212529] text-white border-[#555657] autofill:bg-[#212529] autofill:text-white' : 'bg-[white] autofill:bg-white autofill:text-black border-gray-400  text-black'}`}
                  />
                </div>
                <div className="input-group">
                  <label className="input-label">Postal Code*</label>
                  <input
                    type="text"
                    name="postal_code"
                    value={formValues.postal_code}
                    onChange={handleChange}
                    placeholder="Enter postal code"
                    className={`w-full p-2 h-10 border rounded-[10px] outline-none ${theme === 'dark' ? 'bg-[#212529] text-white border-[#555657] autofill:bg-[#212529] autofill:text-white' : 'bg-[white] autofill:bg-white autofill:text-black border-gray-400  text-black'}`}
                  />
                </div>
                <div className="input-group">
                  <label className="input-label">City*</label>
                  <input
                    type="text"
                    name="city"
                    value={formValues.city}
                    onChange={handleChange}
                    placeholder="Enter city"
                    className={`w-full p-2 h-10 border rounded-[10px] outline-none ${theme === 'dark' ? 'bg-[#212529] text-white border-[#555657] autofill:bg-[#212529] autofill:text-white' : 'bg-[white] autofill:bg-white autofill:text-black border-gray-400  text-black'}`}
                  />
                </div>
                <div className="input-group">
                  <label className="input-label">State*</label>
                  <input
                    type="text"
                    name="state"
                    value={formValues.state}
                    onChange={handleChange}
                    placeholder="Enter state"
                    className={`w-full p-2 h-10 border rounded-[10px] outline-none ${theme === 'dark' ? 'bg-[#212529] text-white border-[#555657] autofill:bg-[#212529] autofill:text-white' : 'bg-[white] autofill:bg-white autofill:text-black border-gray-400  text-black'}`}
                  />
                </div>
                <div className="input-group">
                  <label className="input-label">Country*</label>
                  <input
                    type="text"
                    name="country"
                    value={formValues.country}
                    onChange={handleChange}
                    placeholder="Enter country"
                    className={`w-full p-2 h-10 border rounded-[10px] outline-none ${theme === 'dark' ? 'bg-[#212529] text-white border-[#555657] autofill:bg-[#212529] autofill:text-white' : 'bg-[white] autofill:bg-white autofill:text-black border-gray-400  text-black'}`}
                  />
                </div>
              </div>
              <div className="input-group">
                <label className="input-label">Site URL</label>
                <input
                  type="text"
                  name="site_url"
                  value={formValues.site_url}
                  onChange={handleChange}
                  placeholder="Enter site URL"
                  className={`w-full p-2 h-10 border rounded-[10px] outline-none ${theme === 'dark' ? 'bg-[#212529] text-white border-[#555657] autofill:bg-[#212529] autofill:text-white' : 'bg-[white] autofill:bg-white autofill:text-black border-gray-400  text-black'}`}
                />
              </div>
              <div className="flex gap-4 items-center mb-2">
                <input
                  type="checkbox"
                  onClick={(e) => setIsChecked(e.target.checked)}
                  value={isChecked}
                />
                <p className="terms-text">
                  By clicking Register, you agree to our{" "}
                  <Link to="/terms_of_service" className="terms-link">
                    Terms of Service
                  </Link>{" "}
                  and{" "}
                  <Link to="/privacy_policy" className="terms-link">
                    Privacy Policy
                  </Link>
                  .
                </p>
              </div>
              <div className="flex justify-center gap-4">
                <div
                  className={`md:w-[35%] w-1/2 cursor-pointer flex text-center py-2 rounded-[20px] justify-center items-center border-2 ${theme === 'dark' ? 'border-[#565656]' : 'border-gray-500'}`}
                  onClick={handleSkip}
                >
                  Skip
                </div>
                <button
                  className="heroButton md:w-[35%] w-1/2"
                  type="submit"
                  disabled={loading}
                >
                  {loading ? "Loading..." : "Register"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegCompany;
