import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const Calendar = ({ setStartDate, setEndDate, startDate, endDate }) => {
  const { theme } = useSelector((state) => state.theme);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [approvedLeaves, setApprovedLeaves] = useState([]);
  const [dateRange, setDateRange] = useState({ start: startDate || null, end: endDate || null });

  const daysOfWeek = ["S", "M", "T", "W", "T", "F", "S"];

  const getDaysInMonth = (date) => {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
  };

  const getFirstDayOfMonth = (date) => {
    return new Date(date.getFullYear(), date.getMonth(), 1).getDay();
  };

  const getPreviousMonthDays = (date) => {
    const firstDay = getFirstDayOfMonth(date);
    const prevMonthLastDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      0
    ).getDate();
    return Array.from(
      { length: firstDay },
      (_, i) => prevMonthLastDate - firstDay + i + 1
    );
  };

  const getNextMonthDays = (date) => {
    const totalDays = getDaysInMonth(date);
    const firstDay = getFirstDayOfMonth(date);
    const totalCells = firstDay + totalDays;
    return Array.from(
      { length: totalCells % 7 === 0 ? 0 : 7 - (totalCells % 7) },
      (_, i) => i + 1
    );
  };

  const handlePrevMonth = () => {
    setCurrentDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1)
    );
  };

  const handleNextMonth = () => {
    setCurrentDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1)
    );
  };

  const isDateApproved = (day) => {
    const currentDateStr = `${currentDate.getFullYear()}-${String(
      currentDate.getMonth() + 1
    ).padStart(2, "0")}-${String(day).padStart(2, "0")}`;
    return approvedLeaves.includes(currentDateStr);
  };

  const handleDateClick = (day) => {
    const clickedDate = `${currentDate.getFullYear()}-${String(
      currentDate.getMonth() + 1
    ).padStart(2, "0")}-${String(day).padStart(2, "0")}`;

    if (!dateRange.start || (dateRange.start && dateRange.end)) {
      // Reset if no start or both dates are already selected
      setDateRange({ start: clickedDate, end: null });
     setStartDate(clickedDate);
    } else if (new Date(clickedDate) < new Date(dateRange.start)) {
      // If clicked date is before start, make it the new start
      setDateRange({ start: clickedDate, end: dateRange.start });
     setStartDate(clickedDate)
     setEndDate(dateRange.start)
    } else {
      // Set as end date
      setDateRange({ ...dateRange, end: clickedDate });
      setEndDate(clickedDate);
    }
  };


  const isStartDate = (day) =>
    dateRange.start ===
    `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(day).padStart(2, "0")}`;
  const isEndDate = (day) =>
    dateRange.end ===
    `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(day).padStart(2, "0")}`;

  const isBetweenRange = (day) => {
    if (!dateRange.start || !dateRange.end) return false;

    const date = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      day
    );
    const start = new Date(dateRange.start);
    const end = new Date(dateRange.end);

    return date > start && date < end;
  };

  return (
    <div
      className={`h-full customFont flex flex-col justify-center items-center `}
    >
      <div
        className={`w-full max-w-md p-2 border rounded-[20px] ${
          theme === "dark"
            ? "bg-[#212529] border-[#555657]"
            : "bg-[#F2F4F7] border-[#D8D8D8]"
        }`}
      >
        <div className="flex items-center justify-between p-2">
          <h2 className="text-xl font-semibold">
            {currentDate.toLocaleString("default", {
              month: "long",
              year: "numeric",
            })}
          </h2>
          <div className="flex gap-4 items-center">
            <button onClick={handlePrevMonth}>
              <FaChevronLeft className="w-4 h-4" />
            </button>
            <button onClick={handleNextMonth}>
              <FaChevronRight className="w-4 h-4" />
            </button>
          </div>
        </div>
        <div className="grid grid-cols-7 text-center text-sm font-medium">
          {daysOfWeek.map((day) => (
            <div key={day} className="p-1">
              {day}
            </div>
          ))}
        </div>
        <div className="grid grid-cols-7 text-center">
          {getPreviousMonthDays(currentDate).map((day, index) => (
            <div key={`prev-${index}`} className="p-2 mb-1  text-gray-400">
              {day}
            </div>
          ))}
         {Array.from(
  { length: getDaysInMonth(currentDate) },
  (_, i) => i + 1
).map((day) => {
  const formattedDate = `${currentDate.getFullYear()}-${String(
    currentDate.getMonth() + 1
  ).padStart(2, "0")}-${String(day).padStart(2, "0")}`;

  const isStart = isStartDate(day);
  const isEnd = isEndDate(day);
  const isBetween = isBetweenRange(day);
  const dayOfWeek = new Date(currentDate.getFullYear(), currentDate.getMonth(), day).getDay();

  let bgClass = "hover:bg-red-500 hover:rounded-full hover:text-white cursor-pointer";
  let roundedClass = "rounded-none";
  let extraStyle = "";

  if (isStart && isEnd) {
    // If same day is start and end
    bgClass = "bg-[#C01927] text-white";
    roundedClass = "rounded-full";
  } else if (isStart) {
    bgClass = "bg-[#C01927] text-white";
    roundedClass = "rounded-l-full";
  } else if (isEnd) {
    bgClass = "bg-[#C01927] text-white";
    roundedClass = "rounded-r-full";
  } else if (isBetween) {
    bgClass = "bg-[#CC092720]"; // Light red
    // Check if first or last in row
    if (dayOfWeek === 0) {
      roundedClass = "rounded-l-full";
    } else if (dayOfWeek === 6) {
      roundedClass = "rounded-r-full";
    }
  }

  return (
    <div
      key={day}
      className={`relative p-2 mb-1 ${roundedClass} ${bgClass} ${extraStyle}`}
      onClick={() => handleDateClick(day)}
    >
      {day}
    </div>
  );
})}


          {getNextMonthDays(currentDate).map((day, index) => (
            <div key={`next-${index}`} className="p-2 text-gray-400">
              {day}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Calendar;
