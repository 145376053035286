import { createSlice,nanoid } from "@reduxjs/toolkit";

const initialState = {
  toasts: [],
};

const toastSlice = createSlice({
  name: "toast",
  initialState,
  reducers: {
    addToast: (state, action) => {
        const id = nanoid(); // Unique ID for each toast
        state.toasts.push({ id, ...action.payload });
  
        // If more than 3 toasts, remove the first one
        if (state.toasts.length > 3) {
          state.toasts.shift();
        }
      },
    removeToast: (state, action) => {
      state.toasts = state.toasts.filter((toast) => toast.id !== action.payload);
    },
  },
});

export const { addToast, removeToast } = toastSlice.actions;
export default toastSlice.reducer;
