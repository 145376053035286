import React, { useEffect, useState } from "react";
import { MdElectricBolt, MdMenu, MdClose, MdLightMode, MdDarkMode } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { toggleTheme } from "../../Redux/Slices/theme-slice";
import { toggleAuth } from "../../Redux/Slices/auth-slice";
import logo from "../../assets/bugtrack.png";
import { motion } from 'framer-motion';
import { TbCoins } from "react-icons/tb";
import { CiDark, CiLight } from "react-icons/ci";

const Navbar = () => {
  const [isNavVisible, setIsNavVisible] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLogin } = useSelector((state) => state?.auth || "");
  const { theme } = useSelector((state) => state.theme);
  const [isDark, setIsDark]=useState(false)

  const handleToggleTheme = () => {
    const newTheme = theme === "dark" ? "light" : "dark";
    dispatch(toggleTheme({ theme: newTheme }));
  };

  const toggleNavVisibility = () => {
    setIsDark(!isDark)
    setIsNavVisible(!isNavVisible);
  };

  const handleLogout = () => {
    dispatch(toggleAuth({ isLogin: false, userInfo: null }));
    navigate("/");
  };
  const handleNavClick = (path, sectionId) => {
    if (window.location.pathname === path) {
      // If already on the same page, just scroll
      document
        .getElementById(sectionId)
        ?.scrollIntoView({ behavior: "smooth" });
    } else {
      // If on a different page, navigate and then scroll after render
      navigate(path);
      setTimeout(() => {
        document
          .getElementById(sectionId)
          ?.scrollIntoView({ behavior: "smooth" });
      }, 500);
    }
  };

  return (
    <header
      className={`sticky-navbar ${
        theme === "dark" ? "dark_mode" : "light_mode"
      }`}
    >
      <div className="header-top">
        <div className="logo">
          <img src={logo} className="h-6 w-6 mb-1" />
          <span>
            <span className="text-[#FF0000] ">ug</span>Trackin
          </span>
        </div>
        <button className="nav-toggle-btn" onClick={toggleNavVisibility}>
          {isNavVisible ? <MdClose /> : <MdMenu />}
        </button>
      </div>
      <nav
        className={`${isNavVisible ? "visible" : ""} ${
          isNavVisible ? (theme === "dark" ? "navdark" : "navlight") : ""
        } text-sm`}
      >
        {isLogin && <NavLink to="/dashboard">Dashboard</NavLink>}
        <a href="#features">Features</a>
        <a href='#contactUs'>
          Contact Us
        </a>
        <a href="#pricingPlans">
          Pricing Plans
        </a>
        <div className={`relative flex p-2 rounded-[35px] w-auto ${theme === 'dark' ? 'bg-black ' : 'bg-white'} ${theme === 'light' ? 'left-0':''} `}>
                {/* Background Slider */}
                <motion.div
                    className={`absolute top-0 h-full w-[50%] bg-gradient-to-r from-[#CC0927] to-[#7E0105] rounded-[30px] ${theme === "light" ? "left-0" : ""} `}
                    animate={{ x: theme === "light" ? 0 : "100%" }}
                    transition={{ type: "spring", stiffness: 200, damping: 20 }}
                />

                {/* Admin Button */}
                <button
                    className={`relative flex items-center gap-1 rounded-full text-base font-medium transition-all duration-300 w-20 justify-center z-10 outline-none ${theme === "light" ? 'text-white':'text-gray-400'}`}
                    onClick={handleToggleTheme}
                >
                    <MdLightMode className=" w-5 h-5" />
                    <span className="text-[16px]">Light</span>
                </button>

                {/* Finance Button */}
                <button
                    className={`relative flex items-center gap-1  rounded-full text-base font-medium transition-all duration-300 w-20 justify-center z-10 outline-none ${theme === "dark" ? 'text-white':'text-gray-600'}`}
                    onClick={handleToggleTheme}
                >
                    <MdDarkMode className="w-5 h-5" />
                    <span className="text-[16px]">Dark</span>
                </button>
            </div>
        {isLogin ? (
          <div className="heroButton cursor-pointer" onClick={handleLogout}>
            Log Out
          </div>
        ) : (
          <NavLink to="/login" className="heroButton">
            Sign In
          </NavLink>
        )}
        {isLogin ? (
          <></>
        ) : (
          <NavLink to="/subscriptionplan" className="heroButton">
            Get Started
          </NavLink>
        )}
      </nav>
    </header>
  );
};

export default Navbar;
