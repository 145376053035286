import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import logo from '../../../assets/bugtrack.png'

const generatePDF = async (data, users,userInfo) => {
  function convertMinutesToHours(minutes) {
    if (isNaN(minutes) || minutes < 0) return "Invalid input";
  
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
  
    return `${hours}:${remainingMinutes}`;
  }
  // Create a wrapper div for the table (in memory)
  const wrapper = document.createElement("div");
  wrapper.style.padding = "20px";
  wrapper.style.backgroundColor = "#fff";
  wrapper.style.width = "1024px";
  wrapper.style.position = "absolute";
  wrapper.style.top = "-9999px"; // Move it off-screen
  wrapper.style.left = "-9999px"; // Ensure it's invisible

  document.body.appendChild(wrapper);

  // Add the title and user details
  const headerHTML = `
  <div style="display: flex; align-items: center; justify-content: start;">
  <img src="${logo}" alt="Logo" style="width: 100px; height: 100px; margin-right: 20px;"/>
    <h2 style="text-align: center;width: 100%; margin-bottom: 20px; font-size: 24px; color: #333; font-weight: bold;">BugTrack Report</h2>
  </div>
  <div style="margin-top: 20px; font-size: 18px; margin-bottom: 20px; font-weight: bold; ">Employee Details</div>
    <div>
      <strong>Title:</strong> ${data.title || "N/A"}
    </div>
    <div>
      <strong>Name:</strong> ${userInfo?.user?.first_name} ${userInfo?.user?.last_name}
    </div>
    <div>
      <strong>Email:</strong> ${userInfo?.user?.email || "N/A"}
    </div>
    <div>
      <strong>Date:</strong> ${data.date || new Date().toLocaleDateString()}
    </div>
  `;
  wrapper.innerHTML = headerHTML;

  // Create the table
  const table = document.createElement("table");
  table.style.border = "1px solid #000";
  table.style.width = "100%";
  table.style.borderCollapse = "collapse";
  table.style.textAlign = "center";
  table.style.marginTop='20px'

  // Add the header row
  const headerRow = `
    <thead style="background-color: #f2f4f7; color:black; font-weight: bold;">
      <tr>
        <th  style="padding: 8px; border-right: 1px solid #000;">Name</th>
        <th style=" padding: 8px; border-right: 1px solid #000;">Date</th>
        <th style=" padding: 8px; border-right: 1px solid #000;">Total Tracked Time(hours)</th>
        <th style=" padding: 8px; border-right: 1px solid #000;">Keyboard Productivity</th>
        <th style=" padding: 8px;  border-right: 1px solid #000;" >Mouse Productivity</th>
        <th style=" padding: 8px;  border-right: 1px solid #000;">Active Time(hours)</th>
        <th style=" padding: 8px;">Active Time Percentage(%)</th>
      </tr>
    </thead>
  `;
  table.innerHTML = headerRow;

  // Add the data rows
  const tbody = document.createElement("tbody");

  Object.keys(data.response || {}).forEach((userId) => {
    const user = users.find((u) => u.id === userId);
    const userName = `${user?.first_name || "Unknown"} ${
      user?.last_name || ""
    }`;
    const weeks = data.response[userId];
    const isEmpty = Object.keys(weeks).length === 0;

    if (isEmpty) {
      const row = `
        <tr>
          <td style=" padding: 8px;  border-right: 1px solid #000;">${userName}</td>
          <td style=" padding: 8px;" colspan="6">No data available</td>
        </tr>
      `;
      tbody.innerHTML += row;
    } else {
      Object.keys(weeks).forEach((weekKey, index) => {
        const weekData = weeks[weekKey];
        const row = `
          <tr>
            ${
              index === 0
                ? `<td style=" padding: 8px;  border-right: 1px solid #000;" rowspan="${
                    Object.keys(weeks).length
                  }">${userName}</td>`
                : ""
            }
             <td style="padding: 8px; border-right: 1px solid #000; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">${weekKey}</td>
            <td style=" padding: 8px; border-right: 1px solid #000;">${
             convertMinutesToHours(weekData.total_tracked_time)
            }</td>
            <td style=" padding: 8px; border-right: 1px solid #000;">${
              typeof weekData.keyboard_productivity_percentage === "number"
                ? weekData.keyboard_productivity_percentage.toFixed(3)
                : weekData.keyboard_productivity_percentage || "-"
            }</td>
            <td style=" padding: 8px; border-right: 1px solid #000;">${
              typeof weekData.mouse_productivity_percentage === "number"
                ? weekData.mouse_productivity_percentage.toFixed(3)
                : weekData.mouse_productivity_percentage || "-"
            }</td>
            <td style=" padding: 8px; border-right: 1px solid #000;">${
            convertMinutesToHours(weekData.active_time)
            }</td>
            <td style=" padding: 8px;">${
              typeof weekData.active_time_percentage === "number"
                ? weekData.active_time_percentage.toFixed(3)
                : weekData.active_time_percentage || "-"
            }</td>
          </tr>
        `;
        tbody.innerHTML += row;
      });
    }
  });

  table.appendChild(tbody);
  wrapper.appendChild(table);

  // Convert the wrapper into a canvas
  const canvas = await html2canvas(wrapper);

  // Generate the PDF
  const pdf = new jsPDF("p", "mm", "a4");
  const imgData = canvas.toDataURL("image/png");
  const imgWidth = 210;
  const imgHeight = (canvas.height * imgWidth) / canvas.width;

  pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
  pdf.save(data.title);

  document.body.removeChild(wrapper);
};

export default generatePDF;
