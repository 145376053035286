import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Default styling for React Quill
import "react-quill/dist/quill.bubble.css"; // Optional styling
import { useSelector } from "react-redux";

const RichTextEditor1 = ({ setProjectData, projectData}) => {
 const {theme}=useSelector((state)=>state.theme)
 

  // Custom styling for dark theme
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      ["bold", "italic", "underline", "strike"], // Formatting buttons
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "code-block"],
      [{ align: [] }],
      
      ["clean"], // Remove formatting
    ],
  };

  const editorStyles = {
    backgroundColor: theme === "dark" ? "transparent" : "transparent",
    color: theme === "dark" ? "#ffffff" : "#000000",
    border: "1px solid",
    borderColor: theme === "dark" ? "transparent" : "#dddddd",
    borderRadius: "10px",
    
  };


  return (
    <div className={theme === 'dark' ? 'quill-dark-theme' : 'quill-light-theme'}>
      <ReactQuill
          value={projectData.description}
          onChange={(e) => setProjectData({ ...projectData, description: e })}
        row={5}
        modules={modules}
        theme="snow"
        style={editorStyles}
          className="custom-border"
      />
    </div>
  );
};

export default RichTextEditor1;
