import React, { useState, useEffect } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import {
  FaUser,
  FaUserCircle,
  FaEye,
  FaEyeSlash,
  FaAngleDown,
  FaAngleUp,
  FaRegUser,
  FaChevronDown,
} from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import styles from "../users/user.module.css";
import { Link } from "react-router-dom";
import { addToast } from "../../../Redux/Slices/toast-slice"; // Assuming this is the correct path
import { fetchApi } from "../../../utlis/axios";
import loader from "../../../assets/loading-gif.gif";
import { toggleAuth } from "../../../Redux/Slices/auth-slice";
import { updateFilterUsers, updateUsers } from "../../../Redux/Slices/users-slice";
import { MdOutlineEmail, MdOutlineTask, MdTask } from "react-icons/md";
import { CiLock } from "react-icons/ci";
import { AiOutlineDollar } from "react-icons/ai";
import { TbCalendarDollar, TbUserCog } from "react-icons/tb";
import { IoIosClose } from "react-icons/io";
import UserProfileCard from "./UserProfileCard";

const UserManagement = () => {
  const { theme } = useSelector((state) => state.theme);
  const { users, totalNoOfUser,  filterUsers } = useSelector((state) => state.allUsers);
  const { userInfo } = useSelector((state) => state.auth);
  const nextPage = useSelector((state) => state.allUsers.nextPage);
  const [formData, setFormData] = useState({
    id: "",
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    currency: "",
    payment_amount: "",
    payment_type: "",
    project: "",
    domain: userInfo?.user?.domain,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [passwordchange, setPasswordChange] = useState(false);
  const dispatch = useDispatch();
  const { userId } = useSelector((state) => state?.allUsers || {});
  const [next, setNext] = useState(null);
  const [projectList, setProjects] = useState([]);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [roles, setRoles] = useState([]);
  const [userPictureUrl, setUserPictureUrl] = useState("");
  const [userProfileModal, setUserProfileModal] = useState(false);
  const [userData, setUserData] = useState({});
  const [deletPopup, setDeletePopup] = useState(false);
  const [deletedUser, setDeleteUser] = useState({});
  const [isDeleting, setIsDeleting] = useState(false);
  const [userList, setUserList] = useState([]);

  // Take only first 5 users
  const displayedUsers = userList?.slice(0, 5) || [];
  const remainingUsersCount = userList ? totalNoOfUser - displayedUsers.length : 0;

  // Format payment amount with currency
  const formatPayment = (amount, currency, type) => {
    if (!amount || !currency || !type) return "N/A";
    return `${currency} ${amount.toLocaleString()}/${
      type === "monthly" ? "month" : "hour"
    }`;
  };

  // Add state to track which dropdown is open
  const [openDropdownId, setOpenDropdownId] = useState(null);

  // Add effect to close dropdown when component unmounts
  useEffect(() => {
    return () => {
      setOpenDropdownId(null);
    };
  }, []);

  useEffect(()=>{
setUserList(users)
  },[])

  // Add handler to close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (openDropdownId && !event.target.closest(".dropdown-container")) {
        setOpenDropdownId(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openDropdownId]);
  const handleGetRoles = async () => {
    try {
      const result = await fetchApi.get("roles/");
      if (result.status === 200) {
        const data = result.data;
        const rolesresults = data?.results?.filter(
          (item) => item.name !== "SUPERUSER"
        );
        setRoles(rolesresults);

        // Find the Time Reporter role if it exists
        const timeReporterRole = rolesresults.find(
          (item) => item.name === "Time Reporter"
        );

        // Set the role to Time Reporter if found, otherwise use the first available role
        if (timeReporterRole) {
          setRole(timeReporterRole.id);
        } else if (rolesresults.length > 0) {
          setRole(rolesresults[0].id);
        }
      }
    } catch (error) {
      dispatch(addToast({ message: "Something went wrong", type: "error" }));
      console.log("Error getting roles: ", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === "password") {
      calculatePasswordStrength(value);
    }
  };
  const calculatePasswordStrength = (password) => {
    let strength = 0;
    if (password.length >= 8) strength++;
    if (/[A-Z]/.test(password)) strength++;
    if (/[a-z]/.test(password)) strength++;
    if (/\d/.test(password)) strength++;
    if (/[@$!%*?&]/.test(password)) strength++;
    setPasswordStrength(strength);
  };

  // Add these state variables at the top of the component with other state declarations
  const [showPassword, setShowPassword] = useState(false);
  const [modalForm, setModalForm] = useState("update");
  const [addLoading, setAddLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  // Add these constants that are used in the modal
  const paymentTypeOptions = ["hourly", "monthly", "weekly"];

  // Add state for role management
  const [role, setRole] = useState(null);
  const [roleDropdown, setRoleDropdown] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null);

  // Add handleOptionChange function
  const handleOptionChange = (field, value) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
    setOpenDropdown(null);
  };

  // Update the handleEdit function
  const handleEdit = (user) => {
    updateFormDataFromUser(user);
    setOpenDropdownId(null);
  };
  const handleView = (user) => {
    setUserData(user);
    setUserProfileModal(true);

    setOpenDropdownId(null);
  };

  const handleDelete = async (id) => {
    setIsDeleting(true);
    try {
        // API call to delete user
        await fetchApi.delete(`users/${id}/`, {
            headers: {
                Authorization: `Bearer ${userInfo.access_token}`,
            },
        });
        // Success: Show confirmation
        const filteredUsers =  filterUsers.filter((item) => item.id !== id);
        const newList= users.filter((item) => item.id !== id);
        setUserList(newList);
        dispatch(updateFilterUsers(filteredUsers)); // Dispatch updateFilterUsers action

        // Check if the deleted user was a Time Reporter
        const deletedUserRole = deletedUser?.role?.name; // Assuming deletedUser has a role property
        if (deletedUserRole === "Time Reporter") {
            dispatch(updateUsers(newList)); // Dispatch updateUsers action if deleted user was Time Reporter
        }

        dispatch(addToast({ message: "User deleted successfully", type: "success" }));
        setDeleteUser(null);
        setDeletePopup(false);
    } catch (error) {
        // Handle unauthorized session (401)
        if (error.response?.status === 401) {
            dispatch(toggleAuth({ isLogin: false, userInfo: null }));
            dispatch(addToast({ message: "Your session expired", type: "error" }));
            return;
        }
        const errorMessage =
            error.response?.data?.detail || error.message || "Something went wrong";
        dispatch(addToast({ message: errorMessage, type: "error" }));
    } finally {
        setIsDeleting(false);
    }
  };
  const updateFormDataFromUser = (user) => {
    handleGetRoles();
    setModalForm("update");
    setFormData((prevFormData) => ({
      ...prevFormData,
      id: user.id,
      first_name: user.first_name || "",
      last_name: user.last_name || "",
      password: user.password || "",
      email: user.email || "",
      currency: user.currency || "",
      payment_amount: user.payment_amount?.toString() || "",
      payment_type: user.payment_type || "",
      project: user?.project?.id || "",
      domain: user.domain || prevFormData.domain,
    }));

    // Set the role if user has one
    if (user.role) {
      setRole(user.role);
    }
    setUserPictureUrl(user.picture || "");

    setIsModalOpen(true);
  };
  const handleUpdateUser = async (e) => {
    e.preventDefault();
    setAddLoading(true);

    // Check for required fields
    if (!formData.first_name || !formData.last_name || !formData.email) {
      dispatch(addToast({ message: "Name and email require", type: "warn" }));
      return;
    }

    // Find the corresponding user from the users array
    const existingUser = users.find((user) => user.id === userId);

    if (!existingUser) {
      dispatch(addToast({ message: "User not found", type: "error" }));
      return;
    }

    const updatedFields = {};
    Object.keys(formData).forEach((key) => {
      if (
        key !== "password" && // Exclude password from comparison
        formData[key] !== existingUser[key] // Compare with the existing user
      ) {
        updatedFields[key] = formData[key];
      }
    });

    // Handle password update separately
    if (formData.password) {
      if (formData.password.length < 8) {
        dispatch(
          addToast({
            message: "Password must be at least 8 characters",
            type: "error",
          })
        );
        return;
      } else {
        try {
          setAddLoading(true);
          const res = await fetchApi.post(
            "change-password/",
            { user_id: formData.id, password: formData.password },
            {
              headers: {
                Authorization: `Bearer ${userInfo.access_token}`,
              },
            }
          );
          setPasswordChange(false);
          if (res.status !== 200) {
            throw new Error("Password update failed");
          }
        } catch (error) {
          setLoading(false);
          dispatch(
            addToast({ message: "Password update failed", type: "error" })
          );
          return;
        }
      }
    }

    // If no fields were changed, abort the request
    if (Object.keys(updatedFields).length === 0) {
      dispatch(addToast({ message: "No changes were made", type: "warn" }));
      return;
    }

    // Add `is_archived` explicitly, if necessary
    updatedFields.is_archived = false;
    try {
      // Make a PATCH request with only the updated fields
      const result = await fetchApi.patch(`users/${formData.id}/`, updatedFields, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });

      if (result.status === 200 || result.status === 204) {
        // Update the user in the user list
        const updatedUserList = users.map(user => 
            user.id === formData.id ? { ...user, ...result.data.user } : user
        );
        setUserList(updatedUserList);
        dispatch(updateUsers(updatedUserList)); // Dispatch updateUsers action

        setFormData({
          first_name: "",
          last_name: "",
          email: "",
          password: "",
          payment_amount: "",
          payment_type: "",
          currency: "",
          project: "",
        });
        setPasswordChange(false);
        dispatch(
          addToast({ message: "User update successfully", type: "success" })
        );
        setIsModalOpen(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(addToast({ message: "Your session expired", type: "error" }));
        return;
      }
      const errorMessage =
        error?.response?.data?.error ||
        error?.response?.data?.email?.[0] ||
        error?.response?.data?.[0] ||
        "User update failed";
      dispatch(addToast({ message: errorMessage, type: "error" }));
      console.log(error);
    } finally {
      setAddLoading(false);
    }
  };

  const getProjects = async () => {
    try {
      const res = await fetchApi.get("projects/", {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });
      if (res.status === 200) {
        setProjects(res.data.results);
        setNext(res.data.next);
      }
    } catch (error) {
      dispatch(addToast({ message: error.message, type: "error" }));
    }
  };
  const getMore = async (url) => {
    try {
      setIsLoadingMore(true);
      const res = await fetchApi.get(url, {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });
      if (res.status === 200) {
        setProjects((prev) => [...prev, ...res.data.results]);
        setNext(res.data.next);
      }
    } catch (error) {
      dispatch(addToast({ message: error.message, type: "error" }));
    } finally {
      setIsLoadingMore(false);
    }
  };

  useEffect(() => {
    getProjects();
  }, []);

  // Add this function to handle project dropdown scroll
  const handleProjectScroll = async (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;

    // Check if we've scrolled to the bottom and there's a next page
    if (scrollHeight - scrollTop === clientHeight && next && !isLoadingMore) {
      await getMore(next);
    }
  };

  const handleAddUser = async (e) => {
    e.preventDefault();

    if (
      !formData.first_name ||
      !formData.last_name ||
      !formData.email ||
      !formData.password ||
      !formData.payment_amount ||
      !formData.payment_type
    ) {
      dispatch(addToast({ message: "All fields is required", type: "error" }));
      return;
    }
    if (!role) {
      dispatch(addToast({ message: "Please select user role", type: "error" }));
      return;
    }

    setAddLoading(true);
    const userData = new FormData();
    userData.append("first_name", formData.first_name);
    userData.append("last_name", formData.last_name);
    userData.append("email", formData.email);
    userData.append("password", formData.password);
    userData.append("payment_amount", formData.payment_amount);
    userData.append("currency", "USD");
    userData.append("payment_type", formData.payment_type);
    userData.append("project", formData.project);
    userData.append("role", role);
    userData.append("domain", userInfo.user.domain);

    try {
      const result = await fetchApi.post("add-user/", userData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });

      if (result.status === 201) {
        const newUser = result.data; // Assuming the API returns the new user
        setUserList((prev) => [...prev, newUser]); // Add new user to the list
        dispatch(updateFilterUsers([...filterUsers, newUser])); // Dispatch updateFilterUsers action
        if(newUser?.role?.name === 'Time Reporter'){
        dispatch(updateUsers([...users, newUser]));
        } // Dispatch updateUsers action for new user

        setFormData({
          first_name: "",
          last_name: "",
          email: "",
          password: "",
          payment_amount: "",
          payment_type: "",
          currency: "",
          project: "",
        });
        dispatch(
          addToast({ message: "User added successfully", type: "success" })
        );
        setIsModalOpen(false);
        setAddLoading(false);
      }
    } catch (error) {
      const errorMessage =
        error?.response?.data?.error ||
        error?.response?.data?.email?.[0] ||
        error?.response?.data?.[0] ||
        error?.response?.data?.non_field_errors[0] ||
        "User add failed";
      setAddLoading(false);
      dispatch(addToast({ message: errorMessage, type: "error" }));
      console.log(error);
    } finally {
      setAddLoading(false);
    }
  };

  return (
    <div
      className={`rounded-[20px] shadow-lg h-full customFont p-4 ${
        theme === "dark" ? "bg-[#1C1C1E] text-white" : "bg-white text-black"
      }`}
    >
      <div className="flex justify-between items-center">
        <div className="flex gap-2 items-center">
          <span className="bg-gradient-to-r from-[#CC0927] to-[#7E0105] p-2 rounded-[10px]">
            <FaUser className="w-6 h-6 text-white" />
          </span>
          <h1 className="text-lg font-semibold">Users</h1>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4 mt-4">
        {displayedUsers.map((user) => (
          <div
            key={user.id}
            className={`relative flex flex-col items-center justify-center border-t border-x rounded-[20px] 
                            ${
                              theme === "dark"
                                ? "bg-[#212529] border-[#555657]"
                                : "bg-[#F2F4F7] border-[#D8D8D8]"
                            } pb-10`}
          >
            <div className="w-full absolute top-[13px] px-2 flex justify-end dropdown-container">
              <div className="relative">
                <BsThreeDotsVertical
                  className="w-5 h-5 cursor-pointer"
                  onClick={() =>
                    setOpenDropdownId(
                      openDropdownId === user.id ? null : user.id
                    )
                  }
                />
                {openDropdownId === user.id && (
                  <div
                    className={`absolute left-0 mt-2 w-32 z-10 rounded-[10px] shadow-lg border overflow-hidden
                                            ${
                                              theme === "dark"
                                                ? "bg-[#212529] border-[#555657]"
                                                : "bg-[#F2F4F7] border-[#9A9F9A]"
                                            }`}
                  >
                    <button
                      className={`w-full text-left px-4 py-2 text-base ${
                        theme === "dark"
                          ? "hover:bg-gradient-to-r hover:from-[#fff3] hover:to-[#fff0] transition-all"
                          : "hover:bg-gradient-to-r hover:from-[#6d6d6d33] hover:to-[#fff0] transition-all"
                      }`}
                      onClick={() => handleEdit(user)}
                    >
                      Edit
                    </button>
                    <button
                      className={`w-full text-left px-4 py-2 text-base text-red-500 ${
                        theme === "dark"
                          ? "hover:bg-gradient-to-r hover:from-[#fff3] hover:to-[#fff0] transition-all"
                          : "hover:bg-gradient-to-r hover:from-[#6d6d6d33] hover:to-[#fff0] transition-all"
                      }`}
                      onClick={() => {
                        setDeletePopup(true);
                        setDeleteUser(user);
                      }}
                    >
                      Delete
                    </button>
                  </div>
                )}
              </div>
            </div>

            {user.picture ? (
              <img
                src={user.picture}
                alt={`${user.first_name} ${user.last_name}`}
                className={`w-[40px] h-[40px] rounded-full mx-auto mb-2 mt-2 object-cover
                                    ${
                                      theme === "dark"
                                        ? "border-[#FFFFFF]"
                                        : "border-[#D8D8D8]"
                                    }`}
              />
            ) : (
              <FaUserCircle className="w-[40px] h-[40px] mx-auto mb-2 mt-2 text-gray-400" />
            )}

            <h3 className="text-center font-medium mb-1">
              {user.first_name} {user.last_name}
            </h3>
            <p className="text-center text-sm text-gray-500 mb-3">
              {formatPayment(
                user.payment_amount,
                user.currency,
                user.payment_type
              )}
            </p>

            <button
              className="w-full py-2 outline-none absolute bottom-0 bg-[#FFB3B5] text-[#CC0927] rounded-b-[20px]"
              onClick={() => handleView(user)}
            >
              View Profile
            </button>
          </div>
        ))}

        {/* Remaining users count */}
        {remainingUsersCount > 0 && (
          <Link to='/dashboard/users'
            className={`flex items-center justify-center p-4 border rounded-[20px] cursor-pointer ${
              theme === "dark"
                ? "bg-[#212529] border-[#555657]"
                : "bg-[#F2F4F7] border-[#D8D8D8]"
            }`}
          >
            <span className="rounded-full p-2 border">
              +{remainingUsersCount}
            </span>
          </Link>
        )}
      </div>
      <button
        className="w-full mt-6 heroButton"
        onClick={() => {
          setIsModalOpen(true);
          setModalForm("new");
          handleGetRoles()
        }}
      >
        Add New User
      </button>

      {isModalOpen && (
        <div className={styles.modalOverlay}>
          <div
            className={`${styles.modal} w-full max-w-screen-sm rounded-[10px] ${
              theme === "dark"
                ? "bg-[#212529] text-white"
                : "bg-[#e5e5e5] text-black"
            }`}
          >
            <div className={styles.modalHeader}>
              <h2 className="font-semibold text-xl flex items-center gap-2">
                {userPictureUrl ? (
                  <img
                    src={userPictureUrl}
                    alt="user"
                    className="w-8 h-8 rounded-full"
                  />
                ) : (
                  <FaUserCircle className="w-8 h-8" />
                )}
                {modalForm === "new"
                  ? "Add New User"
                  : `${formData.first_name} ${formData.last_name}`}
              </h2>
              <button
                className={` p-2 outline-none text-center flex items-center rounded-full cursor-pointer ${
                  theme === "dark" ? "bg-[#555657]" : "bg-[#D8D8D8]"
                }`}
                onClick={() => {
                  setIsModalOpen(false);
                  setPasswordChange(false);
                  setFormData({
                    first_name: "",
                    last_name: "",
                    email: "",
                    password: "",
                    payment_amount: "",
                    payment_type: "",
                    currency: "",
                    project: "",
                  });
                  setUserPictureUrl("");
                }}
              >
                <IoIosClose className="w-6 h-6" />
              </button>
            </div>
            <hr
              className={`border-t-2 mt-4 mb-3 rounded-md ${
                theme === "dark" ? "border-[#555657]" : "border-gray-400"
              }`}
            />
            <form
              onSubmit={modalForm === "new" ? handleAddUser : handleUpdateUser}
            >
              <div className="grid sm:grid-cols-2 grid-cols-1 sm:gap-4">
                <div className={styles.formGroup}>
                  <label className="flex items-center gap-1 mb-2 text-base">
                    <FaRegUser className="w-5 h-5" /> First name
                  </label>
                  <input
                    type="text"
                    name="first_name"
                    value={formData.first_name}
                    onChange={handleInputChange}
                    placeholder="Enter first name"
                    className={`cursor-pointer p-2 border rounded-[10px] w-full ${
                      theme === "dark"
                        ? "bg-transparent text-white border-[#555657] "
                        : "bg-transparent text-black border-gray-400"
                    }
                  ${formData.payment_type ? "" : "text-[#ABADB3]"} `}
                  />
                </div>

                <div className={styles.formGroup}>
                  <label className="flex items-center gap-1 mb-2 text-base">
                    <FaRegUser className="w-5 h-5" /> Last name
                  </label>
                  <input
                    type="text"
                    name="last_name"
                    value={formData.last_name}
                    onChange={handleInputChange}
                    placeholder="Enter last name"
                    className={`cursor-pointer p-2 border rounded-[10px] w-full ${
                      theme === "dark"
                        ? "bg-transparent text-white border-[#555657] "
                        : "bg-transparent text-black border-gray-400"
                    }
                  ${formData.payment_type ? "" : "text-[#ABADB3]"}`}
                  />
                </div>

                <div className={styles.formGroup}>
                  <label className="flex items-center gap-1 mb-2 text-base">
                    <MdOutlineEmail className="w-6 h-6" /> E-Mail
                  </label>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    placeholder="Enter email"
                    className={`cursor-pointer p-2 border rounded-[10px] w-full ${
                      theme === "dark"
                        ? "bg-transparent text-white border-[#555657] "
                        : "bg-transparent text-black border-gray-400"
                    }
                  ${formData.payment_type ? "" : "text-[#ABADB3]"}`}
                  />
                </div>

                <div
                  className={styles.formGroup}
                  style={{ marginBottom: "0px" }}
                >
                  <label className="mb-2 flex items-center gap-1 text-base">
                    <CiLock className="w-5 h-5" /> Password
                  </label>
                  {modalForm === "update" && !passwordchange ? (
                    <div
                      className={`rounded-[10px] border p-2 w-full flex justify-between ${
                        theme === "dark"
                          ? "border-[#555657]"
                          : "border-gray-400"
                      }`}
                    >
                      <input
                        type="password"
                        value="........"
                        className="bg-transparent w-[70px] outline-none"
                      />
                      <span
                        onClick={() => setPasswordChange(true)}
                        className="cursor-pointer hover:text-red-600"
                      >
                        Change
                      </span>
                    </div>
                  ) : (
                    <div
                      className={`relative p-2 bg-transparent border rounded-[10px] ${
                        theme === "dark"
                          ? "border-[#555657] text-white"
                          : "border-gray-400 text-black"
                      }`}
                    >
                      <input
                        type={showPassword ? "text" : "password"}
                        name="password"
                        value={formData.password}
                        onChange={handleInputChange}
                        placeholder={
                          modalForm === "update"
                            ? "Enter New Password"
                            : "Enter Password"
                        }
                        className="input bg-transparent outline-none"
                        style={{ paddingRight: "35px" }}
                      />
                      <div
                        className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <FaEye /> : <FaEyeSlash />}
                      </div>
                    </div>
                  )}
                  <div className="w-full h-2 mt-2 rounded-full overflow-hidden">
                    <div
                      className={`h-full rounded-full transition-all duration-300 ${
                        passwordStrength < 2
                          ? "bg-red-500"
                          : passwordStrength < 4
                          ? "bg-orange-500"
                          : "bg-green-500"
                      }`}
                      style={{ width: `${(passwordStrength / 5) * 100}%` }}
                    ></div>
                  </div>
                </div>

                {/* Payment Type Selector */}
                <div className="formGroup mb-[15px]">
                  <label className="flex items-center gap-1 mb-2 text-base">
                    <TbCalendarDollar className="w-5 h-5" /> Payment Type
                  </label>
                  <div className="relative">
                    <div
                      className={`cursor-pointer p-2 border rounded-[10px] w-full flex justify-between items-center ${
                        theme === "dark"
                          ? "bg-transparent text-white border-[#555657] "
                          : "bg-transparent text-black border-gray-400"
                      }
                      ${formData.payment_type ? "" : "text-[#ABADB3]"}
                      `}
                      onClick={() =>
                        setOpenDropdown((prev) =>
                          prev === "paymentType" ? null : "paymentType"
                        )
                      }
                    >
                      {formData.payment_type || "Select Payment Type"}
                      {openDropdown === "paymentType" ? (
                        <FaAngleUp className="text-gray-500" />
                      ) : (
                        <FaAngleDown className="text-gray-500" />
                      )}
                    </div>
                    {openDropdown === "paymentType" && (
                      <div
                        className={`absolute z-10 mt-2 max-h-[200px] overflow-y-auto w-full rounded shadow-lg ${
                          theme === "dark" ? "bg-dashboardDarkMode" : "bg-white"
                        }`}
                      >
                        {paymentTypeOptions.map((type, index) => (
                          <div
                            key={index}
                            onClick={() =>
                              handleOptionChange("payment_type", type)
                            }
                            className={`p-2 cursor-pointer ${
                              theme === "dark"
                                ? "hover:bg-gradient-to-r hover:from-[#fff3] hover:to-[#fff0] transition-all"
                                : "hover:bg-gradient-to-r hover:from-[#6d6d6d33] hover:to-[#fff0] transition-all"
                            }`}
                          >
                            {type}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>

                <div className={styles.formGroup}>
                  <label className="flex items-end gap-1 mb-2">
                    <AiOutlineDollar className="w-5 h-5" /> Payment Amount
                  </label>
                  <input
                    type="number"
                    name="payment_amount"
                    value={formData.payment_amount}
                    onChange={handleInputChange}
                    placeholder="Enter an amount"
                    className={`cursor-pointer p-2 border rounded-[10px] w-full ${
                      theme === "dark"
                        ? "bg-transparent text-white border-[#555657] "
                        : "bg-transparent text-black border-gray-400"
                    }
                  ${formData.payment_type ? "" : "text-[#ABADB3]"}`}
                  />
                </div>
                <div className="formGroup mb-[15px]">
                  <label className="flex mb-2 gap-1 items-center text-base">
                    <MdOutlineTask className="w-5 h-5" /> Project
                  </label>
                  <div className="relative">
                    <div
                      className={`cursor-pointer p-2 border rounded-[10px] w-full flex justify-between items-center ${
                        theme === "dark"
                          ? "bg-transparent text-white border-[#555657]"
                          : "bg-transparent text-black border-gray-400"
                      } ${formData.project ? "" : "text-[#ABADB3]"}`}
                      onClick={() =>
                        setOpenDropdown((prev) =>
                          prev === "project" ? null : "project"
                        )
                      }
                    >
                      {formData?.project
                        ? projectList.find((c) => c.id === formData.project)
                            ?.title || "Choose project"
                        : "Choose project"}
                      {openDropdown === "project" ? (
                        <FaAngleDown className="text-gray-500" />
                      ) : (
                        <FaAngleUp className="text-gray-500" />
                      )}
                    </div>
                    {openDropdown === "project" && (
                      <div
                        className={`absolute z-10 bottom-[46px] max-h-[200px] overflow-y-auto w-full rounded shadow-lg ${
                          theme === "dark" ? "bg-dashboardDarkMode" : "bg-white"
                        }`}
                        onScroll={handleProjectScroll}
                      >
                        <Link
                          to="/dashboard/board"
                          className={`p-2 cursor-pointer w-full flex ${
                            theme === "dark"
                              ? "bg-gradient-to-r from-[#fff3] to-[#fff0] transition-all"
                              : "bg-gradient-to-r from-[#6d6d6d33] to-[#fff0] transition-all"
                          }`}
                        >
                          Create New Project
                        </Link>
                        {projectList.map((project, index) => (
                          <div
                            key={index}
                            onClick={() =>
                              handleOptionChange("project", project.id)
                            }
                            className={`p-2 cursor-pointer ${
                              theme === "dark"
                                ? "hover:bg-gradient-to-r hover:from-[#fff3] hover:to-[#fff0] transition-all"
                                : "hover:bg-gradient-to-r hover:from-[#6d6d6d33] hover:to-[#fff0] transition-all"
                            }`}
                          >
                            {project.title}
                          </div>
                        ))}
                        {isLoadingMore && (
                          <div className="flex justify-center p-2">
                            <img
                              src={loader}
                              alt="Loading..."
                              className="w-6 h-6"
                            />
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className={`${styles.formGroup} `}>
                  <label className="flex items-center gap-1 mb-2 text-base">
                    <TbUserCog className="w-5 h-5" /> User Role
                  </label>
                  <div className="relative">
                    <div
                      className={`cursor-pointer p-2 border rounded-[10px] w-full flex justify-between items-center ${
                        theme === "dark"
                          ? "bg-transparent text-white border-[#555657]"
                          : "bg-transparent text-black border-gray-400"
                      } ${formData.project ? "" : "text-[#ABADB3]"}`}
                      onClick={() => setRoleDropdown(!roleDropdown)}
                    >
                      {role
                        ? roles?.find((c) => c.id === role)?.name ||
                          "Choose user role"
                        : "Choose user role"}
                      {roleDropdown ? (
                        <FaAngleDown className="text-gray-500" />
                      ) : (
                        <FaAngleUp className="text-gray-500" />
                      )}
                    </div>
                    {roleDropdown && (
                      <div
                        className={`absolute z-50 mt-2 w-full rounded shadow-lg ${
                          theme === "dark" ? "bg-dashboardDarkMode" : "bg-white"
                        }`}
                        style={{ top: "auto", bottom: "100%" }}
                      >
                        {roles?.map((role, index) => (
                          <div
                            key={index}
                            onClick={() => {
                              setRoleDropdown(false);
                              setRole(role.id);
                            }}
                            className={`p-2 cursor-pointer ${
                              theme === "dark"
                                ? "hover:bg-gradient-to-r hover:from-[#fff3] hover:to-[#fff0] transition-all"
                                : "hover:bg-gradient-to-r hover:from-[#6d6d6d33] hover:to-[#fff0] transition-all"
                            }`}
                          >
                            {role.name}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div
                className={`${styles.actions} flex w-full gap-2 items-center mt-2`}
              >
                <button
                  className={`py-2 rounded-full w-1/2 text-center border ${
                    theme === "dark" ? "border-[#555657]" : "border-gray-400"
                  } `}
                  onClick={() => {
                    setIsModalOpen(false);
                    setPasswordChange(false);
                    setFormData({
                      first_name: "",
                      last_name: "",
                      email: "",
                      password: "",
                      payment_amount: "",
                      payment_type: "",
                      currency: "",
                      project: "",
                    });
                    setUserPictureUrl("");
                  }}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={loading}
                  className="heroButton w-1/2"
                >
                  {addLoading
                    ? "Loading..."
                    : modalForm === "update"
                    ? "Update"
                    : "Create"}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      {userProfileModal && (
        <div className={`${styles.modalOverlay}`}>
          <div
            className={` max-w-sm rounded-[20px] w-full ${styles.modal} ${
              theme === "dark"
                ? "bg-[#212529] text-white"
                : "bg-[#F5F6FA] text-black"
            }`}
            style={{ overflow: "visible" }}
          >
            <div className={`${styles.modalHeader} flex w-full justify-end`}>
              <button
                className={` p-2 outline-none text-center flex items-center rounded-full cursor-pointer ${
                  theme === "dark" ? "bg-[#555657]" : "bg-[#D8D8D8]"
                }`}
                onClick={() => setUserProfileModal(false)}
              >
                <IoIosClose className="w-6 h-6" />
              </button>
            </div>
            <UserProfileCard
              data={userData}
              setUserProfileModal={setUserProfileModal}
              setIsModalOpen={setIsModalOpen}
              updateData={updateFormDataFromUser}
            />
          </div>
        </div>
      )}
      {deletPopup && (
        <div className={`${styles.modalOverlay}`}>
          <div
            className={` max-w-sm rounded-[20px] p-8 w-full ${styles.modal} ${
              theme === "dark"
                ? "bg-[#212529] text-white"
                : "bg-[#F5F6FA] text-black"
            }`}
            style={{ overflow: "visible", flexDirection: "column" }}
          >
            <div className={`${styles.modalHeader} flex w-full items-center`}>
              <h2 className="text w-full text-center text-lg font-medium">
                Confirm Delete User
              </h2>
              <button
                className={` p-2 outline-none text-center flex items-center rounded-full cursor-pointer ${
                  theme === "dark" ? "bg-[#555657]" : "bg-[#D8D8D8]"
                }`}
                onClick={() => {
                  setDeleteUser(null);
                  setDeletePopup(false);
                }}
              >
                <IoIosClose className="w-6 h-6" />
              </button>
            </div>
            <p className="my-4 text-center">
            Are you sure to delete user <span className="font-bold">{deletedUser?.first_name}{" "}
            {deletedUser?.last_name}</span>
          </p>
          <div className="flex gap-4 items-center justify-center w-[90%]">
            <button
              className={`py-2 rounded-full w-1/2 text-center border ${
                theme === "dark" ? "border-[#555657]" : "border-gray-400"
              } `}
              onClick={() => {
                setDeleteUser(null);
                setDeletePopup(false);
              }}
            >
              Cancel
            </button>
            <button
              className="heroButton w-1/2"
              onClick={() => handleDelete(deletedUser.id)}
              disabled={isDeleting}
            >
              {isDeleting ? "Deleting..." : "Delete"}
            </button>
          </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserManagement;
