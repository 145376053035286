import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  LabelList,
} from "recharts";

// Format date for display (monthly format)
const formatDateLabel = (dateStr) => {
  if (!dateStr) return "";
  
  const date = new Date(dateStr);
  return date.toLocaleDateString('en-US', { month: 'short', year: 'numeric' });
};

// Calculate Y-axis ticks with appropriate intervals
const calculateYTicks = (maxValue) => {
  if (maxValue <= 0) return [0];
  
  // Calculate nice rounded intervals
  const step = Math.pow(10, Math.floor(Math.log10(maxValue))) / 2;
  const tickCount = Math.ceil(maxValue / step) + 1;
  
  return Array.from({ length: tickCount }, (_, i) => i * step);
};

const MonthlyMileStoneBreakdown = ({ data }) => {
  const { theme } = useSelector((state) => state.theme);
  const [chartData, setChartData] = useState([]);
  const [yTicks, setYTicks] = useState([0]);
  
  useEffect(() => {
    if (!data || Object.keys(data).length === 0) {
      setChartData([]);
      setYTicks([0]);
      return;
    }
    
    // Process data for the chart
    const processedData = Object.entries(data).map(([date, value]) => ({
      date,
      label: formatDateLabel(date),
      value
    }));
    
    setChartData(processedData);
    
    // Calculate Y-axis ticks
    const maxValue = Math.max(...processedData.map(item => item.value));
    setYTicks(calculateYTicks(maxValue));
  }, [data]);

  return (
    <div className={`p-4 border rounded-[20px] lg:w-[65%] md:w-[55%] w-full flex flex-col items-center justify-center ${theme === 'dark' ? 'bg-[#212529] border-[#555657]' : 'bg-[#F2F4F7] border-[#D8D8D8]'}`}>
      <div className="w-full text-start flex">
        <h2 className="text-lg font-semibold mb-4 ml-4 text-center capitalize">
          Monthly Milestone Breakdown
        </h2>
      </div>

      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          data={chartData}
          margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
          barCategoryGap={20}
        >
          <CartesianGrid 
            strokeDasharray="3 3" 
            stroke={theme === "dark" ? "#666" : "#BBB"} 
            vertical={true} 
            horizontal={true}
          />
          
          <XAxis
            dataKey="label"
            tick={{ fill: theme === "dark" ? "white" : "black" }}
            tickLine={false}
            fontSize={10}
            axisLine={{ stroke: theme === "dark" ? "#666" : "#BBB" }}
          />
          
          <YAxis
            ticks={yTicks}
            domain={[0, 'dataMax + (dataMax * 0.2)']} // 20% padding
            tick={{ fill: theme === "dark" ? "white" : "black" }}
            axisLine={{ stroke: theme === "dark" ? "#666" : "#BBB" }}
            tickLine={{ stroke: theme === "dark" ? "#666" : "#BBB" }}
            tickFormatter={(value) => value.toLocaleString()}
          />
          
          <Tooltip 
            formatter={(value) => [value.toLocaleString(), "Value"]}
            labelFormatter={(label) => `Month: ${label}`}
            contentStyle={{ 
              backgroundColor: theme === "dark" ? "#222" : "#fff", 
              borderRadius: 5,
              border: `1px solid ${theme === "dark" ? "#555" : "#ddd"}`
            }}
            cursor={false}
          />
          
          <Bar
            dataKey="value"
            fill={theme === "dark" ? "#07DBFA" : "#01CCEA"}
            barSize={30}
            radius={[0, 0, 0, 0]}
            style={{ cursor: 'pointer' }}
          >
            
          </Bar>
        </BarChart>
      </ResponsiveContainer>

    </div>
  );
};

export default MonthlyMileStoneBreakdown;