import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { fetchApi } from "../../../utlis/axios";
import { useSelector } from "react-redux";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { FaUserCircle } from "react-icons/fa";
import { format, parseISO } from "date-fns";
import { useDispatch } from "react-redux";
import { toggleUserChats } from "../../../Redux/Slices/chat-slice";
import { GrAttachment } from "react-icons/gr";
import { addToast } from "../../../Redux/Slices/toast-slice";
const Chat = ({
  setIsOpen,
  sendMessage,
  readyState,
  messageHistory,
  isLoading,
  activeChat,
  openedChat,
  messagesEndRef,
}) => {
  const { userInfo } = useSelector((state) => state?.auth || {});
  const { theme } = useSelector((state) => state.theme);
  const chatHistory = useSelector((state) => state.chat.userChats);
  const dispatch = useDispatch();
  const [message, setMessage] = useState("");
  const [agentFirstMessage, setAgentFirstMessage] = useState([]);
  const fileInputRef = useRef(null);
  const [uploadedFileLink, setUploadedFileLink] = useState(null); //
  const [isUploading, setIsUploading] = useState(false);

  const handleClickSendMessage = useCallback(() => {
    if (readyState === ReadyState.OPEN) {
      if (message.trim() !== "" || uploadedFileLink) {
        let fileMessage = "";
        if (isUploading) {
          dispatch(addToast({ message:'File uploading...', type :'error' })); 
          return;
        } else {
          if (uploadedFileLink) {
            // Extract file extension from the URL
            const fileExtension = uploadedFileLink
              .split(".")
              .pop()
              .toLowerCase();

            // Define supported file types
            const imageTypes = ["jpg", "jpeg", "png", "gif", "webp"];
            const videoTypes = ["mp4", "webm", "ogg"];
            const pdfTypes = ["pdf"];

            if (imageTypes.includes(fileExtension)) {
              fileMessage = `<img src="${uploadedFileLink}" alt="attachment" />`;
            } else if (videoTypes.includes(fileExtension)) {
              fileMessage = `<video controls><source src="${uploadedFileLink}" type="video/${fileExtension}">Your browser does not support the video tag.</video>`;
            } else if (pdfTypes.includes(fileExtension)) {
              fileMessage = `<a href="${uploadedFileLink}" target="_blank">View PDF</a>`;
            } else {
              fileMessage = `<a href="${uploadedFileLink}" target="_blank">Download File</a>`;
            }
          }

          // Construct the final message
          const finalMessage = fileMessage
            ? `${fileMessage} <p>${message}</p>`
            : message;

          sendMessage(
            JSON.stringify({
              message: finalMessage,
              sender: userInfo?.user?.id,
            })
          );

          // Reset fields
          setMessage("");
          setUploadedFileLink(null);
        }
      }
    } else {
      console.error("WebSocket is not open. Message not sent.");
    }
  }, [message, readyState, uploadedFileLink, userInfo?.user?.id]);

  const connectionStatus = {
    [ReadyState.CONNECTING]: "Connecting",
    [ReadyState.OPEN]: "Open",
    [ReadyState.CLOSING]: "Closing",
    [ReadyState.CLOSED]: "Closed",
    [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  }[readyState];

  const groupedChatHistory = useMemo(() => {
    if (!Array.isArray(chatHistory)) return {};
    const groups = {};

    const sortedChatHistory = [...chatHistory].sort(
      (a, b) => new Date(a.created_at) - new Date(b.created_at)
    );

    sortedChatHistory.forEach((msg) => {
      const dateKey = format(parseISO(msg.created_at), "dd MMM yyyy"); // e.g., "07 Feb 2025"
      if (!groups[dateKey]) {
        groups[dateKey] = [];
      }
      groups[dateKey].push(msg);
    });

    return groups;
  }, [chatHistory]);
  const markMessageAsRead = async (messageId) => {
    try {
      await fetchApi.patch(
        `message/${messageId}/`,
        { read_status: true },
        {
          headers: {
            Authorization: `Bearer ${userInfo.access_token}`,
          },
        }
      );
    } catch (error) {
      console.error(`❌ Error marking message ${messageId} as read:`, error);
    }
  };
  const closeChat = async (chatId) => {
    setIsOpen(false);
    dispatch(toggleUserChats({ userChats: [] }));
    try {
      const res = await fetchApi.patch(
        `chat/${chatId}/`,
        { status: "closed" },
        {
          headers: {
            Authorization: `Bearer ${userInfo.access_token}`,
          },
        }
      );
    } catch (error) {}
  };
  useEffect(() => {
    const sortedChatHistory = [...chatHistory].sort(
      (a, b) => new Date(a.created_at) - new Date(b.created_at)
    );
    setAgentFirstMessage(
      sortedChatHistory.find((msg) => msg.sender.id !== userInfo.user.id)
    );
  }, [chatHistory]);

  const handleButtonClick = () => {
    fileInputRef.current.click(); // Trigger the hidden file input
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0]; // Get the selected file

    if (file) {
      // Simulate file upload
      const formData = new FormData();
      formData.append("file", file);
      setIsUploading(true);
      try {
        const response = await fetchApi.post("/attachments/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${userInfo.access_token}`,
          },
        });

        if (response.status === 201) {
          setUploadedFileLink(response.data.file);
        } else {
          dispatch(addToast({ message : ' File upload failed', type :'error' })); 
        }
      } catch (error) {
        dispatch(addToast({ message:'Something went wrong', type :'error' })); 
      } finally {
        setIsUploading(false);
      }
    }
  };

  return (
    <div>
      <div
        className={`customFont fixed bottom-24 z-50 right-8 sm:w-[430px] w-full max-w-full rounded-lg shadow-xl border-black border ${
          theme === "dark" ? "bg-darkMode text-white" : "bg-white text-black"
        }`}
      >
        <div
          className="flex items-center justify-between px-4 py-3 text-white rounded-t-lg"
          style={{
            background: "linear-gradient(90deg, #d90a2c 1.05%, #730000)",
          }}
        >
          <h3 className="font-semibold flex gap-2 justify-between">
            <span className="flex items-center space-x-2">
              <span
                className={`w-3 h-3 rounded-full ${
                  openedChat.status === "in_progress"
                    ? "bg-green-500"
                    : openedChat.status === "closed"
                    ? "bg-red-500"
                    : "bg-gray-500"
                }`}
              ></span>
              <span>
                {openedChat.status === "in_progress"
                  ? "Active"
                  : openedChat.status === "closed"
                  ? "Ended"
                  : "Waiting"}
              </span>
            </span>
            Chat Support{" "}
          </h3>
          <buttons className="heroButton" onClick={() => closeChat(activeChat)}>
            End chat
          </buttons>
        </div>

        <div className="p-4 max-h-72 overflow-y-auto gap-1">
          {chatHistory.length > 0 ? (
            Object.entries(groupedChatHistory).map(([date, messages]) => (
              <div key={date}>
                {/* Date Badge */}
                <div className="text-center text-xs bg-gray-300 text-black rounded-md py-1 my-2">
                  {date}
                </div>

                {/* Messages */}
                {messages?.map((msg, i) => (
                  <div key={i}>
                    {agentFirstMessage?.id === msg.id && (
                      <div className="text-center text-xs bg-gray-300 text-black rounded-md py-1 my-2">
                        {`Agent has joined the chat`}
                      </div>
                    )}
                    <div
                      className={`flex items-end gap-2 my-2 ${
                        msg?.sender?.id === userInfo?.user?.id
                          ? "justify-start flex-row-reverse"
                          : " "
                      }`}
                    >
                      <FaUserCircle className="w-6 h-6 text-gray-600 dark:text-gray-400" />
                      <div
                        className={` max-w-xs ${
                          msg?.sender?.id === userInfo.user?.id ? "" : " "
                        }`}
                      >
                        <div className="text-sm font-semibold flex gap-2 items-center">
                          {msg.sender.id === userInfo.user.id
                            ? `${msg.sender.first_name} ${msg.sender.last_name}`
                            : "Bugtrack Agent"}
                          <span className="text-xs text-gray-500 dark:text-gray-400">
                            {format(parseISO(msg.created_at), "hh:mm a")}
                          </span>
                        </div>
                        <div
                          className={`${
                            msg.sender.id === userInfo.user.id
                              ? theme === "dark"
                                ? "bg-red-600 text-white rounded-bl-md"
                                : "bg-red-600 text-white rounded-bl-md"
                              : theme === "dark"
                              ? "bg-gray-800 text-white rounder-br-md"
                              : "bg-gray-200 text-gray-700 rounder-br-md"
                          } p-1 rounded-t-md mt-1 text-sm}`}
                          dangerouslySetInnerHTML={{ __html: msg.content }}
                        ></div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ))
          ) : (
            <p>Hello! How can we help you?</p>
          )}

          {/* Render live messageHistory (WebSocket messages) */}
          {messageHistory.map((msg, i) => (
            <div
              key={i}
              className={`flex items-end gap-2 my-2 ${
                msg.sender === userInfo.user.id
                  ? "justify-start flex-row-reverse"
                  : ""
              }`}
            >
              <FaUserCircle className="w-6 h-6 text-gray-600 dark:text-gray-400" />
              <div
                className={`px-2 rounded-lg max-w-xs ${
                  msg.sender === userInfo.user.id ? "" : ""
                }`}
              >
                <div className="text-sm font-semibold flex gap-2 items-center">
                  {msg.sender === userInfo.user.id
                    ? `${msg.user_name}`
                    : "Bugtrack Agent"}
                  <span className="text-xs text-gray-500 dark:text-gray-400">
                    {format(parseISO(msg.timestamp), "hh:mm a")}
                  </span>
                </div>
                <div
                  className={`${
                    msg.sender === userInfo.user.id
                      ? theme === "dark"
                        ? "bg-red-600 text-white rounded-bl-md"
                        : "bg-red-600 text-white rounded-bl-md"
                      : theme === "dark"
                      ? "bg-gray-800 text-white rounder-br-md"
                      : "bg-gray-200 text-gray-700 rounder-br-md"
                  } p-1 rounded-t-md mt-1 text-sm}`}
                  dangerouslySetInnerHTML={{ __html: msg.message }}
                ></div>
              </div>
            </div>
          ))}

          <div ref={messagesEndRef} />
        </div>
        <div className="flex items-center p-4 gap-2 border-t border-gray-300">
          <div
            className={`flex gap-2 px-2 w-full items-center  border bg-transparent rounded-md f ${
              theme === "dark"
                ? "text-white border-gray-600"
                : "text-gray-900 border-gray-400"
            }`}
          >
            {" "}
            <input
              type="text"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Type a message..."
              className={`bg-transparent py-2 px-1 outline-none border-none w-full`}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleClickSendMessage();
                }
              }}
            />
            <div>
              <button onClick={handleButtonClick}>
                <GrAttachment className="w-6 h-6" />
              </button>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }} // Hide the file input
                onChange={handleFileChange}
                disabled={isLoading}
              />
            </div>
          </div>
          <button
            className="heroButton cursor-pointer text-center flex justify-center items-center"
            onClick={handleClickSendMessage}
            disabled={isLoading}
          >
            Send
          </button>
        </div>
      </div>
    </div>
  );
};

export default Chat;
