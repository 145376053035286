import React, { useState, useEffect, useRef } from "react";
import styles from "./offtime.module.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchApi } from "../../../utlis/axios";
import Loader from "../../Loader/Loader";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { IoMdArrowDropdown } from "react-icons/io";
import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io";
import { addToast } from "../../../Redux/Slices/toast-slice";

const OfftimeCreate = ({ parent }) => {
  const { theme } = useSelector((state) => state.theme);
  const { userInfo } = useSelector((state) => state?.auth || {});
  const { userId, users, dataLoading } = useSelector(
    (state) => state?.allUsers || {}
  );
  const dispatch = useDispatch()
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const [requestData, setRequestData] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [dataByDate, setDataByDate] = useState(
    //new Date().toISOString().split("T")[0]
    []
  );
  const [days, setDays] = useState([]);
  const [selectedDate, setSelectedDate] = useState([]);
  const [openDropdownId, setOpenDropdownId] = useState(null);
  const dropdownRef = useRef(null);
  const [next, setNext] = useState(null);
  const [prev, setPrev] = useState(null);
  const [expandedRow, setExpandedRow] = useState(null);
  const [approvedLeaves, setApprovedLeaves] = useState([]);

  const handleRowToggle = (rowId) => {
    setExpandedRow((prevRow) => (prevRow === rowId ? null : rowId));
  };

  // Ref to prevent repeated fetch calls
  const isFetchedRef = useRef(false);
  const handleCreate = async () => {
    if (!title || selectedDate.length === 0) {
      dispatch(addToast({ message: 'All fields required', type: 'error' }));
    } else {
      setIsDropdownOpen(false);
      try {
        setUpdating(true);
        const result = await fetchApi.post(
          "user-timeoffs/",
          {
            title,
            dates: selectedDate,
            status: "pending",
            user: userInfo?.user?.id,
          },
          {
            headers: {
              Authorization: `Bearer ${userInfo.access_token}`,
            },
          }
        );

        if (result.status === 200) {
          GetTimeOffData();
          setTitle("");
          setSelectedDate([]);
          setDataByDate([]);
          setIsDropdownOpen(false);
          setIsModalOpen(false);
          dispatch(addToast({ message: 'Request created', type: 'success' }));
        }
      } catch (error) {
        dispatch(addToast({ message: 'Something went wrong', type: 'error' }));
      } finally {
        setUpdating(false);
      }
    }
  };

  const GetTimeOffData = async () => {
    setLoading(true);
    try {
      const res = await fetchApi.get(`user-timeoffs/`,
        {
          headers: {
            Authorization: `Bearer ${userInfo.access_token}`,
          },
        }
      );

      if (res.status === 200) {
        setRequestData(res.data.results);
        setNext(res.data.next);
        setPrev(res.data.previous);
      }
    } catch (error) {
      dispatch(addToast({ message: 'Something went wrong', type: 'error' }));
    } finally {
      setLoading(false);
    }
  };
  const GetTimeOffDataForUser = async () => {
    setLoading(true);
    try {
      const res = await fetchApi.get(`user-timeoffs/?user=${userId}`,
        {
          headers: {
            Authorization: `Bearer ${userInfo.access_token}`,
          },
        }
      );

      if (res.status === 200) {
        setRequestData(res.data.results);
        setNext(res.data.next);
        setPrev(res.data.previous);
      }
    } catch (error) {
      dispatch(addToast({ message: 'Something went wrong', type: 'error' }));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isFetchedRef.current) return;
    if (parent !== "activity-tab") {
      isFetchedRef.current = true;
      GetTimeOffData();
    }
  }, []);
  useEffect(() => {
    if (parent === "activity-tab") {
      GetTimeOffDataForUser()
    }
  }, [userId]);


  const handleNext = async () => {
    setLoading(true);

    try {
      const res = await fetchApi.get(`${next}`, {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });

      if (res.status === 200) {
        setRequestData(res.data.results);
        setNext(res.data.next);
        setPrev(res.data.previous);
      }
    } catch (error) {
      dispatch(addToast({ message: error.message, type: 'error' }));
    } finally {
      setLoading(false);
    }
  };
  const handlePrevious = async () => {
    setLoading(true);

    try {
      const res = await fetchApi.get(`${prev}`, {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });

      if (res.status === 200) {
        setRequestData(res.data.results);
        setNext(res.data.next);
        setPrev(res.data.previous);
      }
    } catch (error) {
      dispatch(addToast({ message: error.message, type: 'error' }));
    } finally {
      setLoading(false);
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setOpenDropdownId(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const joiningDate = new Date(userInfo?.user?.created_at);
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  useEffect(() => {
    generateCalendar(currentDate);
  }, [currentDate]);

  const generateCalendar = (date) => {
    const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    const lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    const lastDayOfPrevMonth = new Date(
      date.getFullYear(),
      date.getMonth(),
      0
    ).getDate();

    const today = new Date();
    today.setHours(0, 0, 0, 0); // Reset time for accurate comparison

    const daysInMonth = [];

    // Add previous month's days in correct order
    const startDay = firstDayOfMonth.getDay(); // Day of the week (0 for Sunday, 1 for Monday, etc.)
    for (let i = startDay - 1; i >= 0; i--) {
      const dayDate = new Date(
        date.getFullYear(),
        date.getMonth() - 1,
        lastDayOfPrevMonth - i
      );
      daysInMonth.push({
        day: lastDayOfPrevMonth - i,
        currentMonth: false,
        isPastDate: dayDate < today,
      });
    }

    // Add current month's days
    for (let i = 1; i <= lastDayOfMonth.getDate(); i++) {
      const dayDate = new Date(date.getFullYear(), date.getMonth(), i);
      daysInMonth.push({
        day: i,
        currentMonth: true,
        isPastDate: dayDate < today,
      });
    }

    // Add next month's days
    const remainingDays = 35 - daysInMonth.length; // Adjust to ensure a 5-week grid
    for (let i = 1; i <= remainingDays; i++) {
      const dayDate = new Date(date.getFullYear(), date.getMonth() + 1, i);
      daysInMonth.push({
        day: i,
        currentMonth: false,
        isPastDate: dayDate < today,
      });
    }

    setDays(daysInMonth);
  };

  const goToPrevMonth = () => {
    const prevMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() - 1,
      1
    );
    if (
      prevMonth.getFullYear() > joiningDate.getFullYear() ||
      (prevMonth.getFullYear() === joiningDate.getFullYear() &&
        prevMonth.getMonth() >= joiningDate.getMonth())
    ) {
      setCurrentDate(prevMonth);
    }
  };

  const goToNextMonth = () => {
    const nextMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      1
    );
    setCurrentDate(nextMonth);
  };

  const handleDateClick = (event, day) => {
    event.stopPropagation(); // Prevent event propagation to document click listener

    const selectedDate = formatDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth(), day)
    );

    setDataByDate((prevDates) => {
      if (prevDates.includes(selectedDate)) {
        // Remove the date if it already exists
        return prevDates.filter((date) => date !== selectedDate);
      } else {
        // Add the date if it doesn't exist
        return [...prevDates, selectedDate];
      }
    });

    setSelectedDate((prevDates) => {
      if (prevDates.includes(selectedDate)) {
        // Remove the date if it already exists
        return prevDates.filter((date) => date !== selectedDate);
      } else {
        // Add the date if it doesn't exist
        return [...prevDates, selectedDate];
      }
    });
  };

  const filterFutureDates = (data) => {
    const currentDate = new Date(); // Get the current date

    // Flatten and filter the dates from all objects
    const futureDates = data.results
      .flatMap((item) => item.dates) // Extract all dates into a single array
      .filter((date) => new Date(date) > currentDate); // Keep only dates after today

    return futureDates;
  };
  const isLeavesFetched = useRef(false);
  const getLeaves = async () => {
    try {
      const response = await fetchApi.get(
        `user-timeoffs/?user=${userInfo?.user?.id}&status=approved`,
        {
          headers: {
            Authorization: `Bearer ${userInfo.access_token}`,
          },
        }
      );
      if (response.status === 200) {
        setApprovedLeaves(filterFutureDates(response?.data));
      }
    } catch (error) { }
  };
  useEffect(() => {
    if (isLeavesFetched.current) return;
    else {
      isLeavesFetched.current = true;
      getLeaves();
    }
  }, []);

  return (
    <>
      <style>
        {`
          .reportInput {
            width: 100%;
            padding: 10px;
            border: ${theme === "dark" ? "1px solid #ffffff" : "1px solid #000000"
          };
            border-radius: 5px;
            margin-top: 5px;
            box-sizing: border-box; 
            overflow-y: auto;
            background-color: transparent;
          }
          .reportInput option {
            color: black;
          }
        `}
      </style>
      {loading && (
        <>
          <div
            className="fixed inset-0 bg-black bg-opacity-50 z-10"
            style={{ top: "95px" }}
          ></div>
          <div
            className="fixed inset-0 flex items-center justify-center z-20"
            style={{ top: "95px" }}
          >
            <Loader />
          </div>
        </>
      )}
      <div
        className={`${styles.main_Heading} ${theme === "dark" ? "text-white" : "text-black"
          } ${parent === "activity-tab" ? "hidden" : "block"}`}
      >
        Time Offs
      </div>
      <hr
        className={`${parent === "activity-tab" ? "hidden" : "block"} w-full`}
      />
      <div
        className={`${styles.main_container} ${parent === "activity-tab" ? "hidden" : "block"
          }`}
      >
        <div className={styles.actions}>
          {isModalOpen && (
            <div className={`${styles.modalOverlay}`}>
              <div
                className={` sm:w-[450px] w-full ${styles.modal} ${theme === "dark"
                    ? "bg-[#212529] text-white"
                    : "bg-[#e5e5e5] text-black"
                  }`}
                style={{ position: "relative", overflow: "visible" }}
              >
                <div className={styles.modalHeader}>
                  <h2 className="font-semibold">Create Timeoffs Request</h2>
                  <button
                    className={styles.closeButton}
                    onClick={() => setIsModalOpen(false)}
                  >
                    &times;
                  </button>
                </div>
                <div
                  className={`${styles.modalContent}`}
                  style={{ minWidth: "auto" }}
                >
                  <div className="text-left mt-3">
                    <div className="w-full flex flex-col gap-4 ">
                      <div className={`${styles.formGroup}  w-[90%]`}>
                        <label>Title*</label>
                        <input
                          className={styles.reportInput}
                          type="text"
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                          placeholder="Enter title"
                          required
                        />
                      </div>
                      <div>
                        <label htmlFor="date">Date*</label>
                        <div
                          className={`${styles["calendar-header"]}  w-[90%] p-[10px]  mt-[5px] rounded-[5px] backdrop-blur`}
                          // onMouseLeave={() => setIsDropdownOpen(false)}
                          style={{
                            border: "1px solid #727171",
                            position: "relative",
                            cursor: "pointer",
                          }}
                        >
                          <div>
                            <span className={styles["month-div"]}>
                              {!(
                                currentDate.getFullYear() ===
                                new Date().getFullYear() &&
                                currentDate.getMonth() === new Date().getMonth()
                              ) && <FaArrowLeft onClick={goToPrevMonth} />}

                              <h2
                                className="cursor-pointer"
                                onClick={() =>
                                  setIsDropdownOpen(!isDropdownOpen)
                                }
                              >
                                {currentDate.toLocaleString("default", {
                                  month: "long",
                                })}{" "}
                                {currentDate.getFullYear()}
                              </h2>
                              <div
                                className={styles.dropdown}
                                onClick={() =>
                                  setIsDropdownOpen(!isDropdownOpen)
                                }
                              >
                                <IoMdArrowDropdown />
                                {isDropdownOpen && (
                                  <div
                                    ref={dropdownRef}
                                    className={`${styles.dropdownMenu} ${theme === "dark"
                                        ? "bg-black text-white"
                                        : "bg-white text-black"
                                      }`}
                                    style={{
                                      position: "absolute",
                                      display: "block",
                                      zIndex: "200",
                                    }}
                                  >
                                    <h2 className="text-center p-2">
                                      {currentDate.toLocaleString("default", {
                                        month: "long",
                                      })}{" "}
                                      {currentDate.getFullYear()}
                                    </h2>
                                    <div className="flex justify-around">
                                      {["S", "M", "T", "W", "T", "F", "S"].map(
                                        (day) => (
                                          <th
                                            key={day}
                                            className={` ${styles["day-header"]}`}
                                          >
                                            {day}
                                          </th>
                                        )
                                      )}
                                    </div>
                                    <div className={styles.calendar}>
                                      {days.map((day, index) => {
                                        // Get the full date value for the current day
                                        const dayDate = new Date(
                                          currentDate.getFullYear(),
                                          day.currentMonth
                                            ? currentDate.getMonth()
                                            : day.day < 15
                                              ? currentDate.getMonth() + 1
                                              : currentDate.getMonth() - 1,
                                          day.day
                                        );

                                        // Format the date to match the leave dates format
                                        const formattedDate =
                                          formatDate(dayDate);

                                        // Check if the current date is in the leaveDates array
                                        const isLeaveDate =
                                          approvedLeaves.includes(
                                            formattedDate
                                          );

                                        return (
                                          <div
                                            key={index}
                                            onClick={(event) => {
                                              if (
                                                !isLeaveDate &&
                                                day.currentMonth &&
                                                !day.isPastDate
                                              ) {
                                                handleDateClick(event, day.day);
                                              }
                                            }}
                                            className={`
          ${theme === "dark" ? "hover:border-white" : "hover:border-black"} 
          ${styles.day} 
          ${day.currentMonth ? styles.currentMonth : styles.otherMonth} 
          ${selectedDate.includes(formattedDate) ? styles.selected : ""} 
          ${day.isPastDate || isLeaveDate
                                                ? "cursor-not-allowed opacity-50 border-none"
                                                : ""
                                              }
        `}
                                          >
                                            {day.day}
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </div>
                                )}
                              </div>
                              <FaArrowRight onClick={goToNextMonth} />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={`${styles.footer}`}>
                    <button
                      className="heroButton"
                      onClick={handleCreate}
                      disabled={updating}
                    >
                      {updating ? "Creating..." : "Create"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          <button
            className="mb-5 heroButton"
            onClick={() => setIsModalOpen(true)}
          >
            Create Timeoffs Request
          </button>
        </div>
      </div>
        <div className="flex justify-center items-center pb-4 flex-col w-full">
          <div className={`${styles.tableResponsive} max-w-screen-lg`}>
            <table
              className={`${theme === "dark" ? "text-white" : "text-black"} ${styles.table
                }`}
            >
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Status</th>
                  <th>Title</th>
                  <th>Dates</th>
                </tr>
              </thead>
              <tbody>
                
      {requestData.length > 0 ?
                requestData.map((item, i) => (
                  <>
                    <tr
                      key={i}
                      className={` ${theme === "dark"
                          ? "hover:bg-gradient-to-r from-[#fff3] to-[#fff0]"
                          : "hover:bg-gradient-to-r from-[#6d6d6d33] to-[#fff0]"
                        } ${expandedRow === i ? "" : "border-b border-red-500"}`}
                    >
                      <td
                        onClick={() => handleRowToggle(i)}
                        className="flex items-start gap-2"
                        style={{ borderBottom: "none" }}
                      >
                        {expandedRow === i ? (
                          <IoIosArrowDown />
                        ) : (
                          <IoIosArrowForward />
                        )}
                        {parent === 'activity-tab' ? users.find(user => user.id === userId)?.first_name + " " + users.find(user => user.id === userId)?.last_name : userInfo?.user?.first_name + " " + userInfo?.user?.last_name}
                      </td>
                      <td style={{ borderBottom: "none" }}>
                        {item?.status === "approved" ? (
                          <span className="bg-green-600 text-white h-[30px] w-[80px] flex rounded-[20px] justify-center items-center">
                            {item?.status}
                          </span>
                        ) : item?.status === "rejected" ? (
                          <span className="bg-red-600 text-white h-[30px] w-[80px] flex rounded-[20px] justify-center items-center">
                            {item?.status}
                          </span>
                        ) : (
                          <span className="bg-yellow-600 text-white h-[30px] w-[80px] flex rounded-[20px] justify-center items-center">
                            {item?.status}
                          </span>
                        )}
                      </td>
                      <td style={{ borderBottom: "none" }}>{item?.title}</td>
                      <td className="gap-1" style={{ borderBottom: "none" }}>
                        {item.dates.slice(0, 1).map((e) => (
                          <span key={e}>{e}</span>
                        ))}{" "}
                        {item.dates.length > 1 && <span>...</span>}
                      </td>
                    </tr>
                    {expandedRow === i && (
                      <tr
                        className={`${theme === "dark"
                            ? "hover:bg-gradient-to-r hover:from-[#fff3] hover:to-[#fff0] transition-all"
                            : "hover:bg-gradient-to-r hover:from-[#6d6d6d33] hover:to-[#fff0] transition-all"
                          }`}
                      >
                        <td colSpan="5">
                          <div className="p-4 bg-transparent">
                            {item.dates.map((date, index) => (
                              <div key={index} className="mb-1">
                                {date}
                              </div>
                            ))}
                          </div>
                        </td>
                      </tr>
                    )}
                  </>
                )) : <td className="p-2 " colSpan={4}>No timeoffs found</td>}
              </tbody>
            </table>
          </div>
          <div className="w-full  mt-2 justify-between flex max-w-screen-lg">
            <span>
              {prev && (
                <button className="heroButton block" onClick={handlePrevious}>
                  Previous
                </button>
              )}
            </span>
            <span>
              {next && (
                <button className="heroButton block" onClick={handleNext}>
                  Next
                </button>
              )}
            </span>
          </div>
        </div>
    </>
  );
};

export default OfftimeCreate;
