import React, { useEffect, useRef, useState } from "react";
import styles from "./report.module.css";
import { IoSearchSharp } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { fetchApi } from "../../../utlis/axios";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { toggleAuth } from "../../../Redux/Slices/auth-slice";
import { MdOutlineFileDownload } from "react-icons/md";
import genratePDF from "./genratePDF";
import { MdDeleteForever } from "react-icons/md";
import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io";
import { updateUserId } from "../../../Redux/Slices/users-slice";
import Loader from "../../Loader/Loader";
import { addToast } from "../../../Redux/Slices/toast-slice";
import loader from '../../../assets/loading-gif.gif'
import { CiSearch } from "react-icons/ci";
import logo from '../../../assets/bugtrack.png'

const Reports = () => {
  const { theme } = useSelector((state) => state.theme);
  const [reports, setReports] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [period, setPeriod] = useState("monthly");
  const [reportData, setReportData] = useState(null);
  const [showPreview, setShowPreview] = useState(false);
  const [reportTitles, setReportTitle] = useState("");
  const [updating, setUpdating] = useState(false);
  const { userInfo } = useSelector((state) => state?.auth || {});
  const { userId, users } = useSelector((state) => state?.allUsers || {});
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const currentDate = new Date();
  const printRef = useRef();
  const dispatch = useDispatch();
  const [loadingSave, setLoadingSave] = useState(false);
  const startDateRef = useRef(null);
  const endDateRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [expandedRow, setExpandedRow] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredUsers, setFilterUsers] = useState(users); // State to track the search query
  const [next, setNext] = useState("");
  const [prev, setPrev] = useState("");
  const [oNext, setONext] = useState(null)
  const [oPrev, setOPrev] = useState(null)
  const isFetched = useRef(false);
  const [deletingStates, setDeletingStates] = useState({})
  const [originalReports, setOrignalReports] = useState({})
  const [searching, setSearching] = useState(false)
  const [noOfReportsGenrated, setNoOfReportsGenrated]=useState(0)

  // Toggle expanded state for a specific row
  const toggleExpandRow = (index) => {
    if (expandedRow === index) {
      setExpandedRow(null); // Collapse if the same row is clicked again
    } else {
      setExpandedRow(index); // Expand the newly clicked row
    }
  };

  const openStartDateCalendar = () => {
    startDateRef.current.showPicker();
  };

  const openEndDateCalendar = () => {
    endDateRef.current.showPicker();
  };
  const formattedDate = currentDate.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });

  const getData = async () => {
    setUpdating(true);
    const userArray = Array.isArray(userId) ? userId : [userId];
    try {
      const result = await fetchApi.post(
        "report/",
        {
          title: title,
          users: userArray,
          domain: userInfo.user.domain,
          report_type: period,
          date_range: [startDate, endDate],
        },
        {
          headers: {
            Authorization: `Bearer ${userInfo.access_token}`,
          },
        }
      );
      if (result.status === 200) {
        getReports();
        setUpdating(false);
        setReportTitle(result.data.title);
        setReportData(result.data.response);
        setSearchQuery("");
        setTitle("");
        setStartDate("");
        setEndDate("");
      } else {
        dispatch(addToast({ message: 'Error geting report', type: 'error' }));
      }
      setUpdating(false);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(toggleAuth({ isLogin: false, userInfo: null }));
        dispatch(addToast({ message: 'Your session expired', type: 'error' }));
        return;
      }
      setUpdating(false);
      console.log(error);
    }
  };
  const getReports = async () => {
    try {
      const res = await fetchApi.get("user-reports/", {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });
      if (res.status === 200) {
        setReports(res.data.results);
        setNoOfReportsGenrated(res.data.count)
        
      } else {
        dispatch(addToast({ message: res.message, type: 'error' }));
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(toggleAuth({ isLogin: false, userInfo: null }));
        dispatch(addToast({ message: 'Your session expired', type: 'error' }));
        return;
      }
      console.log("Error while call user-summaries API: ", error);

      dispatch(addToast({ message: 'Something went wrong', type: 'error' }));
    }
  };
  const getAllReports = async () => {
    setIsLoading(true); // Set loading state
    try {
      const res = await fetchApi.get("user-reports/", {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`, // Attach token
        },
      });

      if (res.status === 200) {
        setReports(res.data.results);
        setOrignalReports(res.data.results)
        setNext(res.data.next); // Update next page URL
        setPrev(res.data.previous); // Update previous page URL
        // Update state with data
        setONext(res.data.next)
        setOPrev(res.data.previous)
        setNoOfReportsGenrated(res.data.count)
        setIsLoading(false); // Reset loading state
      } else {
        dispatch(addToast({ message: res.message, type: 'error' }));
      }
    } catch (error) {
      // Handle errors gracefully
      if (error.response && error.response.status === 401) {
        dispatch(toggleAuth({ isLogin: false, userInfo: null })); // Log user out
        dispatch(addToast({ message: 'Your session expired', type: 'error' }));
        return;
      }

      console.error("Error while calling user-reports API: ", error);
      dispatch(addToast({ message: 'Something went wrong', type: 'error' }));
    }
  };
  useEffect(() => {
    if (!isFetched.current) {
      isFetched.current = true;
      getAllReports();
    }
  }, []);
  const handlePageChange = async (url) => {
    setIsLoading(true);
    try {
      const res = await fetchApi.get(url, {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });
      if (res.status === 200) {
        setReports(res.data.results);
        setOrignalReports(res.data.results)
        setNext(res.data.next)
        setPrev(res.data.previous)
        setONext(res.data.next)
        setOPrev(res.data.previous)
        setIsLoading(false);
      } else {
        dispatch(addToast({ message: res.message, type: 'error' }));
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(toggleAuth({ isLogin: false, userInfo: null }));

        return;
      }
      console.log("Error while call user-summaries API: ", error);
      dispatch(addToast({ message: 'Something went wrong', type: 'error' }));
      setIsLoading(false);
    }
  };

  const handleSearch = async () => {
    if(!searchQuery){
      dispatch(addToast({ message: 'Please enter a search query', type: 'warn' }));
      return
    }
    setSearching(true)
    try {
      const res = await fetchApi.get(`user-reports/?search=${searchQuery}`, {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`, // Attach token
        },
      });
      if(res.status === 200){
        setReports(res.data.results);
        let next = res.data.next;
        let previous = res.data.previous;
        if (next) {
          setNext(next)
        } else {
          setNext(null)
        }
        if (previous) {
          setPrev(previous)
        } else {
          setPrev(null)
        }
      }
    } catch (error) {
      dispatch(addToast({ message: 'Something went wrong', type: 'error' }));
    }
    finally{
      setSearching(false)
    }
  }

  const handleClearSearch = () => {
    setSearchQuery("");
    setReports(originalReports) // Restore original data
    setNext(oNext); // Restore original pagination
    setPrev(oPrev);
  };

  const deleteReport = async (id) => {
    setDeletingStates((state) => ({ ...state, [id]: true })) // Create a deep copy to avoid reference issues

    try {
      await fetchApi.delete(`user-reports/${id}`, {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });
      setReports((prevReports) =>
        prevReports.filter((report) => report.id !== id)
      );
      dispatch(addToast({ message: 'Report deleted', type: 'success' }));
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(toggleAuth({ isLogin: false, userInfo: null }));
        dispatch(addToast({ message: 'Your session expired', type: 'error' }));
        return;
      }

      dispatch(addToast({ message: 'Error deleting report', type: 'error' }));
    } finally {
      setDeletingStates((state) => ({ ...state, [id]: false }))
    }
  };

  const handleCreate = async () => {
        if (!title || !startDate || !endDate || !userId) {
      dispatch(addToast({ message: 'Please fill all fields', type: 'warn' }));
      return;
    }
    await getData();
    setIsModalOpen(false);
    setShowPreview(true);
  
}

  const handleStartDateChange = (e) => {
    const selectedStartDate = e.target.value;
    if (endDate && selectedStartDate > endDate) {
      dispatch(addToast({ message: 'Start date can not be latter than before', type: 'warn' }));
    } else {
      setStartDate(selectedStartDate);
    }
  };

  const handleEndDateChange = (e) => {
    const selectedEndDate = e.target.value;
    if (startDate && selectedEndDate < startDate) {
      dispatch(addToast({ message: 'End date can not earlier than start date', type: 'warn' }));
    } else {
      setEndDate(selectedEndDate);
    }
  };

  const handleSave = async () => {
    setLoadingSave(true);
    try {
      await new Promise((resolve) => setTimeout(resolve, 100));
      const canvas = await html2canvas(printRef.current, {
        scale: 2,
      });
      const imgData = canvas.toDataURL("image/png");

      const pdf = new jsPDF("p", "mm", "a4");
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save("report.pdf");
      dispatch(addToast({ message: 'Report save as pdf', type: 'success' }));
    } catch (error) {
      console.error("Error saving PDF:", error);
      dispatch(addToast({ message: 'Failed to save', type: 'error' }));
    } finally {
      setLoadingSave(false);
    }
  };

  const handleDownloads = (jsonData) => {
    if (!jsonData) {
      console.error("No valid JSON data provided.");
      return;
    }
    genratePDF(jsonData, users, userInfo);
  };

  useEffect(() => {
    setFilterUsers(
      users.filter(
        (user) =>
          user.first_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          user.last_name.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  }, [searchQuery]);
  function convertMinutesToHours(minutes) {
    if (isNaN(minutes) || minutes < 0) return "Invalid input";

    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    return `${hours}:${remainingMinutes}`;
  }
console.log(noOfReportsGenrated)


  return (
    <>
      <style>
        {`
          .reportInput {
            width: 100%;
            padding: 10px;
            border: ${theme === "dark" ? "1px solid #ffffff" : "1px solid #000000"
          };
            border-radius: 5px;
            margin-top: 5px;
            box-sizing: border-box; 
            overflow-y: auto;
            background-color: transparent;
          }
          .reportInput option {
            color: black;
          }
        `}
      </style>
      {isLoading && (
        <>
          <div
            className="fixed inset-0 bg-black bg-opacity-50 z-10"
            style={{ top: "95px" }}
          ></div>
          <div
            className="fixed inset-0 flex items-center justify-center z-20"
            style={{ top: "95px" }}
          >
            <Loader />
          </div>
        </>
      )}
      <div
        className={`${styles.main_Heading} ${theme === "dark" ? "text-white" : "text-black"
          }`}
      >
        Reports
      </div>
      <hr className="w-full" />
      <div className={`${styles.main_container} relative`}>
        <div className={styles.actions}>
          {isModalOpen && (
            <div className={styles.modalOverlay}>
              <div
                className={`${styles.modal} ${theme === "dark"
                    ? "bg-[#212529] text-white"
                    : "bg-[#e5e5e5] text-black"
                  }`}
              >
                <div className={styles.modalHeader}>
                  <h2 className="font-semibold ">Create a Report</h2>
                  <button
                    className={styles.closeButton}
                    onClick={() => setIsModalOpen(false)}
                  >
                    &times;
                  </button>
                </div>
                <div
                  className={`${styles.modalContent} ${theme === "dark" ? " text-white" : " text-black"
                    }`}
                >
                  <div className="text-left mt-3">
                    <div className="md:flex justify-between block ">
                      <div className="w-full md:w-[45%]">
                        <div className={styles.formGroup}>
                          <label>Title*</label>
                          <input
                            className={styles.reportInput}
                            type="text"
                            value={title}
                            placeholder="Enter report title"
                            onChange={(e) => setTitle(e.target.value)}
                            required
                          />
                        </div>
                        <div className={styles.formGroupRow}>
                          <div className={styles.formGroup}>
                            <label>Period</label>
                            <select
                              className={styles.reportInput}
                              value={period}
                              onChange={(e) => setPeriod(e.target.value)}
                            >
                              <option value="weekly">Weekly</option>
                              <option value="monthly">Monthly</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div
                        className={`w-full md:w-[45%] ${styles.formGroupRow}`}
                      >
                        <div className={styles.formGroup}>
                          <div className={styles.formInnerGroup}>
                            <label>Users*</label>
                          </div>
                          <div>
                            <div className={styles.formGroupSearch}>
                              <input
                                type="text"
                                placeholder="Search"
                                onChange={(e) => setSearchQuery(e.target.value)}
                                className={styles.reportInput}
                              />
                              <IoSearchSharp className={styles.checkboxIcon} />
                            </div>
                            <select
                              className={styles.reportInputSelect}
                              multiple
                              value={userId}
                              onChange={(e) => {
                                const selectedValues = [
                                  ...e.target.selectedOptions,
                                ].map((o) => o.value);
                                if (selectedValues.includes("all")) {
                                  dispatch(
                                    updateUserId(users.map((data) => data?.id))
                                  );
                                } else {
                                  dispatch(updateUserId(selectedValues));
                                }
                              }}
                            >
                              <>
                                <option value="all">Select All</option>
                                {userInfo?.user?.role?.name !==
                                  "Time Reporter" ? (
                                  <>
                                    {filteredUsers.map((data) => {
                                      if (
                                        data?.role?.name === "Time Reporter"
                                      ) {
                                        return (
                                          <option
                                            key={data?.id}
                                            value={data?.id}
                                          >
                                            {data?.first_name} {data?.last_name}
                                          </option>
                                        );
                                      }
                                      return null;
                                    })}
                                  </>
                                ) : (
                                  <option>
                                    {userInfo?.user?.first_name}{" "}
                                    {userInfo?.user?.last_name}
                                  </option>
                                )}
                              </>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`flex md:flex-row flex-col justify-between md:gap-0 gap-3 ${styles.formGroup}`}
                    >
                      <div
                        className="w-full md:w-[45%]"
                        onClick={openStartDateCalendar}
                      >
                        <label>Start Date</label>
                        <input
                          ref={startDateRef}
                          className={styles.reportInput}
                          type="date"
                          value={startDate}
                          onChange={handleStartDateChange}
                          placeholder="YYYY-MM-DD"
                        />
                      </div>
                      <div
                        className="w-full md:w-[45%]"
                        onClick={openEndDateCalendar}
                      >
                        <label>End Date</label>
                        <input
                          ref={endDateRef}
                          className={styles.reportInput}
                          type="date"
                          value={endDate}
                          onChange={handleEndDateChange}
                          placeholder="YYYY-MM-DD"
                        />
                      </div>
                    </div>
                  </div>
                  <div className={styles.footer}>
                    <div className={styles.footer_innerDiv}>
                      <button
                        className="heroButton"
                        onClick={handleCreate}
                        disabled={updating}
                      >
                        {updating ? "Creating" : "Create"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="w-full flex justify-between items-center mb-5">
            <button className=" heroButton"
              onClick={() => {
                if(userInfo?.user?.plan?.name === 'Basic'){
                if(noOfReportsGenrated < 5){
                setIsModalOpen(true)
                }else{
                  dispatch(addToast({
                    message: "You can only generate 5 reports. For generating more reports, please upgrade plan.",
                    type: "error"
                  }
                  ))
                } } else{
                  setIsModalOpen(true)
                }
              }}
            >
              Create Report
            </button>
            <div className={`flex items-center gap-2 sm:w-56 w-full justify-between px-4 border border-red-600 rounded-3xl ${theme === 'dark' ? 'text-white':'text-black'}`}>
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                 if(!e.target.value){
                  handleClearSearch();
                 }
                }}
                placeholder="Search reports..."
                className="py-2 bg-transparent border-none outline-none w-full"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSearch();
                  }
                }}
              />
              {searching ? (
                <img src={loader} alt="loading" className="w-6 h-6" />
              ) : (
                <CiSearch
                  className={`w-6 h-6 cursor-pointer ${theme === 'dark' ? 'text-white':'text-black'}`}
                  onClick={handleSearch}
                />
              )}
            </div>
          </div>
          {showPreview && (
            <div className=" text-black w-full max-w-6xl mx-auto bg-white rounded-lg shadow-lg relative">
              <div ref={printRef} className="p-6">
               <div className="flex justify-start items-center ">
<img src={logo} alt="Logo" className="w-20 h-20 "/>
               <h2 className="text-center text-xl w-full font-semibold">BugTrack Report</h2>
               </div>
               <h4 className="mt-3 text-base font-semibold">Employee Details</h4>
                <div className="mt-3">
                  <div>
                    <span className="font-semibold">Title:</span> {reportTitles}
                  </div>
                  <div>
                    <span className="font-semibold ">Name:</span>{" "}
                    {userInfo?.user?.first_name} {userInfo?.user?.last_name}
                  </div>
                  <div>
                    <span className="font-semibold">Email:</span>{" "}
                    {userInfo?.user?.email}
                  </div>
                  <div>
                    <span className="font-semibold">Date:</span> {formattedDate}
                  </div>
                  <div className="overflow-auto mt-4">
                    <table className="w-full text-center border border-[#555657]">
                      <thead className="bg-gray-200">
                        <tr>
                          <th className="border-r p-2">Name</th>
                          <th className="border-r p-2">Date</th>
                          <th className="border-r p-2">{`Total Tracked Time(hours)`}</th>
                          <th className="border-r p-2">Keyboard Productivity</th>
                          <th className="border-r p-2">Mouse Productivity</th>
                          <th className="border-r p-2">{`Active Time(hours)`}</th>
                          <th className=" p-2">{`Active Time Percentage(%)`}</th>
                        </tr>
                      </thead>

                      <tbody>
                        {Object.keys(reportData || {}).map((userId) => {
                          // Find user name from users array
                          const firstName =
                            users.find((user) => user.id === userId)
                              ?.first_name || "Unknown User";
                          const lastName = users.find(
                            (user) => user.id === userId
                          )?.last_name;
                          const weeks = reportData[userId]; // Get the data for the user

                          // Check if the object is empty
                          const isEmptyObject = Object.keys(weeks).length === 0;

                          // Render the row with the user's name if no week data is available
                          if (isEmptyObject) {
                            return (
                              <tr key={userId}>
                                <td className="border-r p-2">
                                  {`${firstName} ${lastName}`}
                                </td>
                                <td className=" p-2" colSpan={6}>
                                  No data available
                                </td>
                              </tr>
                            );
                          }

                          // Otherwise, render week details
                          return Object.keys(weeks).map((weekKey, index) => {
                            const weekData = weeks[weekKey];

                            return (
                              <tr key={`${userId}-${weekKey}`}>
                                {index === 0 && (
                                  <td
                                    className="border-r p-2"
                                    rowSpan={Object.keys(weeks).length}
                                  >
                                    {`${firstName} ${lastName}`}
                                  </td>
                                )}
                                <td className="border-r p-2">{weekKey}</td>
                                <td className="border-r p-2">
                                  {weekData.total_tracked_time
                                    ? convertMinutesToHours(
                                      weekData.total_tracked_time
                                    )
                                    : "-"}
                                </td>
                                <td className="border-r p-2">
                                  {weekData.keyboard_productivity_percentage
                                    ? Number(
                                      weekData.keyboard_productivity_percentage
                                    ).toFixed(3)
                                    : weekData.keyboard_productivity_percentage ||
                                    "-"}
                                </td>
                                <td className="border-r p-2">
                                  {weekData.mouse_productivity_percentage
                                    ? Number(
                                      weekData.mouse_productivity_percentage
                                    ).toFixed(3)
                                    : weekData.mouse_productivity_percentage ||
                                    "-"}
                                </td>
                                <td className="border-r p-2">
                                  {weekData.active_time
                                    ? convertMinutesToHours(
                                      weekData.active_time
                                    )
                                    : "-"}
                                </td>
                                <td className="p-2">
                                  {weekData.active_time_percentage
                                    ? Number(
                                      weekData.active_time_percentage
                                    ).toFixed(3)
                                    : weekData.active_time_percentage || "-"}
                                </td>
                              </tr>
                            );
                          });
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className={`${styles.footer_innerDiv} pl-5 py-4`}>
                <button
                  className="heroButton"
                  onClick={() => setShowPreview(false)}
                >
                  Cancel
                </button>
                <button
                  className="heroButton"
                  onClick={handleSave}
                  disabled={loadingSave}
                >
                  {loadingSave ? "Saving..." : "Download Report"}
                </button>
              </div>
            </div>
          )}
          <>
            <div className="overflow-x-auto mt-5 relative">
              <>
                <div className={styles.tableResponsive}>
                  <table
                    className={`${theme === "dark" ? "text-white" : "text-black"
                      } ${styles.table}`}
                  >
                    <thead>
                      <tr>
                        <th>Title</th>
                        <th>Type</th>
                          <th>Actions</th>
                      </tr>
                    </thead>

                    <tbody>
                      {reports.length > 0 ? (
                        reports.map((item, i) => (
                          <React.Fragment key={i}>
                            {/* Main row */}
                            <tr
                              className={` border-red-500 ${theme === "dark"
                                  ? "hover:bg-gradient-to-r hover:from-[#fff3] hover:to-[#fff0] transition-all"
                                  : "hover:bg-gradient-to-r hover:from-[#6d6d6d33] hover:to-[#fff0] transition-all"
                                } ${expandedRow === i ? "" : "border-b"}`}
                            >
                              <td
                                className="flex gap-2 items-center cursor-pointer"
                                style={{ border: "none" }}
                                onClick={() => toggleExpandRow(i)} // Handle row toggle
                              >
                                {expandedRow === i ? (
                                  <IoIosArrowDown />
                                ) : (
                                  <IoIosArrowForward />
                                )}
                                {item?.title}
                              </td>
                              <td style={{ border: "none" }}>Saved</td>
                                <td
                                  className="flex gap-2 items-center"
                                  style={{ border: "none" }}
                                >
                                  <MdOutlineFileDownload
                                    className="cursor-pointer w-5 h-5"
                                    onClick={() =>
                                      handleDownloads(item, item.title)
                                    }
                                  />
                                  
                              {userInfo?.user?.plan?.name === "Premium" && (
                                  <button
                                    onClick={() => deleteReport(item.id)}
                                    className="outline-none" disabled={deletingStates[item.id]}
                                  >
                                    {deletingStates[item.id] ? <img src={loader} alt="loadin" className="w-5 h-5" /> : <MdDeleteForever className="w-5 h-5 cursor-pointer" />}
                                  </button> )}
                                </td>
                            </tr>
                            {expandedRow === i && (
                              <tr
                                className={` ${theme === "dark"
                                    ? "hover:bg-gradient-to-r hover:from-[#fff3] hover:to-[#fff0] transition-all"
                                    : "hover:bg-gradient-to-r hover:from-[#6d6d6d33] hover:to-[#fff0] transition-all"
                                  }`}
                              >
                                <td colSpan={3} className="p-4">
                                  <div>
                                    <ul className="flex justify-between">
                                      <li className="flex flex-col">
                                        <strong>Created At:</strong>
                                        <span>
                                          {" "}
                                          {new Date(item.created_at)
                                            .toISOString()
                                            .split("T")[0] || "N/A"}
                                        </span>
                                      </li>
                                      <li className="flex flex-col">
                                        <span
                                          className={` text-base font-medium ${theme === "dark"
                                              ? " text-slate-300"
                                              : "text-gray-600"
                                            }`}
                                        >
                                          Users
                                        </span>
                                        {Object.keys(item.response || {}).map(
                                          (userId, index) => {
                                            // Find user by ID
                                            const user = users.find(
                                              (user) => user.id === userId
                                            );
                                            const userName = `${user?.first_name || "Unknown"
                                              } ${user?.last_name || ""}`.trim();

                                            return (
                                              <div key={userId} className="">
                                                {userName}
                                              </div>
                                            );
                                          }
                                        )}
                                      </li>
                                      <li></li>
                                    </ul>
                                  </div>
                                </td>
                              </tr>
                            )}
                          </React.Fragment>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={3} className="text-center">
                            No reports found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="w-full  mt-2 justify-between flex">
                  <span>
                    {prev && (
                      <button
                        className="heroButton block"
                        onClick={() => handlePageChange(prev)}
                      >
                        Previous
                      </button>
                    )}
                  </span>
                  <span>
                    {next && (
                      <button
                        className="heroButton block"
                        onClick={() => handlePageChange(next)}
                      >
                        Next
                      </button>
                    )}
                  </span>
                </div>
              </>
            </div>
          </>
        </div>
      </div>
    </>
  );
};

export default Reports;
