import React from "react";
import { useSelector } from "react-redux";

const UserGuide = () => {
  const { theme } = useSelector((state) => state.theme);
  return (
    <div className={`p-6 max-w-screen-lg mt-8 mx-auto ${theme === 'dark' ? 'bg-darkMode text-white' : 'bg-white text-black'} rounded-lg shadow-md`}>
      <h1 className="text-3xl font-bold mb-6 text-center">User Guide: Installing the Tracker App</h1>
      
      {/* macOS Section */}
      <h2 className="text-2xl font-semibold mt-6 mb-3">Installing on macOS</h2>
      <h3 className="text-xl font-medium mb-2">Download the App:</h3>
      <ul className="list-disc pl-5 mb-4">
        <li>Visit the download link for the macOS version.</li>
        <li>Download the .dmg file for Intel-based Macs or Apple Silicon (M1/M2).</li>
      </ul>
      <h3 className="text-xl font-medium mb-2">Install the App:</h3>
      <ul className="list-disc pl-5 mb-4">
        <li>Open the downloaded .dmg file.</li>
        <li>Drag the app icon into the Applications folder.</li>
      </ul>
      <h3 className="text-xl font-medium mb-2">Run the App:</h3>
      <ul className="list-disc pl-5 mb-4">
        <li>Open the Applications folder and double-click the tracker app.</li>
        <li>If you see a warning about an unidentified developer, right-click the app, select Open, and confirm.</li>
      </ul>
      <h3 className="text-xl font-medium mb-2">Grant Permissions:</h3>
      <ul className="list-disc pl-5 mb-4">
        <li>Go to System Preferences &gt; Security & Privacy &gt; Privacy.</li>
        <li>Enable permissions for <b>Screen Capture</b> and <b>Input Monitoring</b>.</li>
      </ul>
      <h3 className="text-xl font-medium mb-2">Reset Permissions (if needed):</h3>
      <pre className="bg-gray-200 text-black p-3 rounded">
        tccutil reset Accessibility com.yourcompany.Bugtrack<br/>
        tccutil reset ScreenCapture com.yourcompany.Bugtrack<br/>
        tccutil reset ListenEvent com.yourcompany.Bugtrack
      </pre>
      
      {/* Windows Section */}
      <h2 className="text-2xl font-semibold mt-6 mb-3">Installing on Windows</h2>
      <h3 className="text-xl font-medium mb-2">Download the App:</h3>
      <ul className="list-disc pl-5 mb-4">
        <li>Visit the download link for the Windows version.</li>
        <li>Download the .exe or .msi installer file.</li>
      </ul>
      <h3 className="text-xl font-medium mb-2">Install the App:</h3>
      <ul className="list-disc pl-5 mb-4">
        <li>Double-click the downloaded installer.</li>
        <li>Follow the on-screen instructions.</li>
      </ul>
      <h3 className="text-xl font-medium mb-2">Run the App:</h3>
      <ul className="list-disc pl-5 mb-4">
        <li>Launch the app from the Start Menu or desktop shortcut.</li>
      </ul>
      
      {/* Linux Section */}
      <h2 className="text-2xl font-semibold mt-6 mb-3">Installing on Linux</h2>
      <h3 className="text-xl font-medium mb-2">Download the App:</h3>
      <ul className="list-disc pl-5 mb-4">
        <li>Visit the download link for the Linux version.</li>
        <li>Download the .deb (Debian/Ubuntu), .rpm (Fedora/Red Hat), or precompiled binary.</li>
      </ul>
      <h3 className="text-xl font-medium mb-2">Install the App:</h3>
      <ul className="list-disc pl-5 mb-4">
        <li>For .deb files: <code>sudo dpkg -i your-app-name.deb</code></li>
        <li>For .rpm files: <code>sudo rpm -i your-app-name.rpm</code></li>
        <li>For binaries:
          <ul className="list-disc pl-5">
            <li>Extract the archive: <code>tar -xzf your-app-name.tar.gz</code></li>
            <li>Move the binary: <code>sudo mv your-app-name /usr/local/bin/</code></li>
          </ul>
        </li>
      </ul>
      <h3 className="text-xl font-medium mb-2">Run the App:</h3>
      <ul className="list-disc pl-5 mb-4">
        <li>Open a terminal and type the app name.</li>
        <li>Or launch it from the application menu.</li>
      </ul>
      
      {/* Troubleshooting Section */}
      <h2 className="text-2xl font-semibold mt-6 mb-3">Troubleshooting</h2>
      <ul className="list-disc pl-5 mb-4">
        <li><b>macOS:</b> Ensure necessary permissions are granted in System Preferences.</li>
        <li><b>Windows:</b> Check for admin privileges and disk space.</li>
        <li><b>Linux:</b> Install all required dependencies. Refer to documentation.</li>
      </ul>
      
      {/* Support Section */}
      <h2 className="text-2xl font-semibold mt-6 mb-3">Support</h2>
      <p>If you need help, contact us at <b>support@bugtrackin.com</b> or visit our website:</p>
      <a
        href="https://bugtrackin.com"
        target="_blank"
        rel="noopener noreferrer"
        className="text-blue-500 underline font-semibold"
      >
        bugtrackin.com
      </a>
    </div>
  );
};

export default UserGuide;
