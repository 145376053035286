import React from 'react'
import UserGuide from './UserGuid'

const Guid = () => {
  return (
    <div>
        <UserGuide/>
    </div>
  )
}

export default Guid