import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  toggleAuth,
  setRememberMe,
  setStoredCredentials,
} from "../../../Redux/Slices/auth-slice";
import { fetchApi } from "../../../utlis/axios";
import { IoIosArrowBack } from "react-icons/io";
import ParticlesBackground from "../../home/ParticalsBackground";
import { FaEye, FaEyeSlash, FaLock, FaUnlock } from "react-icons/fa";
import AnimationLottie from "../../../lottie/animation-lottie";
import loginAnimation from "../../../lottie/loginAnimation.json";
import { addToast } from "../../../Redux/Slices/toast-slice";
import logo from "../../../assets/bugtrack.png";
import { HiOutlineMail } from "react-icons/hi";
import { IoLockClosedOutline } from "react-icons/io5";
import { TbWorld } from "react-icons/tb";
import forgotpasswordAnimation from '../../../lottie/forgotpasswordAnimation.json'
import { CiLock, CiUnlock } from "react-icons/ci";
const Login = () => {
  const { theme } = useSelector((state) => state.theme);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { isLogin, userInfo, rememberMe } = useSelector(
    (state) => state?.auth || {}
  );

  const [showPassword, setShowPassword] = useState(false);
  const [state, setState] = useState("login");
  const [updating, setUpdating] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [userDomain, setUserDomain]=useState('')
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [otpGenrated, setOtpGenrated] = useState(false);

  const getSubdomain = () => {
    const host = window.location.host;
    if (host.includes("localhost")) {
      return host.split(".localhost")[0];
    } else if (host.includes(".bugtrackin")) {
      return host.split(".bugtrackin.com")[0];
    }
    return null;
  };

  const subdomain = getSubdomain();
  const [formValues, setFormValues] = useState({
    email: "",
    password: "",
    domain: subdomain,
  });

  useEffect(() => {
    // Load stored credentials if rememberMe is true
    const storedCredentials = JSON.parse(
      localStorage.getItem("userCredentials")
    );
    if (storedCredentials && rememberMe) {
      setFormValues({
        email: storedCredentials.email,
        password: storedCredentials.password,
        domain: subdomain,
      });
      dispatch(setStoredCredentials({ userInfo: storedCredentials }));
    }
  }, [dispatch, rememberMe, subdomain]);

  const handleLogin = async (e) => {
    e.preventDefault();
    if (!formValues?.email || !formValues?.password) {
      dispatch(addToast({ message: "Both fields required", type: "error" }));
      return;
    }
    setLoading(true);
    try {
      const result = await fetchApi.post("login/", formValues);
      if (result.status === 200) {
        dispatch(toggleAuth({ isLogin: true, userInfo: result.data }));
        if (rememberMe) {
          localStorage.setItem("userCredentials", JSON.stringify(formValues));
        } else {
          localStorage.removeItem("userCredentials");
        }
        navigate("/dashboard");
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      const errorMessage = error?.response?.data?.error || "Login failed";
      dispatch(addToast({ message: errorMessage, type: "error" }));
      setLoading(false);
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleRememberMe = (e) => {
    dispatch(setRememberMe(e.target.checked));
  };

  const handleBack = () => {
    navigate("/");
  };
  const handleGetOtp = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (otpGenrated) {
      try {
        const res = await fetchApi.post(
          "forgot-password/verify-otp/",
          { email: email, otp: otp, domain:userDomain },
          {}
        );
        if (res.status === 200) {
          setLoading(false);
          setOtpGenrated();
          setEmail("");
          setOtp("");
          setState("changePassword");
          dispatch(toggleAuth({ isLogin: true, userInfo: res.data }));
        }
      } catch (error) {
        const errorMessage =
          error?.response?.data?.error || "Something went wrong";
        dispatch(addToast({ message: errorMessage, type: "error" }));
        setLoading(false);
      }
    } else {
      try {
        const res = await fetchApi.post(
          "forget-password/",
          { email: email,domain:userDomain },
          {}
        );
        if (res.status === 200) {
          setLoading(false);
          setOtpGenrated(true);
          dispatch(
            addToast({ message: "OTP sent on your email", type: "success" })
          );
        }
      } catch (error) {
        const errorMessage =
          error?.response?.data?.error || "Something went wrong";
        dispatch(addToast({ message: errorMessage, type: "error" }));
        setLoading(false);
      }
    }
  };
  const handleChangePassword = async (e) => {
    e.preventDefault();
    if (!newPassword || !confirmPassword) {
      dispatch(
        addToast({ message: "Please enter new password", type: "warn" })
      );
      return;
    }
    if (newPassword.length < 8) {
      dispatch(
        addToast({
          message: "Password must be at least8 characters",
          type: "warn",
        })
      );
      return;
    }
    if (newPassword !== confirmPassword) {
      dispatch(addToast({ message: "Password not matched", type: "error" }));
      return;
    }
    setLoading(true);
    try {
      const res = await fetchApi.post(
        "change-password/",
        { user_id: userInfo?.user?.id, password: newPassword },
        {
          headers: {
            Authorization: `Bearer ${userInfo.access_token}`,
          },
        }
      );
      if (res.status === 200) {
        dispatch(
          addToast({ message: "Password update successfully", type: "success" })
        );
        setNewPassword("");
        setConfirmPassword("");
        navigate("/dashboard");
      }
    } catch (error) {
      dispatch(addToast({ message: "Something went wrong", type: "error" }));
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <style>{`
        .login-box {
          background-color:${
            theme === "dark" ? "#1E1E1ECC" : "rgba(223, 223, 223, 0.8)"
          };
          color:${theme === "dark" ? "#ffffff" : "#000000"};
        }
        .input-field {
          background-color:${theme === "dark" ? "#212529" : "#ffffff"};
          color:${theme === "dark" ? "#ffffff" : "#000000"};
          border:${theme === "dark" ? "1px solid #555657" : "1px solid #9CA3AF"};
          border-radius: 10px;
          outline:none;
        }
          .input-label{
            font-size: 16px;
            font-weight: 300;}
      `}</style>
      <ParticlesBackground id="particaljs" />
      <div className="login-container">
        <button
          onClick={handleBack}
          style={{
            position: "fixed",
            top: "30px",
            left: "30px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "10px",
            fontSize: "20px",
            backgroundColor: "red",
            color: "#fff",
            border: "none",
            borderRadius: "50%",
            cursor: "pointer",
            outline: "none",
            zIndex: "9999",
          }}
        >
          <IoIosArrowBack />
        </button>
        <div className="md:flex justify-center xl:w-[60%] md:w-1/2 hidden">
        {state === "login" ?
          <AnimationLottie animationPath={loginAnimation} width={"70%"} /> : 
          <AnimationLottie animationPath={forgotpasswordAnimation} width={"70%"} /> }
        </div>
        <div className="flex justify-center w-full py-8 md:py-0 xl:w-[40%] md:w-1/2 h-full">
          <div
            className="login-box md:w-full sm:w-[80%] w-[90%] flex flex-col items-center justify-center p-8 md:h-[100vh] h-max"
            style={{  maxWidth: "100%", margin: "0px" }}
          >
            <div className="w-full flex justify-center items-center my-4">
              <img src={logo} alt="logo" className="w-28 h-28" />
            </div>
            {state === "login" ? (
              <div className="xl:w-[75%] w-[95%] fontRoboto">
                <h2 className="main-heading text-center">Login</h2>
                <p className="text-lg font-normal mt-4 mb-6 w-full text-center">
                  Let's get things done smarter and faster.
                </p>
                <form onSubmit={handleLogin}>
                  <div className="input-group">
                    <label
                      className={`input-label  justify-start items-center gap-2 ${
                        theme === "dark" ? "text-white" : "text-black"
                      }`} style={{display:"flex"}}
                    >
                      <HiOutlineMail className="w-6 h-6"/>
                      Email
                    </label>
                    <input
                      type="email"
                      name="email"
                      value={formValues.email}
                      onChange={handleChange}
                      placeholder="Enter your email"
                      className="input-field"
                    />
                  </div>
                  <div className="input-group " style={{ marginTop: "22px" }}>
                    <label
                      className={`input-label flex justify-start items-center gap-2 ${
                        theme === "dark" ? "text-white" : "text-black"
                      }`} style={{display:"flex"}}
                    >
                      <IoLockClosedOutline className="w-6 h-6"/>
                      Password
                    </label>
                    <div className="relative">
                      <input
                        type={showPassword ? "text" : "password"}
                        name="password"
                        value={formValues.password}
                        onChange={handleChange}
                        placeholder="Enter your password"
                        className="input-field"
                        style={{ paddingRight: "35px" }}
                      />
                      <div
                        className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <FaEye /> : <FaEyeSlash />}
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-between items-center my-4">
                    <div
                      className="input-group flex items-center"
                      style={{ marginBottom: "0px" }}
                    >
                      <input
                        type="checkbox"
                        id="rememberMe"
                        checked={rememberMe}
                        onChange={handleRememberMe}
                      />
                      <label htmlFor="rememberMe" className="ml-2">
                        Remember Me
                      </label>
                    </div>
                    <div
                      className="text-base hover:text-red-600 cursor-pointer"
                      onClick={() => setState("forgotPassword")}
                    >
                      Forgot password
                    </div>
                  </div>
                  <button
                    className="heroButton w-full"
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? "Loading..." : "Login"}
                  </button>
                </form>{" "}
              </div>
            ) : state === "forgotPassword" ? (
              <div className="xl:w-[75%] w-[95%] fontRoboto">
                <h2 className="main-heading text-center">Forgot Password</h2>
                <p className="text-lg font-normal mt-4 mb-6 w-full text-center">
                Let’s get you back on track quickly and securely.
                </p>
                <form onSubmit={handleGetOtp}>
                  <div className="input-group">
                    <label
                      className={`input-label flex justify-start items-center gap-2 ${
                        theme === "dark" ? "text-white" : "text-black"
                      }`} style={{display: "flex"}}
                    >
                       <HiOutlineMail className="w-6 h-6"/>
                      Email
                    </label>
                    <input
                      type="email"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Enter your email"
                      className="input-field"
                    />
                  </div>
                  <div className="input-group mt-4">
                    <label
                      className={`input-label flex justify-start items-center gap-2 ${
                        theme === "dark" ? "text-white" : "text-black"
                      }`} style={{display: "flex"}}
                    >
                       <TbWorld className="w-6 h-6"/>
                      Domain
                    </label>
                    <input
                      type="text"
                      name="domain"
                      value={userDomain}
                      onChange={(e) => setUserDomain(e.target.value)}
                      placeholder="Enter your domain name"
                      className="input-field"
                    />
                  </div>
                  {otpGenrated && (
                    <div className="input-group">
                      <label
                        className={`input-label ${
                          theme === "dark" ? "text-white" : "text-black"
                        }`}
                      >
                        Enter OTP
                      </label>
                      <input
                        type="number"
                        name="otp"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                        placeholder="Enter recieved otp"
                        className="input-field"
                      />
                    </div>
                  )}
                  <button
                    className="heroButton w-full"
                    type="submit"
                    disabled={loading}
                  >
                    {loading
                      ? otpGenrated
                        ? "Verifying"
                        : "Genrating..."
                      : otpGenrated
                      ? "Verify"
                      : "Genrate otp"}
                  </button>
                </form>
              </div>
            ) : (
              <div className="xl:w-[75%] w-[95%] fontPoppins">
                <h2 className="main-heading text-center">Change Password</h2>
                <p className="text-lg font-normal mt-4 mb-6 w-full text-center">
                Let’s get you back on track quickly and securely.
                </p>
                <form onSubmit={handleChangePassword}>
                  <div className="input-group">
                    <label
                      className={`input-label justify-start items-center gap-2 text-lg font-medium ${
                        theme === "dark" ? "text-white" : "text-black"
                      }`} style={{display: "flex"}}
                    >
                      <CiLock className="w-6 h-6"/>
                      New Password
                    </label>
                    <div className="relative">
                      <input
                        type={showPassword ? "text" : "password"}
                        name="password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        placeholder="Enter your password"
                        className="input-field"
                        style={{ paddingRight: "35px" }}
                      />
                      <div
                        className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <FaEye /> : <FaEyeSlash />}
                      </div>
                    </div>
                  </div>
                  <div className="input-group">
                    <label
                      className={`input-label justify-start items-center gap-2 text-lg font-medium ${
                        theme === "dark" ? "text-white" : "text-black"
                      }`} style={{display: 'flex', marginTop:'22px'}}
                    >
                  <CiUnlock className="w-6 h-6"/>
                      Confirm Password
                    </label>
                    <input
                      type="password"
                      name="confirm-password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      placeholder="Confirm Password"
                      className="input-field"
                    />
                  </div>
                  <button
                    className="heroButton w-full"
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? "Changing..." : "Change"}
                  </button>
                </form>
              </div>
            )}
            <div className="forgot-password xl:w-[75%] w-[85%] flex justify-end ">
              <a href="/subscriptionplan" className="forgot-link font-normal text-lg mt-2 items-center" style={{fontSize:'16px'}}>
                Do not have any account?
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
