import React, { useEffect, useState, useRef, useCallback } from "react";
import { FaChartLine, FaChevronDown } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Line,
} from "recharts";
import { FiRefreshCw } from "react-icons/fi";
import { fetchApi } from "../../../../utlis/axios";
import { addToast } from "../../../../Redux/Slices/toast-slice";
import loader from "../../../../assets/loading-gif.gif";

const data = [
  { name: "Jan", value: 0 },
  { name: "Feb", value: 10 },
  { name: "Mar", value: 30 },
  { name: "Apr", value: 20 },
  { name: "May", value: 35 },
  { name: "Jun", value: 15 },
  { name: "Jul", value: 50 },
  { name: "Aug", value: 25 },
  { name: "Sept", value: 45 },
  { name: "Oct", value: 55 },
  { name: "Nov", value: 90 },
  { name: "Dec", value: 40 },
];

const ProjectProgressChart = () => {
  const { theme } = useSelector((state) => state.theme);
  const { userInfo } = useSelector((state) => state?.auth || {});

  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedProjects, setSelectedProjects] = useState(null);
  const [projects, setProjects] = useState([]);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [next, setNext] = useState(null);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const dropdownRef = useRef(null);
  const projectDropdownRef = useRef(null);

  const getProjects = async () => {
    try {
      const res = await fetchApi.get("projects/", {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });
      if (res.status === 200) {
        setProjects(res.data.results);
        setNext(res.data.next);
      }
    } catch (error) {
      dispatch(addToast({ message: error.message, type: "error" }));
    }
  };
  const getMore = async (url) => {
    try {
      setIsLoadingMore(true);
      const res = await fetchApi.get(url, {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });
      if (res.status === 200) {
        setProjects(prev => [...prev, ...res.data.results]);
        setNext(res.data.next);
      }
    } catch (error) {
      dispatch(addToast({ message: error.message, type: "error" }));
    } finally {
      setIsLoadingMore(false);
    }
  };

  const processProgressData = (data) => {
    // Initialize all 12 months with 0
    const monthlyData = Array.from({ length: 12 }, (_, index) => ({
      name: new Date(2025, index).toLocaleString('default', { month: 'short' }),
      value: 0
    }));

    // Process the milestone progress data
    if (data?.milestone_progress?.[0]) {
      const progressData = data.milestone_progress[0];
      
      // Loop through each month in the response
      Object.keys(progressData).forEach(month => {
        if (month !== 'project' && month !== 'year') {
          const monthIndex = parseInt(month) - 1; // Convert to 0-based index
          const progressInfo = progressData[month][0];
          
          if (progressInfo) {
            // Calculate percentage from "x/y achieved" format
            const [achieved, total] = progressInfo.progress.split('/');
            const percentage = (parseInt(achieved) / parseInt(total)) * 100;
            monthlyData[monthIndex].value = percentage;
          }
        }
      });
    }

    return monthlyData;
  };

  const getProjectProgressData = async () => {
    try {
      const res = await fetchApi.get(
        `admin-dashboard-project-progress/?project=${selectedProjects}`,
        {
          headers: {
            Authorization: `Bearer ${userInfo.access_token}`,
          },
        }
      );
      if (res.status === 200) {
        const processedData = processProgressData(res.data);
        setChartData(processedData);
      }
    } catch (error) {
      dispatch(addToast({ message: error.message, type: "error" }));
    }
  };

  const refreshData = async () => {
    setIsRefreshing(true);
    await Promise.all([getProjects(), selectedProjects && getProjectProgressData()]);
    setIsRefreshing(false);
  };

  // Add scroll handler for infinite scroll
  const handleScroll = useCallback(() => {
    if (!dropdownRef.current || isLoadingMore || !next) return;

    const { scrollTop, scrollHeight, clientHeight } = dropdownRef.current;
    if (scrollTop + clientHeight >= scrollHeight - 20) { // 20px before bottom
      getMore(next);
    }
  }, [next, isLoadingMore]);

  useEffect(() => {
    getProjects();
  }, []);

  useEffect(() => {
    if (selectedProjects) {
      getProjectProgressData();
    }
  }, [selectedProjects]);

  const handleClickOutside = (event) => {
    if (projectDropdownRef.current && !projectDropdownRef.current.contains(event.target)) {
      setIsOpen(false); // Close dropdown
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [isOpen]);

  return (
    <div
      className={`p-6 rounded-xl customFont ${
        theme === "dark" ? "bg-[#1C1C1E] text-white" : "bg-white text-black"
      }`}
    >
      {/* Header */}
      <div className="flex justify-between items-center mb-4">
        <div className="flex gap-2 items-center">
          <span className="bg-gradient-to-r from-[#CC0927] to-[#7E0105] p-2 rounded-[10px]">
            <FaChartLine className="w-6 h-6 text-white" />
          </span>
          <h1 className="text-lg font-semibold">Project Progress</h1>
        </div>
        <div className="flex gap-2">
          <div className="relative" ref={projectDropdownRef}>
            <div
              className={`border rounded-[10px] cursor-pointer  gap-4 flex items-center justify-between p-2 min-w-[170px]    ${
                theme === "dark"
                  ? "bg-[#212529] border-[#555657]"
                  : "bg-[#F2F4F7] border-[#9A9F9A]"
              }`}
              onClick={() => setIsOpen(!isOpen)}
            >
              {selectedProjects
                ? selectedProjects
                : "Select Project"}
              <FaChevronDown className="w-4 h-4" />
            </div>
            {isOpen && (
              <div
                ref={dropdownRef}
                onScroll={handleScroll}
                className={`z-10 absolute mt-1 w-40 border overflow-auto shadow-lg rounded-[10px] max-h-[180px] ${
                  theme === "dark"
                    ? "bg-[#212529] border-[#555657]"
                    : "bg-[#F2F4F7] border-[#9A9F9A]"
                }`}
                style={{
                  paddingTop: "4px",
                  paddingBottom: "4px",
                }}
              >
                {projects?.map((project) => (
                  <div
                    key={project.id}
                    className={`p-2 cursor-pointer border-b border-[#9A9f9A] ${
                      theme === "dark"
                        ? "hover:bg-gradient-to-r hover:from-[#fff3] hover:to-[#fff0] transition-all"
                        : "hover:bg-gradient-to-r hover:from-[#6d6d6d33] hover:to-[#fff0] transition-all"
                    }`}
                    onClick={() => {
                      setSelectedProjects(project.title);
                      setIsOpen(false);
                    }}
                  >
                    {project.title}
                  </div>
                ))}
                {isLoadingMore && (
                  <div className="p-2 flex justify-center">
                    <img src={loader} alt="Loading..." className="w-5 h-5" />
                  </div>
                )}
              </div>
            )}
          </div>
          <div
            className={`p-2 flex items-center justify-center rounded-[10px] border cursor-pointer ${
              theme === "dark"
                ? "bg-[#252529] border-[#555657]"
                : "bg-[#F2F4FA] border-[#D8D8D8]"
            }`}
            onClick={refreshData}
          >
            {isRefreshing ? (
              <img src={loader} alt="Loading..." className="w-5 h-5" />
            ) : (
              <FiRefreshCw className="w-5 h-5" />
            )}
          </div>
        </div>
      </div>
      <div
        className={`pt-2 pb-2 px-2 border w-full rounded-[20px] flex items-center gap-1 ${
          theme === "dark"
            ? "bg-[#212529] border-[#555657]"
            : "bg-[#F2F4F7] border-[#D8D8D8]"
        }`}
      >
        <div className="w-[10px] h-full flex items-center justify-center">
          <span
            className={`origin-center -rotate-90 whitespace-nowrap customFont ${
              theme === "dark" ? "text-[#FFFFFF]" : "text-[#000000]"
            }`}
          >
            Employee Worked Hours
          </span>
        </div>

        <ResponsiveContainer width="100%" height={300}>
          <AreaChart data={chartData}>
            {/* Grid lines without hover effect */}
            <CartesianGrid
              strokeDasharray="3 3"
              stroke={theme === "dark" ? "#000000" : "#000000"}
              pointerEvents="none"
              margin={{ top: 20, right: 20, bottom: 0, left: 0 }}
            />

            <XAxis
              dataKey="name"
              tick={{ fill: theme === "dark" ? "white" : "black" }}
              tickLine={false}
            />
            <YAxis
              tick={{ fill: theme === "dark" ? "white" : "black" }}
              domain={[0, 100]}
              tickFormatter={(value) => `${value}`}
            />
            <Tooltip
              contentStyle={{
                backgroundColor: theme === "dark" ? "#1C1C1E" : "#FFFFFF",
                borderColor: theme === "dark" ? "#FFFFFF" : "#D8D8D8",
                borderRadius: "6px",
                color: theme === "dark" ? "#FFFFFF" : "black",
              }}
              formatter={(value) => [`${value}%`, 'Progress']}
            />

            {/* Small red line at each dot position */}
            <Line
              type="monotone"
              dataKey="value"
              stroke={theme === "dark" ? "#000000" : "#000000"}
              strokeWidth={2}
            />
            <defs>
              <linearGradient id="lightGradient" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor="rgba(204, 9, 39, 0.3)" />
                <stop offset="100%" stopColor="rgba(204, 9, 39, 0.05)" />
              </linearGradient>

              <linearGradient id="darkGradient" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor="rgba(255, 77, 77, 0.3)" />
                <stop offset="100%" stopColor="rgba(255, 77, 77, 0.05)" />
              </linearGradient>
            </defs>

            <Area
              type="monotone"
              dataKey="value"
              stroke={theme === "dark" ? "#FF4D4D" : "#D22B2B"}
              strokeWidth={2}
              fill={
                theme === "dark" ? "url(#darkGradient)" : "url(#lightGradient)"
              }
              dot={{ r: 4, fill: "red", stroke: "white", strokeWidth: 2 }}
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
      <div className="w-full my-2 flex justify-center items-center gap-1 relative">
        {/* Red Line and Dot */}
        <div className="flex items-center gap-1 relative">
          <span className="bg-red-600 w-10 h-1 rounded-md absolute ml-[-14px]"></span>
          <span className="bg-red-600 w-3 h-3 rounded-full border-2 border-white z-10"></span>
        </div>

        {/* Year Below */}
        <span className="text-center ml-4">2025</span>
      </div>
    </div>
  );
};

export default ProjectProgressChart;
