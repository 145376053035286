import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    projects: [],
    tickets:[], 
    columns: []
  };

  const projectSlice = createSlice({
    name: 'board',
    initialState,
    reducers: {
      updateProject: (state, { payload }) => {
        state.projects = payload
      },
      updateTickets: (state, { payload }) => {
        state.tickets = payload
      },
      updateColumns: (state, { payload }) => {
        state.columns = payload
      }
    },
  });
  export const { updateProject,updateTickets, updateColumns } = projectSlice.actions;

  export default projectSlice.reducer;
  