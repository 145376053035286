import React from "react";
import AnimationLottie from "../../../lottie/animation-lottie";
import productivityAnimation from "../../../lottie/productivityAnimation.json";
import styles from "./styles.module.css"; 
import { useSelector } from "react-redux";

const BoostProductivity = () => {
  const { theme } = useSelector((state) => state.theme);
  return (
    <section className={styles.heroSection}>
      <div className={styles.heroContainer}>
        <div className={styles.heroAnimation}>
          <AnimationLottie animationPath={productivityAnimation} />
          <h4 className="text-[19px] font-medium mt-2">Track work hours, generate detailed reports, and customize workflows effortlessly with BugTrackin for efficient workforce management.</h4>
        </div>
        <div className={styles.heroText}>
          <h2 className="main-heading">Effortless Workforce Management</h2>
          <div className="flex flex-col gap-5">
            <div className={`${styles.productivityText}  ${
                    theme === "dark" ?'bg-[#000000]': "bg-[#FFFFFF]"
                  }`}>
              <h6 className="sub-heading text-[22px]">Detailed Work Reports</h6>
              <span>Generate daily, weekly, and monthly reports to analyze performance and improve productivity.</span>
            </div>
            <div className={`${styles.productivityText}  ${
                    theme === "dark" ?'bg-[#000000]': "bg-[#FFFFFF]"
                  }`}>
              <h6 className="sub-heading text-[22px]">Automated Time Tracking</h6>
              <span>Adapt your workflow with flexible task management and personalized settings.</span>
            </div>
            <div className={`${styles.productivityText}  ${
                    theme === "dark" ?'bg-[#000000]': "bg-[#FFFFFF]"
                  }`}>
              <h6 className="sub-heading text-[22px]">Customizable Workflows</h6>
              <span>Adapt your workflow with flexible task management and personalized settings.</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BoostProductivity;
