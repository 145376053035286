import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  users: [], // Initial state as an empty array
  dataLoading: false, // Initial state for data loading
  userId: [],
  filterUsers:[],
  nextPage:'',
  totalNoOfUser:'' ,
  selectedUser:''// Initial state for userId
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    updateUsers: (state, { payload }) => {
      // Update the users array with the provided payload
      state.users = payload;
    },
    toggleDataLoading: (state) => {
      // Toggle the value of dataLoading between true and false
      state.dataLoading = !state.dataLoading;
    },
    updateUserId: (state, { payload }) => {
      state.userId = payload;
    },
    updateFilterUsers: (state, { payload }) => {
      state.filterUsers = payload;
    },
    updateNextPage: (state, { payload }) => {
      state.nextPage = payload;
    },
    updateTotalNoOfUser: (state, { payload }) => {
      state.totalNoOfUser = payload;
    },
    updateSelectedUser: (state, { payload }) => {
      state.selectedUser = payload;
    }
  },
});

export const { updateUsers,  toggleDataLoading, updateUserId,updateFilterUsers, updateNextPage,updateTotalNoOfUser,updateSelectedUser } = usersSlice.actions;

export default usersSlice.reducer;
