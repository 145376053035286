import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  LabelList,
} from "recharts";

// Convert time (hh:mm) to decimal hours (e.g., 3:40 → 3.67 hours)
const convertToHours = (time) => {
  if (!time) return 0;
  const [hours, minutes] = time.split(":").map(Number);
  return hours + minutes / 60;
};

// Convert decimal hours back to `hh:mm` format for display
const formatTime = (hours) => {
  if (hours === 0) return "0:00";
  const h = Math.floor(hours);
  const m = Math.round((hours - h) * 60);
  return `${h}:${m < 10 ? '0' + m : m}`;
};

// Calculate the maximum Y value plus one extra hour
const getYAxisDomain = (data) => {
  if (!data || data.length === 0) return [0, 1];
  
  const maxValue = Math.max(...data.map(item => convertToHours(item.activeTime)));
  return [0, Math.ceil(maxValue) + 1]; // Round up and add 1
};

// Format date for display based on report type
const formatDateLabel = (dateStr) => {
  if (!dateStr) return "";
  
  // Handle weekly format (week_2025_10)
  if (dateStr.startsWith('week_')) {
    const parts = dateStr.split('_');
    if (parts.length === 3) {
      return `Week ${parts[2]}`;
    }
  }
  
  // Handle daily format (YYYY-MM-DD)
  if (dateStr.match(/^\d{4}-\d{2}-\d{2}$/)) {
    const date = new Date(dateStr);
    return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
  }
  
  // For monthly or other formats, return as is
  return dateStr;
};

const WeeklyActiveTime = ({ data, title }) => {
  const { theme } = useSelector((state) => state.theme);
  const [chartData, setChartData] = useState([]);
  const [yAxisDomain, setYAxisDomain] = useState([0, 1]);
  
  useEffect(() => {
    
    if (!data || data.length === 0) {
      setChartData([]);
      setYAxisDomain([0, 1]);
      return;
    }
    
    // Process data for the chart
    const processedData = data.map(item => ({
      date: item.date,
      label: formatDateLabel(item.date),
      activeTime: convertToHours(item.activeTime)
    }));
    
    setChartData(processedData);
    setYAxisDomain(getYAxisDomain(data));
  }, [data]);

  return (
    <div
      className={`w-full ${
        theme === "dark" ? "" : ""
      }`}
    >
      <h2 className="text-lg font-semibold mb-4 ml-4 capitalize">{title}</h2>

      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          data={chartData}
          margin={{ top: 0, right: 30, left: 0, bottom: 0 }}
          barCategoryGap={20} // Ensure bars are well-centered
        >
          {/* Stronger Grid Lines (No hover effect) */}
          <CartesianGrid
            strokeDasharray="3 3"
            stroke={theme === "dark" ? "#666" : "#BBB"}
            horizontalFillOpacity={0} // Remove background change on hover
            verticalFillOpacity={0} // Remove background change on hover
          />

          {/* X-Axis with formatted labels */}
          <XAxis
            dataKey="label"
            tick={{ fill: theme === "dark" ? "white" : "black" }}
            tickLine={false}
            axisLine={{ stroke: theme === "dark" ? "#666" : "#BBB" }}
            fontSize={10}
          />

          {/* Y-Axis in hours (0h, 2h, 4h, ...) */}
          <YAxis
            tickFormatter={(hours) => `${Math.floor(hours)}h`}
            domain={yAxisDomain}
            tick={{ fill: theme === "dark" ? "white" : "black" }}
            axisLine={{ stroke: theme === "dark" ? "#666" : "#BBB" }}
          />

          {/* Tooltip showing active time in hh:mm format */}
          <Tooltip
            formatter={(value) => [formatTime(value), "Active Time"]}
            contentStyle={{ 
              backgroundColor: theme === "dark" ? "#1C1C1E" : "#FFFFFF", 
              borderRadius: 5, 
              border: `1px solid ${theme === 'dark' ? "#FFFFFF" : "#D8D8D8"}`, 
              color: theme === 'dark' ? 'white' : "black" 
            }}
            cursor={false} // This prevents the background color change
          />

          {/* Bars centered in grid columns */}
          <Bar
            dataKey="activeTime"
            fill={theme === "dark" ? "#6FD195" : "#16A34A"}
            barSize={20}
            radius={[0, 0, 0, 0]}
            style={{ cursor: 'pointer' }}
          >
            {/* Show active time value at the end of each bar */}
            <LabelList
              dataKey="activeTime"
              position="top"
              formatter={(value) => value > 0 ? formatTime(value) : ""}
              fill={theme === "dark" ? "white" : "black"}
              fontSize={12}
              fontWeight="bold"
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
      <div className="w-full flex mt-2 justify-center items-center gap-2">
        <div className={`w-3 h-3 rounded-sm ${theme === "dark" ? "bg-[#6FD195]" : "bg-[#16A34A]"}`}></div>
        <span className="text-sm">Weekly Active Time(Hours)</span>
      </div>
    </div>
  );
};

export default WeeklyActiveTime;
