import React, { useEffect, useRef, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styles from "./user.module.css";
import profile from "../../../assets/face1.jpg";
import { useDispatch, useSelector } from "react-redux";
import { fetchApi } from "../../../utlis/axios";
import Loader from "../../Loader/Loader";
import {
  FaAngleDown,
  FaAngleUp,
  FaEye,
  FaEyeSlash,
  FaUserCircle,
} from "react-icons/fa";
import { toggleAuth } from "../../../Redux/Slices/auth-slice";
import { CiEdit, CiSearch } from "react-icons/ci";
import { updateFilterUsers, updateSelectedUser } from "../../../Redux/Slices/users-slice";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { Tooltip } from "@mui/material";
import loader from "../../../assets/loading-gif.gif";
import { Link } from "react-router-dom";
import { addToast } from "../../../Redux/Slices/toast-slice";
import { updateUsers } from "../../../Redux/Slices/users-slice";
import { updateUserId } from "../../../Redux/Slices/users-slice";

const User = () => {
  const { theme } = useSelector((state) => state.theme);
  const { userInfo } = useSelector((state) => state?.auth || {});
  const users = useSelector((state) => state.allUsers?.filterUsers);
  const filterUsers = useSelector((state) => state.allUsers?.filterUsers);
  const [showPassword, setShowPassword] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  //const [users, setUsers] = useState([]);
  const [role, setRole] = useState("Time Reporter");
  const [roleId, setRoleId] = useState();
  const [projectList, setProjectList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [formData, setFormData] = useState({
    id: "",
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    currency: "",
    payment_amount: "",
    payment_type: "",
    project: "",
    domain: userInfo?.user?.domain,
  });
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [openDropdown, setOpenDropdown] = useState(null);
  const [roleDropdown, setRoleDropdown] = useState(false);
  const [modalForm, setModalForm] = useState("create");
  const [userId, setUserId] = useState("");
  const [searching, setSearching] = useState(false);
  const [orignalUsers, setOrignalUsers] = useState(null);
  const [passwordchange, setPasswordChange] = useState(false);
  const [roles, setRoles] = useState(null);
  const dispatch = useDispatch();
  const [deletingStates, setDeletingStates] = useState({});
  const { nextPage, selectedUser } = useSelector(
    (state) => state?.allUsers || {}
  );
  const [next, setNext] = useState(nextPage);
  const [userList, setuserList] = useState([]);
  const [prev, setPrev] = useState("");
  const [originalNext, setOriginalNext] = useState("");
  const [originalPrev, setOriginalPrev] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isUsersLoaded, setIsUsersLoaded] = useState(false);
  const [isNavigateButtonLoaded, setIsNavigateButtonLoaded] = useState(false)

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === "password") {
      calculatePasswordStrength(value);
    }
  };
  const calculatePasswordStrength = (password) => {
    let strength = 0;
    if (password.length >= 8) strength++;
    if (/[A-Z]/.test(password)) strength++;
    if (/[a-z]/.test(password)) strength++;
    if (/\d/.test(password)) strength++;
    if (/[@$!%*?&]/.test(password)) strength++;
    setPasswordStrength(strength);
  };

  const handleAddUser = async (e) => {
    e.preventDefault();

    if (
      !formData.first_name ||
      !formData.last_name ||
      !formData.email ||
      !formData.password ||
      !formData.payment_amount ||
      !formData.payment_type
    ) {
      dispatch(addToast({ message: "All fields is required", type: "error" }));
      return;
    }
    if (!role) {
      dispatch(addToast({ message: "Please select user role", type: "error" }));
      return;
    }

    setAddLoading(true);
    const userData = new FormData();
    userData.append("first_name", formData.first_name);
    userData.append("last_name", formData.last_name);
    userData.append("email", formData.email);
    userData.append("password", formData.password);
    userData.append("payment_amount", formData.payment_amount);
    userData.append("currency", "USD");
    userData.append("payment_type", formData.payment_type);
    userData.append("project", formData.project);
    userData.append("role", role);
    userData.append("domain", userInfo.user.domain);

    try {
      const result = await fetchApi.post("add-user/", userData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });

      if (result.status === 201) {
        setFormData({
          first_name: "",
          last_name: "",
          email: "",
          password: "",
          payment_amount: "",
          payment_type: "",
          currency: "",
          project: "",
        });
        dispatch(
          addToast({ message: "User added successfully", type: "success" })
        );
        setIsModalOpen(false);
        setAddLoading(false);
        setuserList([...userList, result.data]);
        getUsers()
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(toggleAuth({ isLogin: false, userInfo: null }));
        dispatch(addToast({ message: "Your session expired", type: "error" }));
        return;
      }
      const errorMessage =
        error?.response?.data?.error ||
        error?.response?.data?.email?.[0] ||
        error?.response?.data?.[0] ||
        error?.response?.data?.non_field_errors[0] ||
        "User add failed";
      setAddLoading(false);
      dispatch(addToast({ message: errorMessage, type: "error" }));
      console.log(error);
    } finally {
      setAddLoading(false);
    }
  };
  let isRoleFetched = false;
  const handleGetRoles = async () => {
    if (isRoleFetched) return;
    isRoleFetched = true;
    try {
      const result = await fetchApi.get("roles/");
      if (result.status === 200) {
        const data = result.data;
        const userRole = data?.results?.filter(
          (item) => item.name === "Time Reporter"
        );
        setRoleId(userRole?.id);
        setRole(userRole?.id);
        const rolesresults = data?.results?.filter(
          (item) => item.name !== "SUPERUSER"
        );
        setRoles(rolesresults);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(toggleAuth({ isLogin: false, userInfo: null }));
        dispatch(addToast({ message: "Your session expired", type: "error" }));
        return;
      }
      dispatch(addToast({ message: "Something went wrong", type: "error" }));
      console.log("Error getting roles: ", error);
    }
  };
  let isProjectFetched = useRef(false);
  const getProjects = async () => {
    if (isProjectFetched.current) return;
    isProjectFetched.current = true;
    try {
      const result = await fetchApi.get("projects/", {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });
      if (result.status === 200) {
        const data = result.data;
        setProjectList(data.results);
        if (data.next) {
          getMore(data.next);
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(toggleAuth({ isLogin: false, userInfo: null }));
        dispatch(addToast({ message: "Your session expired", type: "error" }));
        return;
      }
      dispatch(addToast({ message: "Something went wrong", type: "error" }));
      console.log("Error getting projects: ", error);
    }
  };
  const getMore = async (url) => {
    try {
      const result = await fetchApi.get(url, {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });
      if (result.status === 200) {
        const data = result.data;
        setProjectList((prev) => [...prev, ...data.results]);
        if (data.next) {
          getMore(data.next);
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(toggleAuth({ isLogin: false, userInfo: null }));
        dispatch(addToast({ message: "Your session expired", type: "error" }));
        return;
      }
      dispatch(addToast({ message: "Something went wrong", type: "error" }));
      console.log("Error getting projects: ", error);
    }
  };
  const getSubdomain = () => {
    const host = window.location.host;
    if (host.includes("localhost")) {
      return host.split(".localhost")[0];
    } else if (host.includes(".bugtrackin")) {
      return host.split(".bugtrackin.com")[0];
    }
    return null;
  };

  const subdomain = getSubdomain();
  const getUsers = async () => {
    try {
      const response = await fetchApi.get(`users/?domain=${subdomain}`, {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });

      if (response.status === 200) {
        let allUsers = response?.data?.results || [];
        const next = response.data.next;

          allUsers = [...allUsers]; // Merge the next users

        // Remove duplicates based on user ID
        const uniqueUsers = Array.from(
          new Map(allUsers.map((user) => [user.id, user])).values()
        );

        dispatch(updateFilterUsers(uniqueUsers));

        if (userInfo?.user?.role?.name === "Time Reporter") {
          dispatch(updateUserId(userInfo?.user?.id));
        } else {
          const timeReporters = uniqueUsers.filter(
            (user) => user.role.name === "Time Reporter"
          );
          if (!userId) {
            if (timeReporters.length === 0) {
              dispatch(updateUserId(userInfo?.user?.id));
            } else {
              dispatch(updateUserId(timeReporters[0].id));
            }
          }
          dispatch(updateUsers(timeReporters));
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(toggleAuth({ isLogin: false, userInfo: null }));
        dispatch(addToast({ message: "Your session expired", type: "error" }));
        return;
      }
      dispatch(addToast({ message: "Something went wrong", type: "error" }));
      console.error(error);
    }
  };

  const handleNextUsers = async (url) => {
    try {
      const response = await fetchApi.get(url, {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });

      if (response.status === 200) {
        let newUsers = response?.data?.results || [];
        const next = response.data.next;

        if (next) {
          const nextUsers = await handleNextUsers(next);
          newUsers = [...newUsers, ...nextUsers]; // Merge recursively fetched users
        }

        return newUsers;
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(toggleAuth({ isLogin: false, userInfo: null }));
        dispatch(addToast({ message: "Your session expired", type: "error" }));
        return [];
      }
      dispatch(addToast({ message: "Something went wrong", type: "error" }));
      console.error(error);
      return [];
    }
  };

  useEffect(() => {
    // console.log(userInfo)
    handleGetRoles();
    getProjects();
    //getUsers();
  }, []);

  function formatDate(dateString) {
    const date = new Date(dateString);

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  }
  useEffect(() => {
    if(isUsersLoaded){
      return
    }else{
      setuserList(filterUsers.filter((item) => item.is_primary_admin === false));
      setIsUsersLoaded(true)
  
    }
  }, [filterUsers]);

  useEffect(() => {
    if(isNavigateButtonLoaded){
      return
    }else{
     setNext(nextPage)
     setOriginalNext(nextPage)
      setIsNavigateButtonLoaded(true)
    }

  }, [nextPage]);

  const paymentTypeOptions = ["monthly", "hourly", "weekly"];

  const handleOptionChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
    setOpenDropdown(null);
  };
  const updateFormDataFromUser = (user) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      id: user.id,
      first_name: user.first_name || "",
      last_name: user.last_name || "",
      password: user.password || "",
      email: user.email || "",
      currency: user.currency || "",
      payment_amount: user.payment_amount?.toString() || "",
      payment_type: user.payment_type || "",
      project: user.project?.id || "",
      domain: user.domain || prevFormData.domain, // Preserve the existing domain if not present in the user object
    }));
    setUserId(user.id);
    setIsModalOpen(true);
    setModalForm("update");
  };
  const handleUpdateUser = async (e) => {
    e.preventDefault();

    // Check for required fields
    if (!formData.first_name || !formData.last_name || !formData.email) {
      dispatch(addToast({ message: "Name and email require", type: "warn" }));
      return;
    }
    const existingUser = userList.find((user) => user.id === formData.id);

    const updatedFields = {};
    Object.keys(formData).forEach((key) => {
      if (key !== "password") { // Exclude password from comparison
        // Special handling for project field
        if (key === "project" && formData.project) {
          // Convert project ID string to object format if it's different from existing
          if (formData.project !== (existingUser.project?.id || existingUser.project)) {
            updatedFields[key] = { id: formData.project };
          }
        } 
        // For all other fields
        else if (formData[key] !== existingUser[key]) {
          updatedFields[key] = formData[key];
        }
      }
    });

    // Handle password update separately
    if (formData.password) {
      if (formData.password.length < 8) {
        dispatch(
          addToast({
            message: "Password must be at least 8 characters",
            type: "error",
          })
        );
        return;
      } else {
        try {
          setAddLoading(true);
          const res = await fetchApi.post(
            "change-password/",
            { user_id: formData.id, password: formData.password },
            {
              headers: {
                Authorization: `Bearer ${userInfo.access_token}`,
              },
            }
          );
          setPasswordChange(false);
          if (res.status !== 200) {
            throw new Error("Password update failed");
          }
        } catch (error) {
          setLoading(false);
          dispatch(
            addToast({ message: "Password update failed", type: "error" })
          );
          return;
        }
      }
    }

    // If no fields were changed, abort the request
    if (Object.keys(updatedFields).length === 0) {
      dispatch(addToast({ message: "No changes were made", type: "warn" }));
      return;
    }

    // Add `is_archived` explicitly, if necessary
    updatedFields.is_archived = false;

    setAddLoading(true);

    try {
      // Make a PATCH request with only the updated fields
      const result = await fetchApi.patch(
        `users/${formData.id}/`,
        updatedFields,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userInfo.access_token}`,
          },
        }
      );

      if (result.status === 200 || result.status === 204) {
        const updatedUserList = users.map((user) =>
          user.id === formData.id ? { ...user, ...result.data.user } : user
        );
        const newList = filterUsers.map((user) =>
          user.id === formData.id ? { ...user, ...result.data.user } : user
        );
        const updatedList=userList.map((user) =>
          user.id === formData.id ? { ...user, ...result.data.user } : user)
        setuserList(updatedList)
        
       // dispatch(updateFilterUsers(newList));
        if (result.data.user.role.name === "Time Reporter") {
         // dispatch(updateUsers(updatedUserList)); // Dispatch updateUsers action
        }
        setFormData({
          first_name: "",
          last_name: "",
          email: "",
          password: "",
          payment_amount: "",
          payment_type: "",
          currency: "",
          project: "",
        });
        setPasswordChange(false);
        dispatch(
          addToast({ message: "User update successfully", type: "success" })
        );
        setIsModalOpen(false);
        setAddLoading(false);
        getUsers();
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(toggleAuth({ isLogin: false, userInfo: null }));
        dispatch(addToast({ message: "Your session expired", type: "error" }));
        return;
      }
      const errorMessage =
        error?.response?.data?.error ||
        error?.response?.data?.email?.[0] ||
        error?.response?.data?.[0] ||
        "User update failed";
      setAddLoading(false);
      dispatch(addToast({ message: errorMessage, type: "error" }));
      console.log(error);
    } finally {
      setAddLoading(false);
      setPasswordChange(false);
    }
  };

  const handleDelete = async (id) => {
    setDeletingStates((state) => ({ ...state, [id]: true }));
    try {
      // API call to delete user
      await fetchApi.delete(`users/${id}/`, {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });
      // Success: Show confirmation
      setuserList(userList.filter((item) => item.id !== id));
      dispatch(updateFilterUsers(filterUsers.filter((item) => item.id !== id)));
      dispatch(updateUsers(users.filter((item) => item.id !== id)));
if(id === selectedUser.id){
  dispatch(updateSelectedUser(null))
}
      dispatch(
        addToast({ message: "User deleted successfully", type: "success" })
      );
    } catch (error) {
      // Handle unauthorized session (401)
      if (error.response?.status === 401) {
        dispatch(toggleAuth({ isLogin: false, userInfo: null }));
        dispatch(addToast({ message: "Your session expired", type: "error" }));
        return;
      }
      const errorMessage =
        error.response?.data?.detail || error.message || "Something went wrong";
      dispatch(addToast({ message: errorMessage, type: "error" }));
    } finally {
      setDeletingStates((state) => ({ ...state, [id]: false }));
    }
  };
  const handleSearch = async (e) => {
    if (!searchQuery) {
        dispatch(addToast({ message: "Please enter UserName", type: "warn" }));
        return;
    }
    setSearching(true);
    try {
        const result = await fetchApi.get(
            `users/?search=${searchQuery}&is_active=true&is_deleted=false&domain=${userInfo?.user?.domain}`,
            {
                headers: {
                    Authorization: `Bearer ${userInfo.access_token}`,
                },
            }
        );

        if (result.status === 200) {
            
              const res=  result.data.results.filter(
                    (item) => item.domain === userInfo?.user?.domain
                )
                setOrignalUsers(res.filter(item => item.is_primary_admin === false));
            setNext(result.data.next);
            setPrev(result.data.previous);
        }
    } catch (error) {
        dispatch(addToast({ message: error.message, type: "error" }));
    } finally {
        setSearching(false);
    }
  };

  const handlePageChange = async (page) => {
    setIsLoading(true);
    try {
      const response = await fetchApi.get(page, {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });
      if (response.status === 200) {
        const userss=response.data.results.filter((item)=>item.is_primary_admin === false)
        setOrignalUsers(userss);
        setuserList(userss);
        setNext(response.data.next);
        setPrev(response.data.previous);
        setOriginalNext(response.data.next);
        setOriginalPrev(response.data.previous);
      }
    } catch (error) {
      dispatch(addToast({ message: error.message, type: "error" }));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <ToastContainer />
      <style>
        {`
      .input {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
   border: ${theme === "dark" ? "1px solid #ffffff" : "1px solid #000000"};
  box-sizing: border-box;
  background-color: transparent;
  option{
     color: #000000; 
    }
}
      `}
      </style>
      {/*showHourRate && (
        <div className={styles.modalOverlay}>
          <div
            className={`${styles.modal} ${
              theme === "dark"
                ? "bg-[#212529] text-white"
                : "bg-[#e5e5e5] text-black"
            }`}
          >
            <HourlyRate setShowHourRate={setShowHourRate} data={rateEditData} />
          </div>{" "}
        </div>
      )*/}

      {isLoading && (
        <>
          <div
            className="fixed inset-0 bg-black bg-opacity-50 z-10"
            style={{ top: "95px" }}
          ></div>
          <div
            className="fixed inset-0 flex items-center justify-center z-20"
            style={{ top: "95px" }}
          >
            <Loader />
          </div>
        </>
      )}

      <div
        className={`${styles.main_Heading} ${
          theme === "dark" ? "text-white" : "text-black"
        }`}
      >
        Users
      </div>
      <hr className="w-full" />
      <div
        className={`${styles.outlet}  ${
          theme === "dark" ? "text-white" : "text-black"
        }`}
      >
        {isModalOpen && (
          <div className={styles.modalOverlay}>
            <div
              className={`${styles.modal} ${
                theme === "dark"
                  ? "bg-[#212529] text-white"
                  : "bg-[#e5e5e5] text-black"
              }`}
              style={{ width: "max-content" }}
            >
              <div className={styles.modalHeader}>
                <h2 className="font-semibold ">
                  {modalForm === "update" ? "Update User" : "Add User"}
                </h2>
                <button
                  className={styles.closeButton}
                  onClick={() => {
                    setIsModalOpen(false);
                    setPasswordChange(false);
                    setModalForm("craete");
                    setFormData({
                      first_name: "",
                      last_name: "",
                      email: "",
                      password: "",
                      payment_amount: "",
                      payment_type: "",
                      currency: "",
                      project: "",
                    });
                  }}
                >
                  &times;
                </button>
              </div>
              <form
                onSubmit={
                  modalForm === "update" ? handleUpdateUser : handleAddUser
                }
              >
                <div className="grid sm:grid-cols-2 grid-cols-1 sm:gap-4">
                  <div className={styles.formGroup}>
                    <label>First name</label>
                    <input
                      className="input"
                      type="text"
                      name="first_name"
                      value={formData.first_name}
                      onChange={handleInputChange}
                      placeholder="Enter first name"
                    />
                  </div>

                  <div className={styles.formGroup}>
                    <label>Last name</label>
                    <input
                      className="input"
                      type="text"
                      name="last_name"
                      value={formData.last_name}
                      onChange={handleInputChange}
                      placeholder="Enter last name"
                    />
                  </div>

                  <div className={styles.formGroup}>
                    <label>E-Mail</label>
                    <input
                      className="input"
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      placeholder="Enter email"
                    />
                  </div>

                  <div
                    className={styles.formGroup}
                    style={{ marginBottom: "0px" }}
                  >
                    <label>Password</label>
                    {modalForm === "update" && !passwordchange ? (
                      <div
                        className={`rounded-md border p-2 w-full flex justify-between ${
                          theme === "dark" ? "border-white" : "border-black"
                        }`}
                      >
                        <input
                          type="password"
                          value="........"
                          className="bg-transparent w-[70px] outline-none"
                        />
                        <span
                          onClick={() => setPasswordChange(true)}
                          className="cursor-pointer hover:text-red-600"
                        >
                          Change
                        </span>
                      </div>
                    ) : (
                      <div className="relative">
                        <input
                          type={showPassword ? "text" : "password"}
                          name="password"
                          value={formData.password}
                          onChange={handleInputChange}
                          placeholder={
                            modalForm === "update"
                              ? "Enter New Password"
                              : "Enter Password"
                          }
                          className="input"
                          style={{ paddingRight: "35px" }}
                        />
                        <div
                          className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? <FaEye /> : <FaEyeSlash />}
                        </div>
                      </div>
                    )}
                    <div className="w-full h-2 mt-2 rounded-full overflow-hidden">
                      <div
                        className={`h-full rounded-full transition-all duration-300 ${
                          passwordStrength < 2
                            ? "bg-red-500"
                            : passwordStrength < 4
                            ? "bg-orange-500"
                            : "bg-green-500"
                        }`}
                        style={{ width: `${(passwordStrength / 5) * 100}%` }}
                      ></div>
                    </div>
                  </div>

                  {/* Payment Type Selector */}
                  <div className="formGroup mb-[15px]">
                    <label>Payment Type</label>
                    <div className="relative">
                      <div
                        className={`cursor-pointer p-2 border rounded w-full ${
                          theme === "dark"
                            ? "bg-transparent text-white border-[#ffffff] "
                            : "bg-transparent text-black border-black"
                        }
                      ${formData.payment_type ? "" : "text-[#ABADB3]"}
                      `}
                        onClick={() =>
                          setOpenDropdown((prev) =>
                            prev === "paymentType" ? null : "paymentType"
                          )
                        }
                      >
                        {formData.payment_type || "Select Payment Type"}
                      </div>
                      {openDropdown === "paymentType" && (
                        <div
                          className={`absolute z-10 mt-2 max-h-[200px] overflow-y-auto w-full rounded shadow-lg ${
                            theme === "dark"
                              ? "bg-dashboardDarkMode"
                              : "bg-white"
                          }`}
                        >
                          {paymentTypeOptions.map((type, index) => (
                            <div
                              key={index}
                              onClick={() =>
                                handleOptionChange("payment_type", type)
                              }
                              className={`p-2 cursor-pointer ${
                                theme === "dark"
                                  ? "hover:bg-gradient-to-r hover:from-[#fff3] hover:to-[#fff0] transition-all"
                                  : "hover:bg-gradient-to-r hover:from-[#6d6d6d33] hover:to-[#fff0] transition-all"
                              }`}
                            >
                              {type}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className={styles.formGroup}>
                    <label>Payment Amount</label>
                    <input
                      className="input placeholder:text-[#ABADB3]"
                      type="number"
                      name="payment_amount"
                      value={formData.payment_amount}
                      onChange={handleInputChange}
                      placeholder="Enter an amount"
                    />
                  </div>
                  {userInfo?.user?.plan?.name !== "Basic" && (
                    <div className="formGroup mb-[15px]">
                      <label>Project</label>
                      <div className="relative">
                        <div
                          className={`cursor-pointer p-2 border rounded w-full flex justify-between items-center ${
                            theme === "dark"
                              ? "bg-transparent text-white border-[#ffffff]"
                              : "bg-transparent text-black border-black"
                          } ${formData.project ? "" : "text-[#ABADB3]"}`}
                          onClick={() =>
                            setOpenDropdown((prev) =>
                              prev === "project" ? null : "project"
                            )
                          }
                        >
                          {formData?.project
                            ? projectList.find((c) => c.id === formData.project)
                                ?.title || "Choose project"
                            : "Choose project"}
                          {openDropdown === "project" ? (
                            <FaAngleDown />
                          ) : (
                            <FaAngleUp />
                          )}
                        </div>
                        {openDropdown === "project" && (
                          <div
                            className={`absolute z-10 bottom-[46px] max-h-[200px] overflow-y-auto w-full rounded shadow-lg ${
                              theme === "dark"
                                ? "bg-dashboardDarkMode"
                                : "bg-white"
                            }`}
                          >
                            <Link
                              to="/dashboard/board"
                              className={`p-2 cursor-pointer w-full flex ${
                                theme === "dark"
                                  ? "bg-gradient-to-r from-[#fff3] to-[#fff0] transition-all"
                                  : "bg-gradient-to-r from-[#6d6d6d33] to-[#fff0] transition-all"
                              }`}
                            >
                              Craete New Project
                            </Link>
                            {projectList.map((project, index) => (
                              <div
                                key={index}
                                onClick={() => {
                                  handleOptionChange("project", project.id);
                                  console.log(project);
                                }}
                                className={`p-2 cursor-pointer ${
                                  theme === "dark"
                                    ? "hover:bg-gradient-to-r hover:from-[#fff3] hover:to-[#fff0] transition-all"
                                    : "hover:bg-gradient-to-r hover:from-[#6d6d6d33] hover:to-[#fff0] transition-all"
                                }`}
                              >
                                {project.title}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  <div className={`${styles.formGroup} `}>
                    <label>User Role</label>
                    <div className="relative">
                      <div
                        className={`cursor-pointer p-2 border rounded w-full flex justify-between items-center ${
                          theme === "dark"
                            ? "bg-transparent text-white border-[#ffffff]"
                            : "bg-transparent text-black border-black"
                        } ${formData.project ? "" : "text-[#ABADB3]"}`}
                        onClick={() => setRoleDropdown(!roleDropdown)}
                      >
                        {role
                          ? roles?.find((c) => c.id === role)?.name ||
                            "Choose user role"
                          : "Choose user role"}
                        {roleDropdown ? <FaAngleDown /> : <FaAngleUp />}
                      </div>
                      {roleDropdown && (
                        <div
                          className={`absolute z-50 mt-2 w-full rounded shadow-lg ${
                            theme === "dark"
                              ? "bg-dashboardDarkMode"
                              : "bg-white"
                          }`}
                          style={{ top: "auto", bottom: "100%" }}
                        >
                          {roles?.map((role, index) => (
                            <div
                              key={index}
                              onClick={() => {
                                setRoleDropdown(false);
                                setRole(role.id);
                              }}
                              className={`p-2 cursor-pointer ${
                                theme === "dark"
                                  ? "hover:bg-gradient-to-r hover:from-[#fff3] hover:to-[#fff0] transition-all"
                                  : "hover:bg-gradient-to-r hover:from-[#6d6d6d33] hover:to-[#fff0] transition-all"
                              }`}
                            >
                              {role.name}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className={styles.actions} disabled={loading}>
                  <button type="submit" className="heroButton w-full">
                    {addLoading
                      ? "Loading..."
                      : modalForm === "update"
                      ? "Update"
                      : "Create"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
        {loading ? (
          <Loader />
        ) : (
          <>
            <div className="flex xs:flex-row flex-col justify-between sm:items-center gap-4 w-full mb-4 ">
              <button
                className=" heroButton w-max"
                onClick={() => setIsModalOpen(true)}
              >
                + New User
              </button>
              <div className="flex items-center gap-2 sm:w-56 w-full justify-between px-4 border border-red-600 rounded-3xl ">
                <input
                  type="text"
                  value={searchQuery}
                  onChange={(e) => {
                    setSearchQuery(e.target.value);
                    if (!e.target.value) {
                        setOrignalUsers(null);
                        setNext(originalNext);
                        setPrev(originalPrev);
                    }
                  }}
                  placeholder="Search for users..."
                  className="py-2 bg-transparent border-none outline-none w-full"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSearch();
                    }
                  }}
                />
                {searching ? (
                  <img src={loader} alt="loading" className="w-6 h-6" />
                ) : (
                  <CiSearch
                    className="w-6 h-6 cursor-pointer"
                    onClick={handleSearch}
                  />
                )}
              </div>
            </div>
            <div className="overflow-x-auto">
              <div className={styles.tableResponsive}>
                <table className={styles.table}>
                  <thead>
                    <tr>
                      <th>Empolyee</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Joining Date</th>
                      <th>Role</th>
                      <th>Hourly Rate</th>
                      <th className="flex justify-center items-center">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {searchQuery && !searching && orignalUsers
                      ? orignalUsers.map((data, i) => (
                          <tr
                            key={data?.id}
                            className={`${
                              theme === "dark"
                                ? "hover:bg-gradient-to-r hover:from-[#fff3] hover:to-[#fff0] transition-all"
                                : "hover:bg-gradient-to-r hover:from-[#6d6d6d33] hover:to-[#fff0] transition-all"
                            }`}
                          >
                            <td className="py-1">
                              {data.picture ? (
                                <img
                                  src={data.picture}
                                  className="w-8 h-8 object-cover rounded-full"
                                  alt="profile picture"
                                  loading="lazy"
                                />
                              ) : (
                                <FaUserCircle className="w-8 h-8" />
                              )}
                            </td>
                            <td>
                              {data?.first_name} {data?.last_name}
                            </td>
                            <td className="no-scrollbar max-w-[200px] overflow-auto">
                              {data?.email}
                            </td>
                            <td>{formatDate(data?.created_at)}</td>
                            <td>
                              {typeof data?.role === "object"
                                ? data?.role?.name
                                : data?.role}
                            </td>
                            <td>
                              {!data.is_primary_admin &&
                              <span className="flex gap-2 items-center">
                                {data?.payment_amount} {data?.currency} /{" "}
                                {data?.payment_type === "hourly"
                                  ? "Hour"
                                  : data?.payment_type === "monthly"
                                  ? "Month"
                                  : " Week"}
                              </span>
                              }
                            </td>
                            <td>
                              {" "}
                              {userInfo?.user?.role?.name === "Admin" ? (
                                !data?.is_primary_admin ?
                                <div className="flex items-center gap-2">
                                  <Tooltip title="Edit" arrow placement="top">
                                    <button
                                      onClick={() =>
                                        updateFormDataFromUser(data)
                                      }
                                    >
                                      <CiEdit
                                        className={`w-5 h-5 cursor-${
                                          userInfo?.user?.role?.name === "Admin"
                                            ? "pointer"
                                            : "not-allowed"
                                        }`}
                                      />
                                    </button>
                                  </Tooltip>
                                  <Tooltip title="Delete" arrow placement="top">
                                    <button
                                      onClick={() => handleDelete(data.id)}
                                      disabled={deletingStates[data.id]}
                                      className="outline-none"
                                    >
                                      {deletingStates[data.id] ? (
                                        <img
                                          src={loader}
                                          alt="loading"
                                          className="w-6 h-6"
                                        />
                                      ) : (
                                        <MdOutlineDeleteOutline className="w-6 h-6 cursor-pointer" />
                                      )}
                                    </button>
                                  </Tooltip>
                                </div> : ''
                              ) : (
                                ""
                              )}
                            </td>
                          </tr>
                        ))
                      : userList?.map((data, i) => (
                          <tr
                            key={data?.id}
                            className={`${
                              theme === "dark"
                                ? "hover:bg-gradient-to-r hover:from-[#fff3] hover:to-[#fff0] transition-all"
                                : "hover:bg-gradient-to-r hover:from-[#6d6d6d33] hover:to-[#fff0] transition-all"
                            }`}
                          >
                            <td className="py-1">
                              {data.picture ? (
                                <img
                                  src={data.picture}
                                  className="w-8 h-8 object-cover rounded-full"
                                  alt="profile picture"
                                  loading="lazy"
                                />
                              ) : (
                                <FaUserCircle className="w-8 h-8" />
                              )}
                            </td>
                            <td>
                              {data?.first_name} {data?.last_name}
                            </td>
                            <td className="no-scrollbar max-w-[200px] overflow-auto">
                              {data?.email}
                            </td>
                            <td>{formatDate(data?.created_at)}</td>
                            <td>
                              {typeof data?.role === "object"
                                ? data?.role?.name
                                : data?.role}
                            </td>
                            <td>
                              {!data?.is_primary_admin &&
                              <span className="flex gap-2 items-center">
                                {data?.payment_amount} {data?.currency} /{" "}
                                {data?.payment_type === "hourly"
                                  ? "Hour"
                                  : data?.payment_type === "monthly"
                                  ? "Month"
                                  : " Week"}
                              </span> }
                            </td>
                            <td>
                              {" "}
                              {userInfo?.user?.role?.name === "Admin" ? (
                                !data?.is_primary_admin ? 
                                <div className="flex items-center gap-2">
                                  <Tooltip title="Edit" arrow placement="top">
                                    <button
                                      onClick={() =>
                                        updateFormDataFromUser(data)
                                      }
                                    >
                                      <CiEdit
                                        className={`w-5 h-5 cursor-${
                                          userInfo?.user?.role?.name === "Admin"
                                            ? "pointer"
                                            : "not-allowed"
                                        }`}
                                      />
                                    </button>
                                  </Tooltip>
                                  <Tooltip title="Delete" arrow placement="top">
                                    <button
                                      onClick={() => handleDelete(data.id)}
                                      disabled={deletingStates[data.id]}
                                      className="outline-none"
                                    >
                                      {deletingStates[data.id] ? (
                                        <img
                                          src={loader}
                                          alt="loading"
                                          className="w-6 h-6"
                                        />
                                      ) : (
                                        <MdOutlineDeleteOutline className="w-6 h-6 cursor-pointer" />
                                      )}
                                    </button>
                                  </Tooltip>
                                </div> : ''
                              ) : (
                                ""
                              )}
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="w-full mt-4 justify-between flex  items-center">
              <div>
                {prev && (
                  <button
                    className="heroButton"
                    onClick={() => handlePageChange(prev)}
                    disabled={isLoading}
                  >
                    Previous
                  </button>
                )}
              </div>
              <div>
                {next && (
                  <button
                    className="heroButton"
                    onClick={() => handlePageChange(next)}
                    disabled={isLoading}
                  >
                    Next
                  </button>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default User;
