import { useState, useEffect, useRef } from "react";
import { FaClock, FaMouse, FaKeyboard, FaRegCalendarAlt } from "react-icons/fa";
import { FiFilter, FiRefreshCw } from "react-icons/fi";
import { useSelector, useDispatch } from "react-redux";
import { FaRegCircleUser } from "react-icons/fa6";
import WeeklyActiveTime from "./charts/WeeklyActiveTime";
import WeeklyActiveTimeChart from "./charts/WeeklyActiveTimeChart";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "./admin.css";
import WeeklyWorkedHours from "./charts/WeeklyWorkedHours";
import WeeklyProductivity from "./charts/WeeklyProductivity";
import { format, startOfMonth, endOfMonth, addDays, parseISO } from "date-fns";
import { IoIosArrowDown } from "react-icons/io";
import { fetchApi } from "../../../utlis/axios";
import loader from "../../../assets/loading-gif.gif";
import { FaUserCircle } from "react-icons/fa";
import { updateNextPage, updateSelectedUser, updateUserId } from "../../../Redux/Slices/users-slice";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Calendar from "./charts/Calender";

const UserProgressDashboard = ({ onLoadingChange }) => {
  const { theme } = useSelector((state) => state.theme);
  const { userInfo } = useSelector((state) => state?.auth || {});
   const { userId, users, nextPage, selectedUser} = useSelector(
      (state) => state?.allUsers || {}
    );
  const [usersList, setuserList] = useState([]);
  const [next, setNext] = useState(false);
  const [reportType, setReportType] = useState("daily"); // daily, weekly, monthly
  const [startDate, setStartDate] = useState(new Date(new Date().setDate(new Date().getDate() - 7)));
  const [endDate, setEndDate] = useState(new Date());
  const [openReportType, setOpenReportType] = useState(false);
  const [progressData, setProgressData] = useState(null);
  const [startDateDropdown, setStartDateDropdown]=useState(false)
  const [endDateDropdwon, setEndDateDropdown]=useState(false)
  const [dataFetched, setDataFetched]=useState(false)
  const userDropdownRef=useRef(null)
  const reportTypeDropdownRef=useRef(null)
  const startDateDropdownRef=useRef(null)
 

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Set initial month and week
  const getCurrentMonthAndWeek = () => {
    const today = new Date();
    const currentMonth = months[today.getMonth()];

    // Get current week
    let startDate = new Date(today);
    while (startDate.getDay() !== 0) {
      startDate = addDays(startDate, -1);
    }
    const endDate = addDays(startDate, 6);

    return {
      month: currentMonth,
      week: {
        display: `${format(startDate, "MMM d")} - ${format(endDate, "MMM d")}`,
        value: `${format(startDate, "yyyy-MM-dd")},${format(
          endDate,
          "yyyy-MM-dd"
        )}`,
      },
    };
  };

  const { month: initialMonth, week: initialWeek } = getCurrentMonthAndWeek();

  // Add these new state variables
  const [weeklyActiveTimeData, setWeeklyActiveTimeData] = useState([]);
  const [weeklyProductivityData, setWeeklyProductivityData] = useState([]);
  const [weeklyTotalTrackedTime, setWeeklyTotalTrackedTime] = useState([]);

  const dispatch = useDispatch();
  const [openUsers, setOpenUsers] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Add these state variables
  const [searchQuery, setSearchQuery] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [originalUsers, setOriginalUsers] = useState([]);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);

  const getStats = () => {
    if (!progressData) {
      return [
        { icon: <FaClock />, value: "0h", label: "Total Tracked Time", id: 1 },
        { icon: <FaClock />, value: "0h", label: "Active Time", id: 2 },
        { icon: <FaClock />, value: "0%", label: "Active Time Percentage", id: 3 },
        { icon: <FaMouse />, value: "0%", label: "Mouse Productivity", id: 4 },
        { icon: <FaKeyboard />, value: "0%", label: "Keyboard Productivity", id: 5 },
      ];
    }

    // Calculate averages across all dates/weeks/months
    let totalTrackedTime = 0;
    let activeTime = 0;
    let activeTimePercentage = 0;
    let mouseProductivity = 0;
    let keyboardProductivity = 0;
    let count = 0;

    // Get the user ID
    const userId = Object.keys(progressData)[0];
    if (!userId) return [];

    // Get the data for this user
    const userData = progressData[userId];
    
    // Process all time periods
    Object.keys(userData).forEach(key => {
      const periodData = userData[key];
      totalTrackedTime += parseInt(periodData.total_tracked_time || 0);
      activeTime += parseInt(periodData.active_time || 0);
      activeTimePercentage += parseFloat(periodData.active_time_percentage || 0);
      mouseProductivity += parseFloat(periodData.mouse_productivity_percentage || 0);
      keyboardProductivity += parseFloat(periodData.keyboard_productivity_percentage || 0);
      count++;
    });

    // Convert minutes to hours for display
    const formatTime = (minutes) => {
      return `${Math.floor(minutes / 60)}h`;
    };

    // Calculate averages
    const avgActiveTimePercentage = count > 0 ? Math.round(activeTimePercentage / count) : 0;
    const avgMouseProductivity = count > 0 ? Math.round(mouseProductivity / count) : 0;
    const avgKeyboardProductivity = count > 0 ? Math.round(keyboardProductivity / count) : 0;

    return [
      { icon: <FaClock />, value: formatTime(totalTrackedTime), label: "Total Tracked Time", id: 1 },
      { icon: <FaClock />, value: formatTime(activeTime), label: "Active Time", id: 2 },
      { icon: <FaClock />, value: `${avgActiveTimePercentage}%`, label: "Active Time Percentage", id: 3 },
      { icon: <FaMouse />, value: `${avgMouseProductivity}%`, label: "Mouse Productivity", id: 4 },
      { icon: <FaKeyboard />, value: `${avgKeyboardProductivity}%`, label: "Keyboard Productivity", id: 5 },
    ];
  };

  const fetchUserProgressData = async () => {

    try {
      if (!userId || !startDate || !endDate) return;

      setIsRefreshing(true);
      
      // Format dates for API
      const formattedStartDate = format(startDate, "yyyy-MM-dd");
      const formattedEndDate = format(endDate, "yyyy-MM-dd");

      const res = await fetchApi.post(
        `admin-dashboard-user-progress/`,
        {
          users: [userId],
          domain: userInfo.user.domain,
          report_type: reportType,
          date_range: [formattedStartDate, formattedEndDate],
        },
        {
          headers: {
            Authorization: `Bearer ${userInfo.access_token}`,
          },
        }
      );

      if (res.status === 200) {
        // Set the progress data first
        setProgressData(res.data);
        
        // Process and update chart data immediately with the new data
        const processedData = processChartData(res.data);
        
        // Update state with new data
        setWeeklyActiveTimeData(processedData.weeklyActiveTimeData);
        setWeeklyProductivityData(processedData.weeklyProductivityData);
        setWeeklyTotalTrackedTime(processedData.weeklyTotalTrackedTime);
      }
    } catch (error) {
      console.error("Error fetching user progress data:", error);
    } finally {
      setIsRefreshing(false);
    }
  };

  const getUserProgressData = async () => {
    try {
      onLoadingChange(true);
      if (!userId || !startDate || !endDate) return;

      setIsRefreshing(true);
      
      // Format dates for API
      const formattedStartDate = format(startDate, "yyyy-MM-dd");
      const formattedEndDate = format(endDate, "yyyy-MM-dd");

      const res = await fetchApi.post(
        `admin-dashboard-user-progress/`,
        {
          users: [userId],
          domain: userInfo.user.domain,
          report_type: reportType,
          date_range: [formattedStartDate, formattedEndDate],
        },
        {
          headers: {
            Authorization: `Bearer ${userInfo.access_token}`,
          },
        }
      );

      if (res.status === 200) {
        // Set the progress data first
        setProgressData(res.data);
        
        // Process and update chart data immediately with the new data
        const processedData = processChartData(res.data);
        
        // Update state with new data
        setWeeklyActiveTimeData(processedData.weeklyActiveTimeData);
        setWeeklyProductivityData(processedData.weeklyProductivityData);
        setWeeklyTotalTrackedTime(processedData.weeklyTotalTrackedTime);
      }
    } catch (error) {
      console.error("Error fetching user progress data:", error);
    } finally {
      setIsRefreshing(false);
      onLoadingChange(false);
      setDataFetched(true)
    }
  };

  // Modify processChartData to accept data parameter
  const processChartData = (data = progressData) => {
    if (!data) return {
      weeklyActiveTimeData: [],
      weeklyProductivityData: [],
      weeklyTotalTrackedTime: []
    };

    const userId = Object.keys(data)[0];
    if (!userId) return {
      weeklyActiveTimeData: [],
      weeklyProductivityData: [],
      weeklyTotalTrackedTime: []
    };

    const userData = data[userId];
    
    // Process data for charts based on report type
    const activeTimeData = [];
    const productivityData = [];
    const trackedTimeData = [];

    Object.entries(userData).forEach(([key, data]) => {
      // Add the entry to the data arrays
      activeTimeData.push({
        date: key,
        activeTime: `${Math.floor(parseInt(data.active_time || 0) / 60)}:${(parseInt(data.active_time || 0) % 60).toString().padStart(2, "0")}`,
        percentage: parseFloat(data.active_time_percentage || 0)
      });

      productivityData.push({
        date: key,
        keyboard: parseFloat(data.keyboard_productivity_percentage || 0),
        mouse: parseFloat(data.mouse_productivity_percentage || 0)
      });

      trackedTimeData.push({
        date: key,
        totalTime: `${Math.floor(parseInt(data.total_tracked_time || 0) / 60)}:${(parseInt(data.total_tracked_time || 0) % 60).toString().padStart(2, "0")}`
      });
    });

    return {
      weeklyActiveTimeData: activeTimeData,
      weeklyProductivityData: productivityData,
      weeklyTotalTrackedTime: trackedTimeData
    };
  };

  useEffect(() => {
    if (userId) {
      getUserProgressData()
    } else {
      onLoadingChange(false); // If no userId, mark as loaded
    }
  }, [userId]);
  useEffect(()=>{
    if (dataFetched){
   fetchUserProgressData()
    }
  },[reportType, startDate, endDate])

  useEffect(() => {
    if (users?.length > 0 && originalUsers.length === 0) {
      setOriginalUsers(users);
    }
    setuserList(users);
  }, [users]);
  useEffect(() => {
    setNext(nextPage);
  }, [nextPage]);


  // Function to handle scroll in users dropdown
  const handleUsersScroll = async (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;

    // Check if we've scrolled to the bottom and there's a next page
    if (scrollHeight - scrollTop === clientHeight && next && !isLoading) {
      setIsLoading(true);
      await fetchNextUsers(next)
      setIsLoading(false);
    }
  };

  const fetchNextUsers = async (url) => {
    try {
      const res = await fetchApi.get(url, {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });
      if (res.status === 200) {
        setuserList((prev) => [...prev, ...res.data.results]);
        setNext(res.data.next);
      }
    } catch (error) {}
  };

  // Add the search function
  const handleSearch = async (e) => {
    if (e.key === 'Enter') {
      setIsSearching(true);
      try {
        const res = await fetchApi.get(`users/?search=${searchQuery}`, {
          headers: {
            Authorization: `Bearer ${userInfo.access_token}`,
          },
        });
        if (res.status === 200) {
          setuserList(res.data.results);
          setNext(res.data.next);
        }
      } catch (error) {
        console.error('Search error:', error);
      }
      setIsSearching(false);
    }
  };

  // Add the search input change handler
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchQuery(value);
    
    // If search input is empty, restore original list
    if (!value.trim()) {
      setuserList(users);
      setNext(nextPage);
    }
  };

  const refreshData = async () => {
    setIsRefreshing(true);
    await fetchUserProgressData();
    setIsRefreshing(false);
  };


  const handleClickOutside = (event) => {
    if (userDropdownRef.current && !userDropdownRef.current.contains(event.target)) {
      setOpenUsers(false); // Close dropdown
    }
  };

  useEffect(() => {
    if (openUsers) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [openUsers]);

  const handleClickOutside1 = (event) => {
    if (reportTypeDropdownRef.current && !reportTypeDropdownRef.current.contains(event.target)) {
      setOpenReportType(false); // Close dropdown
    }
  };

  useEffect(() => {
    if (openReportType) {
      document.addEventListener("mousedown", handleClickOutside1);
    } else {
      document.removeEventListener("mousedown", handleClickOutside1);
    }
    return () => document.removeEventListener("mousedown", handleClickOutside1);
  }, [openReportType]);

  const handleClickOutside2 = (event) => {
    if (startDateDropdownRef.current && !startDateDropdownRef.current.contains(event.target)) {
      setStartDateDropdown(false); // Close dropdown
    }
  };

  useEffect(() => {
    if (startDateDropdown) {
      document.addEventListener("mousedown", handleClickOutside2);
    } else {
      document.removeEventListener("mousedown", handleClickOutside2);
    }
    return () => document.removeEventListener("mousedown", handleClickOutside2);
  }, [startDateDropdown]);

  return (
    <div
      className={`p-6 rounded-xl customFont ${
        theme === "dark" ? "bg-[#1C1C1E] text-white" : "bg-white text-black"
      }`}
    >
      {/* Header */}
      <div className="flex justify-between md:items-center items-start mb-4">
        <div className="flex gap-2 items-center">
          <span className="bg-gradient-to-r from-[#CC0927] to-[#7E0105] p-2 rounded-[10px]">
            <FaRegCircleUser className="w-6 h-6 text-white" />
          </span>
          <h1 className="text-lg font-semibold">User Progress</h1>
        </div>
        <div className="flex gap-2 md:items-center items-start">
          {/* Users Dropdown */}
          <div className="flex gap-2 md:flex-row flex-col flex-wrap">
            <div className="relative" ref={userDropdownRef}>
              <div
                className={`border rounded-[10px] gap-4 flex items-center justify-between p-2 min-w-[200px] cursor-pointer ${
                  theme === "dark"
                    ? "bg-[#212529] border-[#555657]"
                    : "bg-[#F2F4F7] border-[#9A9F9A]"
                }`}
                onClick={() => setOpenUsers(!openUsers)}
              >
                <span>
                  {selectedUser
                    ? selectedUser?.first_name + " " + selectedUser?.last_name
                    : "Select User"}
                </span>
                <IoIosArrowDown className="w-4 h-4" />
              </div>
              {openUsers && (
                <div
                  className={`z-10 absolute mt-1 w-full border overflow-auto shadow-lg rounded-[10px] max-h-[180px] ${
                    theme === "dark"
                      ? "bg-[#212529] border-[#555657]"
                      : "bg-[#F2F4F7] border-[#9A9F9A]"
                  }`}
                  onScroll={handleUsersScroll}
                  style={{
                    paddingTop: "4px",
                    paddingBottom: "4px",
                  }}>
                  <div className="relative">
                    <input 
                      type="text"  
                      className={`p-2 pl-8 bg-transparent border-b outline-none w-full ${
                        theme === "dark" ? "border-[#555657]" : "border-[#D8D8D8]"
                      }`}
                      value={searchQuery}
                      onChange={handleSearchChange}
                      onKeyDown={handleSearch}
                      placeholder="Search users..."
                    />
                    {isSearching && (
                      <div className="absolute left-2 top-1/2 -translate-y-1/2">
                        <img src={loader} alt="Searching..." className="w-4 h-4" />
                      </div>
                    )}
                  </div>
                  {usersList?.map((user) => (
                    <div
                      key={user.id}
                      className={`p-2 cursor-pointer hover:bg-opacity-10 hover:bg-gray-500 flex items-center gap-2 ${
                        selectedUser === user.id ? "bg-opacity-10 bg-gray-500" : ""
                      } ${
                        theme === "dark"
                          ? "hover:bg-gradient-to-r hover:from-[#fff3] hover:to-[#fff0] transition-all"
                          : "hover:bg-gradient-to-r hover:from-[#6d6d6d33] hover:to-[#fff0] transition-all"
                      }`}
                      onClick={() => {
                        dispatch(updateSelectedUser(user))
                        dispatch(updateUserId(user.id))
                        setOpenUsers(false);
                      }}
                    >
                      {user.picture ? (
                        <img 
                          src={user.picture} 
                          alt={`${user.first_name}`}
                          className="w-6 h-6 rounded-full object-cover"
                        />
                      ) : (
                        <FaUserCircle className="w-6 h-6 text-gray-400" />
                      )}
                      <span>
                        {user.first_name} {user.last_name}
                      </span>
                    </div>
                  ))}
                  {isLoading && (
                    <div className="flex justify-center p-2">
                      <img src={loader} alt="Loading..." className="w-6 h-6" />
                    </div>
                  )}
                </div>
              )}
            </div>

            {/* Report Type Dropdown */}
            <div className="relative" ref={reportTypeDropdownRef}>
              <div
                className={`border rounded-[10px] gap-4 flex items-center justify-between p-2 min-w-[170px] ${
                  theme === "dark"
                    ? "bg-[#212529] border-[#555657]"
                    : "bg-[#F2F4F7] border-[#9A9F9A]"
                }`}
                onClick={() => setOpenReportType(!openReportType)}
              >
                <span>{reportType.charAt(0).toUpperCase() + reportType.slice(1)}</span>
                <IoIosArrowDown className="w-4 h-4" />
              </div>
              {openReportType && (
                <div
                  className={`z-10 absolute mt-1 w-40 border overflow-auto shadow-lg rounded-[10px] ${
                    theme === "dark"
                      ? "bg-[#212529] border-[#555657]"
                      : "bg-[#F2F4F7] border-[#9A9F9A]"
                  }`}
                  style={{
                    paddingTop: "4px",
                    paddingBottom: "4px",
                  }}
                >
                  {["daily", "weekly", "monthly"].map((type) => (
                    <div
                      key={type}
                      className={`p-2 cursor-pointer ${
                        theme === "dark"
                          ? "hover:bg-gradient-to-r hover:from-[#fff3] hover:to-[#fff0] transition-all"
                          : "hover:bg-gradient-to-r hover:from-[#6d6d6d33] hover:to-[#fff0] transition-all"
                      }`}
                      onClick={() => {
                        setReportType(type);
                        setOpenReportType(false);
                      }}
                    >
                      {type.charAt(0).toUpperCase() + type.slice(1)}
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className="relative" ref={startDateDropdownRef}>
              <div
                className={`border cursor-pointer rounded-[10px] gap-4 flex items-center justify-between p-2 min-w-[170px] ${
                  theme === "dark"
                    ? "bg-[#212529] border-[#555657]"
                    : "bg-[#F2F4F7] border-[#9A9F9A]"
                }`}
                onClick={() =>{
                   setStartDateDropdown(!startDateDropdown)
                 setEndDateDropdown(false) 
                }}
              >
                <span>{startDate ? `From ${format(startDate, "yyyy-MM-dd")} To ${format(endDate, "yyyy-MM-dd")}` : 'From'}</span>
                <IoIosArrowDown className="w-4 h-4" />
              </div>
              {startDateDropdown && (
                <div
                  className={`z-10 absolute mt-1 w-[270px] right-0 `}
                  style={{
                    paddingTop: "4px",
                    paddingBottom: "4px",
                  }}
                >
                 <Calendar setStartDate={setStartDate} setEndDate={setEndDate} startDate={startDate} endDate={endDate}/>
                </div>
              )}
            </div>

            
          </div>
          <div
            className={`p-2 flex items-center justify-center rounded-[10px] border cursor-pointer ${
              theme === "dark"
                ? "bg-[#252529] border-[#555657]"
                : "bg-[#F2F4FA] border-[#D8D8D8]"
            }`}
            onClick={refreshData}
          >
            {isRefreshing ? (
              <img src={loader} alt="Loading..." className="w-5 h-5" />
            ) : (
              <FiRefreshCw className={`w-5 h-5`} />
            )}
          </div>
        </div>
      </div>

      {/* Stats Cards */}
      <Swiper
        spaceBetween={10}
        slidesPerView={1}
        breakpoints={{
          640: { slidesPerView: 1.2 },
          768: { slidesPerView: 2.2 },
          1024: { slidesPerView: 3.2 },
          1280: { slidesPerView: 4.2 },
          1380: { slidesPerView: 5 },
        }}
        pagination={{ clickable: false }}
        navigation={false}
      >
        {getStats().map((card) => (
          <SwiperSlide key={card.id}>
            <StatBox
              icon={card.icon}
              value={card.value}
              label={card.label}
              theme={theme}
              id={card.id}
            />
          </SwiperSlide>
        ))}
      </Swiper>

      {/* Graphs */}
      <div
        className="grid xxl:grid-cols-4
       md:grid-cols-2 grid-cols-1  gap-4"
      >
        <div
          className={`py-4 border rounded-[20px] flex-col flex items-center gap-4 ${
            theme === "dark"
              ? "bg-[#212529] border-[#555657]"
              : "bg-[#F2F4F7] border-[#D8D8D8]"
          }`}
        >
          <WeeklyWorkedHours key={`worked-hours-${reportType}`} data={weeklyTotalTrackedTime} title={`${reportType} Hours Worked`} />
        </div>
        <div
          className={`py-4 border rounded-[20px] flex-col flex items-center gap-4 ${
            theme === "dark"
              ? "bg-[#212529] border-[#555657]"
              : "bg-[#F2F4F7] border-[#D8D8D8]"
          }`}
        >
          <WeeklyActiveTime key={`active-time-${reportType}`} data={weeklyActiveTimeData} title={`${reportType} Active Time`} />
        </div>
        <WeeklyActiveTimeChart key={`active-time-chart-${reportType}`} data={weeklyActiveTimeData} title={`${reportType} Active Time`} />
        <div className="w-full min-h-[400px]">
          <WeeklyProductivity key={`productivity-${reportType}`} data={weeklyProductivityData} title={`${reportType} Productivity`} />
        </div>
      </div>
    </div>
  );
};

const StatBox = ({ icon, value, label, theme, id }) => (
  <div
    className={`px-6 py-6 border rounded-[20px] flex-1 flex items-center gap-4  ${
      theme === "dark"
        ? "bg-[#212529] border-[#555657]"
        : "bg-[#F2F4F7] border-[#D8D8D8]"
    }`}
  >
    <div
      className={`text-2xl p-2 rounded-full ${
        theme === "dark" ? "text-black" : "text-white"
      } ${
        id === 1
          ? "bg-red-400"
          : id === 2
          ? "bg-green-400"
          : id === 3
          ? "bg-purple-400"
          : id === 4
          ? "bg-yellow-400"
          : "bg-blue-400"
      }`}
    >
      {icon}
    </div>
    <div>
      <p
        className={`text-2xl font-semibold} ${
          id === 1
            ? "text-red-400"
            : id === 2
            ? "text-green-400"
            : id === 3
            ? "text-purple-400"
            : id === 4
            ? "text-yellow-400"
            : "text-blue-400"
        }`}
      >
        {value}
      </p>
      <p className="text-sm text-gray-400 whitespace-nowrap">{label}</p>
    </div>
  </div>
);

export default UserProgressDashboard;
