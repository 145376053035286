import React, { useEffect, useRef, useState } from "react";
import {
  FaArrowLeft,
  FaArrowRight,
  FaMouse,
  FaRegLightbulb,
  FaKeyboard,
} from "react-icons/fa";
import { FiActivity, FiChevronDown } from "react-icons/fi";
import { IoIosArrowDown, IoMdArrowDropdown } from "react-icons/io";
import styles from "./activity.module.css";
import FilterModal from "../filterModal/FilterModal";
import PreviewModal from "../previewModal/PreviewModal";
import { useDispatch, useSelector } from "react-redux";
import { fetchApi } from "../../../utlis/axios";
import Loader from "../../Loader/Loader";
import { toggleAuth } from "../../../Redux/Slices/auth-slice";
import { togglesummaryData } from "../../../Redux/Slices/summaryData-slice";
import { updateUserId } from "../../../Redux/Slices/users-slice";
import OfftimeCreate from "../offtime/OfftimeCreate";
import { addToast } from "../../../Redux/Slices/toast-slice";
import { FaUserCircle } from "react-icons/fa";
import loader from '../../../assets/loading-gif.gif'
import { updateSelectedUser } from "../../../Redux/Slices/users-slice";
import CreateOvertime from '../overtime/CreateOvertime'

const Activity = () => {
  const { theme } = useSelector((state) => state.theme);
  const { summaryDate } = useSelector((state) => state.summaryData);
  const { summaryUserId } = useSelector((state) => state.summaryData);
  const { userInfo } = useSelector((state) => state?.auth || {});
  const { userId, users, nextPage, selectedUser} = useSelector(
    (state) => state?.allUsers || {}
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [days, setDays] = useState([]);
  const [showOfflineActivity, setShowOfflineActivity] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const [userSummary, setUserSummary] = useState();
  const [imageUrl, setImageUrl] = useState();
  const [dataByDate, setDataByDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [isOpen, setIsOpen] = useState(false);
  const [idFromSummary, setIdFromSummary] = useState();
  const dispatch = useDispatch();
  const [openDropdownId, setOpenDropdownId] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const dropdownRef = useRef(null);
  const [randomScreenShots, setRandomScreenShots] = useState(false);
  const [imageActivityRows, setImageActivityRows] = useState([]);
  const [dataToRender, setDataToRender] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [slotData, setSlotData] = useState([]);
  const [moniter, setMoniter] = useState("1");
  const [moniterLabel, setMoniterLabel] = useState("Default");
  const [next, setNext] = useState(nextPage);
  const [userList, setuserList] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const userDropdownRef=useRef(null)
  const moniterDropdownRef=useRef(null)

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setOpenDropdownId(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleDropdownToggle = (dropdownId) => {
    setOpenDropdownId((prevId) => (prevId === dropdownId ? null : dropdownId));
  };

  let isFetched = false;
  const getSummaryData = async () => {
    if (isFetched) return;
    isFetched = true;
    if (summaryDate !== null) {
      setDataByDate(summaryDate);
      setIdFromSummary(summaryUserId);
    }
    if (selectedUser) {
      setIsloading(true);
      const dataById = selectedUser.id;
      try {
        const response = await fetchApi.get(
          `user-summaries/?user=${dataById}&date=${dataByDate}`,
          {
            headers: {
              Authorization: `Bearer ${userInfo.access_token}`,
            },
          }
        );
        if (response.status === 200) {
          const imageActivity = response?.data?.results[0]?.image_activity[0];
          setIsloading(false);
          setUserSummary(response.data.results[0]);
          dispatch(
            togglesummaryData({ summaryDate: null, summaryUserId: null })
          );
          checkMoniter(imageActivity);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          dispatch(toggleAuth({ isLogin: false, userInfo: null }));
          dispatch(addToast({ message : 'Your session expired', type :'error' })); 
          return;
        }
        setIsloading(false);
        console.log("Error while call user-summaries API: ", error);
        dispatch(addToast({ message:'Something went wrong', type :'error' })); 
      }
    }
  };
  function checkMoniter(imageActivity) {
    if (imageActivity?.image) {
      setMoniter("1");
      setMoniterLabel("Default");
    } else if (imageActivity?.image_one) {
      setMoniter("2");
      setMoniterLabel("Moniter 1");
    } else if (imageActivity?.image_two) {
      setMoniter("3");
      setMoniterLabel("Moniter 2");
    } else if (imageActivity?.image_three) {
      setMoniter("4");
      setMoniterLabel("Moniter 3");
    } else if (imageActivity?.image_four) {
      setMoniter("5");
      setMoniterLabel("Moniter 4");
    } else {
      setMoniter("1");
    }
  }

  useEffect(() => {
    getSummaryData();
  }, [selectedUser, dataByDate]);

  const joiningDate = new Date(userInfo?.user?.created_at);
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  useEffect(() => {
    generateCalendar(currentDate);
  }, [currentDate]);

  const generateCalendar = (date) => {
    const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    const lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    const lastDayOfPrevMonth = new Date(
      date.getFullYear(),
      date.getMonth(),
      0
    ).getDate();

    const daysInMonth = [];

    // Add previous month's days in correct order
    const startDay = firstDayOfMonth.getDay(); // Day of the week (0 for Sunday, 1 for Monday, etc.)
    for (let i = startDay - 1; i >= 0; i--) {
      daysInMonth.push({
        day: lastDayOfPrevMonth - i,
        currentMonth: false,
      });
    }

    // Add current month's days
    for (let i = 1; i <= lastDayOfMonth.getDate(); i++) {
      daysInMonth.push({
        day: i,
        currentMonth: true,
      });
    }

    // Add next month's days
    const remainingDays = 35 - daysInMonth.length; // Adjust to ensure a 5-week grid
    for (let i = 1; i <= remainingDays; i++) {
      daysInMonth.push({
        day: i,
        currentMonth: false,
      });
    }

    setDays(daysInMonth);
  };

  const goToPrevMonth = () => {
    const prevMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() - 1,
      1
    );
    if (
      prevMonth.getFullYear() > joiningDate.getFullYear() ||
      (prevMonth.getFullYear() === joiningDate.getFullYear() &&
        prevMonth.getMonth() >= joiningDate.getMonth())
    ) {
      setCurrentDate(prevMonth);
    }
  };

  const goToNextMonth = () => {
    const nextMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      1
    );
    const today = new Date();
    if (nextMonth <= today) {
      setCurrentDate(nextMonth);
    }
  };
  let getRowsWithAverages;
  if (randomScreenShots) {
    getRowsWithAverages = (activities = []) => {
      const defaultRowLength = 3;

      if (!Array.isArray(activities)) {
        activities = [];
      }

      const convertTo24HourFormat = (hour, period) => {
        let hours = parseInt(hour, 10);
        if (period?.toLowerCase() === "pm" && hours !== 12) hours += 12;
        if (period?.toLowerCase() === "am" && hours === 12) hours = 0;
        return hours;
      };

      const parseHourSlot = (hourSlot) => {
        if (hourSlot.includes(":")) {
          // Format: "11:10am - 11:20am"
          const [startTime, endTime] = hourSlot.split(" - ");
          const [startHourMin, startPeriod] = startTime
            .trim()
            .split(/(?=[APap][Mm])/);
          const [endHourMin, endPeriod] = endTime
            .trim()
            .split(/(?=[APap][Mm])/);

          const start = convertTo24HourFormat(
            startHourMin.split(":")[0],
            startPeriod
          );
          const end = convertTo24HourFormat(
            endHourMin.split(":")[0],
            endPeriod
          );

          return { start, end, rawSlot: hourSlot };
        } else {
          // Format: "11am - 12pm"
          const [startHour, startPeriod, , endHour, endPeriod] =
            hourSlot.split(" ");
          const start = convertTo24HourFormat(startHour, startPeriod);
          const end = convertTo24HourFormat(endHour, endPeriod);

          return { start, end, rawSlot: hourSlot };
        }
      };

      const groupedByHourSlot = activities.reduce((acc, activity) => {
        const { hour_slot, kpp, mpp, minute_to_add } = activity;

        const { rawSlot } = parseHourSlot(hour_slot);

        const minutesToAdd = isNaN(minute_to_add)
          ? 0
          : parseInt(minute_to_add, 10);

        if (!acc[rawSlot]) {
          acc[rawSlot] = {
            hour_slot: rawSlot,
            kppSum: isNaN(parseFloat(kpp)) ? 0 : parseFloat(kpp),
            mppSum: isNaN(parseFloat(mpp)) ? 0 : parseFloat(mpp),
            totalMinutes: minutesToAdd,
            count: 1,
          };
        } else {
          acc[rawSlot].kppSum += isNaN(parseFloat(kpp)) ? 0 : parseFloat(kpp);
          acc[rawSlot].mppSum += isNaN(parseFloat(mpp)) ? 0 : parseFloat(mpp);
          acc[rawSlot].totalMinutes += minutesToAdd;
          acc[rawSlot].count += 1;
        }

        return acc;
      }, {});

      let result = Object.values(groupedByHourSlot).map((group, index) => ({
        hour_slot: group.hour_slot,
        avgKpp: group.count > 0 ? Math.round(group.kppSum / group.count) : 0,
        avgMpp: group.count > 0 ? Math.round(group.mppSum / group.count) : 0,
        totalMinutes: group.totalMinutes,
        rowNumber: index + 1,
      }));

      const sampleData = [
        {
          hour_slot: "00",
          avgKpp: 0,
          avgMpp: 0,
          totalMinutes: 0,
          rowNumber: result.length + 1,
        },
        {
          hour_slot: "00",
          avgKpp: 0,
          avgMpp: 0,
          totalMinutes: 0,
          rowNumber: result.length + 2,
        },
        {
          hour_slot: "00",
          avgKpp: 0,
          avgMpp: 0,
          totalMinutes: 0,
          rowNumber: result.length + 3,
        },
      ];

      if (result.length < defaultRowLength) {
        const neededRows = defaultRowLength - result.length;
        result = [...result, ...sampleData.slice(0, neededRows)];
      }
      result.sort((a, b) => {
        const get24HourTimes = (hourSlot) => {
          const [startTime] = hourSlot.split(" - "); // Get only the start time
          const [hour, period] = startTime.trim().split(/(?=[APap][Mm])/);
          let hours = parseInt(hour, 10);
          if (period?.toLowerCase() === "pm" && hours !== 12) hours += 12;
          if (period?.toLowerCase() === "am" && hours === 12) hours = 0;
          return hours;
        };

        return get24HourTimes(b.hour_slot) - get24HourTimes(a.hour_slot);
      });

      return result;
    };
  } else {
    getRowsWithAverages = (imageActivity = []) => {
      const defaultRowLength = 3;

      if (!Array.isArray(imageActivity)) {
        imageActivity = [];
      }

      const convertTo24HourFormat = (time, period) => {
        if (randomScreenShots) {
          //let hours = parseInt(time, 10);
          let [hours, minutes] = time.split(":").map(Number);
          if (period?.toLowerCase() === "pm" && hours !== 12) hours += 12;
          if (period?.toLowerCase() === "am" && hours === 12) hours = 0;
          return { hours, minutes: minutes || 0 };
        } else {
          let [hours, minutes] = time.split(":").map(Number);
          if (period?.toLowerCase() === "pm" && hours !== 12) hours += 12;
          if (period?.toLowerCase() === "am" && hours === 12) hours = 0;
          return { hours, minutes: minutes || 0 };
        }
      };

      const groupedByHourSlot = imageActivity.reduce((acc, activity) => {
        const { hour_slot, kpp, mpp, minute_to_add } = activity;

        const [startTime, endTime] = hour_slot.split(" - ");
        const [startTimeStr, startPeriod] = startTime.trim().split(" ");
        const start = convertTo24HourFormat(
          startTimeStr,
          startPeriod
        );

        // Format start hour
        const startHourFormatted =
          start.hours % 12 === 0 ? 12 : start.hours % 12;

        // Add one hour to calculate the end time in 24-hour format
        let endHour24 = (start.hours + 1) % 24;

        // Format end hour
        const endHourFormatted = endHour24 % 12 === 0 ? 12 : endHour24 % 12;

        // Correct AM/PM logic
        const amPmStart = start.hours < 12 ? "AM" : "PM";
        const amPmEnd = endHour24 < 12 ? "AM" : "PM";

        const hourString = `${startHourFormatted}:00 ${amPmStart} - ${endHourFormatted}:00 ${amPmEnd}`;
        // Ensure minute_to_add is treated as an integer
        const minutesToAdd = isNaN(minute_to_add)
          ? 0
          : parseInt(minute_to_add, 10);
        // Group by hour slot
        if (!acc[hourString]) {
          acc[hourString] = {
            hour_slot: hourString,
            kppSum: isNaN(parseFloat(kpp)) ? 0 : parseFloat(kpp),
            mppSum: isNaN(parseFloat(mpp)) ? 0 : parseFloat(mpp),
            totalMinutes: minutesToAdd,
            count: 1,
          };
        } else {
          acc[hourString].kppSum += isNaN(parseFloat(kpp))
            ? 0
            : parseFloat(kpp);
          acc[hourString].mppSum += isNaN(parseFloat(mpp))
            ? 0
            : parseFloat(mpp);
          acc[hourString].totalMinutes += minutesToAdd;
          acc[hourString].count += 1;
        }

        return acc;
      }, {});

      // Map the grouped data to the final result format
      let result = Object.values(groupedByHourSlot).map((group, index) => ({
        hour_slot: group.hour_slot,
        avgKpp: group.count > 0 ? Math.round(group.kppSum / group.count) : 0,
        avgMpp: group.count > 0 ? Math.round(group.mppSum / group.count) : 0,
        totalMinutes: group.totalMinutes,
        rowNumber: index + 1,
      }));
      // Sample data rows if not enough data is present
      const sampleData = [
        {
          hour_slot: "00",
          avgKpp: 0,
          avgMpp: 0,
          totalMinutes: 0,
          rowNumber: result.length + 1,
        },
        {
          hour_slot: "00",
          avgKpp: 0,
          avgMpp: 0,
          totalMinutes: 0,
          rowNumber: result.length + 2,
        },
        {
          hour_slot: "00",
          avgKpp: 0,
          avgMpp: 0,
          totalMinutes: 0,
          rowNumber: result.length + 3,
        },
      ];

      // Ensure there are at least 3 rows
      if (result.length < defaultRowLength) {
        const neededRows = defaultRowLength - result.length;
        result = [...result, ...sampleData.slice(0, neededRows)];
      }
      result.sort((a, b) => {
        const get24HourTimes = (hourSlot) => {
          const [startTime] = hourSlot.split(" - "); // Get only the start time
          const [hour, period] = startTime.trim().split(/(?=[APap][Mm])/);
          let hours = parseInt(hour, 10);
          if (period?.toLowerCase() === "pm" && hours !== 12) hours += 12;
          if (period?.toLowerCase() === "am" && hours === 12) hours = 0;
          return hours;
        };

        return get24HourTimes(b.hour_slot) - get24HourTimes(a.hour_slot);
      });
      return result;
    };
  }

  useEffect(() => {
    const rows = randomScreenShots
      ? getRowsWithAverages(userSummary?.random_screenshots)
      : getRowsWithAverages(userSummary?.image_activity);
    const data = randomScreenShots
      ? userSummary?.random_screenshots &&
        userSummary?.random_screenshots.length > 0
        ? userSummary.random_screenshots
        : [{ hour_slot: null, minute_to_add: "0", image: null, kpp: 0, mpp: 0 }]
      : userSummary?.image_activity && userSummary?.image_activity.length > 0
      ? userSummary.image_activity
      : [{ hour_slot: null, minute_to_add: "0", image: null, kpp: 0, mpp: 0 }];

    setImageActivityRows(rows);
    setDataToRender(data);
  }, [randomScreenShots, userSummary]);

  const handleDateClick = (day) => {
    const selectedDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      day
    );
    setDataByDate(formatDate(selectedDate));
    setIsDropdownOpen(false);
  };

  const convertMinutesToHours = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours}:${remainingMinutes.toString().padStart(2, "0")}`;
  };

  const handleNext = () => {
    let newData = slotData.sort((a, b) => a.hour_slot - b.hour_slot);
    if (currentIndex < newData.length - 1) {
      if (moniter === "1") {
        setImageUrl(newData[currentIndex + 1].image);
        setCurrentIndex(currentIndex + 1);
      } else if (moniter === "2") {
        setImageUrl(newData[currentIndex + 1].image_one);
        setCurrentIndex(currentIndex + 1);
      } else if (moniter === "3") {
        setImageUrl(newData[currentIndex + 1].image_two);
        setCurrentIndex(currentIndex + 1);
      } else if (moniter === "4") {
        setImageUrl(newData[currentIndex + 1].image_three);
        setCurrentIndex(currentIndex + 1);
      } else if (moniter === "5") {
        setImageUrl(newData[currentIndex + 1].image_four);
        setCurrentIndex(currentIndex + 1);
      }
    }
  };

  const handlePrevious = () => {
    let newData = slotData.sort((a, b) => a.hour_slot - b.hour_slot);
    if (currentIndex > 0) {
      if (moniter === "1") {
        setImageUrl(newData[currentIndex - 1].image);
        setCurrentIndex(currentIndex - 1);
      } else if (moniter === "2") {
        setImageUrl(newData[currentIndex - 1].image_one);
        setCurrentIndex(currentIndex - 1);
      } else if (moniter === "3") {
        setImageUrl(newData[currentIndex - 1].image_two);
        setCurrentIndex(currentIndex - 1);
      } else if (moniter === "4") {
        setImageUrl(newData[currentIndex - 1].image_three);
        setCurrentIndex(currentIndex - 1);
      } else if (moniter === "5") {
        setImageUrl(newData[currentIndex - 1].image_four);
        setCurrentIndex(currentIndex - 1);
      }
    }
  };
  const sortByHourSlot = (data) => {
    return data.sort((a, b) => {
      const parseTime = (slot) => {
        if (!slot || typeof slot !== "string") return Infinity; // Handle missing or invalid slots

        const match = slot.match(/(\d+):(\d+) (\w+)/);
        if (!match) return Infinity; // Handle malformed time formats

        let [_, hours, minutes, period] = match;
        hours = parseInt(hours, 10);
        minutes = parseInt(minutes, 10);

        if (period.toLowerCase() === "pm" && hours !== 12) {
          hours += 12; // Convert PM times to 24-hour format
        }
        if (period.toLowerCase() === "am" && hours === 12) {
          hours = 0; // Convert 12 AM to 0 hours
        }

        return hours * 60 + minutes; // Convert to total minutes
      };

      return parseTime(a.hour_slot) - parseTime(b.hour_slot);
    });
  };

  
  useEffect(() => {
    setuserList(users);
  }, [users]);

  useEffect(() => {
    setNext(next);
  }, [next]);

  const handleUsersScroll = async (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;

    // Check if we've scrolled to the bottom and there's a next page
    if (scrollHeight - scrollTop === clientHeight && next && !isFetching) {
      setIsFetching(true); // Set fetching state to true
      await fetchNextUsers(next);
      setIsFetching(false); // Reset fetching state after fetching
    }
  };

  const fetchNextUsers = async (url) => {
    try {
      const res = await fetchApi.get(url, {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });
      if (res.status === 200) {
        const timeReporters = res.data.results.filter(
          (user) => user.role.name === "Time Reporter"
        );
        setuserList((prev) => [...prev, ...timeReporters]);
        setNext(res.data.next);
      }
    } catch (error) {
      console.error("Error fetching next users:", error);
    }
  };

  // Add the search function
  const handleSearch = async (e) => {
    if (e.key === "Enter") {
      setIsSearching(true);
      try {
        const res = await fetchApi.get(`users/?search=${searchQuery}`, {
          headers: {
            Authorization: `Bearer ${userInfo.access_token}`,
          },
        });
        if (res.status === 200) {
          setuserList(res.data.results);
          setNext(res.data.next);
        }
      } catch (error) {
        console.error("Search error:", error);
      }
      setIsSearching(false);
    }
  };

  // Add the search input change handler
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchQuery(value);

    // If search input is empty, restore original list
    if (!value.trim()) {
      setuserList(users);
      setNext(nextPage);
    }
  };

  const handleClickOutside1 = (event) => {
    if (userDropdownRef.current && !userDropdownRef.current.contains(event.target)) {
      setIsOpen(false); // Close dropdown
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside1);
    } else {
      document.removeEventListener("mousedown", handleClickOutside1);
    }
    return () => document.removeEventListener("mousedown", handleClickOutside1);
  }, [isOpen]);

  const handleClickOutside2 = (event) => {
    if (moniterDropdownRef.current && !moniterDropdownRef.current.contains(event.target)) {
      setShowDropdown(false); // Close dropdown
    }
  };

  useEffect(() => {
    if (showDropdown) {
      document.addEventListener("mousedown", handleClickOutside2);
    } else {
      document.removeEventListener("mousedown", handleClickOutside2);
    }
    return () => document.removeEventListener("mousedown", handleClickOutside2);
  }, [showDropdown]);

  return (
    <>
      {showModal && (
        <div className={styles.modalOverlay}>
          <div className={`${styles.modal}`}>
            <PreviewModal
              onClose={() => setShowModal(false)}
              imgSrc={imageUrl}
              handleNext={handleNext}
              handlePrevious={handlePrevious}
              currentIndex={currentIndex}
              slotDataLength={slotData.length}
            />
          </div>{" "}
        </div>
      )}
      {isLoading && (
        <>
          <div
            className="fixed inset-0 bg-black bg-opacity-50 z-10"
            style={{ top: "95px" }}
          ></div>
          <div
            className="fixed inset-0 flex items-center justify-center z-20"
            style={{ top: "95px" }}
          >
            <Loader />
          </div>
        </>
      )}
      <>
        <div
          className={`${styles.main_Heading} ${
            theme === "dark" ? "text-white" : "text-black"
          }`}
        >
          Activity
        </div>
        <hr className="w-full" />
        <div
          className={` lg:w-5/6 w-full  ${
            theme === "dark" ? "text-white" : "text-black"
          }`}
        >
          {isModalOpen && <FilterModal onClose={() => setIsModalOpen(false)} />}
          <div className={styles.container}>
            <div
              className={`${styles["calendar-header"]} flex-wrap items-center`}
            >
              <div>
                <span className={styles["month-div"]}>
                  <FaArrowLeft onClick={goToPrevMonth} />
                  <h2
                    className="cursor-pointer"
                    onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                  >
                    {currentDate.toLocaleString("default", { month: "long" })}{" "}
                    {currentDate.getFullYear()}
                  </h2>
                  <div
                    className={styles.dropdown}
                    onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                  >
                    <IoMdArrowDropdown />
                    {isDropdownOpen && (
                      <div
                        className={`${styles.dropdownMenu} ${
                          theme === "dark"
                            ? "bg-black text-white"
                            : "bg-white text-black"
                        }`}
                        onMouseLeave={() => setIsDropdownOpen(false)}
                      >
                        <div className="flex items-center justify-between px-2">
                          <FaArrowLeft
                            onClick={() => {
                              goToPrevMonth();
                              setIsDropdownOpen(true);
                            }}
                          />
                          <h2 className="text-center p-2">
                            {currentDate.toLocaleString("default", {
                              month: "long",
                            })}{" "}
                            {currentDate.getFullYear()}
                          </h2>
                          <FaArrowRight
                            onClick={() => {
                              goToNextMonth();
                              setIsDropdownOpen(true);
                            }}
                          />
                        </div>
                        <div className="flex justify-around">
                          {["S", "M", "T", "W", "T", "F", "S"].map((day) => (
                            <th
                              key={day}
                              className={` ${styles["day-header"]}`}
                            >
                              {day}
                            </th>
                          ))}
                        </div>
                        <div className={styles.calendar}>
                          {days.map((day, index) => (
                            <div
                              key={index}
                              onClick={() => {
                                if (day.currentMonth) {
                                  handleDateClick(day.day);
                                }
                              }}
                              className={`
                                ${
                                  theme === "dark"
                                    ? "hover:border-white"
                                    : "hover:border-black"
                                } 
                                ${styles.day} 
                                ${
                                  day.currentMonth
                                    ? styles.currentMonth
                                    : styles.otherMonth
                                } 
                                ${
                                  day.currentMonth &&
                                  dataByDate ===
                                    formatDate(
                                      new Date(
                                        currentDate.getFullYear(),
                                        currentDate.getMonth(),
                                        day.day
                                      )
                                    )
                                    ? styles.selected
                                    : ""
                                }
                                ${
                                  !day.currentMonth
                                    ? "cursor-not-allowed opacity-50"
                                    : ""
                                }
                              `}
                            >
                              {day.day}
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                  <FaArrowRight onClick={goToNextMonth} />
                </span>
                <div className={`${styles.flexRow}`}>
                  {/* <label className={styles.options}>
                      Select:
                      <select className={styles.selectBox} disabled={true}>
                        <option value="None">None</option>
                      </select>
                      <select className={styles.selectBox} disabled={true}>
                        <option value="Monitor">Monitor</option>
                      </select>
                    </label> */}
                  {/* <button onClick={() => setShowMore(!showMore)}>
                <VscGraph /> Show statistics
              </button> */}

                  <button className="outline-none"
                    onClick={() =>{
                      if(userInfo?.user?.plan?.name === 'Basic'){
                        dispatch(addToast({message:'You need to upgrade your plan to access this feature', type:'error'}))
                        return
                      }
                      setShowOfflineActivity(!showOfflineActivity)}}
                  >
                    <FaRegLightbulb /> {showOfflineActivity ? "Show online activity" : "Show overtime"}{" "}
                  
                  </button>
                </div>
                <div className={`${styles.flexRow} w-56`}>
                  <button
                    onClick={() =>{
                      if(userInfo?.user?.plan?.name === 'Basic'){
                        dispatch(addToast({message:'You need to upgrade your plan to access this feature', type:'error'}))
                        return 
                      }
                       setRandomScreenShots(!randomScreenShots)}}
                    className="relative focus:outline-none"
                  >
                    <FiActivity />
                    <div
                      className={`${
                        theme === "dark" ? "bg-[#212529]" : "bg-[#DFDFDF]"
                      }  px-1 rounded-full`}
                    >
                      <div className="bg-transparent py-1 px-2 rounded-2xl relative overflow-hidden">
                        <div
                          className={`absolute top-0 bottom-0 left-0 w-1/2 rounded-[19px] bg-red-600 transition-all duration-500 ease-in-out ${
                            randomScreenShots ? "translate-x-full" : ""
                          }`}
                        ></div>
                        <div className="relative flex justify-between">
                          <span
                            className={` px-2  ${
                              !randomScreenShots
                                ? "text-white"
                                : theme === "dark"
                                ? "text-white"
                                : "text-black"
                            }`}
                          >
                            Simple
                          </span>
                          <span
                            className={` ml-3 pr-2 pl-[10px] ${
                              randomScreenShots
                                ? "text-white"
                                : theme === "dark"
                                ? "text-white"
                                : "text-black"
                            }`}
                          >
                            Random
                          </span>
                        </div>
                      </div>
                    </div>
                  </button>
                </div>
              </div>

              <div className={styles.productivity_main}>
                <span>
                  Total day time:{" "}
                  {convertMinutesToHours(
                    userSummary?.total_tracked_time || "0"
                  )}
                </span>
                <span>
                  Active Time:{" "}
                  {convertMinutesToHours(userSummary?.active_time || "0")}{" "}
                  {`(${userSummary?.active_time_percentage || "0"}%)`}
                </span>
                <span className={styles.productivity_div}>
                  Productivity:{" "}
                  {(parseFloat(
                    userSummary?.keyboard_productivity_percentage || "0"
                  ) +
                    parseFloat(
                      userSummary?.mouse_productivity_percentage || "0"
                    )) /
                    2}
                  % (
                  <div className={styles.productivity_inner_div}>
                    <FaKeyboard />{" "}
                    {userSummary?.keyboard_productivity_percentage || "0"}%{" "}
                    <FaMouse />{" "}
                    {userSummary?.mouse_productivity_percentage || "0"}%{" "}
                  </div>
                  )
                </span>
              </div>
              <div
                className={`text-left ${styles.user_main}`}
                style={{ gap: "0" }}
              >
                <div className="flex gap-1 items-center ">
                  <span>User: </span>
                  <div className="relative" ref={userDropdownRef}>
                        <div
                          className={`rounded-md gap-4 flex items-center justify-between p-2 min-w-[200px] cursor-pointer ${
                            theme === "dark"
                              ? "hover:bg-gradient-to-r hover:from-[#fff3] hover:to-[#fff0] transition-all"
                              : "hover:bg-gradient-to-r hover:from-[#6d6d6d33] hover:to-[#fff0] transition-all"
                          }`}
                          onClick={() => setIsOpen(!isOpen)}
                        >
                          <span>
                            {selectedUser
                              ? selectedUser?.first_name +
                                " " +
                                selectedUser?.last_name
                              : "Select User"}
                          </span>
                          <IoIosArrowDown className="w-4 h-4" />
                        </div>
                        {isOpen && (
                          <div
                            className={`z-10 absolute mt-1 w-full border overflow-auto shadow-lg rounded-[10px] max-h-[280px] ${
                              theme === "dark"
                                ? "bg-[#212529] border-[#555657]"
                                : "bg-[#F2F4F7] border-[#9A9F9A]"
                            }`}
                            onScroll={handleUsersScroll}
                            style={{
                              paddingTop: "4px",
                              paddingBottom: "4px",
                            }}
                          >
                            <div className="relative">
                              <input
                                type="text"
                                className={`p-2 pl-8 bg-transparent border-b outline-none w-full ${
                                  theme === "dark"
                                    ? "border-[#555657]"
                                    : "border-[#D8D8D8]"
                                }`}
                                value={searchQuery}
                                onChange={handleSearchChange}
                                onKeyDown={handleSearch}
                                placeholder="Search users..."
                              />
                              {isSearching && (
                                <div className="absolute left-2 top-1/2 -translate-y-1/2">
                                  <img
                                    src={loader}
                                    alt="Searching..."
                                    className="w-4 h-4"
                                  />
                                </div>
                              )}
                            </div>
                            {userList.length > 0 ?
                            userList?.map((user) => (
                              <div
                                key={user.id}
                                className={`p-2 cursor-pointer  flex items-center gap-2 ${
                                  selectedUser?.id === user?.id
                                    ? "bg-opacity-10 bg-gray-500"
                                    : ""
                                } ${
                                  theme === "dark"
                                    ? "hover:bg-gradient-to-r hover:from-[#fff3] hover:to-[#fff0] transition-all"
                                    : "hover:bg-gradient-to-r hover:from-[#6d6d6d33] hover:to-[#fff0] transition-all"
                                }`}
                                onClick={() => {
                                  dispatch(updateSelectedUser(user));
                                  dispatch(updateUserId(user.id));
                                  setIsOpen(false);
                                }}
                              >
                                {user.picture ? (
                                  <img
                                    src={user.picture}
                                    alt={`${user.first_name}`}
                                    className="w-6 h-6 rounded-full object-cover"
                                  />
                                ) : (
                                  <FaUserCircle className="w-6 h-6 text-gray-400" />
                                )}
                                <span>
                                  {user.first_name} {user.last_name}
                                </span>
                              </div>
                            )) : <div  className={`p-2 cursor-pointer  flex items-center gap-2 ${
                              theme === "dark"
                                ? "hover:bg-gradient-to-r hover:from-[#fff3] hover:to-[#fff0] transition-all"
                                : "hover:bg-gradient-to-r hover:from-[#6d6d6d33] hover:to-[#fff0] transition-all"
                            }`}>No user found</div>}
                            {isFetching && (
                              <div className="flex justify-center p-2">
                                <img
                                  src={loader}
                                  alt="Loading..."
                                  className="w-6 h-6"
                                />
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                </div>
            {userInfo.user.plan.name !== 'Basic' &&
                <div className="flex space-x-4 items-center my-1">
                  <div className="flex gap-1 items-center relative">
                    <span>Project: </span>
                    <div className={``}>
                      {selectedUser?.project ? selectedUser?.project?.name
                        : "No project"}{" "}
                    </div>
                  </div>
                </div>
}
                <div className="flex gap-1 items-center w-3/5">
                  <label htmlFor="moniter-select" className="font-normal">
                    Monitors:
                  </label>
                  <div className="relative" ref={moniterDropdownRef}>
                    <button
                      id="moniter-select"
                      className={`${
                        theme === "dark"
                          ? "text-white hover:bg-gradient-to-r hover:from-[#fff3] hover:to-[#fff0] transition-all hover:shadow-md"
                          : "text-black hover:bg-gradient-to-r hover:from-[#6d6d6d33] hover:to-[#fff0] transition-all hover:shadow-md"
                      } transition-all duration-200 ease-in-out px-1 py-1 rounded-md flex gap-2 items-center outline-none font-medium`}
                      onClick={() => {
                        setShowDropdown(!showDropdown);
                        setIsOpen(false);
                      }}
                    >
                      <span className=" whitespace-nowrap">{moniterLabel}</span>
                      <span>
                        <FiChevronDown className="ml-2 w-4 h-4" />
                      </span>
                    </button>
                    {showDropdown && (
                      <ul
                        className={`absolute block left-0 mt-[-4px] text-sm border rounded-lg overflow-hidden w-full z-10 ${
                          theme === "dark"
                            ? "bg-darkMode text-white border-white"
                            : "bg-white text-black"
                        }`}
                        style={{
                          boxShadow: "0 4px 8px rgba(143, 142, 142, 0.2)",
                        }}
                      >
                        {[
                          { value: "1", label: "Default" },
                          { value: "2", label: "Monitor 1" },
                          { value: "3", label: "Monitor 2" },
                          { value: "4", label: "Monitor 3" },
                          { value: "5", label: "Monitor 4" },
                        ].map((option) => (
                          <li
                            key={option.value}
                            className={` cursor-pointer px-2 py-[4px] hover:bg-[#f0f0f0a9]`}
                            onClick={() => {
                              setMoniterLabel(option.label);
                              setMoniter(option.value);
                              setShowDropdown(false);
                            }}
                          >
                            {option.label}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {showOfflineActivity ? (
              <div className="w-full max-w-screen-lg flex flex-col justify-center item-center mt-4" style={{maxWidth:'1024px'}} >
                <CreateOvertime parent="activity-tab" />
              </div>
            ) : (
              !randomScreenShots ?
              <div
                className={`flex justify-center ${styles["activity-section"]}`} style={{width: '-webkit-fill-available'}}
              >
                <div className={styles["table-container"]}>
                  <table className={styles["activity-table"]}>
                    <tbody>
                      <span
                        style={{
                          borderLeft: `1px solid ${
                            theme === "dark" ? "white" : "black"
                          }`,
                          paddingLeft: "20px",
                        }}
                      >
                        {imageActivityRows.map((activity) => (
                          <>
                            <tr key={activity.id} className={styles.table_row}>
                              <td className={styles["activity-first-cell"]}>
                                <div
                                  style={{
                                    backgroundColor: `${
                                      theme === "dark" ? "white" : "black"
                                    }`,
                                  }}
                                  className={styles.circleDiv}
                                />
                                <div className={styles.time_div1}>
                                  <span>{activity?.hour_slot}</span>
                                </div>
                                {randomScreenShots ? (
                                  <></>
                                ) : (
                                  <div className={styles.time_div2}>
                                    +{activity?.totalMinutes}m
                                  </div>
                                )}
                              </td>
                            </tr>
                            {(() => {
                           const convertTo24HourTime = (timeStr) => {
                            if (!timeStr || typeof timeStr !== "string") return null; // Prevents errors
                          
                            let parts = timeStr.trim().split(" ");
                            if (parts.length < 2) return null; // Ensure format "HH:MM AM/PM"
                          
                            let [time, modifier] = parts;
                            let [hh, mm] = time.split(":").map(Number);
                          
                            if (isNaN(hh) || isNaN(mm)) return null;
                          
                            if (modifier.toUpperCase() === "PM" && hh !== 12) hh += 12;
                            if (modifier.toUpperCase() === "AM" && hh === 12) hh = 0;
                          
                            return hh * 60 + mm; // Convert to total minutes of the day
                          };
                          
                          const isTimeInRange = (itemTimeRange, activityTimeRange) => {
                            if (!itemTimeRange || !activityTimeRange) return false;
                          
                            const [activityStart, activityEnd] = activityTimeRange
                              .split(" - ")
                              .map(convertTo24HourTime);
                            const [itemStart, itemEnd] = itemTimeRange.split(" - ").map(convertTo24HourTime);
                          
                            if (activityStart === null || activityEnd === null || itemStart === null || itemEnd === null) {
                              return false;
                            }
                          
                            // 🔹 Handle cross-midnight case (e.g., 11:00 PM - 12:00 PM next day)
                            let adjustedActivityEnd = activityEnd;
                            if (activityEnd < activityStart) {
                              adjustedActivityEnd += 24 * 60; // Add 24 hours to make it next day
                            }
                          
                            let adjustedItemStart = itemStart;
                            let adjustedItemEnd = itemEnd;
                            if (itemEnd < itemStart) {
                              adjustedItemEnd += 24 * 60; // Adjust for next-day cases
                            }
                          
                            return adjustedItemStart >= activityStart && adjustedItemEnd <= adjustedActivityEnd;
                          };
                          
                          
                          let slotData = dataToRender.filter((item) =>
                            isTimeInRange(item.hour_slot, activity.hour_slot)
                          );
                                             
                              if (slotData.length < 6) {
                                const sampleDataNeeded = Array.from(
                                  { length: 6 - slotData.length },
                                  (_, index) => ({
                                    minute_to_add: "0",
                                    image: null,
                                    kpp: 0,
                                    mpp: 0,
                                  })
                                );

                                slotData = [...slotData, ...sampleDataNeeded];
                              }

                              return (
                                <tr
                                  className={`flex gap-2 relative  ${
                                    theme === "dark" ? "dark" : "light"
                                  }}`}
                                  style={{
                                    overflowX: "auto",
                                    maxWidth: "1015px",
                                  }}
                                >
                                  {sortByHourSlot(slotData).map(
                                    (data, index) => {
                                      return (
                                        <td key={index}>
                                          <div className="flex justify-center w-full">
                                            {userInfo?.user?.plan.name !== 'Basic' &&
                                            <span
                                              className={`${
                                                styles.platformDiv
                                              } ${
                                                theme === "dark"
                                                  ? "bg-[#2c2c2e]"
                                                  : "bg-lightMode "
                                              } `}
                                            >
                                             {selectedUser.project?.name ? selectedUser?.project?.name
                                             : 'No project'}
                                            </span> }
                                          </div>
                                          <div
                                            className={` ${
                                              theme === "dark"
                                                ? "bg-[#2c2c2e] text-white shadow-sm"
                                                : "bg-lightMode text-black shadow-sm shadow-black"
                                            } ${styles.cardContainer} `}
                                            onMouseLeave={() =>
                                              setOpenDropdownId(null)
                                            }
                                          >
                                            <div className="relative">
                                              {data.image ||
                                              data.image_one ||
                                              data.image_two ||
                                              data.image_three ||
                                              data.image_four ? (
                                                <>
                                                  <span
                                                    className={
                                                      styles.screenshotMain
                                                    }
                                                  >
                                                    {(moniter === "1" &&
                                                      data.image) ||
                                                    (moniter === "2" &&
                                                      data.image_one) ||
                                                    (moniter.toString() ===
                                                      "3" &&
                                                      data.image_two) ||
                                                    (moniter === "4" &&
                                                      data.image_three) ||
                                                    (moniter === "5" &&
                                                      data.image_four) ? (
                                                      <img
                                                        src={
                                                          moniter.toString() ===
                                                          "1"
                                                            ? data.image
                                                            : moniter.toString() ===
                                                              "2"
                                                            ? data.image_one
                                                            : moniter.toString() ===
                                                              "3"
                                                            ? data.image_two
                                                            : moniter.toString() ===
                                                              "4"
                                                            ? data.image_three
                                                            : moniter.toString() ===
                                                              "5"
                                                            ? data.image_four
                                                            : ""
                                                        }
                                                        alt="Screenshot"
                                                        className={
                                                          styles.screenshot
                                                        }
                                                      />
                                                    ) : (
                                                      <div
                                                        className={
                                                          styles.activity_img
                                                        }
                                                      >
                                                        Extra moniter not
                                                        connnected
                                                      </div>
                                                    )}

                                                    <button
                                                      className={
                                                        styles.viewButton
                                                      }
                                                      onClick={() => {
                                                        if (moniter === "1") {
                                                          setImageUrl(
                                                            data.image
                                                          );
                                                        } else if (
                                                          moniter === "2"
                                                        ) {
                                                          setImageUrl(
                                                            data.image_one
                                                          );
                                                        } else if (
                                                          moniter === "3"
                                                        ) {
                                                          setImageUrl(
                                                            data.image_two
                                                          );
                                                        } else if (
                                                          moniter === "4"
                                                        ) {
                                                          setImageUrl(
                                                            data.image_three
                                                          );
                                                        } else if (
                                                          moniter === "5"
                                                        ) {
                                                          setImageUrl(
                                                            data.image_four
                                                          );
                                                        }
                                                        setCurrentIndex(index); // Reset to the first image
                                                        setShowModal(true);
                                                        setSlotData(slotData); // Show the modal
                                                      }}
                                                    >
                                                      View screens
                                                    </button>
                                                  </span>

                                                  <div className="relative">
                                                    <div
                                                      className={
                                                        styles.screenDiv
                                                      }
                                                      onClick={() =>
                                                        handleDropdownToggle(
                                                          data?.id
                                                        )
                                                      }
                                                    >
                                                      {(() => {
                                                        const screenCount = [
                                                          data?.image,
                                                          data?.image_one,
                                                          data?.image_two,
                                                          data?.image_three,
                                                          data?.image_four,
                                                        ].filter(
                                                          Boolean
                                                        ).length;

                                                        return screenCount === 1
                                                          ? "1 Screen"
                                                          : `+${
                                                              screenCount - 1
                                                            } Screens`;
                                                      })()}
                                                    </div>
                                                  </div>
                                                </>
                                              ) : (
                                                <div
                                                  className={
                                                    styles.activity_img
                                                  }
                                                >
                                                  BugTrack agent was disabled
                                                </div>
                                              )}
                                            </div>
                                            {randomScreenShots ? (
                                              <></>
                                            ) : (
                                              <div className="px-2">
                                                <div className={styles.details}>
                                                  <span className={styles.time}>
                                                    {data?.hour_slot}
                                                  </span>
                                                  <div
                                                    className={
                                                      styles.time_main2
                                                    }
                                                  >
                                                    <div className="flex gap-1 items-center">
                                                      {data?.kpp}%{" "}
                                                      <FaKeyboard />
                                                    </div>
                                                    <div className="flex gap-1 items-center">
                                                      {data?.mpp}% <FaMouse />
                                                    </div>
                                                  </div>
                                                </div>

                                                <div
                                                  className={`${
                                                    theme === "dark"
                                                      ? "bg-[#dddede]"
                                                      : "bg-[#a0a0a0]"
                                                  } ${
                                                    styles.progressBarContainer
                                                  }`}
                                                >
                                                  <div
                                                    className={`${
                                                      Math.floor(
                                                        (parseFloat(
                                                          data?.kpp || "0"
                                                        ) +
                                                          parseFloat(
                                                            data?.mpp || "0"
                                                          )) /
                                                          2
                                                      ) < 50
                                                        ? "bg-red-500"
                                                        : Math.floor(
                                                            (parseFloat(
                                                              data?.kpp || "0"
                                                            ) +
                                                              parseFloat(
                                                                data?.mpp || "0"
                                                              )) /
                                                              2
                                                          ) < 70
                                                        ? "bg-orange-500"
                                                        : "bg-green-500"
                                                    } ${styles.progressBar}`}
                                                    style={{
                                                      width: `${
                                                        (parseFloat(
                                                          data?.kpp || "0"
                                                        ) +
                                                          parseFloat(
                                                            data?.mpp || "0"
                                                          )) /
                                                        2
                                                      }%`,
                                                    }}
                                                  ></div>
                                                  <span
                                                    className={
                                                      styles.progressText
                                                    }
                                                  >
                                                    {(parseFloat(
                                                      data?.kpp || "0"
                                                    ) +
                                                      parseFloat(
                                                        data?.mpp || "0"
                                                      )) /
                                                      2}
                                                    % of {data?.minute_to_add}m
                                                  </span>
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                        </td>
                                      );
                                    }
                                  )}
                                </tr>
                              );
                            })()}
                          </>
                        ))}
                      </span>
                    </tbody>
                  </table>
                </div>
              </div> : (
                 <div
                 className={`flex justify-center ${styles["activity-section"]}`} style={{width: '-webkit-fill-available'}}
               >
                 <div className={styles["table-container"]}>
                   <table className={styles["activity-table"]}>
                     <tbody>
                       <span
                         style={{
                           borderLeft: `1px solid ${
                             theme === "dark" ? "white" : "black"
                           }`,
                           paddingLeft: "20px",
                         }}
                       >
                         {imageActivityRows.map((activity) => (
                           <>
                             <tr key={activity.id} className={styles.table_row}>
                               <td className={styles["activity-first-cell"]}>
                                 <div
                                   style={{
                                     backgroundColor: `${
                                       theme === "dark" ? "white" : "black"
                                     }`,
                                   }}
                                   className={styles.circleDiv}
                                 />
                                 <div className={styles.time_div1}>
                                   <span>{activity?.hour_slot}</span>
                                 </div>
                               </td>
                             </tr>
                             {(() => {
                           let slotData = dataToRender.filter(
                            (item) =>
                              activity?.hour_slot
                                ?.split(" ")[0]
                                ?.split(":")[0] ===
                              item?.hour_slot
                                ?.split(" ")[0]
                                ?.split(":")[0]
                          );
                                              
                               if (slotData.length < 6) {
                                 const sampleDataNeeded = Array.from(
                                   { length: 6 - slotData.length },
                                   (_, index) => ({
                                     minute_to_add: "0",
                                     image: null,
                                     kpp: 0,
                                     mpp: 0,
                                   })
                                 );
  
                                 slotData = [...slotData, ...sampleDataNeeded];
                               }
  
                               return (
                                 <tr
                                   className={`flex gap-2 relative  ${
                                     theme === "dark" ? "dark" : "light"
                                   }}`}
                                   style={{
                                     overflowX: "auto",
                                     maxWidth: "1015px",
                                   }}
                                 >
                                   {sortByHourSlot(slotData).map(
                                     (data, index) => {
                                       return (
                                         <td key={index}>
                                           <div className="flex justify-center w-full">
                                            {userInfo?.user?.plan?.name !== 'Basic' &&
                                             <span
                                               className={`${
                                                 styles.platformDiv
                                               } ${
                                                 theme === "dark"
                                                   ? "bg-[#2c2c2e]"
                                                   : "bg-lightMode "
                                               } `}
                                             >
                                                {selectedUser.project?.name ? selectedUser?.project?.name
                                             : 'No project'}
                                             </span>
                                   }
                                           </div>
                                           <div
                                             className={` ${
                                               theme === "dark"
                                                 ? "bg-[#2c2c2e] text-white shadow-sm"
                                                 : "bg-lightMode text-black shadow-sm shadow-black"
                                             } ${styles.cardContainer} `}
                                             onMouseLeave={() =>
                                               setOpenDropdownId(null)
                                             }
                                           >
                                             <div className="relative">
                                               {data.image ||
                                               data.image_one ||
                                               data.image_two ||
                                               data.image_three ||
                                               data.image_four ? (
                                                 <>
                                                   <span
                                                     className={
                                                       styles.screenshotMain
                                                     }
                                                   >
                                                     {(moniter === "1" &&
                                                       data.image) ||
                                                     (moniter === "2" &&
                                                       data.image_one) ||
                                                     (moniter.toString() ===
                                                       "3" &&
                                                       data.image_two) ||
                                                     (moniter === "4" &&
                                                       data.image_three) ||
                                                     (moniter === "5" &&
                                                       data.image_four) ? (
                                                       <img
                                                         src={
                                                           moniter.toString() ===
                                                           "1"
                                                             ? data.image
                                                             : moniter.toString() ===
                                                               "2"
                                                             ? data.image_one
                                                             : moniter.toString() ===
                                                               "3"
                                                             ? data.image_two
                                                             : moniter.toString() ===
                                                               "4"
                                                             ? data.image_three
                                                             : moniter.toString() ===
                                                               "5"
                                                             ? data.image_four
                                                             : ""
                                                         }
                                                         alt="Screenshot"
                                                         className={
                                                           styles.screenshot
                                                         }
                                                       />
                                                     ) : (
                                                       <div
                                                         className={
                                                           styles.activity_img
                                                         }
                                                       >
                                                         Extra moniter not
                                                         connnected
                                                       </div>
                                                     )}
  
                                                     <button
                                                       className={
                                                         styles.viewButton
                                                       }
                                                       onClick={() => {
                                                         if (moniter === "1") {
                                                           setImageUrl(
                                                             data.image
                                                           );
                                                         } else if (
                                                           moniter === "2"
                                                         ) {
                                                           setImageUrl(
                                                             data.image_one
                                                           );
                                                         } else if (
                                                           moniter === "3"
                                                         ) {
                                                           setImageUrl(
                                                             data.image_two
                                                           );
                                                         } else if (
                                                           moniter === "4"
                                                         ) {
                                                           setImageUrl(
                                                             data.image_three
                                                           );
                                                         } else if (
                                                           moniter === "5"
                                                         ) {
                                                           setImageUrl(
                                                             data.image_four
                                                           );
                                                         }
                                                         setCurrentIndex(index); // Reset to the first image
                                                         setShowModal(true);
                                                         setSlotData(slotData); // Show the modal
                                                       }}
                                                     >
                                                       View screens
                                                     </button>
                                                   </span>
  
                                                   <div className="relative">
                                                     <div
                                                       className={
                                                         styles.screenDiv
                                                       }
                                                       onClick={() =>
                                                         handleDropdownToggle(
                                                           data?.id
                                                         )
                                                       }
                                                     >
                                                       {(() => {
                                                         const screenCount = [
                                                           data?.image,
                                                           data?.image_one,
                                                           data?.image_two,
                                                           data?.image_three,
                                                           data?.image_four,
                                                         ].filter(
                                                           Boolean
                                                         ).length;
  
                                                         return screenCount === 1
                                                           ? "1 Screen"
                                                           : `+${
                                                               screenCount - 1
                                                             } Screens`;
                                                       })()}
                                                     </div>
                                                   </div>
                                                 </>
                                               ) : (
                                                 <div
                                                   className={
                                                     styles.activity_img
                                                   }
                                                 >
                                                   BugTrack agent was disabled
                                                 </div>
                                               )}
                                             </div>
                                           </div>
                                         </td>
                                       );
                                     }
                                   )}
                                 </tr>
                               );
                             })()}
                           </>
                         ))}
                       </span>
                     </tbody>
                   </table>
                 </div>
               </div>
              )
            )}
          </div>
        </div>
      </>
    </>
  );
};

export default Activity;
