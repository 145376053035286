import React,{useEffect, useState} from "react";
import { useSelector } from "react-redux";
import { FaEnvelope, FaPhone, FaMapMarkerAlt, FaArrowUp } from "react-icons/fa";
import { FaLinkedin, FaInstagram, FaFacebook } from "react-icons/fa";
import logo from "../../assets/bugtrack.png";
import { Link} from 'react-router-dom'

const PrimaryFooter = () => {
  const { theme } = useSelector((state) => state.theme);
 

  return (
    <footer
      className={`w-full py-10 px-6 md:px-16 primaryFooter ${
        theme === "dark" ? "bg-[#0F0F0F] text-white" : "bg-gray-100 text-black"
      }`}
    >
      <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-8 items-start">
        {/* Left Section - Logo & Description */}
        <div>
          <h2 className={`text-2xl flex items-center font-bold ${theme === 'dark'? 'text-white': 'text-black'}`}>
            <span className="text-[#FF0000] flex"><img src={logo} className="h-6 w-6 mb-1" />ug</span>Trackin
          </h2>
          <p className="mt-3 text-base">
            Track time, manage tasks, and boost productivity with our powerful
            desktop app.
          </p>
          <Link to='/subscriptionplan' className="mt-4 heroButton">
            Get Started
          </Link>
        </div>

        {/* Middle Section - Contact Info */}
        <div>
          <h3 className="text-lg font-semibold mb-4">Contact Us</h3>
          <p className="flex items-center gap-2 text-base mb-2">
            <FaEnvelope className="text-[#CC0927]" /> support@bugtrackin.com
          </p>
          <p className="flex items-center gap-2 text-base mt-2">
            <FaPhone className="text-[#CC0927]" /> +44 7400 100878
          </p>
          <p className="flex items-center gap-2 text-base mt-4 ">
            <FaMapMarkerAlt className="text-[#CC0927]" /> GL50 3HU, Hight Street Cheltenham, 
            Gloucestershire, UK
          </p>
        </div>

        {/* Right Section - Social Links */}
        <div>
          <h3 className="text-lg font-semibold mb-4">Get in Touch</h3>
          <div className="flex gap-4">
            <a href="https://www.linkedin.com/company/bugdev/" target='_blank' className="text-blue-500 text-2xl">
              <FaLinkedin />
            </a>
            <a href="https://www.instagram.com/bugdeve?igsh=YWlwd2ZvMXJkdWpj" target='_blank' className="text-pink-500 text-2xl">
              <FaInstagram />
            </a>
            <a href="#" className="text-blue-600 text-2xl">
              <FaFacebook />
            </a>
          </div>
        </div>
      </div>

    
    </footer>
  );
};

export default PrimaryFooter;
