import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchApi } from "../../../utlis/axios";
import Loader from "../../Loader/Loader";
import GetRequest from "../overtime/GetRequest";
import OfftimeGet from "../offtime/OfftimeGet";
import noFile from "../../../assets/noFile.png";
import CompanyTimeoffs from "./CompanyTimeoffs";
import AdminTimeSheet from "../timesheet/AdminTimeSheet";
import CompanyTemplates from "../template/CompanyTemplates";
import { useNavigate } from "react-router-dom";
import RegCompany from "./CompanyForm";
import { addToast } from "../../../Redux/Slices/toast-slice";

const UpdateCompany = () => {
  const { userInfo, companyInfo } = useSelector((state) => state?.auth || {});
  const { theme } = useSelector((state) => state?.theme);
  const dispatch=useDispatch()
  const [companyData, setCompanyData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [formValues, setFormValues] = useState({
    name: "",
    site_url: "",
    logo: [],
    stamp: [],
    address: "",
    postal_code: "",
    city: "",
    state: "",
    country: "",
    owner: "",
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [isOpen, setIsOpen]=useState(false);

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    if (files?.length > 0) {
      // Update state for file inputs
      setFormValues({ ...formValues, [name]: files[0] });
    } else {
      // Update state for other input types
      setFormValues({ ...formValues, [name]: value });
    }
  };

  const handleFileChange = (field) => (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const fileDataURL = reader.result; // Generate data URL for preview
        setCompanyData((prev) => ({
          ...prev,
          [field]: fileDataURL, // Update preview
        }));
        setFormValues((prev) => ({
          ...prev,
          [field]: file, // Update form values with the actual file
        }));
      };
      reader.readAsDataURL(file); // Read file to generate a data URL
    }
  };
  let isFetched = false;
  const getCompanyData = async () => {
    if (isFetched) return;
    isFetched = true;
    if (userInfo?.company ) {
      try {
        setIsLoading(true);
        const res = await fetchApi.get(`companies/${userInfo.company.id}`, {
          headers: {
            Authorization: `Bearer ${userInfo.access_token}`,
          },
        });
        if (res.status === 200) {
          const company = res.data;
          setCompanyData(company);

          // Update formValues with fetched data
          setFormValues({
            name: company?.name || "",
            site_url: company?.site_url || "",
            logo: [],
            stamp: [],
            address: company?.address || "",
            postal_code: company?.postal_code || "",
            city: company?.city || "",
            state: company?.state || "",
            country: company?.country || "",
            owner: company?.owner || "",
          });
          setIsLoading(false);
        } else {
          dispatch(addToast({ message:'Something went wrong', type :'error' })); 
          setIsLoading(false);
        }
      } catch (err) {
        console.log(err);
        dispatch(addToast({ message :'Error fetching company data', type :'error' })); 
        setIsLoading(false);
      }
    } else {
      return;
    }
  };
  useEffect(() => {
    getCompanyData();
  }, []);
  const handleUpdate = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    setLoading(true) // Indicate that the update is in progress

    // Create an object to hold the updated values
    const updatedValues = {};

    // Compare the initial `companyData` with the current `formValues`
    for (let key in formValues) {
      if (key === "logo" || key === "stamp") {
        // Include file input only if a file is selected
        if (formValues[key] instanceof File) {
          updatedValues[key] = formValues[key];
        }
      } else if (
        formValues[key] !== companyData[key] &&
        formValues[key] !== undefined
      ) {
        // Include other fields only if they are changed
        updatedValues[key] = formValues[key];
      }
    }

    // Ensure there are updated values to send
    if (Object.keys(updatedValues).length === 0) {
      setLoading(false);
      dispatch(addToast({ message:'No changes made', type :'warn' })); 
      return;
    }

    // Prepare FormData for the API request (for file uploads)
    const formData = new FormData();
    for (let key in updatedValues) {
      formData.append(key, updatedValues[key]);
    }

    try {
      const res = await fetchApi.patch(
        `companies/${companyData.id}/`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${userInfo.access_token}`,
            "Content-Type": "multipart/form-data", // Indicate multipart data
          },
        }
      );

      if (res.status === 200) {
        setCompanyData(res.data); // Update the local state with the new data
        dispatch(addToast({ message :'Compnay detail update successfully', type:'success' })); 
      }
    } catch (error) {
      console.error("Error updating company details:", error);
      dispatch(addToast({ message:'Failed to update company data pleas try again', type:'error' })); 
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  const [activeTab, setActiveTab] = useState(0); // Track the active tab
  const underlineRef = useRef(null); // Ref for the underline
  const tabsRef = useRef([]); // Ref for all the tab buttons

  useEffect(() => {
    if (tabsRef.current[activeTab] && underlineRef.current) {
      const activeButton = tabsRef.current[activeTab];
      underlineRef.current.style.width = `${activeButton.offsetWidth}px`;
      underlineRef.current.style.left = `${activeButton.offsetLeft}px`;
    }
  }, [activeTab]);

  const tabs = [
    { label: "Company Info", value: "1" },
    { label: "Company Timeoffs", value: "2" },
    { label: "Overtime", value: "3" },
    { label: "Timeoffs", value: "4" },
    { label: "Timesheet", value: "5" },
    // {label:'Templates', value: "6" },
  ];
  const standaredUserTabs=[
    { label: "Company Info", value: "1" },
    { label: "Company Timeoffs", value: "2" },
  ]
  return (
    <div className="mt-4 w-full max-w-[1024px] lg:px-0 sm:px-5 px-2 h-max ">
      {(isLoading || loading ) && (
        <>
          <div
            className="fixed inset-0 bg-black bg-opacity-50 z-10 md:top-[95px] top-[52px]"
          ></div>
          <div
            className="fixed inset-0 flex items-center justify-center z-20 md:top-[95px] top-[52px]"
          >
            <Loader />
          </div>
        </>
      )}
      {isOpen && <RegCompany isOpen={isOpen} setOpen={setIsOpen} setCompnayData={setCompanyData} updateFormValues={setFormValues}/>}
      <div className="relative flex overflow-x-auto whitespace-nowrap no-scrollbar items-center gap-4 border-gray-700 pb-2">
        {userInfo?.user?.plan?.name === "Standard" ? standaredUserTabs.map((tab, index) => (
          <button
            key={index}
            ref={(el) => (tabsRef.current[index] = el)}
            onClick={() => {
              if (userInfo.company) {
                setActiveTab(index);
              } else {
                dispatch(addToast({ message: 'You need to create company first', type:'error' })); 
              }
            }}
            className={`tab-button py-2 px-4 text-base font-medium outline-none ${
              activeTab === index
                ? "text-red-600 font-semibold"
                : theme === "light"
                ? "text-gray-800 hover:text-black"
                : "text-white"
            }`}
          >
            {tab.label}
          </button>
        )) :
        tabs.map((tab, index) => (
          <button
            key={index}
            ref={(el) => (tabsRef.current[index] = el)}
            onClick={() => {
              if (userInfo.company) {
                setActiveTab(index);
              } else {
                dispatch(addToast({ message: 'You need to create company first', type:'error' })); 
              }
            }}
            className={`tab-button py-2 px-4 text-base font-medium outline-none ${
              activeTab === index
                ? "text-red-600 font-semibold"
                : theme === "light"
                ? "text-gray-800 hover:text-black"
                : "text-white"
            }`}
          >
            {tab.label}
          </button>
        ))}
        {/* Underline */}
        <div
          ref={underlineRef}
          className="absolute bottom-0 h-1 rounded-lg bg-red-600 transition-all duration-300 ease-in-out"
        ></div>
      </div>
      <div className="h-full">
        {activeTab === 0 ? (
          <div
            className={`flex flex-col justify-center items-center w-full  md:mr-5 mr-0 `}
          >
            {!userInfo.company ? (
              <div className="w-full flex items-start">
                <button
                  className="my-4 heroButton"
                  onClick={() => setIsOpen(true)}
                >
                  Create Company
                </button>
              </div>
            ) : (
              <>
                <h2
                  className={`main-heading text-center ${
                    theme === "light" ? "text-black" : ""
                  }`}
                >
                  Update Your Company Data
                </h2>
                <div
                  className={`signup-box max-w-[768px] ${
                    theme === "dark"
                      ? "text-[#ffffff] bg-[#1E1E1E] shadow-2xl"
                      : "text-[#000000] bg-white shadow-2xl"
                  }`}
                >
                  <form onSubmit={handleUpdate} className="">
                    <div className="grid sm:grid-cols-2 grid-cols-1 gap-4">
                      <div className="input-group">
                        <label className="input-label">Name*</label>
                        <input
                          type="text"
                          name="name"
                          value={formValues.name}
                          readOnly
                          placeholder="Enter company name"
                          className={`bg-transparent w-full p-2 border rounded-md ${
                            theme === "dark"
                              ? "text-white border-gray-400"
                              : "text-black border-gray-600"
                          }`}
                        />
                      </div>
                      <div className="input-group">
                        <label className="input-label">Address*</label>
                        <input
                          type="text"
                          name="address"
                          value={formValues.address}
                          onChange={handleChange}
                          placeholder="Enter address"
                          className={`bg-transparent w-full p-2 border rounded-md ${
                            theme === "dark"
                              ? "text-white border-gray-400"
                              : "text-black border-gray-600"
                          }`}
                        />
                      </div>

                      <div className="input-group">
                        <label className="input-label">Postal Code*</label>
                        <input
                          type="text"
                          name="postal_code"
                          value={formValues.postal_code}
                          onChange={handleChange}
                          placeholder="Enter postal code"
                          className={`bg-transparent w-full p-2 border rounded-md ${
                            theme === "dark"
                              ? "text-white border-gray-400"
                              : "text-black border-gray-600"
                          }`}
                        />
                      </div>
                      <div className="input-group">
                        <label className="input-label">City*</label>
                        <input
                          type="text"
                          name="city"
                          value={formValues.city}
                          onChange={handleChange}
                          placeholder="Enter city"
                          className={`bg-transparent w-full p-2 border rounded-md ${
                            theme === "dark"
                              ? "text-white border-gray-400"
                              : "text-black border-gray-600"
                          }`}
                        />
                      </div>
                      <div className="input-group">
                        <label className="input-label">State*</label>
                        <input
                          type="text"
                          name="state"
                          value={formValues.state}
                          onChange={handleChange}
                          placeholder="Enter state"
                          className={`bg-transparent w-full p-2 border rounded-md ${
                            theme === "dark"
                              ? "text-white border-gray-400"
                              : "text-black border-gray-600"
                          }`}
                        />
                      </div>
                      <div className="input-group">
                        <label className="input-label">Country*</label>
                        <input
                          type="text"
                          name="country"
                          value={formValues.country}
                          onChange={handleChange}
                          placeholder="Enter country"
                          className={`bg-transparent w-full p-2 border rounded-md ${
                            theme === "dark"
                              ? "text-white border-gray-400"
                              : "text-black border-gray-600"
                          }`}
                        />
                      </div>
                    </div>
                    <div className="input-group">
                      <label className="input-label">Site URL</label>
                      <input
                        type="text"
                        name="site_url"
                        value={formValues.site_url}
                        onChange={handleChange}
                        placeholder="Enter site URL"
                        className={`bg-transparent w-full p-2 border rounded-md ${
                          theme === "dark"
                            ? "text-white border-gray-400"
                            : "text-black border-gray-600"
                        }`}
                      />
                    </div>
                    <div className="grid sm:grid-cols-2 grid-cols-1 gap-4">
                      <div className="input-group">
                        <label className="input-label">Logo</label>
                        {companyData.logo ? (
                          <>
                            <img
                              src={companyData.logo}
                              alt="Logo Preview"
                              className="w-16 h-16 object-cover"
                            />
                            <button
                              type="button"
                              onClick={() => {
                                const fileInput =
                                  document.getElementById("logo-file-input");
                                if (fileInput) fileInput.click();
                              }}
                              className="heroButton mt-2"
                            >
                              Change
                            </button>
                            <input
                              type="file"
                              id="logo-file-input"
                              name="logo"
                              onChange={handleFileChange("logo")}
                              className="hidden"
                            />
                          </>
                        ) : (
                          <>
                            <img
                              src={noFile}
                              alt="no file selected"
                              className="w-16 h-16 object-cover"
                            />
                            <button
                              type="button"
                              onClick={() => {
                                const fileInput =
                                  document.getElementById("logo-file-input");
                                if (fileInput) fileInput.click();
                              }}
                              className="heroButton mt-2"
                            >
                              Upload
                            </button>
                            <input
                              type="file"
                              id="logo-file-input"
                              name="logo"
                              onChange={handleFileChange("logo")}
                              className="hidden"
                            />
                          </>
                        )}
                      </div>

                      <div className="input-group">
                        <label className="input-label">Stamp</label>
                        {companyData.stamp ? (
                          <>
                            <img
                              src={companyData.stamp}
                              alt="Stamp Preview"
                              className="w-16 h-16 object-cover"
                            />
                            <button
                              type="button"
                              onClick={() => {
                                const fileInput =
                                  document.getElementById("stamp-file-input");
                                if (fileInput) fileInput.click();
                              }}
                              className="heroButton mt-2"
                            >
                              Change
                            </button>
                            <input
                              type="file"
                              id="stamp-file-input"
                              name="stamp"
                              onChange={handleFileChange("stamp")}
                              className="hidden"
                            />
                          </>
                        ) : (
                          <>
                            <img
                              src={noFile}
                              alt="no file selected"
                              className="w-16 h-16 "
                            />
                            <button
                              type="button"
                              onClick={() => {
                                const fileInput =
                                  document.getElementById("stamp-file-input");
                                if (fileInput) fileInput.click();
                              }}
                              className="heroButton mt-2"
                            >
                              Upload
                            </button>
                            <input
                              type="file"
                              id="stamp-file-input"
                              name="stamp"
                              onChange={handleFileChange("stamp")}
                              className="hidden"
                            />
                          </>
                        )}
                      </div>
                    </div>
                    <div className="flex justify-center gap-4">
                      <button
                        className="heroButton w-1/2"
                        type="submit"
                        disabled={loading}
                      >
                        {loading ? "Updating..." : "Update"}
                      </button>
                    </div>
                  </form>
                </div>
              </>
            )}
          </div>
        ) : (
          ""
        )}
        {activeTab === 2 ? <GetRequest /> : ""}
        {activeTab === 3 ? <OfftimeGet /> : ""}
        {activeTab === 1 ? <CompanyTimeoffs /> : ""}
        {activeTab === 4 ? <AdminTimeSheet /> : ""}
        {/*   {activeTab === 5 ? <div className="text-center customFont mt-8">Comming soon...</div> : ""}*/}
      </div>
    </div>
  );
};

export default UpdateCompany;
