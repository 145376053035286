import React from "react";
import { IoTimerSharp } from "react-icons/io5";
import { FaTasks } from "react-icons/fa";
import { GoProjectRoadmap } from "react-icons/go";
import styles from "./styles.module.css";
import { useSelector } from "react-redux";
import { RiTimeFill } from "react-icons/ri";

function FeaturesSection() {
  const { theme } = useSelector((state) => state.theme);
  return (
    <section className={styles.featureMain} id="features">
      <div className="container">
        <h2 className="main-heading">Optimize Workflows</h2>
        <div className={styles.featureContainer}>
          <div className={`${styles.featureContainerDiv} hover:text-white cursor-pointer ${
                    theme === "dark" ?'bg-[#000000]': "bg-[#FFFFFF]"
                  }`}>
            <div className="flex items-center gap-2">
            <RiTimeFill className={`w-10 h-10 rounded-md fill-[#CC0927] ${styles.featureIcon}`}/>
            <h3 className="sub-heading">Time Logging</h3>
            </div>
            <p className="textBase">
              Easily start and stop time tracking for tasks.
            </p>
          </div>
          <div className={`${styles.featureContainerDiv} hover:text-white cursor-pointer  ${
                    theme === "dark" ?'bg-[#000000]': "bg-[#FFFFFF]"
                  }`}>
            <div className="flex items-center gap-2">
            <div
              style={{
                background: "linear-gradient(180deg, #CC0927 0%, #CC0927 100%)",
              }}
              className={`w-9 h-9 flex justify-center items-center iconDark ${theme === "dark" ? "text-black" : "text-white"} rounded-md ${styles.icon}`}
            >
              <FaTasks className="w-[22px] h-[22px]" />
            </div>
            <h3 className="sub-heading">Task Categorization</h3>
            </div>
            <p className="textBase">Organize tasks with custom categories.</p>
          </div>
          <div className={`${styles.featureContainerDiv} hover:text-white cursor-pointer  ${
                    theme === "dark" ?'bg-[#000000]': "bg-[#FFFFFF]"
                  }`}>
            <div className="flex items-center gap-2">
            <GoProjectRoadmap className={`w-10 h-10 fill-[#CC0927] ${styles.featureIcon}`}/>
            <h3 className="sub-heading">Project Tracking</h3>
            </div>
            <p className="textBase">
              Monitor progress across multiple projects.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FeaturesSection;
