import React, { useState } from "react";
import {  FaUserCircle } from "react-icons/fa";
import "react-toastify/dist/ReactToastify.css";
import styles from "./styles.module.css"; 
import { fetchApi } from '../../../utlis/axios';
import { useDispatch, useSelector } from "react-redux";
import { toggleAuth } from "../../../Redux/Slices/auth-slice";
import { addToast } from "../../../Redux/Slices/toast-slice";
import { MdEmail } from "react-icons/md";
import { IoCall } from "react-icons/io5";
import { RiEditFill } from "react-icons/ri";

 const ContactUs = () => {
  const { theme } = useSelector((state) => state.theme);
  const { userInfo } = useSelector((state) => state?.auth || {});
  const dispatch = useDispatch()
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone:"",
    message: "",
  });
  const [isSending, setIsSending] = useState(false);

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  const handleSubmit = async (e) => {    
    e.preventDefault();
    if (!formData.name || !formData.email || !formData.message) {
      dispatch(addToast({ message:'All fields required', type :'error' })); 
    }
setIsSending(true)
    try {
      const response = await fetchApi.post('contact/', formData,);

      if (response.status === 200) {
        dispatch(addToast({ message:'Message sent successfully', type :'success' })); 
        setFormData({
          name: "",
          email: "",
          phone: "",
          message: "",
        });
      } else {
        dispatch(addToast({ message:'Failed to send message please try again', type :'error' })); 
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(toggleAuth({ isLogin: false, userInfo: null }));
        dispatch(addToast({ message : 'Your session expired', type :'error' })); 
        return;
      }
      dispatch(addToast({ message:'Something went wrong', type :'error' })); 
    }finally{
      setIsSending(false)
    }
  };

  return (
    <>
    <style>{`
        .input,
  .textarea {
    width: 100%;
    padding: 0.75rem;
    border-radius: 0.25rem;
    background-color:${theme === 'dark' ? '#333333' : '#ffffff'} ;
    transform: scale(1.05);
    color:${theme === 'dark' ? '#ffffff' : '#000000'};
    border: none;
  }
  .textarea {
    height: 8rem;
    resize: none;
  }
  
    `}</style>
    <div className={styles.contactus} id="contactUs">
      <div className={styles.content}>
        <div className={styles.formContainer}>
          <form className={`${styles.form} ${theme === 'dark' ? 'bg-[#000000]':'bg-[#FFFFFF]'}`} onSubmit={handleSubmit}>

            
      <h2 className="main-heading">Contact Us</h2>
      <p className={styles.introText}>We'd love to hear from you</p>
            <div className={styles.formGroup}>
              <label htmlFor="name" className={`${styles.label}`}>
                <FaUserCircle className='w-6 h-6'/>
                Full Name 
              </label>
              <input
                id="name"
                placeholder="Your full name"
                className="input "
                style={{border:"1px solid red",width:'98%'}}
                value={formData.name}
                onChange={handleInputChange}
              />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="email" className={styles.label}>
                <MdEmail className='w-6 h-6'/>
                Email Address
              </label>
              <input
                id="email"
                type="email"
                placeholder="Your email address"
                className="input"
                value={formData.email}
                onChange={handleInputChange}
                style={{border:"1px solid red",width:'98%'}}
              />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="phone" className={styles.label}>
                <IoCall className="w-6 h-6"/>
                Phone Number
              </label>
              <input 
                id="phone"
                type='tel'
                placeholder="Your phone number"
                className="input"
                value={formData.phone}
                onChange={handleInputChange}      
                style={{border:"1px solid red",width:'98%'}}
              />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="message" className={styles.label}>
                <RiEditFill className='w-6 h-6'/>
                Your Message
              </label>
              <textarea
                id="message"
                placeholder="Write your message"
                className="textarea"
                value={formData.message}
                onChange={handleInputChange}
                style={{border:"1px solid red",width:'98%'}}
              />
            </div>
            <div className='flex w-full justify-end'>
            <button type="submit" className="heroButton" onClick={handleSubmit} disabled={isSending}>
            {isSending ? "Sending..." :'Send'}
            </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    </>
  );
};

export default ContactUs;
