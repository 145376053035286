import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchApi } from "../../../utlis/axios";
import styles from "../users/user.module.css";
import { toggleAuth } from "../../../Redux/Slices/auth-slice";
import { addToast } from "../../../Redux/Slices/toast-slice";

const RegCompany = ({ isOpen, setOpen, updateFormValues, setCompnayData }) => {
  const { theme } = useSelector((state) => state.theme);
  const { userInfo } = useSelector((state) => state?.auth || {});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState({
    name: "",
    site_url: "",
    logo: [],
    stamp: [],
    address: "",
    postal_code: "",
    city: "",
    state: "",
    country: "",
    owner: "",
  });
  const [isChecked, setIsChecked] = useState(false);

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    if (files?.length > 0) {
      // Update state for file inputs
      setFormValues({ ...formValues, [name]: files[0] });
    } else {
      // Update state for other input types
      setFormValues({ ...formValues, [name]: value });
    }
  };

  const handleRegisterCompany = async (e) => {
    e.preventDefault();

    const requiredFields = [
      "name",
      "address",
      "postal_code",
      "city",
      "state",
      "country",
    ];
    for (let field of requiredFields) {
      if (!formValues[field]) {
        dispatch(addToast({ message: `${field.replace("_", " ")} is required!`, type: 'error' }));
        return;
      }
    }

    if (!isChecked) {
      dispatch(addToast({ message: 'Please accept terms and condition', type: 'error' }));
      return;
    }
    setLoading(true);
    try {
      // Handle registration logic here
      const companyData = new FormData();
      companyData.append("name", formValues.name);
      companyData.append("site_url", formValues.site_url);
      if (formValues.logo) companyData.append("logo", formValues.logo);
      if (formValues.stamp) companyData.append("stamp", formValues.stamp);
      companyData.append("address", formValues.address);
      companyData.append("postal_code", formValues.postal_code);
      companyData.append("city", formValues.city);
      companyData.append("state", formValues.state);
      companyData.append("country", formValues.country);
      companyData.append("owner", userInfo?.user?.id);
      const result = await fetchApi.post("companies/", companyData, {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      if (result.status === 201) {
        dispatch(toggleAuth({
          isLogin: true,
          userInfo: {
            ...userInfo,  // Preserve existing data
            company: result.data // Add new key
          }
        }));
        let company = result.data
        setCompnayData(company)
        updateFormValues({
          name: company?.name || "",
          site_url: company?.site_url || "",
          logo: [],
          stamp: [],
          address: company?.address || "",
          postal_code: company?.postal_code || "",
          city: company?.city || "",
          state: company?.state || "",
          country: company?.country || "",
          owner: company?.owner || "",
        });
        dispatch(addToast({ message: 'Company registered', type: 'success' }));
        setOpen(false);
      }
    } catch (error) {
      dispatch(addToast({ message: 'Something went wrong', type: 'error' }));
    } finally {
      setLoading(false);
    }
  };


  const handleSkip = () => {
    setOpen(false);
  };
  return (
    <>
      <style>{`
        .login-box {
          background-color:${theme === "dark" ? "rgba(33, 33, 33, 0.9)" : "#dfdfdf"
        };
          color:${theme === "dark" ? "#ffffff" : "#000000"};
        }
        .input-field {
          background-color:${theme === "dark" ? "#444" : "#ffffff"};
          color:${theme === "dark" ? "#ffffff" : "#000000"};
        }
        .forgot-link, .terms-text {
          color:${theme === "dark" ? "#ccc" : "#000000"};
        }
        .terms-link {
          color:${theme === "dark" ? "#ffffff" : "#ff2f2f"};
        }
      `}</style>

      <div className={styles.modalOverlay} style={{ zIndex: 9999 }}>
        <div
          className={`${styles.modal} sm:mt-auto mt-[25rem] ${theme === "dark" ? "" : " text-black"
            }`}
          style={{ width: "max-content", zIndex: 10000 }}
        >
          <div className="flex justify-center max-w-screen-md">
            <div
              className={`signup-box z-50 ${theme === "dark"
                  ? "text-[#ffffff] bg-[#1E1E1E]"
                  : "text-[#000000] bg-[#dfdfdf]"
                }`}
            >
              <div className="flex justify-between items-center mb-4">
                <h2 className="main-heading text-center">
                  Register Your Company
                </h2>
                <button
                  className={styles.closeButton}
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  &times;
                </button>
              </div>
              <form onSubmit={handleRegisterCompany} className="">
                <div className="grid sm:grid-cols-2 grid-cols-1 gap-4">
                  <div className="input-group">
                    <label className="input-label">Name*</label>
                    <input
                      type="text"
                      name="name"
                      value={formValues.name}
                      onChange={handleChange}
                      placeholder="Enter company name"
                      className="input-field"
                    />
                  </div>
                  <div className="input-group">
                    <label className="input-label">Logo</label>
                    <input
                      type="file"
                      name="logo"
                      onChange={handleChange}
                      placeholder="logo"
                      className="input-field file-input"
                    />
                  </div>
                  <div className="input-group">
                    <label className="input-label">Stamp</label>
                    <input
                      type="file"
                      name="stamp"
                      onChange={handleChange}
                      placeholder="stamp"
                      className="input-field file-input"
                    />
                  </div>
                  <div className="input-group">
                    <label className="input-label">Address*</label>
                    <input
                      type="text"
                      name="address"
                      value={formValues.address}
                      onChange={handleChange}
                      placeholder="Enter address"
                      className="input-field"
                    />
                  </div>
                  <div className="input-group">
                    <label className="input-label">Postal Code*</label>
                    <input
                      type="text"
                      name="postal_code"
                      value={formValues.postal_code}
                      onChange={handleChange}
                      placeholder="Enter postal code"
                      className="input-field"
                    />
                  </div>
                  <div className="input-group">
                    <label className="input-label">City*</label>
                    <input
                      type="text"
                      name="city"
                      value={formValues.city}
                      onChange={handleChange}
                      placeholder="Enter city"
                      className="input-field"
                    />
                  </div>
                  <div className="input-group">
                    <label className="input-label">State*</label>
                    <input
                      type="text"
                      name="state"
                      value={formValues.state}
                      onChange={handleChange}
                      placeholder="Enter state"
                      className="input-field"
                    />
                  </div>
                  <div className="input-group">
                    <label className="input-label">Country*</label>
                    <input
                      type="text"
                      name="country"
                      value={formValues.country}
                      onChange={handleChange}
                      placeholder="Enter country"
                      className="input-field"
                    />
                  </div>
                </div>
                <div className="input-group">
                  <label className="input-label">Site URL</label>
                  <input
                    type="text"
                    name="site_url"
                    value={formValues.site_url}
                    onChange={handleChange}
                    placeholder="Enter site URL"
                    className="input-field"
                  />
                </div>
                <div className="flex gap-4 items-center mb-2">
                  <input
                    type="checkbox"
                    onClick={(e) => setIsChecked(e.target.checked)}
                    value={isChecked}
                  />
                  <p className="terms-text">
                    By clicking Register, you agree to our{" "}
                    <Link to="/terms_of_service" className="terms-link">
                      Terms of Service
                    </Link>{" "}
                    and{" "}
                    <Link to="/privacy_policy" className="terms-link">
                      Privacy Policy
                    </Link>
                    .
                  </p>
                </div>
                <div className="flex justify-center gap-4">
                  <div
                    className="heroButton w-1/2 bg-gray-400 flex justify-center text-center items-center"
                    onClick={handleSkip}
                  >
                    Skip
                  </div>
                  <button
                    className="heroButton w-1/2"
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? "Loading..." : "Register"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegCompany;
