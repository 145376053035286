import React, { useEffect, useRef, useState } from "react";
import grapesjs from "grapesjs";
import "grapesjs/dist/css/grapes.min.css";
import pluginExport from "grapesjs-plugin-export";
import presetWebpage from "grapesjs-preset-webpage";
import { useSelector } from "react-redux";
import { fetchApi } from "../../../utlis/axios";

const Editor = ({setIsModalOpen, setHtmlText}) => {
  const editorRef = useRef(null);
  const { userInfo } = useSelector((state) => state?.auth || {});
  const { theme } = useSelector((state) => state.theme);
  const [selectedTemplate, setSelectedTemplate] = useState("salarySlip");
  const [templates, setTemplates] = useState([]);

  const handleImageUploadClick = async () => {
    try {
      const [fileHandle] = await window.showOpenFilePicker({
        types: [
          {
            description: "Images",
            accept: { "image/*": [".png", ".jpg", ".jpeg", ".gif", ".webp"] },
          },
        ],
        multiple: false,
      });
  
      if (fileHandle) {
        const file = await fileHandle.getFile();
        uploadImage(file); // Upload function
      }
    } catch (error) {
      console.error("File selection cancelled or failed", error);
    }
  };
  
  const uploadImage = async (file) => {
    const formData = new FormData();
         formData.append("file", file);
         try {
           const res = await fetchApi.post("/attachments/", formData, {
             headers: {
               "Content-Type": "multipart/form-data",
               Authorization: `Bearer ${userInfo.access_token}`,
             },
           });
      if (res.status === 201) {
        insertImageIntoEditor(res.data.file); // Add image to canvas
      }
    } catch (error) {
      console.error("Image upload failed", error);
    }
  };
  // Function to insert image into GrapesJS canvas
  const insertImageIntoEditor = (imageUrl) => {
    if (editorRef.current) {
      const editor = editorRef.current;
        editor.addComponents(`<img src="${imageUrl}" style="max-width:100%; height:auto;" />`);
  
      // Store the image in GrapesJS asset manager
      editor.AssetManager.add({ src: imageUrl });
    }
  };
  useEffect(() => {
    if (!editorRef.current) {
      editorRef.current = grapesjs.init({
        container: "#gjs",
        plugins: [pluginExport, presetWebpage],
        pluginsOpts: {
          [pluginExport]: {},
          [presetWebpage]: {},
        },
        fromElement: false,
        storageManager: { autoload: false },
        assetManager: {
          upload: false, // Disable GrapesJS built-in upload handling
          autoAdd: false, // Prevent auto-adding
        },
      });

      // Add custom button for image upload
      editorRef.current.Panels.addButton("options", {
        id: "custom-image-upload",
        className: "fa fa-image",
        command: "open-image-upload",
        attributes: { title: "Upload Image" },
      });

      // Command to trigger file selection
      editorRef.current.Commands.add("open-image-upload", {
        run: () => handleImageUploadClick(),
      });
    }

    // Load selected template
    if (selectedTemplate && templates[selectedTemplate]) {
      editorRef.current.setComponents(templates[selectedTemplate]);
    }
  }, [selectedTemplate]);

  const exportHTML = async () => {
    const editor = editorRef.current;
    const html = editor.getHtml();
    const css = editor.getCss();
    console.log("html: " + html,"css"+css);

    // Convert external CSS to inline styles
    const styledHTML = convertToInlineStyles(html, css);
   setHtmlText(styledHTML)
   setIsModalOpen(true)
};

const convertToInlineStyles = (html, css) => {
   return `
    <style>
    ${css}
    </style>
    ${html}
`
};



  return (
    <div
      className={`w-full p-4 ${
        theme === "dark" ? "bg-[#212529] text-white" : "bg-[#e5e5e5] text-black"
      }`}
    >
      <>
        <div
          id="gjs"
          style={{ height: "500px", border: "1px solid #ddd" }}
          className={`${theme === "dark" ? "darkEditor" : "lightEditor"}`}
        >
        </div>

        <div className="flex justify-center items-center p-2">
          <button onClick={exportHTML} className="heroButton">
            Create Template
          </button>
        </div>
      </>
    </div>
  );
};

export default Editor;
