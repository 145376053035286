
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FaRegEdit, FaSearchDollar,  FaUserCircle } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'


const SalaryRankingTable = ({ data }) => {
    const { theme } = useSelector((state) => state.theme)
    const navigate = useNavigate()

    useEffect(() => {

    }, [])

    const handleApprove = async (item) => {
      navigate('/dashboard/users')
    };


    return (
        <div className={`
        rounded-[20px] shadow-lg p-4 fontPoppins w-full h-full ${theme === "dark" ? "bg-[#1C1C1E] text-white" : "bg-white text-black"}`}>
            <div className='flex justify-between items-center'>
                <div className='flex gap-2 items-center'>
                    <span className='bg-gradient-to-r from-[#CC0927] to-[#7E0105] p-2 rounded-[10px]'>
                        <FaSearchDollar className='w-6 h-6 text-white' />
                    </span>
                    <h1 className='text-lg font-semibold'>Salary Ranking</h1>
                </div>
            </div>
            <div className='mt-6'>
                <div className='overflow-x-auto'>
                    <table className={`w-full text-left h-full`}>
                        <thead>
                            <tr className={` ${theme === 'dark' ? 'bg-[#212529] ' : 'bg-[#F2F4F7] '} text-sm`}>
                                <th className='py-2 px-1'>User</th>
                                <th className='py-2 px-1'>Salary</th>
                                <th className='py-2 px-1'>Actions</th>
                            </tr>
                        </thead>
                        <tbody className={` border  ${theme === 'dark' ? 'border-[#555657]' : ' border-[#D8D8D8]'}`}>
                            {data?.top_earners.length > 0 ? data?.top_earners.map((req, idx) => (
                                <tr key={idx} className='border-b'>
                                    <td className='py-2 pl-2  flex items-center gap-1 whitespace-nowrap'>
                                       
                                        {req?.name}
                                    </td>
                                    <td className='py-2 whitespace-nowrap text-center'>{req?.total_salary}</td>
                                    <td className='py-2 pr-2 flex gap-2 justify-center items-center'>
                                        <div className={` rounded-[10px] border cursor-pointer ${theme === 'dark' ? 'bg-[#252529] border-[#555657]' : 'bg-[#F2F4FA] border-[#D8D8D8]'}`} onClick={() => handleApprove(req)}>
                                           
                                                <FaRegEdit className={`w-4 h-4 ${theme === 'dark' ? 'text-gray-300' : 'text-gray-600'} `} />
                                        </div>
                                    </td>
                                </tr>
                            )) : (
                                <tr>
                                    <td colSpan="4" className="p-2 text-center">No requests found</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    )
}

export default SalaryRankingTable