import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { 
  ResponsiveContainer, 
  AreaChart, 
  Area, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip, 
  Legend,
  Dot
} from "recharts";

// Format date for display based on report type
const formatDateLabel = (dateStr) => {
  if (!dateStr) return "";
  
  // Handle weekly format (week_2025_10)
  if (dateStr.startsWith('week_')) {
    const parts = dateStr.split('_');
    if (parts.length === 3) {
      return `Week ${parts[2]}`;
    }
  }
  
  // Handle daily format (YYYY-MM-DD)
  if (dateStr.match(/^\d{4}-\d{2}-\d{2}$/)) {
    const date = new Date(dateStr);
    return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
  }
  
  // For monthly or other formats, return as is
  return dateStr;
};

// Custom dot component with white border and glow effect
const CustomDot = (props) => {
  const { cx, cy, stroke, payload, value, dataKey } = props;
  
  // Only render dots for non-zero values
  if (value === 0) return null;
  
  return (
    <svg x={cx - 6} y={cy - 6} width={16} height={16}>
      <defs>
        <filter id={`glow-${dataKey}`} x="-50%" y="-50%" width="200%" height="200%">
          {/* Create the outer glow effect */}
          <feGaussianBlur stdDeviation="1.5" result="coloredBlur"/>
          <feMerge>
            <feMergeNode in="coloredBlur"/>
            <feMergeNode in="SourceGraphic"/>
          </feMerge>
        </filter>
        
        {/* Radial gradient for the glow */}
        <radialGradient id={`dotGradient-${dataKey}`}>
          <stop offset="0%" stopColor={stroke} stopOpacity="0.4"/>
          <stop offset="100%" stopColor={stroke} stopOpacity="0"/>
        </radialGradient>
      </defs>
      
      {/* Outer glow circle */}
      <circle
        cx={6}
        cy={6}
        r={5}
        fill={`url(#dotGradient-${dataKey})`}
        filter={`url(#glow-${dataKey})`}
      />
      
      {/* Main dot with white border */}
      <circle 
        cx={6}
        cy={6}
        r={3}
        fill={stroke}
        stroke="white"
        strokeWidth={1.5}
      />
    </svg>
  );
};

// Custom tooltip component
const CustomTooltip = ({ active, payload, label, theme,  }) => {
  if (active && payload && payload.length) {
    return (
      <div className={`p-3 rounded-md ${theme === "dark" ? "bg-[#222]" : "bg-white"} shadow-lg border ${theme === "dark" ? "border-gray-600" : "border-gray-200"}`}>
        <p className="font-semibold mb-1">{label}</p>
        {payload.map((entry, index) => (
          <div key={index} className="flex items-center gap-2 mb-1">
            <div className="w-3 h-3 rounded-full" style={{ backgroundColor: entry.color }}></div>
            <p className={`text-sm ${theme === "dark" ? "text-white" : "text-gray-800"}`}>
              <span className="font-medium">{entry.name}: </span>
              {entry.value}%
            </p>
          </div>
        ))}
      </div>
    );
  }
  return null;
};

const WeeklyProductivity = ({ data, title }) => {
  const {theme} = useSelector((state) => state.theme);
  const [chartData, setChartData] = useState([]);
  
  useEffect(() => {
    
    if (!data || data.length === 0) {
      setChartData([]);
      return;
    }
    
    // Process data for the chart
    const processedData = data.map(item => ({
      date: item.date,
      formattedDate: formatDateLabel(item.date),
      keyboard: item.keyboard || 0,
      mouse: item.mouse || 0
    }));
    
    setChartData(processedData);
  }, [data]);

  return (
    <div className={`py-4 border rounded-[20px] flex-col flex items-center gap-4 h-full ${theme === 'dark' ? 'bg-[#212529] border-[#555657]' : 'bg-[#F2F4F7] border-[#D8D8D8]'}`}>
     <div className="flex justify-start mb-4 px-4 w-full">
     <h2 className="text-lg font-semibold capitalize">{title}</h2>
     </div>

      <ResponsiveContainer width="100%" height='100%'>
        <AreaChart data={chartData} margin={{ top: 0, right: 30, left: 0, bottom: 0 }}>
          {/* Grid */}
          <CartesianGrid strokeDasharray="3 3" stroke={theme === 'dark' ? "#444" : "#ddd"} />

          {/* X & Y Axis */}
          <XAxis dataKey="formattedDate" tick={{ fill: theme === 'dark' ? "white" :'black'}} fontSize={10}/>
          <YAxis 
            tick={{ fill: theme === 'dark' ? "white" :'black'}} 
            domain={[0, 100]} 
            fontSize={14} 
            tickFormatter={(value) => `${value}%`}
          />

          {/* Tooltip */}
          <Tooltip 
            content={<CustomTooltip theme={theme} />}
            cursor={false}
          />

          {/* Gradient Definitions */}
          <defs>
            {/* Keyboard Productivity (Green Gradient) */}
            <linearGradient id="colorKeyboard" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="rgba(111, 209, 149, 0.3)" />
              <stop offset="100%" stopColor="rgba(111, 209, 149, 0.05)" />
            </linearGradient>

            {/* Mouse Productivity (Orange Gradient) */}
            <linearGradient id="colorMouse" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="rgba(255, 174, 76, 0.3)" />
              <stop offset="100%" stopColor="rgba(255, 174, 76, 0.05)" />
            </linearGradient>
          </defs>

          {/* Area Charts with Gradient Fills and Custom Dots */}
          <Area 
            type="monotone" 
            name="Keyboard"
            dataKey="keyboard" 
            stroke="#6FD195" 
            fill="url(#colorKeyboard)" 
            strokeWidth={2} 
            connectNulls={true}
            dot={<CustomDot />}
            activeDot={{ r: 6, stroke: "white", strokeWidth: 2, fill: "#6FD195" }}
          />
          <Area 
            type="monotone" 
            name="Mouse"
            dataKey="mouse" 
            stroke="#FFAE4C" 
            fill="url(#colorMouse)" 
            strokeWidth={2} 
            connectNulls={true}
            dot={<CustomDot />}
            activeDot={{ r: 6, stroke: "white", strokeWidth: 2, fill: "#FFAE4C" }}
          />
        </AreaChart>
      </ResponsiveContainer>

      {/* Add legend */}
      <div className="flex gap-4 justify-center mt-4">
        <div className="flex items-center gap-2">
          <div className="w-3 h-3 bg-[#6FD195] rounded-sm"></div>
          <span className="text-sm">Keyboard</span>
        </div>
        <div className="flex items-center gap-2">
          <div className="w-3 h-3 bg-[#FFAE4C] rounded-sm"></div>
          <span className="text-sm">Mouse</span>
        </div>
      </div>
    </div>
  );
};

export default WeeklyProductivity;
