import React from "react";
import { FaCoins, FaUserCircle } from "react-icons/fa";
import { IoImages } from "react-icons/io5";
import { MdManageHistory, MdManageAccounts } from "react-icons/md";
import { useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import '../heroSection/styles.module.css'

import img1 from "../../../assets/img1.png";
import img2 from "../../../assets/img5.jpg";
import img3 from "../../../assets/img2.jpg";
import img4 from "../../../assets/img3.jpg";
import img5 from "../../../assets/img4.jpg";
import img1l from "../../../assets/img1l.png";
import img2l from "../../../assets/img2l.png";
import img3l from "../../../assets/img3l.png";
import img4l from "../../../assets/img4l.png";
import img5l from "../../../assets/img5l.png";

const Gallery = () => {
  const { theme } = useSelector((state) => state.theme);
  const [selectedTab, setSelectedTab] = React.useState(1);
  const swiperRef = React.useRef(null); // Ref for Swiper instance

  const options = [
    {
      id: 1,
      name: "Administrative Overview",
      icon: <MdManageAccounts className={`w-[26px] h-[26px] transition-colors duration-300 ${selectedTab === 1 ? 'text-white': 'group-hover:text-white'} text-red-700`} />,
      img: theme === 'dark' ? img1 : img1l,
      title: "Comprehensive Administrative Overview",
      desc: `Gain full control over project costs and activities with our detailed Admin and Finance Dashboards. Efficiently manage user performance, track working hours, and streamline financial operations in one comprehensive solution.`,
    },
    {
      id: 2,
      name: "Activity Monitoring",
      icon: <IoImages className={`w-[26px] h-[26px] transition-colors duration-300 ${selectedTab === 2 ? 'text-white': 'group-hover:text-white'} text-red-700`} />,
      img:  theme === 'dark' ? img2 : img2l,
      title: "Activity Monitoring Insights",
      desc: ` Take full control of your team's productivity with our advanced Activity Monitoring. Capture periodic screenshots every 10 minutes, track active time, and analyze mouse and keyboard productivity, all within one comprehensive platform.`,
    },
    {
      id: 3,
      name: "Finance Summary",
      icon: <FaCoins className={`w-[26px] h-[26px] transition-colors duration-300 ${selectedTab === 3 ? 'text-white': 'group-hover:text-white'} text-red-700`} />,
      img:  theme === 'dark' ? img3 : img3l,
      title: 'Financial Summary Insights',
      desc: `Take full control of your financial operations with our detailed Finance Dashboard. Seamlessly manage budget allocations, monitor payments and salaries, and streamline financial reporting, all within one comprehensive platform.`
    },
    {
      id: 4,
      name: "Project Management",
      icon: <MdManageHistory className={`w-[26px] h-[26px] transition-colors duration-300 ${selectedTab === 4 ? 'text-white': 'group-hover:text-white'} text-red-700`} />,
      img:  theme === 'dark' ? img4 : img4l,
      title: 'Project Management Overview',
      desc: `Get a comprehensive view of your projects with our efficient Project Management. View all milestones, manage project tasks, monitor to-do lists, and track the progress of various tickets, all within one powerful platform.`
    },
    {
      id: 5,
      name: "User Operations",
      icon: <FaUserCircle className={`w-[26px] h-[26px] transition-colors duration-300 ${selectedTab === 5 ? 'text-white': 'group-hover:text-white'} text-red-700`} />,
      img:  theme === 'dark' ? img5 : img5l,
      title: 'Comprehensive User Management',
      desc: `Manage all aspects of user interactions with our comprehensive User Operations. Add and edit user profiles, handle overtime requests, approve leave applications, and manage roles, all within one powerful platform.`
    },
  ];
  

  // Handle tab click and sync swiper
  const handleTabClick = (id) => {
    setSelectedTab(id);
    if (swiperRef.current) swiperRef.current.slideTo(id - 1);
  };

  return (
    <div className=" md:px-4 px-2 md:py-8 py-4 flex flex-col justify-center items-center md:gap-6 gap-2 relative gallary">
      {/* Tabs */}
      <div className="flex gap-4 flex-wrap justify-center max-w-[1200px]">
        {options.map((option) => (
         <div
         key={option.id}
         className={`group flex items-center gap-2 px-4 py-3 rounded-[10px] cursor-pointer transition-all duration-300
           ${
             selectedTab === option.id
               ? "bg-gradient-to-r from-[#CC0927] to-[#7E0105] text-white"
               : "border-[#212529] border"
           }
           ${
             selectedTab === option.id
               ? ""
               : "hover:bg-gradient-to-r hover:from-[#CC0927] hover:to-[#7E0105] hover:text-white"
           }`}
         onClick={() => handleTabClick(option.id)}
       >
         {option.icon}
         <p className="text-[14px] font-medium">{option.name}</p>
       </div>
       
        ))}
      </div>

      {/* Slider with buttons outside */}
      <div className={`relative w-full max-w-[1200px] flex items-center justify-center md:gap-6 gap-2`}>
        {/* Left Button */}
        <div className={`swiper-button-prev-custom ${theme === 'dark' ?'bg-[#212529] ':'bg-black/50'} rounded-full p-3 cursor-pointer z-10`}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-6 h-6 text-white"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M15 19l-7-7 7-7"
            />
          </svg>
        </div>

        {/* Swiper Slider */}
        <div className="w-full max-w-[900px] h-[500px] overflow-hidden bg-transparent flex items-center justify-center">
          <Swiper
            modules={[Navigation]}
            slidesPerView={1}
            onSwiper={(swiper) => (swiperRef.current = swiper)} // Bind Swiper instance
            onSlideChange={(swiper) => setSelectedTab(swiper.activeIndex + 1)} // Sync tab on slide change
            navigation={{
              nextEl: ".swiper-button-next-custom",
              prevEl: ".swiper-button-prev-custom",
            }}
          >
            {options.map((option) => (
              <SwiperSlide
                key={option.id}
                className="flex items-center justify-center"
              >
                <img
                  src={option.img}
                  alt={option.name}
                  className="object-contain max-h-[480px] max-w-full rounded-3xl"
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>

        {/* Right Button */}
        <div className={`swiper-button-next-custom ${theme === 'dark' ?'bg-[#212529]':'bg-black/50'} rounded-full p-3 cursor-pointer z-10`}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-6 h-6 text-white"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5l7 7-7 7"
            />
          </svg>
        </div>
      </div>

      <div className="mt-2 text-center max-w-[1024px]">
        
        <h3 className="text-2xl font-semibold">{options.find((option) => option.id === selectedTab)?.title}</h3>
        <p className="text-base font-normal md:mt-2 mt-4">{options.find((option) => option.id === selectedTab)?.desc}</p>
      </div>
    </div>
  );
};

export default Gallery;
