import React, { useState, useEffect, useRef } from "react";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";
import { useSelector } from "react-redux";
import { IoIosArrowDown } from "react-icons/io";

// Format date for display based on report type
const formatDateLabel = (dateStr) => {
  if (!dateStr) return "";
  
  // Handle weekly format (week_2025_10)
  if (dateStr.startsWith('week_')) {
    const parts = dateStr.split('_');
    if (parts.length === 3) {
      return `Week ${parts[2]}, ${parts[1]}`;
    }
  }
  
  // Handle daily format (YYYY-MM-DD)
  if (dateStr.match(/^\d{4}-\d{2}-\d{2}$/)) {
    const date = new Date(dateStr);
    return date.toLocaleDateString('en-US', { 
      weekday: 'long', 
      month: 'short', 
      day: 'numeric' 
    });
  }
  
  // For monthly or other formats, return as is
  return dateStr;
};

const WeeklyActiveTimeChart = ({ data, title}) => {
    const { theme } = useSelector((state) => state.theme);
    const [openDaySelect, setOpenDaySelect] = useState(false);
    const [selectedDay, setSelectedDay] = useState('');
    const [formattedData, setFormattedData] = useState([]);
    const dropdownRef=useRef()

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          setOpenDaySelect(false); // Close dropdown
        }
      };
    
      useEffect(() => {
        if (openDaySelect) {
          document.addEventListener("mousedown", handleClickOutside);
        } else {
          document.removeEventListener("mousedown", handleClickOutside);
        }
        return () => document.removeEventListener("mousedown", handleClickOutside);
      }, [openDaySelect]);
    
    useEffect(() => {
        
        if (!data || data.length === 0) {
            setFormattedData([]);
            setSelectedDay(''); // Reset selected day when data changes
            return;
        }
        
        // Format the data for display
        const processed = data.map(item => ({
            date: item.date,
            formattedDate: formatDateLabel(item.date),
            activeTime: item.activeTime || '0:00',
            percentage: item.percentage || 0
        }));
        
        setFormattedData(processed);
        
        // Set the first day as selected by default
        setSelectedDay(processed[0].date);
    }, [data]);
    
    // Get the current percentage for the selected day
    const currentPercentage = data?.find((item) => item.date === selectedDay)?.percentage || 0;

    // Define color ranges based on percentage
    const getColors = (percentage) => {
      
            return ["#CC0927", "#BEBEBE"]; // Green for high
    };

    const chartData = [
        { name: "Active", value: currentPercentage },
        { name: "Inactive", value: 100 - currentPercentage }
    ];

    const COLORS = getColors(currentPercentage);

    // Get text color based on percentage
    const getTextColor = (percentage) => {
        if(theme === 'dark'){
            return '#FFFFFF'
        }else{
            return '#000000'
        }
    };

    return (
        <div className={`p-6 border rounded-[20px] flex-col flex items-center gap-4 ${theme === 'dark' ? 'bg-[#212529] border-[#555657]' : 'bg-[#F2F4F7] border-[#D8D8D8]'}`}>
            <div className="flex justify-between items-center mb-4 w-full">
                <h1 className="text-lg font-semibold capitalize">{title} %</h1>
                <div className="relative" ref={dropdownRef}>
                    <div
                        className={`border rounded-[10px] gap-4 flex items-center justify-between p-2 min-w-[170px] cursor-pointer ${
                            theme === "dark"
                                ? "bg-[#212529] border-[#555657]"
                                : "bg-[#F2F4F7] border-[#9A9F9A]"
                        }`}
                        onClick={() => setOpenDaySelect(!openDaySelect)}
                    >
                        <span>
                            {selectedDay
                                ? formatDateLabel(selectedDay)
                                : 'Select Day'}
                        </span>
                        <IoIosArrowDown className="w-4 h-4" />
                    </div>
                    
                    {openDaySelect && (
                        <div
                            className={`z-10 absolute mt-1 w-40 border overflow-auto shadow-lg rounded-[10px] max-h-[180px] ${
                                theme === "dark"
                                    ? "bg-[#212529] border-[#555657]"
                                    : "bg-[#F2F4F7] border-[#9A9F9A]"
                            }`}
                            style={{
                                paddingTop: "4px",
                                paddingBottom: "4px",
                            }}
                        >
                            {data.map((day, index) => (
                                <div
                                    key={index}
                                    className={`p-2 cursor-pointer ${
                                        theme === "dark"
                                          ? "hover:bg-gradient-to-r hover:from-[#fff3] hover:to-[#fff0] transition-all"
                                          : "hover:bg-gradient-to-r hover:from-[#6d6d6d33] hover:to-[#fff0] transition-all"
                                      }`}
                                    onClick={() => {
                                        setSelectedDay(day.date);
                                        setOpenDaySelect(false);
                                    }}
                                >
                                    {formatDateLabel(day.date)}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
            
            {/* Chart */}
            <div className="relative w-full h-64">
                <ResponsiveContainer width="100%" height="100%">
                    <PieChart>
                        <Pie
                            data={chartData}
                            cx="50%"
                            cy="50%"
                            innerRadius={70}
                            outerRadius={90}
                            startAngle={90}
                            endAngle={-270}
                            dataKey="value"
                            stroke="none"
                        >
                            {chartData.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index]} />
                            ))}
                        </Pie>
                    </PieChart>
                </ResponsiveContainer>
                <div 
                    className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-3xl font-bold"
                    style={{ color: getTextColor(currentPercentage) }}
                >
                    {currentPercentage.toFixed(0)}%
                </div>
            </div>
            
            {/* Legend */}
            <div className="flex items-center justify-center gap-2 mt-2">
                <span 
                    className="w-3 h-3 inline-block rounded-sm"
                    style={{ backgroundColor: COLORS[0] }}
                ></span>
                <span className="text-sm">Active Time Percentage (%)</span>
            </div>
        </div>
    );
};

export default WeeklyActiveTimeChart;
