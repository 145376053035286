import React from 'react';
import { useSelector } from 'react-redux';
import { FaCheck, FaTimes } from 'react-icons/fa';

const plans = [
  { name: 'Basic', price: '$3.99 / user/month', features: [1, 1, 1, 1, 1, 0, 1, 0, 1, 0, 0, 0,0,0,0,0,0 ,1, 0,0,0,1,0,1,0,0] },
  { name: 'Standard', price: '$5.99 / user/month', features: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,1,0,0,1,0,0,0,1,1,0,1,0] },
  { name: 'Premium', price: '$7.99 / user/month', features: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,1,1,1,1,1,1,1,1,1,0,0,0,0] },
  { name: 'Enterprise', price: 'Fixed Payment', features: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,1,1,1,1,1,1,1,1,1,1,0,0,1] }
];

const features = [
  'Time tracker', 'Activity level', 'Productivity calculation', 'Keyboard & mouse productivity', 'Limited screenshots',
  'Unlimited screenshots', 'Limited reports', 'Unlimited Reports', 'Idle Timeout', 'Company setup', 'Company time-offs',
  'Project management', 'Milestones tracking', 'Task management','Leave Request', "OverTime Tracking",'Timesheets Approval', 'User Profiles', 'Employee invoices', 'Admin Dasboard', 'Finiance and reeports dashboard','Email Support', 'Chat Support', 'Two day Email Support','One day Email Support','Fixed Yearly Payment'
];
const firstList = features.slice(0, 9);  // First 9 items
const secondList = features.slice(9, 14); // Next 5 items
const thirdList = features.slice(14, 19); // Next 5 items
const fourthList = features.slice(19, 21); // Next 2 items
const fifthList = features.slice(21, 26); // Last 5 items


const FeatureTable = () => {
  const { theme } = useSelector(state => state.theme);
  
  return (
    <div className={`md:py-4 py-2 rounded-[30px] w-full max-w-[1200px] border  ${theme === 'dark' ? 'border-red-500  text-white bg-[#000000]' : 'border-gray-300  text-black bg-[#FFFFFF]'}`}>
      {/* Header Row */}
      <div className={`grid grid-cols-5 items-center border-b ${theme === 'dark' ? 'border-red-500':'border-gray-300'} pb-6 mb-4`}>
        <h2 className="md:text-2xl text-lg font-semibold text-center md:px-4 px-1">Features</h2>
          {plans.map((plan, index) => (
            <div key={index} className="text-center flex flex-col sm:items-center">
              <p className="md:text-lg text-base font-semibold text-white py-1 md:px-4 px-1 text-white md:mt-[-40px] sm:mt-[-30px] mt-[-25px] text-xs font-thin  rounded-[20px]" style={{background: 'linear-gradient(90deg, #CC0927 0%, #7E0105 100%)', width:'max-content'}}>{plan.name}</p>
              <p className="md:text-base text-xs white-space-wrap mt-9">{plan.price}</p>
            </div>
          ))}
      </div>
     
      <div className="col-span-5 md:p-3 p-2 md:ml-[6px] ml-[2px] font-semibold my-2 md:text-2xl text-lg font-medium">Activity & Time Tracking</div>
      {firstList.map((feature, idx) => (
  <div key={idx} className={`grid grid-cols-5  ${
      idx % 2 === 0
        ? theme === 'dark'
          ? 'bg-black'
          : 'bg-white' : ""}`}>
    <div className="col-span-1 md:p-3 p-2 md:ml-[6px] ml-[2px] border-r border-red-500">{feature}</div>
    {plans.map((plan, index) => (
      <div key={index} className={`md:p-3 p-2 flex items-center justify-center text-center border-red-500 ${plan.name !== 'Enterprise'? 'border-r':''}`}>
        {plan.features[features.indexOf(feature)] ? <FaCheck className="text-red-500" /> : <FaTimes className="text-gray-500" />}
      </div>
    ))}
  </div>
))}

      <div className="col-span-5 md:p-3 p-2 md:ml-[6px] ml-[2px] font-semibold my-2 md:text-2xl text-lg font-medium">Project & Task Managment</div>
      {secondList.map((feature, idx) => (
  <div key={idx} className="grid grid-cols-5 ">
    <div className="col-span-1 p-3 border-r border-red-500">{feature}</div>
    {plans.map((plan, index) => (
      <div key={index} className={`md:p-3 p-2 flex items-center justify-center text-center border-red-500 ${plan.name !== 'Enterprise'? 'border-r':''}`}>
        {plan.features[features.indexOf(feature)] ? <FaCheck className="text-red-500" /> : <FaTimes className="text-gray-500" />}
      </div>
    ))}
  </div>
))}
<div className="col-span-5 md:p-3 p-2 md:ml-[6px] ml-[2px] font-semibold my-2 md:text-2xl text-lg font-medium">Employee Managment & Request</div>
      {thirdList.map((feature, idx) => (
  <div key={idx} className="grid grid-cols-5 ">
    <div className="col-span-1 md:p-3 p-2 md:ml-[6px] ml-[2px] border-r border-red-500">{feature}</div>
    {plans.map((plan, index) => (
      <div key={index} className={`md:p-3 p-2 flex items-center justify-center text-center border-red-500 ${plan.name !== 'Enterprise'? 'border-r':''}`}>
        {plan.features[features.indexOf(feature)] ? <FaCheck className="text-red-500" /> : <FaTimes className="text-gray-500" />}
      </div>
    ))}
  </div>
))}
<div className="col-span-5 md:p-3 p-2 md:ml-[6px] ml-[2px] font-semibold my-2 md:text-2xl text-lg font-medium">Admin & Finicial Control</div>
      {fourthList.map((feature, idx) => (
  <div key={idx} className="grid grid-cols-5 ">
    <div className="col-span-1 md:p-3 p-2 md:ml-[6px] ml-[2px] border-r border-red-500">{feature}</div>
    {plans.map((plan, index) => (
      <div key={index} className={`md:p-3 p-2 flex items-center justify-center text-center border-red-500 ${plan.name !== 'Enterprise'? 'border-r':''}`}>
        {plan.features[features.indexOf(feature)] ? <FaCheck className="text-red-500" /> : <FaTimes className="text-gray-500" />}
      </div>
    ))}
  </div>
))}
<div className="col-span-5 md:p-3 p-2 md:ml-[6px] ml-[2px] font-semibold my-2 md:text-2xl text-lg font-medium">Support & Communication</div>
      {fifthList.map((feature, idx) => (
  <div key={idx} className="grid grid-cols-5 ">
    <div className="col-span-1 md:p-3 p-2 md:ml-[6px] ml-[2px] border-r border-red-500">{feature}</div>
    {plans.map((plan, index) => (
      <div key={index} className={`md:p-3 p-2 flex items-center justify-center text-center border-red-500 ${plan.name !== 'Enterprise'? 'border-r':''}`}>
        {plan.features[features.indexOf(feature)] ? <FaCheck className="text-red-500" /> : <FaTimes className="text-gray-500" />}
      </div>
    ))}
  </div>
))}
    </div>
  );
};

export default FeatureTable;
