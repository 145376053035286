import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Line,
} from "recharts";
import { FaArrowTrendUp } from "react-icons/fa6";
import { LuCalendarDays } from "react-icons/lu";
import loader from '../../../../assets/loading-gif.gif'

const BugTrackPaymentChart = ({chartData, getTotalInvoices}) => {
  const { theme } = useSelector((state) => state.theme);
  const { userInfo } = useSelector((state) => state?.auth || {});
  const [isOpen, setIsOpen] = useState(false);
  const [years, setYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const dropdownRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const startYear = currentYear - 8;
    const futureYear = currentYear + 2;
    const allYears = Array.from({ length: futureYear - startYear + 1 }, (_, i) => startYear + i);
    setYears(allYears);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleYearChange =async (year) => {
    setIsLoading(true)
    await getTotalInvoices(year)
    setIsLoading(false)
  }

  const calculateYDomain = () => {
    if (chartData.length === 0) return [0, 100];
    const maxValue = Math.max(...chartData.map(item => item.value));
    const minValue = Math.min(...chartData.map(item => item.value));
    const padding = maxValue * 0.2;
    return [Math.max(0, minValue - padding), Math.ceil(maxValue + padding)];
  };

  const CustomDot = (props) => {
    const { cx, cy, stroke } = props;
    return (
      <svg x={cx - 8} y={cy - 8} width={16} height={16}>
        <defs>
          <filter id="dot-glow" x="-50%" y="-50%" width="200%" height="200%">
            <feGaussianBlur stdDeviation="3" result="blur" />
            <feMerge>
              <feMergeNode in="blur" />
              <feMergeNode in="SourceGraphic" />
            </feMerge>
          </filter>
        </defs>
        <circle cx={8} cy={8} r={8} fill="rgba(204, 9, 39, 0.50)" filter="url(#dot-glow)" />
        <circle cx={8} cy={8} r={4} fill={stroke} stroke="white" strokeWidth={1.5} />
      </svg>
    );
  };  

  // Format month names to show just the first letter
  const formatMonthTick = (tick) => {
    return tick.slice(0, 3);
  };

  return (
    <div className={`p-6 rounded-xl customFont ${
      theme === "dark" ? "bg-[#1C1C1E] text-white" : "bg-white text-black"
    }`}>
      {/* Header */}
      <div className="flex justify-between items-center mb-4">
        <div className="flex gap-2 items-center">
          <span className="bg-gradient-to-r from-[#CC0927] to-[#7E0105] p-2 rounded-[10px]">
            <FaArrowTrendUp className="w-6 h-6 text-white" />
          </span>
          <h1 className="text-lg font-semibold">Admin Payment to Bugtrackin</h1>
        </div>
        <div className="flex gap-2">
          <div className="relative inline-block">
            {/* Calendar Icon Button */}
            <div
              className={`p-2 flex items-center justify-center rounded-[10px] border cursor-pointer ${
                theme === "dark" ? "bg-[#252529] border-[#555657]" : "bg-[#F2F4FA] border-[#D8D8D8]"
              }`}
            >
              {isLoading ? (
                <img src={loader} alt="loading" className="w-6 h-6" />
              ) :
              
              <LuCalendarDays className="w-6 h-6" onClick={() => setIsOpen(!isOpen)}/> }
            </div>

            {/* Year Dropdown */}
            {isOpen && (
              <div
                ref={dropdownRef}
                className={`absolute right-0 mt-2 p-3 w-48 rounded-lg shadow-lg z-50 ${
                  theme === "dark" ? "bg-[#1C1C1E] text-white" : "bg-white text-black border border-gray-300"
                }`}
              >
                <h3 className="text-sm font-semibold mb-2">Select Year</h3>
                <div className="grid grid-cols-3 gap-2">
                  {years.map((year) => (
                    <button
                      key={year}
                      onClick={() => {
                        setSelectedYear(year);
                        
                        setIsOpen(false);
                        handleYearChange(year)
                      }}
                      className={`px-3 py-1 rounded-full text-sm transition ${
                        selectedYear === year
                          ? "bg-red-800 text-white font-semibold"
                          : theme === "dark"
                          ? "hover:bg-red-700"
                          : "hover:bg-red-700"
                      }`}
                    >
                      {year}
                    </button>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      
      <div
        className={`pt-2 pb-2 px-2 border w-full rounded-[20px] flex items-center gap-1 ${
          theme === "dark"
            ? "bg-[#212529] border-[#555657]"
            : "bg-[#F2F4F7] border-[#D8D8D8]"
        }`}
      >
        <div className="w-[10px] h-full flex items-center justify-center">
          <span
            className={`origin-center -rotate-90 whitespace-nowrap customFont ${
              theme === "dark" ? "text-[#FFFFFF]" : "text-[#000000]"
            }`}
          >
            Amount ($)
          </span>
        </div>
        <ResponsiveContainer width="100%" height={350}>
          <AreaChart
            data={chartData}
            margin={{ top: 10, right: 30, left: 0, bottom: 20 }} // Increased bottom margin
          >
            <CartesianGrid
              strokeDasharray="3 3"
              stroke={theme === "dark" ? "#444" : "#ddd"}
              horizontal={true}
              vertical={false} // Only show horizontal grid lines
            />
            
            <XAxis
              dataKey="name"
              tick={{ fill: theme === "dark" ? "white" : "black" }}
              tickLine={{ stroke: theme === "dark" ? "#fff" : "#000" }} // Visible tick line
              axisLine={{ stroke: theme === "dark" ? "#fff" : "#000" }} // Visible axis line
              tickFormatter={formatMonthTick} // Show only first letter of month
              interval={0} // Show all ticks
              height={40} // Give more space for ticks
              padding={{ left: 20, right: 20 }} // Add padding
            />
            
            <YAxis
              domain={[0, (dataMax) => Math.max(dataMax * 1.1, 1000)]}
              tick={{ fill: theme === "dark" ? "white" : "black" }}
              tickFormatter={(value) => `$${value}`}
              tickLine={{ stroke: theme === "dark" ? "#fff" : "#000" }} // Visible tick line
              axisLine={{ stroke: theme === "dark" ? "#fff" : "#000" }} // Visible axis line
            />
            
            <Tooltip
              contentStyle={{
                backgroundColor: theme === "dark" ? "#1C1C1E" : "#FFFFFF",
                borderColor: theme === "dark" ? "#FFFFFF" : "#D8D8D8",
                borderRadius: "6px",
              }}
              formatter={(value) => [`$${value}`, "Amount"]}
              labelFormatter={(value) => `Month: ${value}`} // Full month name in tooltip
            />
            
            <defs>
              <linearGradient id="lightGradient" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor="rgba(204, 9, 39, 0.3)" />
                <stop offset="100%" stopColor="rgba(204, 9, 39, 0.05)" />
              </linearGradient>
              <linearGradient id="darkGradient" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor="rgba(255, 77, 77, 0.3)" />
                <stop offset="100%" stopColor="rgba(255, 77, 77, 0.05)" />
              </linearGradient>
            </defs>

            <Area
              type="monotone"
              dataKey="value"
              stroke={theme === "dark" ? "#FF4D4D" : "#CC0927"}
              fill={theme === "dark" ? "url(#darkGradient)" : "url(#lightGradient)"}
              strokeWidth={2}
              dot={<CustomDot />}
              activeDot={{ r: 6 }}
              connectNulls={true}
              baseValue={0}
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
      
      <div className="w-full my-2 flex justify-center items-center gap-1 relative">
        <div className="flex items-center gap-1 relative">
          <span className="bg-red-600 w-10 h-1 rounded-md absolute ml-[-14px]"></span>
          <span className="bg-red-600 w-3 h-3 rounded-full border-2 border-white z-10"></span>
        </div>
        <span className="text-center ml-4">{selectedYear}</span>
      </div>
    </div>
  );
};

export default BugTrackPaymentChart;