import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Default styling for React Quill
import "react-quill/dist/quill.bubble.css"; // Optional styling
import { useSelector } from "react-redux";

const RichTextEditor = ({ description, setDescription,setEditorOpen,handleSave }) => {
 const {theme}=useSelector((state)=>state.theme)
 const previousDescription = description

  const handleCancel = () => {
    setDescription(previousDescription)
   setEditorOpen(false)
  };

  // Custom styling for dark theme
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      ["bold", "italic", "underline", "strike"], // Formatting buttons
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "code-block"],
      [{ align: [] }],
      
      ["clean"], // Remove formatting
    ],
  };

  const editorStyles = {
    backgroundColor: theme === "dark" ? "transparent" : "transparent",
    color: theme === "dark" ? "#ffffff" : "#000000",
    border: "1px solid",
    borderColor: theme === "dark" ? "#444444" : "#dddddd",
    borderRadius: "10px",
    
  };

  const buttonStyles = {
    container: {
      display: "flex",
      justifyContent: "flex-start",
      gap: "10px",
      marginTop: "10px",
    },
    button: {
      padding: "10px 20px",
      borderRadius: "5px",
      border: "none",
      cursor: "pointer",
      fontSize: "16px",
    },
    save: {
      backgroundColor: theme === "dark" ? "#4caf50" : "#007bff",
      color: "#ffffff",
    },
    cancel: {
      backgroundColor: theme === "dark" ? "#f44336" : "#d9534f",
      color: "#ffffff",
    },
  };

  return (
    <div className={theme === 'dark' ? 'quill-dark-theme' : 'quill-light-theme'}>
      <ReactQuill
        value={description}
        onChange={setDescription}
        modules={modules}
        theme="snow"
        style={editorStyles}
       className="custom-border"
      />
      <div style={buttonStyles.container}>
        <button
          className="heroButton"
          onClick={handleSave}
        >
          Save
        </button>
        <button
         
          onClick={handleCancel}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default RichTextEditor;
