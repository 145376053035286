import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IoIosArrowDown } from "react-icons/io";
import { fetchApi } from "../../../../utlis/axios";
import { updateSelectedUser } from "../../../../Redux/Slices/users-slice";
import { updateUserId } from "../../../../Redux/Slices/users-slice";
import { FaUserCircle } from "react-icons/fa";
import loader from '../../../../assets/loading-gif.gif'
import { FaRegCircleUser } from "react-icons/fa6";
import { addToast } from "../../../../Redux/Slices/toast-slice";


const UserCard = ({ user }) => {
  const { theme } = useSelector((state) => state.theme);
  const { userId, users, nextPage, selectedUser } = useSelector(
    (state) => state?.allUsers || {}
  );
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state?.auth || {});
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [next, setNext] = useState(null);
  const [usersList, setUsersList] = useState([]);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const dropdownRef = useRef(null);
  const userDropdownRef = useRef(null);

  const [data, setUserData] = useState({
    "user": "",
    "payment_type": "monthly",
    "currency": "USD",
    "total_tracked_hours_last_month": 0,
    "last_month_salary": 0,
    "total_tracked_hours_current_month": 0,
    "upcoming_month_salary": 0
  });

  // Initialize usersList with users from Redux
  useEffect(() => {
    setUsersList(users);
    setNext(nextPage);
  }, [users, nextPage]);

  const fetchUserData = async () => {
    if (!selectedUser?.id) return;
    
    setIsRefreshing(true)
    try {
      const res = await fetchApi.get(`finance-dashboard-user-salary/?id=${selectedUser.id}`, {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`
        }
      });
      if (res.status === 200) {
        setUserData({
          user: res.data.user,
          payment_type: res.data.payment_type,
          currency: res.data.currency,
          total_tracked_hours_last_month: res.data.total_tracked_hours_last_month,
          last_month_salary: res.data.last_month_salary,
          total_tracked_hours_current_month: res.data.total_tracked_hours_current_month,
          upcoming_month_salary: res.data.upcoming_month_salary
        });
      }
    } catch (error) {
      dispatch(addToast({ message: error.message, type: 'error' }));
    } finally {
     setIsRefreshing(false)
    }
  };

  useEffect(() => {
    fetchUserData();
  }, [selectedUser]);

  const handleUsersScroll = async (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    const scrollThreshold = 50; // pixels from bottom to trigger load

    // Check if we're near the bottom and there's more to load
    if (scrollHeight - (scrollTop + clientHeight) < scrollThreshold && next && !isLoading) {
      await fetchNextUsers(next);
    }
  };

  const fetchNextUsers = async (url) => {
    if (!url || isLoading) return;
    
    setIsLoading(true);
    try {
      const res = await fetchApi.get(url, {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });
      if (res.status === 200) {
        setUsersList(prev => [...prev, ...res.data.results]);
        setNext(res.data.next);
      }
    } catch (error) {
      console.error('Error fetching more users:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = async (e) => {
    if (e.key === 'Enter') {
      setIsSearching(true);
      try {
        const res = await fetchApi.get(`users/?search=${searchQuery}`, {
          headers: {
            Authorization: `Bearer ${userInfo.access_token}`,
          },
        });
        if (res.status === 200) {
          setUsersList(res.data.results);
          setNext(res.data.next);
        }
      } catch (error) {
        console.error('Search error:', error);
      } finally {
        setIsSearching(false);
      }
    }
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchQuery(value);
    
    if (!value.trim()) {
      setUsersList(users);
      setNext(nextPage);
    }
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className={`rounded-[20px] shadow-lg p-4 ${theme === "dark" ? "bg-[#1C1C1E] text-white" : "bg-white text-black"}`}>
      {/* ... rest of your JSX remains the same until the dropdown ... */}
      <div className="flex items-center justify-between">
        <div className='flex gap-2 items-center'>
          <span className='bg-gradient-to-r from-[#CC0927] to-[#7E0105] p-2 rounded-[10px]'>
            <FaRegCircleUser className='w-6 h-6 text-white' />
          </span>
          <h1 className='text-lg font-semibold fontPoppins'>User Salary</h1>
        </div>
      <div className="relative" ref={dropdownRef}>
        <div
          className={`border rounded-[10px] gap-4 flex items-center justify-between p-2 min-w-[200px] cursor-pointer ${
            theme === "dark"
              ? "bg-[#212529] border-[#555657]"
              : "bg-[#F2F4F7] border-[#9A9F9A]"
          }`}
          onClick={() => setIsOpen(!isOpen)}
        >
          <span>
            {selectedUser
              ? selectedUser?.first_name + " " + selectedUser?.last_name
              : "Select User"}
          </span>
          { isRefreshing ? <img src={loader} className="w-4 h-4" alt="loading"/>: 
          <IoIosArrowDown className="w-4 h-4" />}
        </div>
        {isOpen && (
          <div
            className={`z-10 absolute mt-1 w-full border overflow-auto shadow-lg rounded-[10px] max-h-[280px] ${
              theme === "dark"
                ? "bg-[#212529] border-[#555657]"
                : "bg-[#F2F4F7] border-[#9A9F9A]"
            }`}
            onScroll={handleUsersScroll}
          >
            <div className="flex items-center justify-between w-full">
              <input 
                type="text"  
                className={`p-2 pl-8 bg-transparent border-b outline-none w-full ${
                  theme === "dark" ? "border-[#555657]" : "border-[#D8D8D8]"
                }`}
                value={searchQuery}
                onChange={handleSearchChange}
                onKeyDown={handleSearch}
                placeholder="Search users..."
              />
              {isSearching && (
                <div className="absolute left-2 top-1/2 -translate-y-1/2">
                  <img src={loader} alt="Searching..." className="w-4 h-4" />
                </div>
              )}
            </div>
            {usersList?.map((user) => (
              <div
                key={user.id}
                className={`p-2 cursor-pointer hover:bg-opacity-10 hover:bg-gray-500 flex items-center gap-2 ${
                  selectedUser?.id === user.id ? "bg-opacity-10 bg-gray-500" : ""
                } ${
                  theme === "dark"
                    ? "hover:bg-gradient-to-r hover:from-[#fff3] hover:to-[#fff0] transition-all"
                    : "hover:bg-gradient-to-r hover:from-[#6d6d6d33] hover:to-[#fff0] transition-all"
                }`}
                onClick={() => {
                  dispatch(updateSelectedUser(user));
                  dispatch(updateUserId(user.id));
                  setIsOpen(false);
                }}
              >
                {user.picture ? (
                  <img 
                    src={user.picture} 
                    alt={`${user.first_name}`}
                    className="w-6 h-6 rounded-full object-cover"
                  />
                ) : (
                  <FaUserCircle className="w-6 h-6 text-gray-400" />
                )}
                <span>
                  {user.first_name} {user.last_name}
                </span>
              </div>
            ))}
            {isLoading && (
              <div className="flex justify-center p-2">
                <img src={loader} alt="Loading..." className="w-6 h-6" />
              </div>
            )}
            {!next && usersList.length > 0 && (
              <div className="text-center p-2 text-sm text-gray-400">
                No more users to load
              </div>
            )}
          </div>
        )}
      </div>
      </div>
      {/* ... rest of your JSX remains the same ... */}

      <div className="flex flex-col items-center py-4">
        <div className="relative">
          <div className={`w-28 h-28 p-1 rounded-full flex items-center justify-center border-4 ${theme === "dark" ? "border-red-800" : "border-red-800"} + " overflow-hidden`}>
            {selectedUser.picture ? <img src={selectedUser.picture} alt="user profile" className="h-[95%] object-cover rounded-full w-[95%" /> :
              <FaUserCircle className="w-full h-full text-gray-400" />}
          </div>
          <div className={`absolute bottom-0 left-1/2 -translate-x-1/2 px-4 py-1 text-sm rounded-full capitalize ${theme === "dark" ? " text-white" : " text-white"}`} style={{ background: 'linear-gradient(90deg, #CC0927 0%, #7E0105 100%)' }}>{data?.payment_type}</div>
        </div>
        <h2 className="mt-2 font-bold text-xl"> {selectedUser
          ? selectedUser?.first_name + " " + selectedUser?.last_name
          : "Select User"}</h2>
        <p className={`text-base ${theme === "dark" ? "text-gray-200" : "text-gray-600"}`}>{data?.user}</p>
      </div>

      <div className={`p-4 mt-4 rounded-xl border fontPoppins  ${theme === "dark" ? "bg-[#212529] border-[#555657] text-white" : "bg-[#F2F4F7] border-[#D8D8D8] text-black"}`}>
        <p className="text-base font-semibold">Detailed User Salary Information</p>
        <div className="mt-2 grid grid-cols-2 gap-2 text-sm">
          <span className="text-gray-400">Currency</span>
          <span className="text-right">{data?.currency}</span>
          <span className="text-gray-400">Last Month Salary</span>
          <span className="text-right">{data?.last_month_salary}</span>
          <span className="text-gray-400">Upcoming Month Salary</span>
          <span className="text-right">{data?.upcoming_month_salary}</span>
        </div>
      </div>

    </div>
  );
};

export default UserCard;
