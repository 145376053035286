import React from "react";
import { FaUserCircle } from "react-icons/fa";
import { IoEyeOff } from "react-icons/io5";
import { IoEyeSharp } from "react-icons/io5";
import { useSelector } from "react-redux";

export default function UserProfileCard({
  data,
  setUserProfileModal,
  setIsModalOpen,
  updateData,
}) {
  const { theme } = useSelector((state) => state.theme);
  const isDark = theme === "dark";

  return (
    <div className={`w-[340px]  ${isDark ? " text-white" : " text-black"}`}>
      <div className="flex flex-col items-center">
        <div className="relative">
          {data?.picture ? (
            <img
              src={data?.picture}
              alt="profile"
              className="w-20 h-20 rounded-full"
            />
          ) : (
            <FaUserCircle className="w-20 h-20 rounded-full text-gray-600" />
          )}
          <div className="absolute whitespace-nowrap -bottom-1 left-1/2 -translate-x-1/2  text-white text-xs px-2 py-0.5 rounded-full" style={{background: 'linear-gradient(90deg, #CC0927 0%, #7E0105 100%)'
}}>
            {data?.role?.name}
          </div>
        </div>
        <h2 className="mt-2 text-lg font-semibold">
          {data?.first_name} {data?.last_name}
        </h2>
        <p className="text-sm text-gray-500 dark:text-gray-400">
          {data?.email}
        </p>
      </div>

      {/* Info fields */}
      <div className="mt-6 space-y-4">
        {/* Password */}
        <div>
          <label className="block text-lg font-medium mb-1">Password</label>
          <div className="relative">
            <input
              type="password"
              value="12345678"
              disabled
              className={`w-full border-none outline-none bg-transparent ${
                isDark ? "text-gray-300" : "text-gray-600"
              }`}
            />
            <IoEyeSharp
              size={16}
              className="absolute right-0 top-1/2 -translate-y-1/2 text-gray-400"
            />
          </div>
          <div className="w-full h-px bg-gray-300 dark:bg-gray-600 mt-2" />
        </div>

        {/* Salary */}
        <div>
          <label className="block text-lg font-medium mb-1">Salary</label>
          <input
            type="text"
            value={data?.payment_amount}
            disabled
            className={`w-full border-none outline-none bg-transparent ${
              isDark ? "text-gray-300" : "text-gray-600"
            }`}
          />
          <div className="w-full h-px bg-gray-300 dark:bg-gray-600 mt-2" />
        </div>

        {/* Project */}
        <div>
          <label className="block text-lg font-medium mb-1">Project</label>
          <input
            type="text"
            value={data?.project?.name || "N/A"}
            disabled
            className={`w-full border-none outline-none bg-transparent ${
              isDark ? "text-gray-300" : "text-gray-600"
            }`}
          />
          <div className="w-full h-px bg-gray-300 dark:bg-gray-600 mt-2" />
        </div>
      </div>

      {/* Button */}
      <button
        className="w-full mt-6 heroButton"
        onClick={() => {
          updateData(data);
          setUserProfileModal(false);
          setIsModalOpen(true);
        }}
      >
        Edit User
      </button>
    </div>
  );
}
