import React, { useState } from "react";
import AnimationLottie from "../../../lottie/animation-lottie";
import workingAnimation from "../../../lottie/workingAnimation.json";
import styles from "./styles.module.css";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import heroAnimation from '../../../lottie/heroAnimation.json'

const HeroSection = () => {
  const { isLogin, userInfo } = useSelector((state) => state?.auth || {});
  const getSubdomain = () => {
    const host = window.location.host;
    if (host.includes("localhost")) {
      return host.split(".localhost")[0];
    } else if (host.includes(".bugtrackin")) {
      return host.split(".bugtrackin.com")[0];
    }
    return null;
  };

  const subdomain = getSubdomain();
  return (
    <section className={`${styles.heroSection} mt-[150px]`}>
      <div className={styles.heroContainer}>
        <div className={styles.heroText}>
          <h2 className="main-heading">Work Smarter, Track Better</h2>
          <p>
          Effortlessly monitor work hours, track productivity, manage tasks, and streamline remote team operations—all in one powerful platform. BugTrackin ensures seamless workflow, accurate time tracking, and hassle-free performance analysis, making online work smoother than ever.
          </p>
          <Link
            to={`${
              isLogin && subdomain === userInfo?.user?.domain
                ? "/dashboard/summary"
                : "/login"
            }`}
            className="heroButton"
          >
            Start Logging Time
          </Link>
        </div>
        <div className={styles.heroAnimation}>
          <AnimationLottie animationPath={heroAnimation} />
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
