import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    summaryDate: null,
    summaryUserId: null,
    summaryData:null
  };

  const summaryDataSlice = createSlice({
    name: 'summaryData',
    initialState,
    reducers: {
      togglesummaryData: (state, { payload }) => {
        state.summaryDate = payload?.summaryDate;
        state.summaryUserId = payload?.summaryUserId;
      },
      updateSummaryData : (state, { payload }) => {
        state = payload
    },
  }});
  export const { togglesummaryData,updateSummaryData } = summaryDataSlice.actions;

  export default summaryDataSlice.reducer;
  